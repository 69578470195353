import React, {FC, useEffect, Suspense, useState} from 'react';

import Modal from "react-bootstrap/cjs/Modal";
import {Trip, TripActivity, TripActivityResponse, TripDestination} from "../../../interfaces/trip.interface";

import '../planninginfo.scss'

import {eSMoment, formatBytes, getUrlFromBucket} from "../../../util";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";



import JPGImg from '../../../images/illustration-icons/file-types/jpeg-icon.svg';
import DocIconImg from '../../../images/illustration-icons/file-types/doc-icon.svg';
import PdfIconImg from '../../../images/illustration-icons/file-types/pdf-icon.svg';
import PngIconImg from '../../../images/illustration-icons/file-types/png-icon.svg';
import BackButtonImgIcon from "../../../images/icons/back-button.svg";
import DownloadImgIcon from "../../../images/icons/download.svg";
import http from "../../../services/api";
import {trip_url} from "../../../services/api.utils";
import Loader from "../../loader/Loader";



const PdfViewer = React.lazy(() => import('../../../utils/pdfViewer'));

//activity


const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}

type Props = {
    destination:TripDestination,
    tripData:Trip,
    updateTripDestination:any
}

const TripPlanningInfo: FC <Props> = ({destination,tripData,updateTripDestination}) => {

    const [isFileLoading, setIsFileLoading] = useState(false);
    const [showFileModel, setFileModel] = useState(false);
    const [selectedFile, setSelectedFile] = useState<{folder: string,
        generatedName:string,
        size:string,
        originalName:string,
        createdAt:string,
        extension?:string
        url: string,
        contentType?: string,
    }>();

    const { width } = useViewport();
    const breakpoint = 620;



    const selectFile = async (invoice:any, extension:any, type:any) =>{

        setIsFileLoading(true)
        let contentType =  'image/png';


        if(extension == "pdf"){

            contentType = 'application/pdf'
        }else if(extension == "doc"){
            contentType = 'application/msword'

        }else if(extension == "docx"){
            contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

        }else if(extension == "jpeg"){
            contentType = 'image/jpeg'

        }else if(extension == "jpg"){
            contentType = 'image/jpg'

        }else if(extension == "png") {
            contentType = 'image/png'
        }

        setFileModel(true)




        http
            .post<null, any>(`/signed/file`,{key: `${tripData._id}/${type}s/${invoice.generatedName}`})
            .then((res) => {
                if (res) {

                    setSelectedFile({
                        ...invoice,
                        url: res.data,
                        extension:extension,
                        contentType:contentType
                    })

                    setIsFileLoading(false)

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {


            });

    }



    return (

        <div className="planning-info-container mb-10 mb-xl-0">

                <div className="card ">
                    <div className="card-header align-items-center border-0">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark">Invoices</span>
                            <span className="text-gray-400 mt-1 fs-7">View and download all the invoices added</span>
                        </h3>

                    </div>
                    <div className="card-body card-body-tab">





                    </div>
                </div>


            <Modal show={showFileModel}   onHide={() => {
                setFileModel(false)
            }} fullscreen={width >= breakpoint? '': 'sm-down'} size={'xl'}  animation={false}>
                <Modal.Header className={`border-0 pb-0`}>
                    <Modal.Title className={`fw-bolder`}>
                        <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=> setFileModel(false)}/>


                    </Modal.Title>
                    <div>
                        <div className="btn btn-icon btn-sm btn-active-light-primary  p-0" onClick={() => {
                            if(selectedFile){
                                window.open(selectedFile.url, "_blank", "noreferrer");
                            }

                        }}>
                            <img src={DownloadImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=> setFileModel(false)}/>
                        </div>

                    </div>

                </Modal.Header>
                <Modal.Body className={`pb-3 text-center`}>




                    {isFileLoading?
                        <a  className="card card-stretch mb-5 bg-light-info hoverable shadow-none trip-list-select cursor-pointer" style={{ height: '84%'}}>

                            <div className="card-body d-flex flex-column pt-5">

                                <Loader cssClass="normal-loader"/>
                            </div>
                        </a>
                        : selectedFile ? selectedFile.extension == "pdf" ?

                        <PdfViewer url={selectedFile.url} />

                        :selectedFile.extension == "jpg"|| selectedFile.extension == "jpeg"|| selectedFile.extension == "png"  ?


                                <img src={selectedFile.url} className={`w-100`}/>

                        :
                        <DocViewer
                            documents={[
                                { uri: selectedFile.url,
                                fileType: selectedFile.contentType}, // Remote file
                            ]}
                            prefetchMethod="GET"
                            pluginRenderers={DocViewerRenderers}
                            style={{ height: '80vh' }}
                            config={{ header: { disableHeader: true } }}

                        />

                    :''}

                </Modal.Body>
            </Modal>
        </div>





    );
};
export default TripPlanningInfo;
