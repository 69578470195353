import GeneralOptions from "../options/GeneralOptions/GeneralOptions";
const GeneralWidget = (props) => {

  return (
    <div>
      <GeneralOptions actionProvider={props.actionProvider} />
    </div>
  );
};

export default GeneralWidget;