import React, {FC, useEffect, useRef, useState} from 'react';
import {
    NavLink, useNavigate, useLocation
} from "react-router-dom";

import './sidebar.css'

import iconLogo from '../../images/home/logo.png';
import HomeIconImg from '../../images/icons/sidebar/home.svg';
import TourPackage from '../../images/icons/sidebar/tour-packages.png';
import TripsActiveIconImg from '../../images/icons/sidebar/trip-active.gif';
import AddTripIconImg from '../../images/icons/sidebar/add.svg';
import NotificationIconImg from '../../images/icons/sidebar/notification.svg';
import DotIconImg from '../../images/icons/dot.svg';
import NotificationActiveIconImg from '../../images/icons/sidebar/notification-active.gif';
import MessageIconImg from '../../images/icons/sidebar/message.svg';
import MessageActiveIconImg from '../../images/icons/sidebar/message-active.gif';
import ProfileIconImg from '../../images/icons/sidebar/account.svg';
import LogoutIconImg from '../../images/icons/sidebar/logout.svg';
import HelpIconImg from '../../images/icons/help.svg';
import CoinsIconImg from '../../images/icons/coins.png';
import Modal from "react-bootstrap/cjs/Modal";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NewTrip from "../trips/newtrip/NewTrip";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import {useAppDispatch} from "../../store";
import {setUser} from "../account/userSlice";
import io, {Socket} from "socket.io-client";
import {intro_url, register_url, trip_url, user_url, wss_url} from "../../services/api.utils";
import {useSelector} from "react-redux";
import {RootState} from "../../rootReducer";
import {MessageInterface, NewMessageInterface} from "../../interfaces/message.interface";
import {User, UserResponse} from "../../interfaces/user.interface";
import {setMessages} from "../messages/messagesSlice";
import {setAppBasic} from "../../app/appBasicSlice";
import BackButtonImgIcon from "../../images/icons/back-button.svg";
import http from "../../services/api";
import {Trip, TripDocumentResponse} from "../../interfaces/trip.interface";
import AiTripIcon from "../../images/illustration-icons/ai-trip.svg";
import CustomTripIcon from "../../images/illustration-icons/custom-trip.svg";


const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const Sidebar: FC<{socket:Socket,userData:User}> = ({socket,userData}) => {

    const { width } = useViewport();
    const breakpoint = 620;

    console.log("userData",userData)

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { appBasic,user } = useSelector((state: RootState) => state);

    const location = useLocation();

    const sliderRef = useRef<Slider>(null);
    const [currentSlide, setCurrentSlide] = useState<number>(1);

    const next = () => {
        if (sliderRef.current) {
            setCurrentSlide(currentSlide+1)

            sliderRef.current.slickNext();
        }
    };

    const previous = () => {
        if (sliderRef.current) {
            setCurrentSlide(currentSlide-1)

            sliderRef.current.slickPrev();
        }
    };

    const [helpModal, setHelpModal] = useState(false)
    const [createTrip, setCreateTrip] = useState(false)


    useEffect(()=>{

        if(!user?.data.isIntroVisited){

            http
                .post<null, UserResponse>(intro_url, {})
                .then((res) => {
                    if (res) {


                        let resData = res
                        resData.data.accessToken = user?.data.accessToken
                        localStorage.setItem('user', JSON.stringify(resData));


                        dispatch(setUser(resData));


                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {


                });
        }
    },[])


    const logout = () =>{

        localStorage.clear();
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };


    if(width < breakpoint ){


        return (
            <div className="">

                <nav className="navbar fixed-bottom bottombar justify-content-center">
                     <ul className="navbar-nav mr-auto">
{/*
                            <li className="nav-item text-center">
                                    <NavLink  to="/dashboard"  className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    } >
                                        <img src={HomeIconImg} width={30} className={`hover-opacity-btn`}/>
                                        <span>Dashboard</span>
                                    </NavLink>
                            </li>*/}
                         {userData.userType == "business" &&
                             <li className="nav-item text-center">
                                 <NavLink to="/packages" className={({isActive, isPending}) =>
                                     isPending ? "pending" : isActive ? "active" : ""
                                 }>
                                     <img src={TourPackage} width={30} className={`hover-opacity-btn`}/>
                                     <span>Packages</span>
                                 </NavLink>
                             </li>
                         }

                         <li className="nav-item text-center">
                             <NavLink to="/trips/upcoming" className={({isActive, isPending}) =>
                                 isPending ? "pending" : isActive ? "active" : ""
                             }>
                                 <img src={HomeIconImg} width={30} className={`hover-opacity-btn`}/>
                                 <span>Trips</span>
                             </NavLink>
                            </li>

                         <li className="nav-item text-center">
                             <NavLink  to="/messages" className={({ isActive, isPending }) =>
                                 isPending ? "pending" : isActive ? "active" : ""
                             }>
                                 <img src={MessageIconImg} width={30} className={`hover-opacity-btn`}/>
                                 <span>Messages</span>
                             </NavLink>

                         </li>
                            <li className="nav-item text-center">
                                <NavLink   to="/new" className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>
                                    <img src={AddTripIconImg} width={30} className={`hover-opacity-btn`}/>
                                    <span>New</span>
                                </NavLink>
                            </li>
                            <li className="nav-item text-center">
                                    <NavLink  to="/notifications" className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }>
                                        <img src={NotificationIconImg} width={30} className={`hover-opacity-btn`}/>
                                        <span>Notifications</span>
                                    </NavLink>

                            </li>
                            <li className="nav-item text-center">
                                    <NavLink  to="/account" className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }>
                                        <img src={ProfileIconImg} width={30} className={`hover-opacity-btn`}/>
                                        <span>Account</span>
                                    </NavLink>

                            </li>
                        </ul>
                </nav>

            </div>
        );
    }else{
        return (
            <div className="col-md-1 col-lg-1 sidebar-main">

                <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
                    <div className="position-sticky pt-3">
                        <div className="d-flex flex-column align-items-center flex-column-auto py-4 pt-lg-10 pb-lg-7"
                             id="kt_aside_logo">
                            <a href="/">
                                <img src={iconLogo} width={30} />
                            </a>
                        </div>
                        <ul className="nav flex-column">
             {/*               <li className={` ${window.location.pathname == '/dashboard'? 'active': ''} nav-item hover-opacity-btn`}>
                                <OverlayTrigger   placement="right" overlay={<Tooltip id="tooltip-dashboard">Dashboard</Tooltip>}>
                                    <span className="nav-link fs-2" onClick={function (){
                                        navigate('/dashboard')
                                    }}>  <img src={HomeIconImg} width={28} className={``} /></span>
                                </OverlayTrigger>
                            </li>*/}
                            {userData.userType == "business"&&
                                <li className={` ${(window.location.pathname).split("/").length && (window.location.pathname).split("/")[1] == 'packages' || (window.location.pathname).split("/").length && (window.location.pathname).split("/")[1] == 'packages' ? 'active' : ''} nav-item hover-opacity-btn`}>
                                    <OverlayTrigger placement="right" overlay={<Tooltip
                                        id="tooltip-dashboard">{(window.location.pathname).split("/").length && (window.location.pathname).split("/")[1] == 'trips' || (window.location.pathname).split("/").length && (window.location.pathname).split("/")[1] == 'packages' ? 'Packages' : 'Packages'}</Tooltip>}>
                                    <span className="nav-link fs-2" style={{position: "relative"}} onClick={() => {
                                        dispatch(setAppBasic({
                                                trip: {
                                                    hasNotification: false
                                                }
                                            }
                                        ));
                                        navigate('/business/packages')
                                    }}>  <img src={TourPackage} width={28} className={``}/>

                                        {appBasic && appBasic.trip?.hasNotification ?
                                            <img src={DotIconImg}
                                                 style={{position: "absolute", top: "-6px", right: "-10px"}}
                                                 width={50}/>
                                            : ""}
                                    </span>

                                    </OverlayTrigger>
                                </li>
                            }
                            <li className={` ${(window.location.pathname).split("/").length && (window.location.pathname).split("/")[1] == 'trips' || (window.location.pathname).split("/").length && (window.location.pathname).split("/")[1] == 'trip' ? 'active' : ''} nav-item hover-opacity-btn`}>
                                <OverlayTrigger placement="right" overlay={<Tooltip
                                    id="tooltip-dashboard">{(window.location.pathname).split("/").length && (window.location.pathname).split("/")[1] == 'trips' || (window.location.pathname).split("/").length && (window.location.pathname).split("/")[1] == 'trip' ? 'Trips' : 'Trips'}</Tooltip>}>
                                    <span className="nav-link fs-2" style={{position: "relative"}} onClick={() => {
                                        dispatch(setAppBasic({
                                                trip: {
                                                    hasNotification: false
                                                }
                                            }
                                        ));
                                        navigate('/trips/upcoming')
                                    }}>  <img src={HomeIconImg} width={28} className={``}/>

                                        {appBasic && appBasic.trip?.hasNotification ?
                                            <img src={DotIconImg}
                                                 style={{position: "absolute", top: "-6px", right: "-10px"}}
                                                 width={50}/>
                                            : ""}
                                    </span>

                                </OverlayTrigger>
                            </li>

                            <li className={` ${window.location.pathname == '/messages'? 'active': ''} nav-item hover-opacity-btn`}>
                                <OverlayTrigger  placement="right" overlay={<Tooltip id="tooltip-dashboard">Messages</Tooltip>}>
                                    <span className="nav-link fs-2" style={{position:"relative"}} onClick={function (){
                                        dispatch(setAppBasic({message:{
                                                hasNotification:false
                                            }}
                                        ));
                                        navigate('/messages')
                                    }}>
                                        <img src={MessageIconImg} className={``} width={25} />
                                        {appBasic && appBasic.message?.hasNotification?
                                            <img src={DotIconImg} style={{  position: "absolute", top: "-6px", right: "-10px"}} width={50}/>
                                            :""}
                                    </span>
                                </OverlayTrigger>
                            </li>
                            <li className={` ${window.location.pathname == '/new'? 'active': ''} nav-item hover-opacity-btn`}>
                                <OverlayTrigger  placement="right" overlay={<Tooltip id="tooltip-dashboard">New Trip</Tooltip>}>
                                    <span className="nav-link fs-2" onClick={function (){
                                        //navigate('/new')

                                        setCreateTrip(true)
                                    }}>  <img src={AddTripIconImg} width={27} className={``} /></span>
                                </OverlayTrigger>
                            </li>
                            <li className={` ${window.location.pathname == '/notifications'? 'active': ''} nav-item hover-opacity-btn`}>
                                <OverlayTrigger  placement="right" overlay={<Tooltip id="tooltip-dashboard">Notifications</Tooltip>}>
                                    <span className="nav-link fs-2"  style={{position:"relative"}}  onClick={function (){
                                        dispatch(setAppBasic({notification:{
                                                hasNotification:false
                                            }}
                                        ));
                                        navigate('/notifications')
                                    }}>   <img src={NotificationIconImg} className={``} width={25} />

                                    {appBasic && appBasic.notification?.hasNotification?
                                        <img src={DotIconImg} style={{  position: "absolute", top: "-6px", right: "-10px"}} width={50}/>
                                        :""}

                                    </span>

                                </OverlayTrigger>
                            </li>
                          {/*  <li className="nav-item">
                                <OverlayTrigger  placement="right" overlay={<Tooltip id="tooltip-dashboard">Calender</Tooltip>}>
                                    <span className="nav-link fs-2" onClick={function (){
                                        navigate('/calender')
                                    }}>    <i style={{ fontSize: "20px"}} className="bi bi-calendar3"></i></span>
                                </OverlayTrigger>
                            </li>*/}
                            <li className={` ${window.location.pathname == '/account'? 'active': ''} nav-item hover-opacity-btn`}>
                                <OverlayTrigger  placement="right" overlay={<Tooltip id="tooltip-dashboard">Account</Tooltip>}>
                                    <span className="nav-link fs-2" onClick={function (){
                                        navigate('/account')
                                    }}>
                                     <img src={ProfileIconImg} className={``} width={25} />
                                    </span>
                                </OverlayTrigger>
                            </li>
                        </ul>
                        <div className="sidebar-footer">

                            <ul className="nav flex-column">
                               {/* {process.env.REACT_APP_MODE == "local"?
                                <li>

                                    <OverlayTrigger  placement="right" overlay={<Tooltip id="tooltip-logout">Coins will be used for in app</Tooltip>}>

                                        <div className={`flex-column`}>

                                            <span className={`fs-5 fw-bolder`}>
                                                  <img src={CoinsIconImg} className={`hover-opacity-btn`} width={25} />
                                                {user?.data?.coins}</span>
                                        </div>
                                    </OverlayTrigger>
                                </li>
                                    :''}*/}

                                <li>

                                    <OverlayTrigger  placement="right" overlay={<Tooltip id="tooltip-logout">Logout</Tooltip>}>
                                        <a className="nav-link fs-2"  onClick={function () {
                                            dispatch(setUser(null));
                                            localStorage.clear();
                                            navigate("/");
                                        }}>
                                            <img src={LogoutIconImg} className={`hover-opacity-btn`} width={25} />
                                        </a>
                                    </OverlayTrigger>
                                </li>
                            </ul>

                        </div>
                    </div>
                </nav>
                <Modal show={helpModal} className="help-modal" onHide={()=>{
                    setHelpModal(false)
                    setCurrentSlide(1)
                }}  size={`lg`} animation={true}>
                    <div className="modal-header pb-0 border-0">

                        <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {

                            setHelpModal(false)

                        }}/>

                        <span className={`fw-bolder fs-4 text-dark text-capitalize`}>How it works</span>
                        <span></span>

                    </div>
                    <Modal.Body>


                        <div className="container">

                            {/*<Slider ref={sliderRef} {...settings}>
                                <div key={1}>
                                    <section className="text-center container">
                                        <div className="row">
                                            <div className="col-6 mx-auto">

                                                <img src={SoloTravelIcon} width={250}  alt="" />

                                            </div>
                                            <div className="col-6 mx-auto">
                                                <h1 className="fw-light">Album example</h1>
                                                <p className="lead text-body-secondary">Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don’t simply skip over it entirely.</p>
                                                <p>
                                                    <a href="#" className="btn btn-primary my-2">Main call to action</a>
                                                    <a href="#" className="btn btn-secondary my-2">Secondary action</a>
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div key={2}>
                                    <section className="text-center container">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-8 mx-auto">
                                                <img src={SoloTravelIcon} width={250}  alt="" />

                                            </div>
                                            <div className="col-lg-6 col-md-8 mx-auto">
                                                <h1 className="fw-light">Album example</h1>
                                                <p className="lead text-body-secondary">Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don’t simply skip over it entirely.</p>
                                                <p>
                                                    <a href="#" className="btn btn-primary my-2">Main call to action</a>
                                                    <a href="#" className="btn btn-secondary my-2">Secondary action</a>
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </Slider>*/}
                            <div style={{ textAlign: "center", marginTop: '50px' }}>
                                <button className="btn btn-primary me-3" disabled={currentSlide == 1?true:false} onClick={previous}>
                                    Previous
                                </button>
                                <button className="btn btn-primary" onClick={()=>{
                                    if(currentSlide < 2) {
                                        next()
                                    }else {
                                        setHelpModal(false)
                                        setCurrentSlide(1)
                                    }
                                }}>
                                    {currentSlide == 2? "Continue": "Next"}
                                </button>
                            </div>
                        </div>


                    </Modal.Body>
                </Modal>

                <Modal show={createTrip} className="help-modal" onHide={()=>{
                    setCreateTrip(false)
                }}  centered={true} size={`lg`} animation={true}>
                    <div className="modal-header pb-0 border-0">

                        <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {

                            setCreateTrip(false)

                        }}/>

                        <span className={`fw-bolder fs-4 text-dark text-capitalize`}></span>
                        <span></span>

                    </div>
                    <Modal.Body>


                        <div className="container">


                            <div className="row choose-create-trip text-center ">

                                <div className="col-lg-6  mb-3">
                                    <div className={`item  p-5 border cursor-pointer rounded`} onClick={()=>{
                                        navigate('/new/ai')
                                        setCreateTrip(false)
                                    }}>
                                        <div className="card align-items-center">
                                            <img src={AiTripIcon} className={`card-photo`} width={175} alt="" />
                                            <div className="card-body">
                                                <h5 className="card-title fs-2  text-dark">AI Generated</h5>
                                                <p className="card-text fs-7">Group trips unite friends, create memories, foster connections in exciting destinations with shared adventures.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6  mb-3">
                                    <div className={`item  p-5 border cursor-pointer rounded`} onClick={()=>{
                                        navigate('/new')
                                        setCreateTrip(false)
                                    }}>
                                         <div className="card align-items-center">
                                                <img src={CustomTripIcon} className={`card-photo`} width={175} alt="" />
                                                <div className="card-body">
                                                    <h5 className="card-title fs-2  text-dark">Custom</h5>
                                                    <p className="card-text fs-7">Group trips unite friends, create memories, foster connections in exciting destinations with shared adventures.</p>

                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Modal.Body>
                </Modal>
            </div>
        );
    }

};

export default Sidebar;
