import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const TermsConditions: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();

    return (
        <div>
            <h2>Terms and Conditions</h2>
            <Form.Group>
                <Form.Label>Booking Policies</Form.Label>
                <Controller
                    name="termsConditions.bookingPolicies"
                    control={control}
                    render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.termsConditions?.bookingPolicies} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.termsConditions?.bookingPolicies?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Cancellation Policies</Form.Label>
                <Controller
                    name="termsConditions.cancellationPolicies"
                    control={control}
                    render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.termsConditions?.cancellationPolicies} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.termsConditions?.cancellationPolicies?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Refund Policies</Form.Label>
                <Controller
                    name="termsConditions.refundPolicies"
                    control={control}
                    render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.termsConditions?.refundPolicies} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.termsConditions?.refundPolicies?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );
};

export default TermsConditions;
