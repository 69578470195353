import TripsOption from "../options/TripOptions/TripsOption";
const TripsWidget = (props) => {

  return (
    <div>
      <TripsOption actionProvider={props.actionProvider} />
    </div>
  );
};

export default TripsWidget;