import React, {FC, useEffect, useState} from 'react';

import './googlePlaces.css'
import PlacesAutocomplete, {geocodeByAddress, getLatLng, geocodeByPlaceId} from "react-places-autocomplete";
import http from "../../services/api";
import {User, UserResponse} from "../../interfaces/user.interface";
import {
    country_code_url, get_place_details,
    message_to_share_url,
    register_url,
    trips_url
} from "../../services/api.utils";
import {CountryResponse} from "../../interfaces/country.interface";
import {showAlert} from "../../util";
import {TripsResponse} from "../../interfaces/trip.interface";


import LoadingEarth from  '../../images/loaders/earth.svg'
type Props = {
    address: string;
    placeholder: string;
    searchType: string;
    handlePlaceChange: any;
    handlePlaceSelect: any;
    lat:number,
    lng:number,
}

function getCountryCode(addrComponents:any){
    for (var i = 0; i < addrComponents.length; i++) {
        if (addrComponents[i].types[0] == "country") {
            return addrComponents[i].short_name;
        }
        if (addrComponents[i].types.length == 2) {
            if (addrComponents[i].types[0] == "political") {
                return addrComponents[i].short_name;
            }
        }
    }
    return false;
}


const GooglePlacesAi: FC <Props> = ({ placeholder,searchType,address,handlePlaceChange,handlePlaceSelect,lat,lng }) => {



    const [isLoading, setIsLoading] = useState(false);


    const handleSelect = async (address:any, placeID:any) => {

        if(placeID){

            let formattedName = address.substring(0, address.indexOf(','))

            handlePlaceSelect({
                name:formattedName?formattedName:address,
                formattedAddress: address,
                placeId: placeID,
                lat: "11.22",
                lng: "22.333",
                photoReference: "",
                _country: "",
            })


        }
    };




    let searchOptionsSchema:any = {}




    return (
        <PlacesAutocomplete
            value={address}
            onChange={handlePlaceChange}
            onSelect={handleSelect}
            searchOptions={searchOptionsSchema}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>{

                return(
                    <div className={`position-relative w-100`} style={{ zIndex: 9999, }}>
                        <div className="">
                            <input
                                {...getInputProps({
                                    placeholder: placeholder,
                                    className: 'form-control form-control-lg form-control-solid place-search-input w-100',
                                })}

                                disabled={isLoading}
                            />
                            {isLoading?
                                <div className="position-absolute" style={searchType == '(cities)'?{ top: '30px', right:'28px'}:{ top: '21px', right:'28px'}}>
                                    <div className="custom-loader"></div>
                                </div>
                            :''}
                        </div>


                        {suggestions && suggestions.length?
                            <div className="autocomplete-dropdown-container text-start" style={{  zIndex: 9999,boxShadow:'0px 0px 20px 0px #4c577d26'}}>
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {

                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };



                                    //   console.log("suggestion",suggestion)
                                    //types

                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                            key={suggestion.placeId}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );


                                })}
                            </div>

                            :""

                        }

                    </div>
            )}}
        </PlacesAutocomplete>
    );
};

export default GooglePlacesAi;
