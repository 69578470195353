import React, { FC } from 'react';
import './pageNotFound.css'
import iconLogo from "../../images/logo.png";


const PageNotFound: FC = () => {
    return (
        <main className="page-not-found">
            <div className="text-center">

                <img className="d-block mx-auto mb-4" src={iconLogo} alt="" width="72" height="72" />
                <h4>Page not found</h4>
            </div>
        </main>

    );
};

export default PageNotFound;
