import React, {FC, useEffect, useRef, useState} from 'react';
import './overview.scss'
import {
    ConvertToBaseValue,
    Settlement,
    Trip,
    TripDestination, TripResponse, TripSettlement
} from "../../../interfaces/trip.interface";
import {eSMoment, numberWithCommas, showAlert} from "../../../util";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";

import http from "../../../services/api";
import {
    currencies_code_url,
    currencies_convert_url,
    trip_url,
    trips_url,
    user_packages
} from "../../../services/api.utils";
import Modal from "react-bootstrap/cjs/Modal";
import {CurrenciesConvertResponse, CurrenciesResponse, Currency} from "../../../interfaces/country.interface";
import Select from "react-select";
import {useAppDispatch} from "../../../store";
import find from "lodash/find";
import some from "lodash/some";
import Chart from "react-apexcharts";
import Tooltip from "react-bootstrap/cjs/Tooltip";

import PlanningImg from '../../../images/illustration-icons/planning.svg';
import ActivitiesImg from '../../../images/illustration-icons/activities.svg';

import HotelImg from "../../../images/illustration-icons/hotel.svg";
import HireVehicleImg from "../../../images/illustration-icons/taxi.svg";
import RentVehicleImg from "../../../images/illustration-icons/rental.svg";
import LocationImg from "../../../images/illustration-icons/attraction.svg";
import currencyExchangeImg from "../../../images/icons/currency-exchange.svg";
import {setCurrencies} from "../../currency/currenciesSlice";
import BackButtonImgIcon from "../../../images/icons/back-button.svg";
import EmptyExpenseImg from "../../../images/illustration-icons/empty-expense.svg";
import EmptyWalletImg from "../../../images/illustration-icons/empty-wallet.svg";

type Props = {
    tripData?:Trip,
    getTripData:any,
    settlementData ?:[Settlement]
}


type OptionSchema ={
    name?: string
    value: string
    label?: string
    _id?: string
}
type CurrencyConvertSchema ={
    code: string
    value: string
    destinationId: string
    destinationName: string
    currencyId: string
}
const TripOverview: FC<Props> = ({tripData,getTripData, settlementData}) => {

    const [activeTab, setActiveTab] = useState("all");

    const { user, currencies } = useSelector((state: RootState) => state);

    const [loading, setLoading] = useState(false);
    const [currencyLoading, setCurrencyLoading] = useState(false);
    const [showConvertModal, setShowConvertModal] = useState(false);
    const [currenciesData, setCurrenciesData] = useState<[Currency]|null>();
    const [currenciesOptions, setCurrenciesOptions] = useState();
    const [isCurrencyConvertSaving, setIsCurrencyConvertSaving] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState<OptionSchema>();
    const [convertedCurrencyValues, setConvertedCurrencyValues] = useState< [CurrencyConvertSchema]>();
    const dispatch = useAppDispatch();

    useEffect(() => {

        if(currencies){
            let currenciesData:any = []

            currencies.data.currencies.map(function (currency) {
                currenciesData.push( { value: currency._id, label: currency.name+" - "+currency.code , code: currency.code })
            })
            setCurrenciesOptions(currenciesData)

        }else{
            getCurrencies()
        }

    },[currencies])

    useEffect(() => {


        if(tripData && tripData.destinations[0].convertToBaseValues && tripData.destinations[0].convertToBaseValues.length){
            setSelectedCurrency( { value: tripData.destinations[0].convertToBaseValues[0].currencyId._id, label:tripData.destinations[0].convertToBaseValues[0].currencyId.name+" - "+tripData.destinations[0].convertToBaseValues[0].currencyId.code })


            let data:any = []

            tripData && tripData.destinations.map((destination)=>{

                if(destination.convertToBaseValues.length){



                    data.push({
                        code: destination.activeCurrency?.code,
                        value: destination.convertToBaseValues[0].value,
                        destinationId: destination._id,
                        destinationName: destination.name,
                        currencyId: destination.convertToBaseValues[0].currencyId._id,
                    })
                }

            })

            setConvertedCurrencyValues(data)

        }

    },[])


    const getCurrencies = () => {

        http
            .get<null, CurrenciesResponse>(currencies_code_url)
            .then((res) => {
                if (res) {

                    if(res.status == 200){
                        dispatch(setCurrencies(res))
                    }
                }

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {


            });


    };
    let planningBudget = 0
    let activityBudget = 0
    let memberPaidPlanningBudget = 0
    let memberPaidActivityBudget = 0
    let memberSpendPlanningBudget = 0
    let memberSpendActivityBudget = 0
    let totalPlanningsCount = 0
    let totalActivitiesCount = 0

    let membersArray:any = []

    let currency = ""
    let currencyCode = ""
    let hasMultipleCurrency = false
    let hasMultipleCurrencyUpdated = false
    let currencyConversionValue = 1


    tripData?.destinations.map(function (activeDestination,key){

        var foundIndex = activeDestination.convertToBaseValues.findIndex(function (convertToBaseValue:ConvertToBaseValue){
            if(convertToBaseValue.userId){
                return  (convertToBaseValue.userId).toString() == user?.data.userId
            }
        });



        console.log("foundIndex",foundIndex)

        if(foundIndex == -1){


            if(currency != ""){

                if(currency != activeDestination.activeCurrency?._id){
                    hasMultipleCurrency = true

                }
            }
        }else{
            hasMultipleCurrencyUpdated = true
        }



        if(activeDestination.activeCurrency?._id){
            currency = activeDestination.activeCurrency?._id
            currencyCode = activeDestination.activeCurrency?.code
        }




        if( activeDestination.convertToBaseValues &&  activeDestination.convertToBaseValues.length){

            let currencyConversionDestination = find( activeDestination.convertToBaseValues, {userId:user?.data.userId});

            if(currencyConversionDestination){
                currencyConversionValue = parseFloat(currencyConversionDestination.value)
                currencyCode = currencyConversionDestination.currencyId.code

            }

        }

        activeDestination?.activities?.map(function (activity){



            if(activity.type == 'live'){
                filter(activity.paidMembers, function(member) {

                    if(member.user?._id && user?.data.userId ){


                        return (member.user?._id).toString() == (user?.data.userId).toString()
                    }else{
                        return false
                    }

                }).map(function (member,key){


                    if(member.paidAmount){
                        memberPaidActivityBudget += (parseFloat(member.paidAmount) * currencyConversionValue)


                    }
                })
                filter(activity.members, function(member) {

                    if(member.user?._id && user?.data.userId ){


                        return (member.user?._id).toString() == (user?.data.userId).toString()
                    }else{
                        return false
                    }

                }).map(function (member,key){

                    if(member.amount){
                        memberSpendActivityBudget += (parseFloat(member.amount) * currencyConversionValue)

                    }
                })
                activityBudget += activity.budget? (parseFloat(activity.budget)*currencyConversionValue): 0





            }else{
                filter(activity.paidMembers, function(member) {

                    if(member.user?._id && user?.data.userId ){


                        return (member.user?._id).toString() == (user?.data.userId).toString()
                    }else{
                        return false
                    }

                }).map(function (member,key){

                    if(member.paidAmount){
                        memberPaidPlanningBudget += (parseFloat(member.paidAmount)*currencyConversionValue)

                    }
                })

                filter(activity.members, function(member) {

                    if(member.user?._id && user?.data.userId ){


                        return (member.user?._id).toString() == (user?.data.userId).toString()
                    }else{
                        return false
                    }

                }).map(function (member,key){

                    if(member.amount){
                        memberSpendPlanningBudget += (parseFloat(member.amount)*currencyConversionValue)

                    }
                })
                planningBudget += activity.budget? (parseFloat(activity.budget)*currencyConversionValue): 0


            }



            //Members
            activity?.members?.map(function (member,key){

                if(membersArray.length && member._id){

                    var foundIndex = membersArray.findIndex(function (membersItem:any){
                        if(member._id){
                            return  (membersItem._id).toString() == (member._id).toString()
                        }
                    });

                    if(foundIndex < 0){
                        let amount = member.amount?(parseFloat(member.amount)*currencyConversionValue): 0

                        membersArray.push({
                            _id: member._id,
                            firstName: member.user?.firstName,
                            lastName: member.user?.lastName,
                            userName: member.user?.userName,
                            relation: member.user?.relation,
                            amount:amount,
                            cover:member.cover,
                            paidAmount: 0,
                        })
                    }else{

                        if(member.amount){

                            let newPaidAmount = parseFloat(membersArray[foundIndex].amount)+parseFloat(member.amount)
                            membersArray[foundIndex].amount = newPaidAmount
                        }
                    }

                }else{

                    let amount = member.amount?(parseFloat(member.amount)*currencyConversionValue): 0

                    membersArray.push({
                        _id: member._id,
                        firstName: member.user?.firstName,
                        lastName: member.user?.lastName,
                        userName: member.user?.userName,
                        relation: member.user?.relation,
                        amount: amount,
                        cover:member.cover,
                        paidAmount: 0
                    })
                }
            })

            //Paid Members
            activity?.paidMembers?.map(function (member,key){

                if(membersArray.length && member._id){

                    var foundIndex = membersArray.findIndex(function (membersItem:any){
                        if(member._id){
                            return  (membersItem._id).toString() == (member._id).toString()
                        }
                    });

                    if(foundIndex < 0){

                        let paidAmount = member.paidAmount?(parseFloat(member.paidAmount)*currencyConversionValue): 0

                        membersArray.push({
                            _id: member._id,
                            firstName: member.user?.firstName,
                            lastName: member.user?.lastName,
                            userName: member.user?.userName,
                            relation: member.user?.relation,
                            paidAmount: paidAmount,
                            cover:member.cover,
                            amount:0
                        })
                    }else{

                        if(member.paidAmount){

                            let newPaidAmount = parseFloat(membersArray[foundIndex].paidAmount)+parseFloat(member.paidAmount)

                            membersArray[foundIndex].paidAmount = newPaidAmount
                        }
                    }

                }else{
                    let paidAmount = member.paidAmount?(parseFloat(member.paidAmount)*currencyConversionValue): 0
                    membersArray.push({
                        _id: member._id,
                        firstName: member.user?.firstName,
                        lastName: member.user?.lastName,
                        userName: member.user?.userName,
                        relation: member.user?.relation,
                        paidAmount: paidAmount,
                        cover:member.cover,
                        amount:0
                    })
                }
            })

        });
    });



    const selectCurrency = (selectedCurrency:any,item:any) => {

        setCurrencyLoading(true)

        if(selectedCurrency){

            setSelectedCurrency(selectedCurrency)





            http
                .post<null, CurrenciesConvertResponse>(currencies_convert_url, {
                    _id: tripData?._id,
                    baseCurrency: selectedCurrency,
                })
                .then((res) => {
                    if (res) {

                        const { data, status} = res;

                        setConvertedCurrencyValues(data)

                        setCurrencyLoading(false)

                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });


        }

    }

    const saveConversionValue = () => {

        setIsCurrencyConvertSaving(true)
        let data = {
            destinations:convertedCurrencyValues,
            updateType : "update-currency-conversion",
            baseCurrency: selectedCurrency?._id
        }

        http
            .put<null, TripResponse>(trip_url+"/"+tripData?._id+"/currency-conversion", data)
            .then((res) => {
                if (res) {

                    const { data, status} = res;
                    setShowConvertModal(false)

                    setIsCurrencyConvertSaving(false)

                    getTripData()

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });

    }


    let newMembersArray =  membersArray.map(function (member:any){

        member.balance = parseFloat((member.paidAmount).toFixed(2)) - parseFloat((member.amount).toFixed(2))
        return member
    })
    membersArray = orderBy(newMembersArray, ['balance'],['desc'])

    const handleInputChange = ( e:any,i:number) => {


        if(convertedCurrencyValues){
            const { value } = e.target;
            const list:[CurrencyConvertSchema] = [...convertedCurrencyValues];
            list[i]= {
                "value": value,
                "code": list[i].code,
                "destinationId": list[i].destinationId,
                "destinationName": list[i].destinationId,
                "currencyId": list[i].currencyId,
            };
            setConvertedCurrencyValues(list);

        }
    }
    /*let activitiesInfos:any = []
    let defaultActivityTypes:any  = ["hotel","flight","taxi","bus","train","rent-vehicle","live"]


    defaultActivityTypes.map(function (defaultActivityType:any){

        let amount = 0
        let count = 0
        let convertValue = "1"
        tripData?.destinations.map((activeDestination)=>{


            if(activeDestination.convertToBaseValues){

                var foundIndex = activeDestination.convertToBaseValues.findIndex(function (convertToBaseValue){
                    if(convertToBaseValue.userId && user?.data.userId ){
                        return  (convertToBaseValue.userId).toString() == (user?.data.userId ).toString()
                    }
                });

                if(foundIndex > -1){
                    convertValue = activeDestination.convertToBaseValues[foundIndex].value

                }

            }


            filter(activeDestination?.activities, { type: defaultActivityType }).map(function (activity){

                if(defaultActivityType != "live"){
                    totalPlanningsCount++
                }else{
                    totalActivitiesCount++
                }

                if(activity.budget){

                    let budget = parseFloat(activity.budget)*parseFloat(convertValue)
                    amount += budget
                    count++;

                }

            })

        })


        activitiesInfos.push({
            name: defaultActivityType == "taxi"? "Taxi / Bus / Train / Metro" : defaultActivityType == "rent-vehicle"? "rent": defaultActivityType == "location"? "Attraction / Place":defaultActivityType == "live"? "Activity": defaultActivityType,
            type: defaultActivityType,
            amount:amount,
            count:count,
            icon: defaultActivityType == "taxi"? HireVehicleImg : defaultActivityType == "rent-vehicle"? RentVehicleImg: defaultActivityType == "location"?  LocationImg: defaultActivityType == "flight"? PlanningImg:  defaultActivityType == "hotel"? HotelImg:ActivitiesImg,

        })

    })
*/


    return (

        <div style={{ display: "contents"}}>
          {/*  {! hasMultipleCurrency ?*/}
                <div className="col-xl-9 col-md-9 destination-expenses-container px-3">
                    <div className="row mb-3">
                        <div className="col-xl-12">
                            <div className="card card-flush h-lg-100">

                                <div className="card-header border-0 pt-0">

                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bolder text-dark">Expenses</span>
                                        <span className="text-gray-400 mt-1 fs-7">Managing and tracking the costs of your travel adventure.</span>
                                    </h3>

                                    {hasMultipleCurrencyUpdated || hasMultipleCurrency?
                                        <div className="card-toolbar d-flex  align-items-end flex-column pt-2">


                                            <h3 className="card-title align-items-end flex-column">
                                                <span className="card-label fw-bolder text-dark fs-7 cursor-pointer"  onClick={() => {
                                                    setShowConvertModal(true)
                                                }}>
                                                    <img src={currencyExchangeImg} width={20} className={`hover-opacity-btn cursor-pointer`}/>
                                                    Currency</span>
                                                <span className="text-gray-400 mt-1 fs-8">Click here for currency conversion </span>
                                            </h3>

                                        </div>

                                        :""}
                                </div>


                                <div className="card-body align-items-end pt-6">

                                    <div className="row align-items-center mx-0 w-100">

                                        <div className="col-xl-7 px-0">
                                            <div
                                                className="d-flex border border-gray-300 border-dashed rounded p-3 mb-3">

                                                <div className="d-flex align-items-center flex-grow-1 me-2 ">

                                                    <div className="symbol symbol-50px me-4">
																				<span className="symbol-label">

																					<span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
																						<img src={PlanningImg} width={20}/>
																					</span>

																				</span>
                                                    </div>


                                                    <div className="me-2">
                                                        <span className="text-gray-800 text-hover-primary fs-6 fw-bold">Plannings</span>
                                                        <span className="text-gray-400 fw-bold d-block fs-7">Strategically arranging a successful and enjoyable upcoming journey.</span>
                                                    </div>

                                                </div>


                                                <div className="d-flex align-items-center">
                                                    <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{numberWithCommas(planningBudget)}</span>  <span className=" fw-bolder fs-6 ">{currencyCode}</span></span>
                                                </div>

                                            </div>
                                            <div
                                                className="d-flex border border-gray-300 border-dashed rounded p-3 mb-3">

                                                <div className="d-flex align-items-center flex-grow-1 me-2 ">

                                                    <div className="symbol symbol-50px me-4">
																				<span className="symbol-label">

																					<span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
																						<img src={ActivitiesImg} width={20}/>
																					</span>

																				</span>
                                                    </div>


                                                    <div className="me-2">
                                                        <span
                                                           className="text-gray-800 text-hover-primary fs-6 fw-bold">Activities</span>
                                                        <span className="text-gray-400 fw-bold d-block fs-7">Organizing exciting experiences for an enjoyable and memorable trip.</span>
                                                    </div>

                                                </div>


                                                <div className="d-flex align-items-center">
                                                    <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{numberWithCommas(activityBudget)}</span>  <span className=" fw-bolder fs-6 ">{currencyCode}</span></span>
                                                </div>

                                            </div>


                                        </div>


                                        <div className="col-xl-5 d-flex  justify-content-center px-0">

                                            {activityBudget > 0 || planningBudget > 0?
                                                <Chart options={{
                                                    chart: {
                                                        type: 'donut',
                                                        toolbar: {
                                                            show: false
                                                        },
                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    labels: ['Plannings','Activities'],
                                                    colors: ['#2E93fA', '#66DA26'],
                                                    legend:{
                                                        show: false
                                                    },
                                                    plotOptions: {

                                                        pie: {
                                                            startAngle: 0,
                                                            expandOnClick: true,
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            customScale: 1,
                                                            dataLabels: {
                                                                offset: 0,
                                                                minAngleToShowLabel: 10,
                                                            },
                                                            donut: {
                                                                size: "60%",
                                                                background: "transparent",
                                                                labels: {
                                                                    show: true,
                                                                    value: {
                                                                        show: true,
                                                                        fontSize: "12px",
                                                                        fontFamily: "Helvetica, Arial, sans-serif",
                                                                        fontWeight: 400,
                                                                        color: undefined,
                                                                        offsetY: -10,
                                                                        formatter: function (val) {
                                                                            let amount = numberWithCommas(val)
                                                                            return `${amount} ${currencyCode}`
                                                                        },
                                                                    },
                                                                    total: {
                                                                        show: true,
                                                                        fontSize: "12px",
                                                                        formatter: function (w) {
                                                                            let amount = numberWithCommas(planningBudget+activityBudget)
                                                                            return `${amount} ${currencyCode}`
                                                                        }
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    }
                                                }} series={[parseFloat((planningBudget.toFixed(2))),parseFloat(activityBudget.toFixed(2))]} type="donut" width={200} height={200} />
                                                :
                                                <div className="text-center p-2">
                                                    <img src={EmptyExpenseImg} width={100}/>
                                                    <p className="fs-5 text-dark fw-bolder">No data available</p>
                                                    <p className="text-gray-400">Please check after adding planning or activities from <span className="fw-bolder">destination dashboard</span></p>
                                                </div>
                                            }



                                        </div>

                                    </div>
                                    <div className="row d-flex">
                                        <div className="d-flex">

                                            <div
                                                className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-3 mb-3">
                                                <span className="fs-6 text-gray fw-bolder">{totalPlanningsCount}</span>

                                                <div className="fw-semibold text-gray-400">Plannings</div>
                                            </div>

                                            <div
                                                className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3">
                                                <span className="fs-6 text-gray fw-bolder">{totalActivitiesCount}</span>

                                                <div className="fw-semibold text-gray-400">Activities</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*<div className="col-xl-4 mt-3 mt-xl-0">
                            <div className="card highlight-card">
                                <div className="card-header border-0 pt-0"><h3
                                    className="card-title align-items-start flex-column"><span
                                    className="card-label fw-bolder text-dark">Summary</span>
                                    <span className="text-gray-400 mt-1  fs-7">Summary from {totalPlanningsCount} plannings and {totalActivitiesCount} activities</span>
                                </h3>
                                </div>
                                <div className="card-body pt-2 pb-2 d-flex align-items-center">
                                    <div className="d-flex flex-column content-justify-center w-100">
                                        {activitiesInfos.map(function (activitiesInfo:any,key:any){
                                            return(
                                                <div key={key}>
                                                    <div className="passenger-card-item d-flex flex-stack">
                                                        <div className="d-flex align-items-center">
                                                            <div className="symbol symbol-50px me-2">
                                                            <span className="symbol-label">
                                                                <img src={activitiesInfo.icon} width={20} />
                                                            </span>
                                                            </div>
                                                            <div className=""><a className="fs-5 fw-bolder text-dark text-hover-primary mb-2 text-capitalize ">{activitiesInfo.name}</a>
                                                                <div className="fw-bold text-gray-400"> {activitiesInfo.count} {activitiesInfo.count > 1? "records": "record" } added</div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-end ms-2 member-manage"><span
                                                            className="my-3 fs-5 px-2 fw-bolder primary-color">{numberWithCommas(activitiesInfo.amount)} {currencyCode}</span></div>
                                                    </div>
                                                    {key < activitiesInfos.length-1?
                                                        <div className="separator separator-dashed my-2"></div>
                                                        :""
                                                    }

                                                </div>

                                            )
                                        })}


                                    </div>
                                </div>
                            </div>
                        </div>*/}
                    </div>

                    {tripData?.tripType != "solo"?

                        <div className="card intro card-stretch bg-light-info hoverable shadow-none pb-3 mb-5 mb-xl-0">
                            <div className="card-header align-items-center border-0"><h3
                                className="card-title align-items-start fw-bolder flex-column">My Expenses</h3>
                                <div className="card-toolbar"></div>
                            </div>
                            <div className="card-body row ">
                                <div className="col-xl-4" style={{borderRight: " 2px #dcdbdb dotted"}}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="">
                                                <p className={` mb-2 fs-2 fw-bolder ${((memberPaidPlanningBudget)- (memberSpendPlanningBudget)) < 0? 'text-danger': 'text-success'}`}>{numberWithCommas((memberPaidPlanningBudget)- (memberSpendPlanningBudget))} {currencyCode}</p>
                                                <h3 className="mb-0 number-font">Your Planning Balance </h3>
                                            </div>
                                            <div className="mt-3">
                                                <p className="mb-1 d-flex">
                                                    <span className=""><i className="bi bi-cash text-muted me-2 fs-16"></i></span>
                                                    <span className="fs-13 font-weight-normal text-muted me-2">Spent Amount </span> :
                                                    <span className="ms-auto fs-14 text-danger">- {numberWithCommas(memberSpendPlanningBudget)}
                                                        <span className=" fw-bolder fs-6 ms-1">{currencyCode}</span>
                                            </span>
                                                </p>
                                                <p className="mb-1 d-flex">
                                                    <span className=""><i className="bi bi-credit-card me-2 fs-16 text-muted"></i></span>
                                                    <span className="fs-13 font-weight-normal text-muted me-2">Paid Amount </span> :
                                                    <span className="ms-auto fs-14  text-success">+ {numberWithCommas(memberPaidPlanningBudget)}
                                                        <span className=" fw-bolder fs-6 ms-1">{currencyCode}</span>
                                            </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4" style={{borderRight: " 2px #dcdbdb dotted"}}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="">
                                                <p className={` mb-2 fs-2 fw-bolder ${((memberPaidActivityBudget)- (memberSpendActivityBudget)) < 0? 'text-danger': 'text-success'}`}>{numberWithCommas(((memberPaidActivityBudget)- (memberSpendActivityBudget)))}  {currencyCode}</p>
                                                <h3 className="mb-0 number-font">Your Activity Balance </h3>
                                            </div>
                                            <div className="mt-3">
                                                <p className="mb-1 d-flex">
                                                    <span className=""><i className="bi bi-cash text-muted me-2 fs-16"></i></span>
                                                    <span className="fs-13 font-weight-normal text-muted me-2">Spent Amount </span> :
                                                    <span className="ms-auto fs-14 text-danger">- {numberWithCommas(memberSpendActivityBudget)}</span>
                                                </p>
                                                <p className="mb-1 d-flex">
                                                    <span className=""><i className="bi bi-credit-card me-2 fs-16 text-muted"></i></span>
                                                    <span className="fs-13 font-weight-normal text-muted me-2">Paid Amount </span> :
                                                    <span className="ms-auto fs-14  text-success">+ {numberWithCommas(memberPaidActivityBudget)}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="text-center mt-8">
                                                <p className={`mb-2 fs-2x fw-bolder ${(((memberPaidActivityBudget)- (memberSpendActivityBudget)) + ((memberPaidPlanningBudget)- (memberSpendPlanningBudget))) < 0? "text-danger": "text-success"}`}>{numberWithCommas(((memberPaidActivityBudget)- (memberSpendActivityBudget)) + ((memberPaidPlanningBudget)- (memberSpendPlanningBudget)))}  {currencyCode}</p>
                                                <h3
                                                    className="mb-0 number-font">Your Trip Account Balance </h3></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {tripData?.tripType == "group"?

                              <>
                                  {  settlementData == undefined?
                                  <div className={`text-center p-2`}>
                                      <p className={`fs-5 text-dark fw-bolder`}>Loading</p>
                                      <p className={`text-gray-400`}>Please wait till we are fetching your settlement</p>
                                  </div>

                                  : settlementData?.length ?
                                  <div>

                                      {filter(settlementData, function (member) {
                                          if (member.user._id == user?.data.userId) {
                                              return true
                                          } else {
                                          }
                                          return false
                                      }).length ?
                                          <div className="card-body mx-2 ">

                                              <table className="table table-borderless">
                                                  <thead>
                                                  <tr>
                                                      <th scope="col" className={` fw-bolder fs-6`}>Owe</th>
                                                      <th scope="col" className={` fw-bolder fs-6`}>Amount</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody>
                                                  {filter(settlementData, function (member) {
                                                      if (member.user._id == user?.data.userId) {
                                                          return true
                                                      } else {
                                                      }
                                                      return false
                                                  }).map(function (member, key: number) {
                                                      if (member.user._id == user?.data.userId) {

                                                          return (

                                                              <tr className="py-3" key={key}>
                                                                  <td>
                                                                      <div className="d-flex align-items-center">
                                                                          <div
                                                                              className="symbol symbol-45px symbol-circle me-2">
                                                                              {member.payUser.cover?.url ?
                                                                                  <img alt="Pic"
                                                                                       src={member.payUser.cover?.url}/>
                                                                                  :
                                                                                  <span
                                                                                      className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{(member.payUser.firstName).charAt(0)}</span>
                                                                              }

                                                                          </div>
                                                                          <div className=""><a
                                                                              className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">{member.payUser.firstName} {member.payUser.lastName}</a>
                                                                              <div
                                                                                  className="fw-bold text-gray-400">{member.payUser?.relation?member.payUser?.relation:member.payUser?.userName}</div>
                                                                          </div>
                                                                      </div>
                                                                  </td>
                                                                  <td>
                                                                      <div className="d-flex flex-column member-manage"><span
                                                                          className="my-3 fs-5 fw-bolder text-danger">{numberWithCommas(Math.abs(parseFloat(member.amount)))} {currencyCode}</span>
                                                                      </div>
                                                                  </td>
                                                              </tr>)
                                                      }

                                                  })}

                                                  </tbody>
                                              </table>
                                          </div>
                                          :
                                        ""
                                      }

                                      {filter(settlementData, function (member) {
                                          if (member.payUser._id == user?.data.userId) {
                                              return true
                                          } else {
                                          }
                                          return false
                                      }).length ?
                                          <div className="card-body  mx-2">
                                              <table className="table table-borderless">
                                                  <thead>
                                                  <tr>
                                                      <th scope="col" className={` fw-bolder fs-6`}>Receive From</th>
                                                      <th scope="col" className={` fw-bolder fs-6`}>Amount</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody>
                                                  {filter(settlementData, function (member) {
                                                      if (member.payUser._id == user?.data.userId) {
                                                          return true
                                                      } else {
                                                      }
                                                      return false
                                                  }).map(function (member, key: number) {


                                                      return (

                                                          <tr className="py-3" key={key}>
                                                              <td>
                                                                  <div className="d-flex align-items-center">
                                                                      <div className="symbol symbol-45px symbol-circle me-2 ">
                                                                          {member.user.cover?.url ?
                                                                              <img alt="Pic" src={member.user.cover?.url}/>
                                                                              :
                                                                              <span
                                                                                  className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{(member.user.firstName).charAt(0)}</span>
                                                                          }

                                                                      </div>
                                                                      <div className=""><a
                                                                          className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">{member.user.firstName} {member.user.lastName}</a>
                                                                          <div
                                                                              className="fw-bold text-gray-400">{member.user?.relation? member.user?.relation: member.user?.userName}</div>
                                                                      </div>
                                                                  </div>
                                                              </td>
                                                              <td>
                                                                  <div className="d-flex flex-column member-manage"><span
                                                                      className="my-3 fs-5 fw-bolder text-danger">{numberWithCommas(Math.abs(parseFloat(member.amount)))} {currencyCode}</span>
                                                                  </div>
                                                              </td>
                                                          </tr>)

                                                  })}

                                                  </tbody>
                                              </table>
                                          </div>
                                          :
                                          ""}
                                  </div> :

                                          <div className={`text-center p-2`}>
                                              <p className={`fs-5 text-dark fw-bolder`}>Settlement</p>
                                              <p className={`text-gray-400`}>No settlement required for your transactions</p>
                                          </div>
                                  }
                              </>


                            :""}



                        </div> :''
                    }



                    {tripData?.tripType != "solo" && membersArray.length?
                        <>
                            <div className="card intro card-stretch mb-5 bg-light-info hoverable shadow-none">

                                <div className="card-header align-items-center border-0">
                                    <h3 className="card-title align-items-start  fw-bolder text-dark flex-column">
                                        Members Account
                                    </h3>
                                    <div className="card-toolbar">


                                    </div>
                                </div>

                                <div className="card-body row mx-1 ">

                                    <table className="table table-borderless">
                                        <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Spent</th>
                                            <th scope="col">Paid</th>
                                            <th scope="col">Balance</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { membersArray.map(function (member:any, key:number){


                                            console.log("member",member)
                                            return(

                                                <tr className="py-3"  key={key}>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="symbol symbol-45px symbol-circle me-2">
                                                                {member.cover?.url?
                                                                    <img alt="Pic" src={member.cover?.url} />
                                                                    :
                                                                    <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{member.firstName?(member?.firstName).charAt(0): ""}</span>
                                                                }

                                                            </div>
                                                            <div className=""><a
                                                                className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">{member?.firstName} {member.lastName}</a>
                                                                <div className="fw-bold text-gray-400">{member?.relation? member?.relation: member?.userName}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex flex-column member-manage"><span
                                                            className="my-3 fs-5 fw-bolder text-danger">{numberWithCommas(member.amount)} {currencyCode}</span></div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex flex-column  member-manage"><span
                                                            className="my-3 fs-5 fw-bolder text-success">{numberWithCommas(member.paidAmount)} {currencyCode}</span></div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex flex-column member-manage"><span
                                                            className={`${(member.balance) < 0? "primary-color": "success-color" } my-3 fs-5 fw-bolder`}>{numberWithCommas(member.balance)} {currencyCode}</span></div>
                                                    </td>
                                                </tr> )

                                        } ) }

                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </>
                        :""}

                </div>
{/*            : <p>No data available</p>} */}
            <Modal show={showConvertModal || hasMultipleCurrency} className="planning-form-modal" onHide={function () {

            }}  onExited={function () {

            }}animation={true}>
                <Modal.Body>

                    <div className="d-flex text-center header">
                        <div className="p-2 flex-fill text-start">
                            <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>{
                                setShowConvertModal(false)
                            }}/>

                        </div>
                        <div className="p-2 flex-fill "></div>
                        { convertedCurrencyValues?
                            isCurrencyConvertSaving?
                                <div className="p-2 flex-fill text-end " ><span className="text-primary">Saving...</span></div>

                                :

                            <div className="p-2 flex-fill text-end link save" onClick={saveConversionValue} ><span className="">Save</span></div>

                            :
                            <div className="p-2 flex-fill text-end " ><span className="">Save</span></div>

                        }
                    </div>
                    <div className="col-12">
                        <div className="mb-3 text-center"><h1 className="mb-3 fs-2"> Multiple currency detected</h1>
                            <div className="text-gray-400 fw-semibold fs-5">We have detected multiple currencies in
                                your trip. Please choose a base currency to provide summary
                            </div>
                            <div className="text-gray-400 fw-semibold fs-7">If you need more info, please check<a
                                href="" className="fw-bold link"> Support Guidelines</a>.
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <label htmlFor="budget" className="form-label">Currency </label>

                        <div className="form-control form-control-solid new-destination-range">



                            <Select
                                value={selectedCurrency}
                                onChange={selectCurrency}
                                options={currenciesOptions}
                                styles={{
                                    control: (base):any => ({
                                        ...base,
                                        border: 'none',
                                        boxShadow:'none',
                                        color:'#5e6278',
                                    }),
                                    indicatorSeparator: (base):any => ({
                                        ...base,
                                        width: '0px',
                                    }),

                                    option: (base, {isFocused, isSelected}):any => ({
                                        ...base,
                                        border: `none`,
                                        height: '100%',
                                        color:'#5e6278',
                                        fontSize: '12px',
                                        backgroundColor: isFocused
                                            ? '#f5f8fa'
                                            : isSelected
                                                ? '#f5f8fa'
                                                : undefined,
                                        "&:active": {
                                            backgroundColor: "#f5f8fa"
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>

                    {! currencyLoading ?

                        convertedCurrencyValues?
                            <>
                                <p className="fs-5 fw-bolder font-dark my-3">Destinations</p>

                                <div className="col-12 px-3">
                                    { convertedCurrencyValues?.map(function (currency, i){
                                        return(
                                            <p key={i}><span className="fw-bolder">{currency.destinationName}</span> : {currency.code}
                                                <input type="number" className="form-control form-control-lg form-control-solid"
                                                       onChange={e => handleInputChange(e, i)} value={currency.value}/>
                                            </p>
                                        )
                                    })}
                                </div>
                            </>
                            : ""

                        :
                        <div className={`text-center p-2`}>
                            <p className={`fs-5 text-dark fw-bolder`}>Loading</p>
                            <p className={`text-gray-400`}>Please wait till we are fetching your settlement</p>
                        </div>
                    }



                </Modal.Body>
            </Modal>
        </div>
    );
};
export default TripOverview;
