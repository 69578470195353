import React, {FC, useEffect, useRef, useState} from 'react';

import './trip.scss'
import http from "../../services/api";
import {
    Trip,
    TripDestination, TripDocument, TripMemberPopulated,
    TripResponse, TripSettlement, TripSettlementResponse,
    TripsResponse
} from "../../interfaces/trip.interface";
import {checklist_url, gm_near_by_places, trip_url, trips_invite_url, trips_url} from "../../services/api.utils";
import {setTrip} from "./tripSlice";
import filter from "lodash/filter";
import {useSelector} from "react-redux";
import {RootState} from "../../rootReducer";
import {useAppDispatch} from "../../store";
import {useNavigate, useParams} from "react-router-dom";
import TripDashboardHeader from "../header/TripDashboardHeader";
import TripMenu from "./TripMenu";
import TripPlanning from "./plannings/TripPlanning";
import TripOverview from './overview/TripOverview';
import TripDestinationDocuments from './documents/TripDestinationDocuments';
import TripCheckList from './checklist/TripCheckList';
import TripLiveActivity from "./activity/TripLiveActivity";
import dayjs from "dayjs";
import {dateRangeAsArray, eSMoment, getUrlFromBucket, showAlert, toastMessage} from "../../util";
import {Checklist, ChecklistsResponse} from "../../interfaces/checklist.interface";
import {setCheckLists} from "./checklist/checkListsSlice";
import DestinationExpenses from "./destinationExpenses/DestinationExpenses";
import {GooglePlace, GooglePlacesResponse} from "../../interfaces/googlePlaces.interface";
import TripNote from "./note/TripNote";
import TripDocuments from "./documents/TripDocuments";
import TripSettingsMenu from "./settings/TripSettingsMenu";
import TripSettingsBasic from "./settings/TripSettingsBasic";
import TripSettingsDestinations from "./settings/TripSettingsDestinations";
import TripSettingsMembers from "./settings/TripSettingsMembers";
import find from "lodash/find";
import Modal from "react-bootstrap/cjs/Modal";

import SortIconImg from '../../images/icons/sort.svg'
import FilterIconImg from '../../images/icons/filter.svg'
import FilterClearIconImg from '../../images/icons/filter_clear.svg'
import Dropdown from "react-bootstrap/cjs/Dropdown";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import HotelImg from "../../images/illustration-icons/hotel.svg";
import LocationImg from "../../images/illustration-icons/attraction.svg";
import WebsiteImg from "../../images/illustration-icons/website.svg";
import PlaneImg from "../../images/illustration-icons/flight.svg";
import RentVehicleImg from "../../images/illustration-icons/rental.svg";
import HireVehicleImg from "../../images/illustration-icons/taxi.svg";
import CloseBtnImg from '../../images/icons/close-red.svg';
import PlusRedImg from '../../images/icons/plus-red.svg';
import {Cloud9} from "aws-sdk";
import CloseButton from '../../images/icons/close-button.svg';
import Loader from "../loader/Loader";
import NotificationHeader from "../header/NotificationHeader";
import BackButtonImgIcon from "../../images/icons/back-button.svg";
import {Helmet} from "react-helmet";
import {setAppBasic} from "../../app/appBasicSlice";
import TripSettingsFamilyMembers from "./settings/TripSettingsFamilyMembers";


const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
type ParamTypes =  {
    tripId: string,
    destinationId:string
}
interface ConvertParamTypes {
    code:string,
    value:number
}

function usePrevious(value:any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}


const planningCategoryArray = [{
    id:"hotel",
    name:"hotel",
    icon:HotelImg
},{
    id:"location",
    name:"Attraction",
    icon:LocationImg
},{
    id:"website",
    name:"Website",
    icon:WebsiteImg
},{
    id:"flight",
    name:"Flight",
    icon:PlaneImg
},{
    id:"rent-vehicle",
    name:"Rent",
    icon:RentVehicleImg
},{
    id:"taxi",
    name:"Taxi",
    icon:HireVehicleImg
}]


const TripContainer: FC = () => {



    const { width } = useViewport();
    const breakpoint = 620;
    const navigate = useNavigate();




    const { trips,trip,user, appBasic } = useSelector((state: RootState) => state);


    const [tripData, updateTripData] = useState(trip as Trip);
    const { tripId,destinationId } = useParams<ParamTypes>()
    const [loading, setLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [activeDestination, setActiveDestination] = useState<TripDestination>();
    const [activeDate, setActiveDate] = useState("");
    const [activeDateRange, setActiveDateRange] = useState<string[]>();
    const [settlementData, setSettlementData] = useState<TripSettlement>();
    const [nearByPlaces, setNearByPlaces] = useState<GooglePlacesResponse>();
    const [convertCurrency, setConvertCurrency] = useState<ConvertParamTypes>();
    const [showAdvanceSettingModal, setShowAdvanceSettingModal] = useState(false);
    const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
    const [showGenerateReportLoading, setShowGenerateReportLoading] = useState(false);
    const [tripStatus, setTripStatus] = useState("");
    const [showNewDestinationLoading, setShowNewDestinationLoading] = useState(false);


    const [activityFilter, setActivityFilter] = useState<{
        members: string[],
        categories: string[]
    }>({
        members:[],
        categories: []
    });


    const [activeDashboard, setActiveDashboard] = useState(destinationId? destinationId == "settings"?"settings":"destination":"trip");

    const [activeTab, setActiveTab] = useState(destinationId? destinationId == "settings"?"Basic":"Planning":"Overview");
    const [tripMemberData, setTripMemberData] = useState<[TripMemberPopulated]>();
    const [advanceSettingType, setAdvanceSettingType] = useState("");

    const dispatch = useAppDispatch();


    const getTripData = () => {

        http
            .get<null,TripResponse>(trip_url+"/"+tripId)
            .then((res) => {

                if(res.status == 200){

                    dispatch(setTrip(res.data));

                    updateTripData(res.data)


                    const index = res.data.destinations.findIndex(destination => destination.suggestionsInProgress !== null);


                    if(index >= 0){
                        dispatch(
                            setAppBasic({
                                aiWaiting: {
                                    _trip: res.data._id,
                                    _destination: res.data.destinations[index]._id,
                                    category: res.data.destinations[index].suggestionsInProgress,
                                    status: 'pending',
                                },
                            })
                        );
                    }

                    if(destinationId){

                        let selectedDestination = filter(res.data.destinations, function(destination) { return destination._id === destinationId; });

                        if(selectedDestination && selectedDestination.length){

                            updateDefaultDestination( selectedDestination[0])
                        }

                        getExpenses(selectedDestination[0])

                    }else{
                        updateDefaultDestination( res.data.destinations[0])
                            getExpenses( res.data.destinations[0])
                    }



                }else{
                    navigate('/dashboard')
                }


            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {
                setLoading(false);
            });

    }

    useEffect(() => {
        if(trip){

            updateTripData(trip)
            if(destinationId){

                let selectedDestination = filter(trip.destinations, function(destination) { return destination._id === destinationId; });

                if(selectedDestination && selectedDestination.length){

                    updateDefaultDestination( selectedDestination[0])
                }
            }else{
                updateDefaultDestination( trip.destinations[0])
            }
        }
    },[trip])

    useEffect(() => {

        getTripData()
     /*   if(trip == null){
            getTripData()
        }else if(trip._id != tripId){
            getTripData()

        }else{



            updateTripData(trip)
            if(destinationId){

                let selectedDestination = filter(trip.destinations, function(destination) { return destination._id === destinationId; });

                if(selectedDestination && selectedDestination.length){

                    updateDefaultDestination( selectedDestination[0])
                }
                getExpenses(selectedDestination[0])

            }else{
                updateDefaultDestination( trip.destinations[0])
                getExpenses(trip.destinations[0])
            }

            setLoading(false);
        }

*/


    }, [dispatch]);

    const updateDefaultDestination = (defaultDestination: TripDestination) => {


        setActiveDestination(defaultDestination)

      //  let date = new Date(defaultDestination.startDate).toString()

     //   setActiveDate(date?date:"")


        let activeRages = dateRangeAsArray(defaultDestination.startDate,defaultDestination.endDate)
        setActiveDateRange(activeRages)


    }


    const updateActiveDestination = (defaultDestination: TripDestination) => {



        console.log("inside here 1")
        setActiveDestination(defaultDestination)


    }
    const updateTripDestination = (updatedDestination: TripDestination) => {


        var newDestinationsData = tripData?.destinations.map(function(destination) {
            return destination._id === updatedDestination._id ? updatedDestination : destination;
        }) as [TripDestination];


        setActiveDestination(updatedDestination)

        if(newDestinationsData){
            updateTripData({
                ...tripData,
                destinations: newDestinationsData
            })

        }
    }

    const updateTripChecklist = (updatedChecklists: [Checklist]) => {


            updateTripData({
                ...tripData,
                checklists: updatedChecklists
            })

    }
    const updateTripDocuments = (updatedDocuments: [TripDocument]) => {
            updateTripData({
                ...tripData,
                documents: updatedDocuments
            })

    }

    const updateTab = (tab: any) => {

        if(tab == "Delete Trip"|| tab == "Move to?"){
            setAdvanceSettingType(tab)
            setShowAdvanceSettingModal(true)
        }else{

            if(tab == "Expenses"){
                const locationIndex = activityFilter.categories.findIndex(category => {
                    return category == "location";
                });
                const websiteIndex = activityFilter.categories.findIndex(category => {
                    return category == "website";
                });
                 let categories  = activityFilter.categories


                categories.splice(locationIndex, 1);
                categories.splice(websiteIndex, 1);



                setActivityFilter({
                    ...activityFilter,
                    categories:categories
                })
            }else if(tab == "Activities"){

                setActivityFilter({
                    ...activityFilter,
                    categories:[]
                })
            }

            setActiveTab(tab)
        }

    }

    const updateActiveDashboard = (dashboard: any) => {

        setActiveDashboard(dashboard)

        setActiveTab(dashboard == "settings"? "Basic":dashboard ==  "trip"? "Overview": "Planning")

    }

    const getExpenses = ( defaultDestination:TripDestination) => {


        http
            .get<null,TripSettlementResponse>(trip_url+"/"+tripId+"/settlement")
            .then((res) => {


                if(res.status == 200){


                    setSettlementData(res.data)


                }



            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {


            });
    }


    const updateTripMemberData = (tripMembers: [TripMemberPopulated]) => {

        if(tripMembers){

            setIsUpdating(true)

            setTripMemberData(tripMembers)



            let updateType = ""

            let formData:any = []
            if(activeTab == "Members"){
                updateType = "members-settings"




                tripMembers.map(function (member) {

                    let pushData = {}

                    if(find(tripData.members, {_id: member._id})) {

                        pushData = { notifications:member.notifications,
                            status: member.status,
                            joinType: member.joinType,
                            canAddData: member.canAddData,
                            role: member.role,
                            budget: member.budget,
                            userId: member.userId?._id,
                            _id:member._id
                        }
                    }else{
                        pushData = { notifications:member.notifications,
                            status: member.status,
                            joinType: member.joinType,
                            canAddData: member.canAddData,
                            role: member.role,
                            budget: 0,
                            userId: member.userId?._id
                        }
                    }
                    formData.push(pushData)
                })
            }


            http
                .put<any, TripResponse>(trip_url+"/"+tripData._id, {updateType:updateType,members:tripMembers})
                .then((res) => {
                    setIsUpdating(false)
                    if(res.status == 200){

                        toastMessage(res.message,'')


                        updateTripData({
                            ...tripData,
                            members:res.data.members
                        })


                    }



                }).catch((error) => {
                console.log(error);
            })
                .finally(() => {
                    setLoading(false);
                });

        }

    }
    const updateTripDestinationData = (tripDestinations: [TripDestination]) => {

        setShowNewDestinationLoading(true)

        if(tripDestinations.length > 0){


            let updateType = "destinations-settings"

            http
                .put<any, TripResponse>(trip_url+"/"+tripData._id, {updateType:updateType,destinations:tripDestinations})
                .then((res) => {

                    if(res.status == 200){
                        setShowNewDestinationLoading(false)

                        dispatch(setTrip(res.data));
                        updateTripData(res.data)
                        toastMessage('Trip updated...','')
                    }


                }).catch((error) => {
                console.log(error);
            })
                .finally(() => {
                    setLoading(false);
                });

        }

    }
    const updateTripBasicData = async (data: Trip) => {

        setLoading(true);

        data.updateType = "basic"


       await http
            .put<any, TripResponse>(trip_url+"/"+tripData._id, data)
            .then((res) => {

                if(res.status == 200){

                    updateTripData({
                        ...tripData,
                        name: data.name,
                        description: data.description,
                    })


                    toastMessage('Trip basic changed','')

                }else{
                    toastMessage(res.message,'error')

                }



            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {
                setLoading(false);
            });



        return true



    }
    const updateTripStatus = () => {
        setLoading(true);

        let updateType = "status"

        http
            .put<any, TripResponse>(trip_url+"/"+tripData._id, {updateType:updateType,status:tripStatus})
            .then((res) => {

                if(res.status == 200){

                    updateTripData({
                        ...tripData,
                        status: tripStatus,
                    })

                    toastMessage('Trip status changed','')

                }else{
                    toastMessage(res.message,'error')

                }


            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {

                setShowAdvanceSettingModal(false)
                setLoading(false);
            });


    }

    const deleteTrip = () => {
        setLoading(true);

        http
            .delete<any, any>(trip_url+"/"+tripData._id, )
            .then((res) => {

                if(res.status == 200){
                    setLoading(false);

                    navigate('/trips/upcoming')
                }


            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {
                setLoading(false);
            });


    }
    const manageFilter = (id:any,type:any) => {

        let filterArray = activityFilter.categories

        if(type == "members"){
            filterArray = activityFilter.members
        }
        let index = filterArray.indexOf(id);

        if(index != -1){
            filterArray.splice(index, 1);
        }else{
            filterArray.push(id)
        }

        if(type == "members"){
            setActivityFilter({
                ...activityFilter,
                members: filterArray
            })
        }else{
            setActivityFilter({
                ...activityFilter,
                categories: filterArray
            })
        }

    }
    const generateReport = () => {

        setShowGenerateReportModal(true)
    }
    const gotToSettings = () => {

        if(tripData.userId._id ==  user?.data.userId){

            setActiveDashboard('settings')

            setActiveTab('Destinations')

        }else{
            toastMessage('Only trip admin can change settings!!!','error')
        }

    }
    const generateTripReport = () => {

        setShowGenerateReportLoading(true)

        setShowGenerateReportModal(false)

        updateTripData({
            ...tripData,
            isReportGenerated: true,
        })
        toastMessage('Trip report is being generated. Please check your email after few minutes','')
        http
            .post<any, any>(`${trip_url}/${tripData._id}/generate`,{settlement:settlementData} )
            .then((res) => {
                setShowGenerateReportLoading(false)


            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {
                setLoading(false);
            });

    }

    const downloadTripReport = () => {


        if(tripData){



            http
                .post<null, any>(`/signed/file`,{key: `${tripData._id}/exports/report.pdf`})
                .then(async (res) => {
                    if (res) {


                        const response = await fetch(res.data);


                        // Check if the request was successful
                        if (response.ok) {
                            const blob = await response.blob();

                            // Create a link element to trigger the download
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = tripData.name+'-report.pdf'; // Specify the desired filename
                            document.body.appendChild(link);

                            // Trigger the download
                            link.click();

                            // Remove the link element
                            document.body.removeChild(link);


                            setShowGenerateReportModal(false)
                            toastMessage('Successfully downloaded the report. Please check your download folder','')


                        }


                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {


                });


        }




    }


    return (
        <main className="col-md-11 ms-sm-auto col-lg-11 px-md-4 container-main trip-dashboard-container"  style={tripData && tripData.cover?.url? { background:"linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("+tripData.cover?.url+")"  } :{backgroundColor:"#f5f8fa"} }>
            {tripData?
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ tripData.name}</title>


                </Helmet>
                :'' }
            {tripData && tripData._id == tripId?

                tripData.status == 'completed' ?

                <>
                    <NotificationHeader message={`Your trip has been completed.`}  subMessage={`WARNING: No ADD or EDIT option available in completed trips!!!`} actionMessage={`Get Trip Report`} action={generateReport}/>

                    <div style={{ marginTop: '70px'}}>

                        <TripDashboardHeader trip={tripData} activeDashboard={activeDashboard}
                                             updateActiveDashboard={updateActiveDashboard}/>
                    </div>

                </>
                :
                    tripData.status == 'active' && eSMoment(new Date()).isAfter(tripData.destinations[tripData.destinations.length-1].endDate) ?


                        <>
                            <NotificationHeader  message={`Guess your holiday is over! Move the trip status to completed in order to generate trip report.`} subMessage={`WARNING: No ADD or EDIT option available in completed trips!!!`} actionMessage={`Settings`} action={gotToSettings}/>

                            <div style={{ marginTop: '60px'}}>

                                <TripDashboardHeader trip={tripData} activeDashboard={activeDashboard}
                                                     updateActiveDashboard={updateActiveDashboard}/>
                            </div>

                        </>
                        :
                <TripDashboardHeader trip={tripData} activeDashboard={activeDashboard}
                                     updateActiveDashboard={updateActiveDashboard}/>


            :''}

            {tripData && tripData._id == tripId?

                <>


                    <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                            <div className=""></div>
                        </div>
                        <div className="chartjs-size-monitor-shrink">
                            <div className=""></div>
                        </div>
                    </div>

                    {tripData && activeDashboard =="destination"?

                        <div className="row mb-0 destinations-row">
                            <div className={`col-xl-12 `}>
                                <nav className="navbar navbar-expand-lg navbar-light">
                                    <div className="destinations-list" id="navbarText">
                                        <ul className="navbar-nav mr-auto flex-row" >
                                            {tripData.destinations.map(function (destination,key) {

                                                let destinationName =  destination.name.split(",");
                                                return(
                                                    <li className={`nav-item ${destination._id == activeDestination?._id? 'active': ''}`} key={key} onClick={function () {


                                                        setActiveDestination(destination)
                                                        setActiveDate("")

                                                      //  setActiveDate(new Date(destination.startDate).toString())
                                                        let activeRages = dateRangeAsArray(destination.startDate,destination.endDate)
                                                        setActiveDateRange(activeRages)
                                                        setActiveTab("Planning");

                                                        window.history.replaceState({}, '', `/trip/${tripId}/${destination._id}`);


                                                    }}>
                                                        <div className=" d-flex flex-column flex-center text-center">
                                                            <h3 className="fs-5 fw-bolder">{destinationName[0]}</h3>
                                                            <p className="mb-0">{dayjs(destination.startDate).format('DD MMMM YY')} - {dayjs(destination.endDate).format('DD MMMM YY')}</p>
                                                        </div>
                                                    </li>
                                                )


                                            })}
                                            <OverlayTrigger  placement="top"  overlay={<Tooltip id="tooltip-dashboard" >Click here to add new destination</Tooltip>}>

                                                <li className={`hover-opacity-btn cursor-pointer`} style={{ backgroundColor: 'white', padding: '12px 20px', borderTopRightRadius: '0.65rem', borderTopLeftRadius: '0.65rem'}} onClick={function () {

                                                            setActiveDashboard('settings')
                                                            setActiveTab("Destinations")
                                                }}>
                                                    <div className=" d-flex flex-column flex-center text-center">
                                                        <img src={PlusRedImg} width={25}  style={{padding:'3px 0px'}}  />
                                                    </div>
                                                </li>
                                            </OverlayTrigger>

                                        </ul>
                                    </div>

                                </nav>
                                <div className={`d-flex flex-column flex-xl-row`}>

                                    <ul className="pagination date-pagination 1" style={width <= 620? {width:'100%'}: {}}>
                                        {activeDateRange && activeDateRange.length? activeDateRange.map(function (date,index) {


                                                return(
                                                    <li className={`page-item ${activeDate == date? 'active':''}`} aria-current="page" key={index} onClick={()=>{
                                                        if(activeDate == date){
                                                            setActiveDate("")
                                                        }else{
                                                            setActiveDate(date)
                                                        }

                                                    }} >
                                                <span className="page-link cursor-pointer">{dayjs(date).format('ddd DD')}
                                                    {activeDate == date?

                                                        <img src={CloseBtnImg} className={`position-absolute`} width={15} style={{ top: '0px', right: '0px'}}/>
                                                        : ''}

                                                </span>
                                                    </li>
                                                )

                                            })

                                            : "" }
                                    </ul>



                                </div>

                            </div>


                        </div>

                        :

                        ""
                    }

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-xl-3 col-md-3 left-menu">

                                    {activeDashboard == "settings"?

                                        <TripSettingsMenu activeTab={activeTab} tripStatus={tripData.status} tripType={tripData.tripType} updateTab={updateTab} />
                                        :
                                        width > breakpoint?
                                            <TripMenu  activeDashboard={activeDashboard} activeTab={activeTab} updateTab={updateTab} />
                                            :""
                                    }
                                </div>

                                {activeDashboard == "settings"?

                                    activeTab == "Basic" ?

                                        <div className="col-xl-9 col-md-9">

                                              <TripSettingsBasic  updateTripBasicData={updateTripBasicData} tripId={tripId} tripData={tripData} />


                                        </div>
                                        : activeTab == "Destinations" ?
                                            <div className="col-xl-9 col-md-9">
                                                <TripSettingsDestinations updateTripDestinationData={updateTripDestinationData} updateTripData={updateTripData}  tripId={tripId} tripData={tripData} />
                                            </div>
                                            :  activeTab == "Members" ?

                                                <div className="col-xl-9 col-md-9">

                                                    {tripData.tripType == "group"?

                                                        <>
                                                            <TripSettingsMembers updateTripMemberData={updateTripMemberData} isUpdating={isUpdating} tripId={tripId} tripData={tripData} />

                                                        </>
                                                        :tripData.tripType == "family"?
                                                            <TripSettingsFamilyMembers updateTripMemberData={updateTripMemberData}  isUpdating={isUpdating}  tripId={tripId} tripData={tripData} />

                                                   :'' }
                                                        </div>
                                                : ""


                                    :
                                    activeTab == "Overview" ?

                                        <TripOverview settlementData={settlementData?.trip} getTripData={getTripData} tripData={tripData}  />

                                        : activeTab == "Planning" ?

                                            <TripPlanning tripData={tripData} activeDate={activeDate} activityFilter={activityFilter}updateActiveDestination={updateActiveDestination} activeDestination={activeDestination}  updateTripDestination={updateTripDestination} />

                                            : activeTab == "Documents--" ?

                                                <TripDestinationDocuments activeDestination={activeDestination}  />
                                                :
                                                activeTab == "Documents" ?

                                                    <TripDocuments tripData={tripData} updateTripDocuments={updateTripDocuments}  />

                                                    : activeTab == "Checklist" ?

                                                        <TripCheckList tripData={tripData} updateTripChecklist={updateTripChecklist} updateTripData={updateTripData}  />
                                                        : activeTab == "Note" ?

                                                            <TripNote tripData={tripData} />


                                                            : activeTab == "Activities" ?


                                                                <TripLiveActivity  tripData={tripData} activityFilter={activityFilter}   activeDate={activeDate} activeDestination={activeDestination}  updateTripDestination={updateTripDestination}   updateDefaultDestination={updateDefaultDestination}    />


                                                                : activeTab == "Expenses" && activeDestination?

                                                                    <DestinationExpenses settlementData={settlementData?.destinations} activityFilter={activityFilter}   activeDate={activeDate} tripData={tripData} activeDestinationItem={activeDestination}   />


                                                                    : ""
                                }


                            </div>
                        </div>

                        { width < breakpoint?
                            <TripMenu activeTab={activeTab} updateTab={updateTab} activeDashboard={activeDashboard} />

                            :""
                        }
                    </div>
                </>

                :
                <a  className="card card-stretch mb-5 bg-light-info hoverable shadow-none trip-list-select cursor-pointer mt-4" style={{ height: '95%'}}>

                    <div className="card-body d-flex flex-column justify-content-center">

                        <Loader cssClass="normal-loader"/>
                    </div>
                </a>

            }

            <Modal show={showAdvanceSettingModal} className="" onHide={function () {

                setShowAdvanceSettingModal(false)
            }}  animation={true} centered>
                <div className="modal-header pb-0 border-0 justify-content-end">

                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={function () {

                        setShowAdvanceSettingModal(false)
                    }}>

                        <i className="bi bi-x fs-1"></i>
                    </div>

                </div>

                {advanceSettingType == "Delete Trip"?
                    <div className="modal-body pt-0">

                        <div className="text-center mb-10">
                            <h1 className="mb-3 fs-1 text-dark">You're about to delete your trip</h1>
                            <div className="text-gray-400 fs-5">All your trip details will be permenently removed and
                                you won't be able to see them again.</div>
                        </div>

                        <div className="d-flex mt-5 justify-content-end">

                            <button type="button" name="backward"
                                    className=" btn btn-lg btn-light shadow-none me-3" onClick={() => {
                                setShowAdvanceSettingModal(false)
                            } }>Cancel
                            </button>
                            <button type="button" name="backward"
                                    className=" btn btn-lg btn-primary shadow-none" disabled={loading? true: false} onClick={() => {

                                if(!loading){
                                    deleteTrip()
                                }
                            }}>Confirm
                            </button>

                        </div>
                    </div>
                    :
                    <div className="modal-body">

                        <div className="text-center mb-0">
                            <h1 className="mb-3 fs-1 text-dark">You're about to move your trip</h1>
                            <div className="text-gray-400 fs-5"> ..</div>
                        </div>
                        <div className="fv-row">

                            <div className=" mb-20">

                                {tripData ?tripData.status == 'pending'?
                                    <label className="d-flex flex-stack mb-5 cursor-pointer">

                                                            <span className="d-flex align-items-center me-2">

                                                                <span className="symbol symbol-50px me-3">
                                                                    <span className="symbol-label bg-light-primary">
                                                                        <span
                                                                            className="svg-icon svg-icon-1 svg-icon-primary">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24"
                                                                                 fill="none"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.3"
                                                                                      d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                                                                                      fill="currentColor"></path>
                                                                                <path
                                                                                    d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                                                                                    fill="currentColor"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </span>
                                                                </span>


                                                                <span className="d-flex flex-column">
                                                                    <span
                                                                        className="fw-bold fs-6">Active trip</span>
                                                                    <span className="fs-7 text-muted">Creating a clear text structure is just one SEO</span>
                                                                </span>

                                                            </span>


                                        <span className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input" type="radio"
                                                                       name="category" onChange={() => {
                                                                    setTripStatus("active")
                                                                }}/>
                                                            </span>

                                    </label>
                                    : tripData.status == 'active'?
                                    <label className="d-flex flex-stack mb-5 cursor-pointer">

                                                            <span className="d-flex align-items-center me-2">
                                                                <span className="symbol symbol-50px me-3">
                                                                    <span className="symbol-label bg-light-danger">

                                                                        <span
                                                                            className="svg-icon svg-icon-1 svg-icon-danger">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24"
                                                                                 fill="none"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <rect x="2" y="2" width="9" height="9"
                                                                                      rx="2" fill="currentColor"></rect>
                                                                                <rect opacity="0.3" x="13" y="2" width="9"
                                                                                      height="9" rx="2"
                                                                                      fill="currentColor"></rect>
                                                                                <rect opacity="0.3" x="13" y="13" width="9"
                                                                                      height="9" rx="2"
                                                                                      fill="currentColor"></rect>
                                                                                <rect opacity="0.3" x="2" y="13" width="9"
                                                                                      height="9" rx="2"
                                                                                      fill="currentColor"></rect>
                                                                            </svg>
                                                                        </span>

                                                                    </span>
                                                                </span>


                                                                <span className="d-flex flex-column">
                                                                    <span
                                                                        className="fw-bold fs-6">Completed trip</span>
                                                                    <span className="fs-7 text-muted">Completed trip cannot be moved</span>
                                                                </span>

                                                            </span>


                                        <span className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input" type="radio"
                                                                       name="category" onChange={() => {
                                                                    setTripStatus("completed")
                                                                }} />
                                                            </span>

                                    </label>
                                        :'' :''}

                            </div>

                        </div>

                        <div className="d-flex mt-5 justify-content-end">

                            <button type="button" name="backward"
                                    className=" btn btn-lg btn-light shadow-none me-3" onClick={() => {
                                setShowAdvanceSettingModal(false)
                            } }>Cancel
                            </button>
                            <button type="button" name="backward"
                                    className=" btn btn-lg btn-primary shadow-none" disabled={loading? true: false} onClick={() => {

                                if(!loading){
                                    updateTripStatus()
                                }
                            }}>Save
                            </button>

                        </div>
                    </div>
                }


            </Modal>
            <Modal show={showGenerateReportModal} className="" onHide={function () {

                setShowGenerateReportModal(false)
            }}  animation={true} centered>
                <Modal.Header className={`border-0 pb-0`}>
                    <Modal.Title>
                        <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=> setShowGenerateReportModal(false)}/>
                    </Modal.Title>
                    <h3 className={`text-dark fs-4 fw-bolder`}></h3>
                    <span  className="cursor-pointer save"  onClick={()=> setShowGenerateReportModal(false)} >  <i className="bi bi-x fs-1"></i></span>

                </Modal.Header>
                {tripData && tripData.isReportGenerated?

                    <div className="modal-body pt-0 text-center">

                        <h3 className={`fs-3 text-dark fw-bolder`}>Download trip report </h3>
                        <p className={`fs-7 text-dark`}>Get your trip report in Excel </p>


                        <button className={`btn btn-primary mt-3`} disabled={showGenerateReportLoading? true: false} onClick={()=>{
                            downloadTripReport()
                        }}>Download</button>
                    </div>

                :
                    <div className="modal-body pt-0 text-center">

                        <h3 className={`fs-3 text-dark fw-bolder`}>Generate trip report </h3>
                        <p className={`fs-7 text-dark`}>All the team members will receive email report in Excel </p>


                        <button className={`btn btn-primary mt-3`} disabled={showGenerateReportLoading? true: false} onClick={()=>{
                            generateTripReport()
                        }}>Generate</button>
                    </div>
                }



            </Modal>

        </main>
    );
};
export default TripContainer;
