import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { showAlert } from '../util';
import { base_url } from './api.utils';

const user = localStorage.getItem('user');
let userData = null;

if (user) {
    userData = JSON.parse(user);
}

const http: AxiosInstance = axios.create({
    baseURL: base_url,
});

http.defaults.headers.post['Content-Type'] = 'application/json';

if (userData && userData.data) {
    http.defaults.headers.common.Authorization = 'Bearer ' + userData.data.accessToken;
}

http.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return response;
    },
    (error: AxiosError) => {
        const { response, request }: { response?: AxiosResponse; request?: XMLHttpRequest } = error;


        if (response) {
            if (response.status === 400) {
                return response.data;
            }else if (response.status === 401) {
                localStorage.clear();

                window.location.reload()
            } else {
                // showAlert(response.data?.message, 'error');
                return Promise.reject(response);
            }
        } else if (request) {
            // showAlert('Request failed. Please try again.', 'error');
            return Promise.reject(request);
        }
        return Promise.reject(error);
    }
);

export default http;
