import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';




const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);


if (process.env.REACT_APP_MODE === 'prod') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
}


root.render(
    <React.StrictMode>
        <Provider store={store}>

                <App />
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
