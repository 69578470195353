import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserResponse} from "../../interfaces/user.interface";

const user = createSlice({
    name: 'user',
    initialState: null as UserResponse | null,
    reducers: {
        setUser(state, { payload }: PayloadAction<UserResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setUser } = user.actions;
export default user.reducer;
