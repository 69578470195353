import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {TripsResponse} from '../../interfaces/trip.interface';

const trips = createSlice({
    name: 'trips',
    initialState: null as TripsResponse | null,
    reducers: {
        setTrips(state, { payload }: PayloadAction<TripsResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setTrips } = trips.actions;
export default trips.reducer;
