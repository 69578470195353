import React from "react";
import Options from "../options/options";

const GeneralOptions = (props) => {
  const options = [
    {
      name: "Create Trip",
      handler: props.actionProvider.handleCreateTrip,
      id: 1,
    },
    {
      name: "Trips",
      handler: props.actionProvider.handleTrips,
      id: 2,
    },
    {
      name: "Profile",
      handler: props.actionProvider.handleProfile,
      id: 3,
    },
    {
      name: "Message",
      handler: props.actionProvider.handleMessage,
      id: 5,
    },
    {
      name: "Join Trip",
      handler: props.actionProvider.handleJoinTrip,
      id: 6,
    },
  ];

  return <Options options={options} />;
};

export default GeneralOptions;