import React, { FC,useEffect,useState } from 'react';

import '../trips.scss'
import {Trip} from '../../../interfaces/trip.interface';


import dayjs from "dayjs";
import CloseRedButtonImgIcon from "../../../images/icons/close-red.svg";
import AcceptButtonImgIcon from "../../../images/icons/accept.svg";
import {getGooglePhotoUrl} from "../../../util";

type ListItemProps = {
    tripItem: Trip;
    tripId: any;
    selectTrip: any;
    keyItem:any,
    tripStatus:string,
    manageTripInvite:any,
    width:number,
}

const ListItem: FC <ListItemProps> = ({ tripItem,tripId, selectTrip ,keyItem,tripStatus,manageTripInvite, width }) => {

    const [isAcceptToggle, setIsAcceptToggle] = useState(false);
    const [isRejectToggle, setIsRejectToggle] = useState(false);

    const acceptToggleHover =()=> {
        setIsAcceptToggle(!isAcceptToggle)
    }
    const rejectToggleHover =()=> {
        setIsRejectToggle(!isRejectToggle)
    }

    return (


        <div className={`d-flex flex-stack py-4 px-2  trip-item  ${width >= 620? tripItem._id == tripId? "active" : "" : ""} ${tripStatus == "invited"? 'invited-trip': ''}`}  onClick={() =>selectTrip(tripItem)} key={keyItem}>
            <div className="d-flex align-items-center">
                <div className="symbol symbol-45px symbol-circle">
                    {tripItem.cover?
                        <img alt="Pic" src={tripItem.cover.url? tripItem.cover.url: getGooglePhotoUrl(tripItem.cover?.google!, 300, 300)} />
                        :
                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-capitalize">{(tripItem.name).charAt(0)}{(tripItem.name).split(" ")[1].charAt(0)}</span>
                    }


                </div>
                <div className="ms-3">
                    <a href="#"
                       className="fs-5 fw-bolder text-dark text-hover-primary mb-2">{(tripItem.name).length > 30? (tripItem.name).substring(0,30) + '...' : tripItem.name}</a>
                    <div className="fw-bold text-gray-400 fs-7">{dayjs(tripItem.destinations[0].startDate).format('DD MMMM YY')} - {dayjs(tripItem.destinations[tripItem.destinations.length-1].endDate).format('DD MMMM YY')}</div>
                </div>
            </div>
            {tripStatus == "invited"?
                <div className="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1 invite-tool">

                        <img src={CloseRedButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onMouseEnter={acceptToggleHover} onMouseLeave={acceptToggleHover}
                             onClick={(e) => manageTripInvite("reject",e)}/>
                        <img src={AcceptButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onMouseEnter={acceptToggleHover} onMouseLeave={acceptToggleHover}
                             onClick={(e) => manageTripInvite("accept",e)}/>


                </div>
            :

                ""
            }
        </div>


    );
};
export default ListItem;
