'use strict';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import {getFileDataURL} from "../util";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const dataURLtoFile = (dataURl, filename) => {
	var arr = dataURl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
};

export const getExtension = (filename, isVideo) => {
	var name = filename.split('.');
	return {
		fileName: name[0],
		extension: isVideo ? 'png' : name[1] || 'png',
	};
};

export const createImage =  (canvas, filename) => {
	var image = canvas.toDataURL();
	var file = dataURLtoFile(image, filename);
	var imageUrl = URL.createObjectURL(file);
	return {file, image, imageUrl};
};export const createCanvas = async (page, config) => {
	try {
		const vp = page.getViewport({ scale: 1 });
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');

		const desiredWidth = config.width || 300;
		const desiredHeight = config.height || 200;

		const scale = Math.min(desiredWidth / vp.width, desiredHeight / vp.height);
		const viewport = page.getViewport({ scale });

		canvas.width = viewport.width;
		canvas.height = viewport.height;

		// Clear any existing transformations before drawing
		context.setTransform(1, 0, 0, 1, 0, 0);

		// Render the page into the canvas
		return page
			.render({
				canvasContext: context,
				viewport: viewport,
			})
			.promise.then(() => {
				return canvas;
			})
			.catch((err) => {
				console.log(err);
				return err;
			});
	} catch (err) {
		return err;
	}
};


export const readPdf = async (pdf, config) => {

	let pdfData = await getFileDataURL(pdf)


	var loadingTask = pdfjs.getDocument(pdfData);
	return loadingTask.promise.then(function(pdf) {
		// you can now use *pdf* here

		return pdf.getPage(1).then(async function(page) {
			// you can now use *page* here

			const createCanvasData = await  createCanvas(page, config)
			return  createImage(createCanvasData, config.name);
		});
	});


}

export const getPDFThumbnail = async (data, config) => {
	return readPdf(data, config)
};
