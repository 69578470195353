import React, {FC, useEffect, useState} from 'react';
import Sidebar from "../sidebar/Sidebar";
import AccountMenu from "./AccountMenu";
import AccountEdit from "./AccountEdit";
import dayjs from "dayjs";
import Header from "../header/Header";
import UserPackageContainer from "../userPackages/UserPackageContainer";
import http from "../../services/api";
import {CountriesResponse} from "../../interfaces/country.interface";
import {base_url, countries_url, upload_url, user_url, s3_user_url} from "../../services/api.utils";
import {User, UserResponse} from "../../interfaces/user.interface";
import {setUser} from "./userSlice";
import {useAppDispatch} from "../../store";
import DefaultAvatarImg from "../../images/illustration-icons/default-avatar.png";
import EditAvatarImg from "../../images/icons/edit-white.svg";
import Logs from "./logs/Logs";
import Addresses from "./addresses/Addresses";
import Family from "./family/Family";
import Transactions from "./transactions/Transactions";
import {showAlert, toastMessage} from "../../util";
import {UploadResponse} from "../../interfaces/upload.interface";
import Modal from "react-bootstrap/cjs/Modal";
import ReactCrop, {Crop} from "react-image-crop";


import ProfileIconImg from '../../images/icons/profile-icon.svg'
import CalenderIconImg from '../../images/icons/calender.svg'
import EmailIconImg from '../../images/icons/email-icon.svg'
import {useSelector} from "react-redux";
import {RootState} from "../../rootReducer";
import Preference from "./preference/Preference";


const AccountContainer: FC = () => {

    const {user } = useSelector((state: RootState) => state);


    const [activeTab, setActiveTab] = useState("Account");
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState<User>();

    const [showProfilePictureModal, setShowProfilePictureModal] = useState(false);
    const [profilePictureFile, setProfilePictureFile] = useState<any>();
    const [pixelCrop, setPixelCrop] = useState<Crop>({
        unit: 'px', // Can be 'px' or '%'
        x: 25,
        y: 25,
        width:150,
        height:150
    });
    const [uploadLoading, setUploadLoading] = useState(false);
    const [crop, setCrop] = useState<Crop>({
        unit: 'px', // Can be 'px' or '%'
        x: 25,
        y: 25,
        width:150,
        height:150
    })
    const dispatch = useAppDispatch();




    useEffect(() => {
        setLoading(true);


        if (user == null) {
            http
                .get<null, UserResponse>(user_url)
                .then((res) => {
                    dispatch(setUser(res));
                    setUserData(res.data)


                }).catch((error) => {
                console.log(error);
            })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setUserData(user.data)
            setLoading(false);
        }

    }, []);

    const updateUserData = (user: User) => {

        setUserData(user)

    }
    const updateTab = (tab: string) => {

        setActiveTab(tab)

    }

    const uploadCallback = (data:any) => {

        let fileSize = data.size /(1024*1024)

        if(fileSize <= 0.7){

            setProfilePictureFile(data)

            setShowProfilePictureModal(true)
        }else{
            showAlert("Image size too large, try below 700KB","error")

        }

    }

    const saveCroppedImage = (data:any) => {



        setUploadLoading(true);
        var formData = new FormData();
        formData.append("file", profilePictureFile);

        formData.append("folder", `profiles/${userData?.userId}`);
        formData.append("crop", JSON.stringify(pixelCrop));


        http
            .post<null, UploadResponse>(base_url+upload_url, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                },})
            .then((res) => {
                if (res) {

                    const { data} = res;
                    let userData = {
                        updateType:"advanced",
                        cover: data,
                    }

                    http
                        .put<User, UserResponse>(user_url, userData)
                        .then((res) => {
                            if (res) {

                                setShowProfilePictureModal(false);



                                let resData = res
                                resData.data.accessToken = user?.data.accessToken


                                dispatch(setUser(resData));
                                setUserData(resData.data)
                                updateUserData(resData.data)

                                localStorage.setItem('user', JSON.stringify(resData));


                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            setUploadLoading(false);
                        });

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setUploadLoading(false);
            });




    }


    console.log("userData",userData)
    return (
        <main className="col-md-11 ms-sm-auto col-lg-11 px-md-4 container-main account-container" >

            <Header title="Account" />

            {userData?
                <>
                    <div className="card mb-5 mb-xl-10 ">
                        <div className="card-body pt-9 pb-0">

                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                                <div className="me-2 mb-1">
                                    <label className="edit-cover symbol symbol-100px overflow-hidden d-flex flex-center z-index-1 cursor-pointer" >

                                        <img src={userData?.cover?.generatedName? `${s3_user_url}/profiles/${userData?.userId}/${userData?.cover.generatedName}`: DefaultAvatarImg} width={75} className="" />
                                        <div className="overlay"></div>
                                        <span className="change-photo">
                                            <span>
                                                <img src={EditAvatarImg} width={`30`} />
                                            </span>
                                        </span>
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            onChange={function (e){
                                                if(e && e.target.files && e.target.files.length){
                                                    uploadCallback(e.target.files[0])
                                                }
                                            }}
                                        />
                                    </label>
                                </div>


                                <div className="flex-grow-1">

                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">

                                        <div className="d-flex flex-column">

                                            <div className="d-flex align-items-center mb-2">
                                                <span
                                                   className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{userData.firstName} {userData.lastName}</span>


                                            </div>


                                            <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                                                <span
                                                   className="d-flex align-items-center text-gray-400  cursor-pointer text-hover-primary me-5 mb-2" onClick={()=>  {
                                                       toastMessage('Copied to clipboard','')
                                                    navigator.clipboard.writeText(userData.userName)
                                                }}>

														<span className="svg-icon svg-icon-4 me-1">
															<img src={ProfileIconImg} width={20} style={{ opacity: 0.5}} />

														</span>
                                                    {userData.userName}
                                                </span>
                                                <span
                                                   className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">

														<span className="svg-icon svg-icon-4 me-1">
															<img src={CalenderIconImg} width={20} style={{ opacity: 0.5}} />
														</span>
                                                    {dayjs(userData.createdAt).format('DD MMMM YY')}
                                                </span>
                                                <span
                                                   className="d-flex align-items-center text-gray-400 cursor-pointer text-hover-primary mb-2"  onClick={()=>  {
                                                    toastMessage('Copied to clipboard','')
                                                    navigator.clipboard.writeText(userData.email)
                                                }}>

														<span className="svg-icon svg-icon-4 me-1">
                                                            <img src={EmailIconImg} width={20} style={{ opacity: 0.5}} />
														</span>
                                                    {userData.email}
                                                </span>
                                            </div>
                                            {/*<div className="d-flex flex-column flex-grow-1 pe-8">

                                                <div className="d-flex flex-wrap">

                                                    <div
                                                        className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                        <div className="d-flex align-items-center">


                                                            <div className="fs-4 text-dark counted" > {userData.userPackage?.packageId?.name}</div>
                                                        </div>


                                                        <div
                                                            className="fw-semibold fs-6 text-gray-400">Package
                                                        </div>

                                                    </div>


                                                    <div
                                                        className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                                                        <div className="d-flex align-items-center">
                                                            <div className="fs-4 text-dark counted" > {dayjs(userData.userPackage?.endDate).format('DD MMMM YY')}  </div>
                                                        </div>

                                                        <div
                                                            className="fw-semibold fs-6 text-gray-400">Renew By
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>*/}

                                        </div>


                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                    <div className="row">

                        <div className="col-xl-3">
                            <AccountMenu activeTab={activeTab} updateTab={updateTab} />
                        </div>
                        <div className="col-xl-9">

                            {activeTab == "Account" ?

                                <AccountEdit updateUserData={updateUserData} userDataProps={userData}/>
                                : activeTab ==  "Preference"?
                                    <Preference updateUserData={updateUserData} userData={userData}/>
                                    : activeTab ==  "Package"?

                                    <UserPackageContainer updateUserData={updateUserData}  fromSettings={true} userData={userData}/>

                                    : activeTab ==  "Addresses"?

                                        <Addresses  updateUserData={updateUserData}  userData={userData}/>

                                        : activeTab ==  "Logs"?

                                            <Logs/>

                                            : activeTab ==  "Family"?

                                                <Family updateUserData={updateUserData}  userData={userData}/>

                                                : activeTab ==  "Transactions"?

                                                    <Transactions/>

                                                    : ""
                            }
                        </div>

                    </div>
                    <Modal show={showProfilePictureModal} className="planning-form-modal" onHide={function () {

                        setShowProfilePictureModal(false)
                    }}  onExited={function () {

                    }} backdrop={"static"} animation={true}>
                        <Modal.Body>
                            <div className="d-flex text-center header">
                                <div className="p-2 flex-fill text-start">
                                    <span className="close cursor-pointer" onClick={()=>
                                        setShowProfilePictureModal(false)
                                    }>Back</span>
                                </div>
                                <div className="p-2 flex-fill title"></div>
                                <div className="p-2 flex-fill text-end save">
                                    {uploadLoading?
                                        <span className="text-muted">Saving...</span>

                                        :
                                        <span className="cursor-pointer"onClick={saveCroppedImage}>Save</span>

                                    }
                                </div>
                            </div>

                            {profilePictureFile &&
                                <ReactCrop crop={crop} aspect={1} keepSelection={true} minHeight={150} minWidth={150} onComplete={function (pixelCropItem ){

                                    setPixelCrop(pixelCropItem)
                                }} onChange={c => setCrop(c)}
                                >
                                    <img src={URL.createObjectURL(profilePictureFile)} width={480}  />

                                </ReactCrop>

                            }


                        </Modal.Body>
                    </Modal>
                </>

                : "Loading..."
            }



        </main>
    );
};
export default AccountContainer;
