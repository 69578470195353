import React, {FC, useEffect, useState} from 'react';
import Dropdown from 'react-bootstrap/cjs/Dropdown';
import {Trip, TripDestination} from "../../../interfaces/trip.interface";
import {eSMoment, formatBytes} from "../../../util";

import './documents.scss'
import introSliderImg from "../../../images/trip/intro_slider_2.svg";
import ProgressBar from "react-bootstrap/cjs/ProgressBar";
import TripDocumentInfo from "./TripDocumentInfo";
type Props = {
    activeDestination:TripDestination | undefined
}

const TripDestinationDocuments: FC<Props> = ({activeDestination}) => {


    let totalSize = 0
    let totalFiles = 0

    let documentInvoiceHtml: any = [];
    let documentHtml: any = [];
    activeDestination?.activities?.map(function (activity, key) {

        let activeActivity = activity.invoices?.map(function (invoice, invoiceKey) {

            let extension = invoice.generatedName.split('.').pop();

            totalSize += parseFloat(invoice.size)
            totalFiles++;
            return (
                <li className="file-box mt-3  me-3 cursor-pointer" key={invoiceKey} onClick={function () {

                    window.location.href = invoice.url;
                }}>
                    <div className="file-top">
                        <i className={`bx primary-color ${extension == "pdf" ? "bxs-file-pdf" : extension == "doc" ? "bxs-file-doc" : extension == "docx" ? "bxs-file-doc" : extension == "jpeg" ? "bxs-file-jpg" : ""}`}></i>
                    </div>

                    <div className="overlay"></div>

                    <div className="download-button">
                        <i className='bx bxs-download'></i>
                    </div>
                    <div className="file-bottom">
                        <h6> {invoice.originalName}.{extension}</h6>
                        <p className="mb-1">{formatBytes(invoice.size, 2)}</p>
                        <p><b>Uploaded on: </b>{eSMoment(invoice.createdAt).format('DD MMM YY')}</p>
                    </div>
                </li>
            )
        })

        let activeDocumentHtml = activity.documents?.map(function (document, documentKey) {

            let extension = document.generatedName.split('.').pop();
            totalSize += parseFloat(document.size)
            totalFiles++;
            return (

                <li className="file-box  mt-3 me-3 cursor-pointer" key={documentKey} onClick={function () {

                    window.location.href = document.url;
                }}>
                    <div className="file-top">
                        <i className={`bx primary-color ${extension == "pdf" ? "bxs-file-pdf" : extension == "doc" ? "bxs-file-doc" : extension == "docx" ? "bxs-file-doc" : extension == "jpeg" ? "bxs-file-jpg" : ""}`}></i>
                    </div>
                    <div className="overlay"></div>

                    <div className="download-button"><a href="#"> BUTTON </a></div>
                    <div className="file-bottom">
                        <h6> {document.originalName}.{extension}</h6>
                        <p className="mb-1">{formatBytes(document.size, 2)}</p>
                        <p><b>Uploaded on: </b>{eSMoment(document.createdAt).format('DD MMM YY')}</p>
                    </div>
                </li>
            )
        })
        if (activeActivity && activeActivity.length) {
            documentInvoiceHtml.push(activeActivity)

        }


        if (activeDocumentHtml && activeDocumentHtml.length) {
            documentHtml.push(activeDocumentHtml)

        }
    })


    return (

        <div className="col-xl-9 col-md-9 trip-document-container">
            <div className="row">

                <div className="col-xl-12 col-md-12">
                    <div className="file-content">
                        <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none trip-list-select">

                            <div className="card-header align-items-center border-0">
                                <div className="card-title align-items-start flex-column">
                                    <h3 className="fw-bolder mb-1">Documents</h3>

                                    <div
                                        className="fs-6 text-gray-400">Total {totalFiles} {totalFiles > 1 ? "files" : "file"}, {formatBytes(totalSize, 2)} space
                                        usage
                                    </div>
                                </div>
                                <div className="card-toolbar">
                                    {/*   <i className='bx bx-list-ul fs-2 primary-color mx-1 cursor-pointer'></i>
                                        <i className='bx bx-grid-alt fs-1 text-gray-400'></i>*/}
                                </div>
                            </div>

                            <div className="card-body d-flex flex-column file-manager">

                                {documentHtml && documentHtml.length || documentInvoiceHtml && documentInvoiceHtml.length ?

                                    <ul className="files">

                                        {documentInvoiceHtml}
                                        {documentHtml}
                                    </ul>

                                    :
                                    <div className="tracking-item text-center my-5">

                                        <img src={introSliderImg} width={250}/>
                                        <div className="my-3 ">
                                            <span className="text-primary fs-5">No document added yet.</span>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    );



};
export default TripDestinationDocuments;
