import React, {FC, useEffect, useState} from 'react';
import '../trip.scss'
import find from "lodash/find";
import SearchMemberItem from "../../trips/cards/SearchMemberItem";
import http from "../../../services/api";
import {Trip, TripMember, TripMemberPopulated, TripResponse} from "../../../interfaces/trip.interface";
import {trip_url, user_search_url, user_url} from "../../../services/api.utils";
import {setTrip} from "../tripSlice";
import filter from "lodash/filter";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch} from "../../../store";
import {MembersResponse, UserSearch, UsersSearchResponse} from "../../../interfaces/user.interface";
import remove from "lodash/remove";
import SettingsSearchMemberItem from "../cards/settings/SettingsSearchMemberItem";
import {cloneDeep, findIndex} from "lodash";
import CloseButtonImgIcon from '../../../images/icons/close.svg'
import FamilyMemberItem from "../../trips/cards/FamilyMemberItem";
import EmptyFamilyImg from "../../../images/illustration-icons/empty-family.svg";
import Loader from "../../loader/Loader";

type ParamTypes =  {
    tripId: string,
    tripData:Trip,
    updateTripMemberData:any,
    isUpdating:boolean
}
const TripSettingsFamilyMembers: FC <ParamTypes>= ({tripData,updateTripMemberData,isUpdating} ) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { searchUsers } = useSelector((state: RootState) => state);

    const [saveLoading, setSaveLoading] = useState(false);

    const [tripBasicData, setTripBasicData] = useState(tripData);
    const [loading, setLoading] = useState(true);
    const [isFamilyloading, setIsFamilyloading] = useState(true);
    const [searchKey, setSearchKey] = useState("");
    const [members, setMembers] = useState(searchUsers as UsersSearchResponse| undefined);
    const { user } = useSelector((state: RootState) => state);
    const [familyMembers, setFamilyMembers] = useState<MembersResponse>();

    const authUserId = user?.data.userId

    useEffect(() => {

        if (familyMembers == undefined) {
            getFamilyMembers()
        }

    },[])

    useEffect(()=>{

        setSaveLoading(isUpdating)
    },[isUpdating])
    const getFamilyMembers = () => {

        http
            .get<null, MembersResponse>(`${user_url}/${authUserId}/family`)
            .then((res) => {


                setIsFamilyloading(false)

                setFamilyMembers(res)


            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {
                setLoading(false);
            });
    }


    const addMember = (user: UserSearch) => {




        let tripBasicDataClone = cloneDeep(tripBasicData)
        if(tripBasicDataClone){

            if(tripBasicDataClone?.members && tripBasicDataClone?.members.length){

                if(! find(tripBasicDataClone.members, {_id: user._id})) {

                    let tripMember:TripMemberPopulated = {
                        notifications: {
                            planning: true,
                            checklist: true,
                            document: true,
                            activity: true,
                            photo: true
                        },
                        status: "pending",
                        joinType: "web",
                        canAddData: false,
                        role: "member",
                        budget: tripBasicDataClone.budget,
                        _id: user._id,
                        parentId: user.parentId,
                        userId: {
                            email: user.email,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            relation: user.relation,
                            role: user.role,
                            status: user.status,
                            userName: user.userName,
                            _id: user._id,
                        }
                    }
                    tripBasicDataClone.members.push(tripMember)


                    if(! user.totalTrips){
                        user.totalTrips = 0;
                    }
                    if(! user.role){
                        user.role = "member";
                    }

                    setTripBasicData(tripBasicDataClone)

                }
            }



        }



    };


    const removeMember = (user: UserSearch) => {

        if(tripBasicData){




            let activeMembers:any = tripBasicData.members.filter(function(member) {
                if(user.userId?._id){

                    return member.userId?._id != user.userId._id
                }else {

                    return member.userId?._id != user._id
                }
            })


            setTripBasicData({
                ...tripBasicData,
                members:activeMembers
            })

        }

    };


    return (
        <div className="row">
            <div className="col-xl-7 col-md-7">
                <div className="card card-stretch mb-5 mb-xxl-8">
                    <div className={`card-header align-items-center border-0`}>
                        <h3 className={`card-title align-items-start flex-column`}>Family Members</h3>
                    </div>

                    <div className="card-body">
                        <div className="card-body p-0 dashboard-menu ">
                            <div className="">

                                {isFamilyloading?

                                    <a  className="card card-stretch mb-5 bg-light-info shadow-none">

                                        <div className="card-body d-flex flex-column pt-5">

                                            <Loader cssClass="normal-loader"/>
                                        </div>
                                    </a>

                                    :familyMembers?.data.length?

                                    <div className="mh-375px scroll-y me-n7 pe-7">
                                        {familyMembers?.data.map(function (user, key) {
                                            let isAdded = tripBasicData?.members.findIndex(member => member.userId?._id == user._id);


                                            return(
                                                <FamilyMemberItem isAdded={isAdded>0?true:false} user={user} key={key} keyValue={key} addMember={addMember} removeMember={removeMember} />
                                            )
                                        })}
                                    </div>
                                    :

                                    <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-40px'}}>

                                        <div className="text-center p-2 mt-1 mb-0">
                                            <img src={EmptyFamilyImg} width={100}/>
                                            <p className="fs-5 text-dark fw-bolder">No data available</p>
                                            <p className="text-gray-400">Please add family member
                                                from <span className="fw-bolder cursor-pointer" onClick={()=>{navigate('/account')}}>profile</span>
                                            </p>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-5 col-md-5">
                <div className="card card-stretch mb-5 mb-xxl-8">
                    <div className="card-header align-items-center border-0"><h3
                        className="card-title align-items-start flex-column"> Members </h3>
                        <div className="card-toolbar"></div>
                    </div>
                    <div className="card-body">


                        <div className="row g-3 mb-3 mt-1 ">

                            <div className="row px-2">

                                {tripBasicData?.members.map(function (user,key) {
                                    let isAdded = false
                                    let isAdminUser = false


                                    if(user.userId && user.userId._id == authUserId){
                                        isAdminUser = true
                                    }
                                    return(
                                        <SettingsSearchMemberItem canManage={tripData.status == 'completed'? false: true} user={user} isAdminUser={isAdminUser} key={key} isAdded={isAdded} keyValue={key} addMember={undefined} removeMember={removeMember} />
                                    )
                                })}
                            </div>
                            <div className={`text-end`}>
                                <button className={`btn btn-primary `} disabled={saveLoading?true:false} onClick={async ()=>{

                                    setSaveLoading(true)
                                    await updateTripMemberData(tripBasicData.members)
                                }}>{saveLoading? "Saving...": "Save Changes"}</button>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </div>


    );
};
export default TripSettingsFamilyMembers;
