import React, {FC, useEffect, useState} from 'react';
import '../trip.scss'
import DashboardMenuItem from "../cards/DashboardMenuItem";

import destinationIcon from "../../../images/icons/destination.svg"
import deleteIcon from "../../../images/icons/delete.svg"
import moveToIcon from "../../../images/icons/moveto.svg"
import membersIcon from "../../../images/icons/members.svg"
import tripBasicIcon from "../../../images/icons/trip-basic.svg"

const menuItems = [
    {
        title:"Basic",
        subTitle:"Edit or add destinations to your trip",
        icon:tripBasicIcon,
        id:1
    },
    {
        title:"Destinations",
        subTitle:"Edit or add destinations to your trip",
        icon:destinationIcon,
        id:2
    },
    {
        title:"Members",
        subTitle:"Invite or manage destinations to your trip",
        icon:membersIcon,
        id:3
    }
]
const advanceMenuItems = [
    {
        title:"Delete Trip",
        subTitle:"Delete trip completely. This can't be undone",
        icon:deleteIcon,
        id:4
    },
    {
        title:"Move to?",
        subTitle:"Move trip from Pending to active, active to completed",
        icon:moveToIcon,
        id:5
    }
]

type Props = {
    tripType:string,
    activeTab:string,
    tripStatus:string,
    updateTab:any
}

const TripSettingsMenu: FC <Props> = ({updateTab,activeTab,tripType,tripStatus} ) => {



    const updateActiveTab = (tab: any) => {

        updateTab(tab)

    }


    if(tripType == "solo"){
        menuItems.splice(2, 1);

    }


    return (
        <div>
            <div className="card card-stretch mb-5 mb-xxl-8">

                <div className="card-header" style={{ minHeight: "auto", borderBottom: '1px dashed #e4e6ef'}}>
                    <span className="fs-5 fw-bolder text-hover-primary my-2">Settings</span>
                </div>

                <div className="card-body">
                    <div className="card-body p-0 dashboard-menu ">

                        {
                            menuItems.map(function (item,key) {

                                    return(<DashboardMenuItem activeTab={activeTab} title={item.title}  subTitle={item.subTitle} icon={item.icon} key={key} updateActiveTab={updateActiveTab} />)


                            })
                        }

                    </div>
                </div>
                <div className="card-header" style={{ minHeight: "auto", borderBottom: '1px dashed #e4e6ef'}}>
                    <span className="fs-5 fw-bolder text-hover-primary my-2">Advance Settings</span>
                </div>

                <div className="card-body">
                    <div className="card-body p-0 dashboard-menu ">

                        {
                            advanceMenuItems.map(function (item,key) {

                                if(tripStatus == 'completed' && item.id == 5){

                                }else{
                                    return(<DashboardMenuItem activeTab={activeTab} title={item.title}  subTitle={item.subTitle} icon={item.icon} key={key} updateActiveTab={updateActiveTab} />)

                                }

                            })
                        }

                    </div>
                </div>
            </div>
        </div>

    );
};
export default TripSettingsMenu;
