import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ChecklistsResponse} from '../../../interfaces/checklist.interface';

const checkLists = createSlice({
    name: 'checkLists',
    initialState: null as ChecklistsResponse | null,
    reducers: {
        setCheckLists(state, { payload }: PayloadAction<ChecklistsResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setCheckLists } = checkLists.actions;
export default checkLists.reducer;
