import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {MessageInterface, MessagesResponse} from '../../interfaces/message.interface';

const messages = createSlice({
    name: 'messages',
    initialState: null as [MessageInterface] | null,
    reducers: {
        setMessages(state, { payload }: PayloadAction<[MessageInterface] | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setMessages } = messages.actions;
export default messages.reducer;
