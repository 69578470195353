import React, { FC, useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TourPackage } from "../../interfaces/tourPackage.interface";
import * as yup from 'yup';
import { Tab, Tabs, Container, Button, Row, Col, Nav } from 'react-bootstrap';
import Transportation from "./components/Transportation";
import TourGuide from "./components/TourGuide";
import TermsConditions from "./components/TermsConditions";
import SpecialRequirements from "./components/SpecialRequirements";
import ContactInfo from "./components/ContactInfo";
import OptionalAddOns from "./components/OptionalAddOns";
import AccommodationMeals from "./components/AccommodationMeals";
import InclusionsExclusions from "./components/InclusionsExclusions";
import Itinerary from "./components/Itinerary";
import BasicInfo from "./components/BasicInfo";
import Media from "./components/Media";
import Destinations from "./components/Destinations";

const tourPackageSchema = yup.object().shape({
    packageName: yup.string().required('Package Name is required'),
    description: yup.string().required('Description is required'),
    duration: yup.string().required('Duration is required'),
    destinations: yup.array().of(
        yup.object().shape({
            name: yup.string().required('Destination name is required'),
            latitude: yup.number().required('Latitude is required'),
            longitude: yup.number().required('Longitude is required')
        })
    ).min(1, 'At least one destination is required'),
    itinerary: yup.array().of(
        yup.object().shape({
            day: yup.number().required('Day is required').positive().integer(),
            activities: yup.string().required('Activities are required'),
            accommodation: yup.string()
        })
    ),
    inclusions: yup.array().of(yup.string().required('Inclusion is required')).min(1, 'At least one inclusion is required'),
    exclusions: yup.array().of(yup.string().required('Exclusion is required')).min(1, 'At least one exclusion is required'),
    accommodationMeals: yup.object().shape({
        accommodationDetails: yup.string().required('Accommodation details are required'),
        meals: yup.string().required('Meals are required')
    }),
    transportation: yup.object().shape({
        transportationDetails: yup.string().required('Transportation details are required')
    }),
    tourGuide: yup.object().shape({
        guideName: yup.string().required('Guide name is required'),
        guideExperience: yup.string().required('Guide experience is required')
    }),
    termsConditions: yup.object().shape({
        bookingPolicies: yup.string().required('Booking policies are required'),
        cancellationPolicies: yup.string().required('Cancellation policies are required'),
        refundPolicies: yup.string().required('Refund policies are required')
    }),
    specialRequirements: yup.object().shape({
        fitnessLevel: yup.string().required('Fitness level is required'),
        ageRestrictions: yup.string().required('Age restrictions are required'),
        travelDocuments: yup.string().required('Travel documents are required')
    }),
    contactInfo: yup.object().shape({
        phoneNumbers: yup.array().of(yup.string().required('Phone number is required')).min(1, 'At least one phone number is required'),
        emailAddresses: yup.array().of(yup.string().email('Invalid email').required('Email is required')).min(1, 'At least one email is required'),
        officeLocations: yup.array().of(yup.string().required('Office location is required')).min(1, 'At least one office location is required')
    }),
    media: yup.object().shape({
        images: yup.array().of(yup.string().required('Image URL is required')).min(1, 'At least one image is required'),
        videos: yup.array().of(yup.string().required('Video URL is required')).min(1, 'At least one video is required')
    }),
    reviews: yup.array().of(yup.string().required('Review is required')).min(1, 'At least one review is required'),
    optionalAddOns: yup.array().of(
        yup.object().shape({
            additionalActivities: yup.array().of(yup.string().required('Additional activity is required')),
            extraServices: yup.array().of(yup.string().required('Extra service is required'))
        })
    )
});

const NewTourPackageAI: FC = () => {
    const [activeTab, setActiveTab] = useState('basicInfo');

    const methods = useForm<TourPackage>({
        resolver: yupResolver(tourPackageSchema)
    });

    const onSubmit = (data: TourPackage) => {
        console.log(data);
    };

    return (
        <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4">
            <header className="header mx-2">
                <div className="d-flex flex-column justify-content-between flex-md-row align-items-center p-3 mb-4 mt-3 header-container">
                    <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                        <h3 className="text-dark fw-bolder fs-4">Create Tour Package</h3>
                    </div>
                </div>
            </header>

            <div className={`container business-container`}>
                <div className={`my-5`}>
                    <h1 className={`fs-2 fw-bolder text-dark`}>Add Package</h1>
                    <span className={`text-gray-400`}>Lorem ipsum dolor sit amet, consectetur.</span>
                </div>

                <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '87vh' }}>
                    <div className={`card-body mt-5`}>
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md={3}>
                                        <Nav variant="pills" className="flex-column b-nav">
                                            <Nav.Item>
                                                <Nav.Link eventKey="basicInfo" active={activeTab === 'basicInfo'} onClick={() => setActiveTab('basicInfo')}>Basic Info</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="destinations" active={activeTab === 'destinations'} onClick={() => setActiveTab('destinations')}>Destinations</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="itinerary" active={activeTab === 'itinerary'} onClick={() => setActiveTab('itinerary')}>Itinerary</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="inclusionsExclusions" active={activeTab === 'inclusionsExclusions'} onClick={() => setActiveTab('inclusionsExclusions')}>Inclusions/Exclusions</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="accommodationMeals" active={activeTab === 'accommodationMeals'} onClick={() => setActiveTab('accommodationMeals')}>Accommodation/Meals</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="transportation" active={activeTab === 'transportation'} onClick={() => setActiveTab('transportation')}>Transportation</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tourGuide" active={activeTab === 'tourGuide'} onClick={() => setActiveTab('tourGuide')}>Tour Guide</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="termsConditions" active={activeTab === 'termsConditions'} onClick={() => setActiveTab('termsConditions')}>Terms/Conditions</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="specialRequirements" active={activeTab === 'specialRequirements'} onClick={() => setActiveTab('specialRequirements')}>Special Requirements</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="contactInfo" active={activeTab === 'contactInfo'} onClick={() => setActiveTab('contactInfo')}>Contact Info</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="media" active={activeTab === 'media'} onClick={() => setActiveTab('media')}>Media</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="optionalAddOns" active={activeTab === 'optionalAddOns'} onClick={() => setActiveTab('optionalAddOns')}>Optional Add-Ons</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col md={9} className={`p-5 rounded-3`} style={{backgroundColor:'#f6f8fa'}}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="basicInfo" active={activeTab === 'basicInfo'}>
                                                <BasicInfo />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="destinations" active={activeTab === 'destinations'}>
                                                <Destinations />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="itinerary" active={activeTab === 'itinerary'}>
                                                <Itinerary />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="inclusionsExclusions" active={activeTab === 'inclusionsExclusions'}>
                                                <InclusionsExclusions />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="accommodationMeals" active={activeTab === 'accommodationMeals'}>
                                                <AccommodationMeals />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="transportation" active={activeTab === 'transportation'}>
                                                <Transportation />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tourGuide" active={activeTab === 'tourGuide'}>
                                                <TourGuide />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="termsConditions" active={activeTab === 'termsConditions'}>
                                                <TermsConditions />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="specialRequirements" active={activeTab === 'specialRequirements'}>
                                                <SpecialRequirements />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="contactInfo" active={activeTab === 'contactInfo'}>
                                                <ContactInfo />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="media" active={activeTab === 'media'}>
                                                <Media />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="optionalAddOns" active={activeTab === 'optionalAddOns'}>
                                                <OptionalAddOns />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                                <Button type="submit" variant="primary" className="mt-3">Submit</Button>
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default NewTourPackageAI;
