import React, {FC, useEffect, useState} from 'react';
import Modal from "react-bootstrap/cjs/Modal";





import CreatableSelect from "react-select/creatable";

import PaidMemberCard from "../cards/PaidMemberCard";
import BudgetPassengerCard from "../cards/BudgetPassengerCard";
import {
    AirportsResponse, GooglePlaceResponse,
    Trip, TripActivity, TripActivityResponse,
    TripDestination,
    TripDestinationResponse
} from "../../../interfaces/trip.interface";

import './activity.scss'


const PdfViewer = React.lazy(() => import('../../../utils/pdfViewer'));

type Props = {
    tripData:Trip,
    activeDestination:TripDestination | undefined,
    updateDefaultDestination:any,
    updateTripDestination:any,
    activeDate: string,
    activityFilter:{
        members: string[],
        categories: string[]
    }
}
type OptionSchema ={
    name?: string
    value: string
    label?: string
}
const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const TripLiveActivity: FC<Props> = ({tripData,activeDestination, updateDefaultDestination, updateTripDestination, activeDate,activityFilter}) => {


    return (
        <div style={{ display: "contents"}}>


        </div>





    );
};
export default TripLiveActivity;
