import React, {FC, ReactElement, useEffect, useRef, useState} from 'react';
import {
    useJsApiLoader,
    GoogleMap,
    MarkerF,
    Marker, InfoWindowF,
    Autocomplete,
    DirectionsRenderer,
    LoadScript
} from '@react-google-maps/api';


import {
    AiData,
    AiResponse,
    GooglePlaceResponse,
    Trip, TripActivity, TripActivityResponse,
    TripDestination,
    TripDestinationResponse,
    TripResponse
} from "../../interfaces/trip.interface";
import EmptyPlanningsImg from "../../images/illustration-icons/empty-plannings.svg";

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}


type Props = {

    activities?: TripActivity[] | undefined;
    tripData:Trip;
    activeDestination:TripDestination| undefined;
}
const TripMap: FC <Props>= ({activities,activeDestination,tripData}) => {


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAXnOmNWA3f3dUAQhJKiAcC1cFFCSi-Byo'

    });
    const { width } = useViewport();
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [zoom, setZoom] = useState(6);
    const [center, setCenter] = useState({
        lat:activeDestination?.lat?parseFloat(activeDestination?.lat):11.11,
        lng: activeDestination?.lng?parseFloat(activeDestination?.lng):11.11
    });
    const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult | null>(null);
    const originRef = useRef<HTMLInputElement | null>(null);
    const destinationRef = useRef<HTMLInputElement | null>(null);
    const markers: { lat: number; lng: number; text: string }[] = [
        { lat: 8.5241, lng: 76.9366, text: 'A' },
        // Add more markers as needed
    ];



    React.useEffect(() => {
        if(activeDestination){
            setCenter({
                lat: parseFloat(activeDestination?.lat)+0.25,
                lng: parseFloat(activeDestination?.lng)-0.60
            })
            setZoom(6)
        }
    }, [activeDestination]);



    let mapKey = 0

    if(isLoaded){
        return (

            <div className={`row position-relative mx-1 mb-5`}>

                    { width >= 620 ?
                        <div className={`col-4 position-absolute`} style={{  top: '10px', borderRadius: '10px', padding: '10px', backgroundColor:'#fff', left:'10px', zIndex: 999, maxHeight: '67vh', overflow: 'scroll'}}>

                            <div className="card card-stretch mb-3 mb-xxl-8">

                                {activities?.length?
                                    <div className="card-body p-0">
                                        <div className="card-body p-0 dashboard-menu ">

                                          {/*  {activities?.map((activity, index) => {

                                                if(((activity.location && activity.location?.lat && activity.location?.lng)|| activity.from && activity.from?.lat && activity.from?.lng) && activity.type === 'attraction') {


                                                    return(
                                                        <div className={` ${activeActivity?._id === activity._id? 'active': "" } d-flex flex-stack py-2 px-2  mb-1 menu-item cursor-pointer`} onClick={function(){

                                                            if(activity?.location?.lat && activity?.location?.lng){
                                                                setCenter({
                                                                    lat:parseFloat(activity.location?.lat),
                                                                    lng:parseFloat(activity.location?.lng)-2
                                                                })
                                                            }else if(activity.from?.lat && activity.from?.lng) {
                                                                setCenter({
                                                                    lat:parseFloat(activity.from?.lat),
                                                                    lng:parseFloat(activity.from?.lng)-2
                                                                })
                                                            }
                                                            setZoom(12)
                                                            setActiveActivity(activity)
                                                        }} key={index}>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-45px symbol-circle">


                                                                    <span className="symbol-label bg-transparent text-danger fs-2 fw-bolder position-absolute">{index+1}</span>


                                                                    <img src={`${google_place_photo_url}${activity.location?activity.location.photoReference:activity.from?.photoReference}`} width={20} className={``}/>
                                                                </div>
                                                                <div className="ms-3"><a href="#" className="fs-5 fw-bolder title text-hover-primary mb-2">{activity.location?activity.location?.name:activity.from?.name}</a>
                                                                    <div className="fw-bold text-gray-400">{activity.location?activity.location?.formattedAddress:activity.from?.formattedAddress}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }


                                            })}*/}


                                        </div>
                                    </div>
                                    :
                                    <div className={`card py-5`}>
                                        <div className="text-center p-2">
                                            <img src={EmptyPlanningsImg} width={100}/>
                                            <p className="fs-5 text-dark fw-bolder">No Plannings</p>
                                            <p className="text-gray-400">Please check after adding planning or activities from <span className="fw-bolder">destination dashboard</span>
                                            </p>
                                        </div>

                                    </div>
                                }

                            </div>
                        </div>
                        :

                       ''
                    }



                <GoogleMap
                    center={center}
                    zoom={zoom}
                    mapContainerStyle={{ width: '100%', height: '70vh', borderRadius: '10px', boxShadow:'0 0 20px 0 rgb(76 87 125 / 5%)' }}

                    options={{
                        zoomControl: true,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        mapId:'8327780461c905eb'
                    }}
                    onLoad={(map) => setMap(map)}
                >

                    {/* Add Markers for other locations */}
                    {/*{activities?.map((activity, index) => {


                            if(((activity.location && activity.location?.lat && activity.location?.lng) || activity.from && activity.from?.lat && activity.from?.lng) && (activity.type === "location" || activity.type === "attraction")){

                                mapKey++

                                let position:any = activity.location && activity.location?.lat && activity.location?.lng?{ lat: parseFloat(activity.location?.lat), lng: parseFloat(activity.location?.lng) }: activity.from?.lat && activity.from?.lng?{ lat: parseFloat(activity.from?.lat), lng: parseFloat(activity.from?.lng) } :{}
                                return(


                                    <MarkerF
                                        key={index}
                                        position={position}
                                        icon={{
                                            url: GpsLocationImg,
                                            scaledSize: new window.google.maps.Size(30, 30),
                                            labelOrigin: new window.google.maps.Point(15, 15),
                                            }}


                                        onClick={(e) => handleMarkerClick(activity, e)}
                                        animation={window.google.maps.Animation.DROP}
                                    >
                                        {activeActivity && activeActivity._id === activity._id &&(
                                            <InfoWindowF
                                                position={position}
                                                onCloseClick={() =>{
                                                    setActiveActivity(null)
                                                }}

                                            >
                                                <div key={activity._id} className={`cursor-pointer`} style={{ maxWidth: '400px', padding: '10px' }} onClick={()=>{


                                                    let currentLocation = "Current+Location"
                                                    if((tripData.status == "pending" || tripData.status == "completed") && activeDestination?.formattedAddress) {

                                                        currentLocation = activeDestination?.formattedAddress
                                                    }

                                                    window.open(`https://www.google.com/maps/dir/${currentLocation}/${activity.location?.formattedAddress}`, '_blank');

                                                }}>

                                                    <div className="card hotel flex-row" >


                                                        <div className={`d-flex col-4 place-card-photo-container`}  >
                                                            <img style={{ maxHeight:'120px'}} className={`place-card-photo`} src={activity.location?.photoReference? getGooglePhotoUrl(activity.location?.photoReference, 300, 300):EmptyLocationImg }/>

                                                            <div className={`d-flex flex-column text-white fw-bolder ps-2 pt-2 position-absolute top-0`}>
                                                                <span className={`text-white fw-bolder  fs-8`}> {activity.location?.entryTime} to  {activity.location?.exitTime}</span>
                                                                <span className={`text-white fw-bolder  fs-8`}>{activity.location?.duration}</span>
                                                            </div>
                                                            <div className={`d-flex flex-column text-white fw-bolder ps-2 pb-2 position-absolute bottom-0`}>
                                                               <span className={`text-white fw-bolder cursor-pointer`} onClick={()=>{
                                                                   // handleActivityUpdate(activity,"distance-modal")
                                                               }}>

                                                                       <OverlayTrigger  placement="top" overlay={
                                                                           <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                                                               <span className={`text-dark`} >
Keep in mind that the displayed distance might not be entirely accurate. Please note that this distance is calculated from your selected destination as the starting point.</span>
                                                                           </Tooltip>
                                                                       }>
                                                                           <span>
                                                                                <span  className={`text-white fw-bolder  fs-8`} >{activity.location?.distance} <img src={ErrorIconImg} width={10} className={`option`}/></span>
                                                                           </span>

                                                                    </OverlayTrigger>

                                                            </span>
                                                            </div>

                                                        </div>

                                                        <div className="hotel-card_info ms-2 flex-fill col-8">
                                                            <div className="d-flex justify-content-between mb-2">
                                                                <div className={``}  >
                                                                    <h5 className="mb-0 mr-2 text-dark  fw-bolder">{activity.location?.name}</h5>
                                                                    <div className="rating text-dark">
                                                                        {activity.location?.rating?
                                                                            <span  className={`d-flex`}><EsRating rating={activity.location?.rating} /><span className="count">{activity.location?.rating} </span></span>
                                                                            : <span className={`text-dark fs-8`}>No rating</span> }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-end"   >
                                                                <div className="hotel-card_details">

                                                                    <div className="text-muted fs-7 mb-2"><i className="bi bi-geo-alt-fill"></i> {activity.location?.formattedAddress}</div>
                                                                    <div className="text-dark fs-7  mb-2">{activity.location?.description}</div>
                                                                </div>
                                                            </div>

                                                            <div className="">
                                                                <h3 className="fs-7 fw-bolder primary-color">{activity.url}</h3>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </InfoWindowF>
                                        )}

                                    </MarkerF>
                                )

                            }


                        }



                    )}
*/}
                </GoogleMap>
            </div>


        );
    }else{
        return (

           <p>Loading...</p>
        );
    }

};

export default TripMap;
