import React, {FC, useEffect, useState} from 'react';
import DashboardMenuItem from "../trip/cards/DashboardMenuItem";


import AccountImage from '../../images/illustration-icons/account.svg'
import FamilyImage from '../../images/illustration-icons/family.svg'
import LogoutImage from '../../images/icons/lock.svg'
import PreferenceImage from '../../images/icons/preference.svg'

import './account.scss'
import {setUser} from "./userSlice";
import {useAppDispatch} from "../../store";
import {useNavigate} from "react-router-dom";


const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const menuItems = [
    {
        title:"Account",
        subTitle:"Manage your profile details by clicking here.",
        icon:AccountImage,
        id:1
    },
    {
        title:"Preference",
        subTitle:"Manage your account preference by clicking here.",
        icon:PreferenceImage,
        id:1
    },
    {
        title:"Family",
        subTitle:"Effortlessly manage your family details with ease.",
        icon:FamilyImage,
        id:2
    },
  /*  {
        title:"Package",
        subTitle:"Edit or add destinations to your trip",
        icon:"bi-geo",
        id:3
    },
    {
        title:"Addresses",
        subTitle:"Edit or add destinations to your trip",
        icon:"bi-geo",
        id:4
    },
    {
        title:"Transactions",
        subTitle:"Edit or add destinations to your trip",
        icon:"bi-geo",
        id:5
    },
    {
        title:"Logs",
        subTitle:"Edit or add destinations to your trip",
        icon:"bi-geo",
        id:6
    }*/
]
const advanceMenuItems = [
    {
        title:"Delete Account",
        subTitle:"Delete trip completely. This can't be undone",
        icon:"bi-trash",
        id:1
    },
    {
        title:"Deactivate Account",
        subTitle:"Move trip from Pending to active, active to completed",
        icon:"bi-arrow-up-right-circle",
        id:2
    }
]

type Props = {
    activeTab:string,
    updateTab:any
}

const AccountMenu: FC <Props> = ({updateTab,activeTab} ) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { width } = useViewport();
    const breakpoint = 620;

    const updateActiveTab = (tab: any) => {

        updateTab(tab)

    }




    return (
        <div>
            <div className="card card-stretch mb-5 mb-xxl-8">

                <div className="card-header border-0" style={{ minHeight: "auto"}}>
                    <span className="fs-5 fw-bolder text-hover-primary my-2">Settings</span>
                </div>

                <div className="card-body">
                    <div className="card-body p-0 account-menu ">

                        {
                            menuItems.map(function (item,key) {

                                return(
                                    <div className={` ${activeTab == item.title? 'active': "" } d-flex flex-stack py-2 px-2  mb-1 menu-item cursor-pointer`} onClick={function(){
                                        updateActiveTab(item.title)
                                    }}>
                                        <div className="d-flex align-items-center">
                                            <div className="symbol symbol-45px symbol-circle">
                                                <span
                                                    className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                    <img src={item.icon} width={35}/>
                                                </span>
                                            </div>
                                            <div className="ms-3"><a href="#" className="fs-5 fw-bolder title text-hover-primary mb-2">{item.title}</a>
                                                <div className="fw-bold text-gray-400">{item.subTitle}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {
                            width < breakpoint?

                                <div className={` d-flex flex-stack py-2 px-2  mb-1 menu-item cursor-pointer`} onClick={function(){
                                    dispatch(setUser(null));
                                    localStorage.clear();
                                    navigate("/");
                                }}>
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-45px symbol-circle">
                                                <span
                                                    className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">

                                                    <img src={LogoutImage} className={`hover-opacity-btn`} width={20}/>

                                                </span>
                                        </div>
                                        <div className="ms-3"><a href="#" className="fs-5 fw-bolder title text-hover-primary mb-2">Logout</a>
                                            <div className="fw-bold text-gray-400">Logout
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                :''


                        }



                    </div>
                </div>
      {/*          <div className="card-header" style={{ minHeight: "auto"}}>
                    <span className="fs-5 fw-bolder text-hover-primary my-2">Advance Settings</span>
                </div>

                <div className="card-body">
                    <div className="card-body p-0 dashboard-menu ">

                        {
                            advanceMenuItems.map(function (item,key) {

                                return(
                                    <div className={` ${activeTab == item.title? 'active': "" } d-flex flex-stack py-2 px-2  mb-1 menu-item cursor-pointer`} onClick={function(){
                                        updateActiveTab(item.title)
                                    }}>
                                        <div className="d-flex align-items-center">
                                            <div className="symbol symbol-45px symbol-circle">
                                                <span
                                                className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                    <img src={item.icon} width={30}/>
                                                </span>
                                            </div>
                                            <div className="ms-3"><a href="#" className="fs-5 fw-bolder title text-hover-primary mb-2">{item.title}</a>
                                                <div className="fw-bold text-gray-400">{item.subTitle}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>*/}
            </div>
        </div>

    );
};
export default AccountMenu;
