import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const Transportation: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();

    return (
        <div>
            <h2>Transportation</h2>
            <Form.Group>
                <Form.Label>Transportation Details</Form.Label>
                <Controller
                    name="transportation.transportationDetails"
                    control={control}
                    render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.transportation?.transportationDetails} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.transportation?.transportationDetails?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );
};

export default Transportation;
