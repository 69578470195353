import React, {FC, useEffect, useRef, useState} from "react";
import ReactQuill from "react-quill";
import { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./styles.css";
import "./quillmention.css";
import {MinimalUser} from "../../interfaces/user.interface";
import filter from "lodash/filter";
import FullCalendar from "@fullcalendar/react";



type Props ={
    onEditorChange:any,
    initialValue?:any,
    users?: [MinimalUser],
    handleSave?:any,
    placeholder?:string
    type?:string
}


const QuillEditor: FC<Props> = ({onEditorChange,initialValue,users,handleSave, placeholder, type}) => {


    const [value, setValue] = useState(initialValue);

    const quillRef = useRef<ReactQuill|null>(null);


    if(type == 'chat'){
        quillRef.current?.focus()



    }

    useEffect(() => {
        setValue(initialValue)
    },[initialValue])



    let usersData:any = []
    let usersFilterData:any = []


    users?.map(function (user,key){

        usersData.push({
            id:user._id,
            value:`${user.firstName} ${user.lastName}`,
            profilePicture: user.cover?.url,
        })
    })
    const getUsers = (keywordString:string) => {

        keywordString = keywordString.toLowerCase()

        var results = filter(usersData,function(user) {
            let userName = (user.value).toLowerCase()
            return userName.includes(keywordString)
        });

        usersFilterData = results

    }


    modules.mention = {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            source: function(searchTerm:any, renderList:any, mentionChar:any) {

                getUsers(searchTerm)
                renderList(usersFilterData, searchTerm);
            },
            dataAttributes: ["id", "value", "link"],
            linkTarget:"_blank",
            renderItem: (item:any) => {

                if(item.profilePicture){
                    return `<div className="mention-user"> <img style="border-radius:50%;margin-right: .5rem!important; margin-left: .5rem!important;" height="24"  width="24" src="${item.profilePicture}"> ${item.value}</div>`;

                }else{
                    return `<div className="mention-user"><div class="symbol symbol-45px symbol-circle "><span class="symbol-label bg-light-danger text-danger fs-6 fw-bolder">${item.value.charAt(0)}</span></div> ${item.value}</div>`;

                }


            }
        };
/*    modules.keyboard = {
        bindings: {
            enter: {
                key: 13,
                handler: () => { // submit form }
                    if( type == 'chat'){

                    }
                }
            }
        }
    }*/

    const handleChange = (value: any) => {


            let userIds = value.match(/data-id="(.*?)"/g)

            let usersIds:string[] = []

            if(userIds && userIds.length){
                userIds.forEach(function (userId:any) {

                    let trimUserId = userId.replace("data-id=","")
                    trimUserId = trimUserId.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'');
                    usersIds.push(trimUserId)

                })

            }
        setValue(value)

        value =  value.replace(/'<span contenteditable="false">'/igm,'');
        value =  value.replace(/'<\/span>﻿<\/span>'/igm,'</span>');

        onEditorChange(value,usersIds)

    };


    return (
        <div className="text-editor p-0 m-0">
            <ReactQuill
                ref={quillRef}
                theme="snow"
                defaultValue={value}
                value={value}
                onChange={handleChange}
                modules={modules}
                formats={formats}
                placeholder={placeholder?placeholder:''}
            />
        </div>
    );
};

export default QuillEditor;
