import React, {FC, useEffect, useState} from 'react';
import Modal from "react-bootstrap/cjs/Modal";

//import { Reorder } from 'framer-motion';

import PlaneImg from '../../../images/illustration-icons/flight.svg'
import LocationImg from "../../../images/illustration-icons/attraction.svg";
import HotelImg from "../../../images/illustration-icons/hotel.svg";
import HireVehicleImg from "../../../images/illustration-icons/taxi.svg";
import RentVehicleImg from "../../../images/illustration-icons/rental.svg";
import WebsiteImg from "../../../images/illustration-icons/website.svg";
import TrainImg from "../../../images/illustration-icons/train.svg";
import BusImg from "../../../images/illustration-icons/bus.svg";
import PreBookImg from "../../../images/illustration-icons/pre-book.svg";
import RestaurantImg from "../../../images/illustration-icons/restaurant.svg";
import EmptyPlanningsImg from "../../../images/illustration-icons/empty-plannings.svg";
import AIIconImg from "../../../images/icons/ai-icon.png";
import checkRedImg from "../../../images/icons/check-red.svg";
import loadingImg from "../../../images/loaders/earth.svg";

import {
    Trip, TripActivity,
    TripDestination, TripDestinationResponse,
} from "../../../interfaces/trip.interface";
import http from "../../../services/api";
import {
    currencies_code_url,
    get_ai_suggestions,
    google_place_photo_url,
    message_to_share_url,
    message_url,
    trip_url,
    trips_url
} from "../../../services/api.utils";
import {
    eSMoment,
    formatBytes,
    getGooglePhotoUrl,
    getImgUrl,
    numberWithCommas,
    showAlert,
    toastMessage
} from "../../../util";

import {GooglePlace, GooglePlacesResponse} from "../../../interfaces/googlePlaces.interface";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import homeImage from "../../../images/sidebar/home.svg";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";


import BackButtonImgIcon from '../../../images/icons/back-button.svg'
import RightArrowImgIcon from '../../../images/icons/right-arrow.svg'
import PdfIconImg from "../../../images/illustration-icons/file-types/pdf-icon.svg";
import DocIconImg from "../../../images/illustration-icons/file-types/doc-icon.svg";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import DownloadIconImg from "../../../images/icons/download.svg";
import CloseIconImg from "../../../images/icons/close-button.svg";
import ViewFileIconImg from "../../../images/icons/view-file.svg";
import ActivityBudgetTab from "../ActivityBudgetTab";
import Loader from "../../loader/Loader";
import dayjs from "dayjs";
import EmptyLocationImg from "../../../images/empty/location.png";
import filter from "lodash/filter";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import {setAppBasic} from "../../../app/appBasicSlice";
import {useAppDispatch} from "../../../store";
import EsRating from "../../esRating/EsRating";
import TripMap from "../TripMap";
import {Helmet} from "react-helmet";
import {cloneDeep} from "lodash";
import SortIconImg from "../../../images/icons/sort.svg";
import {CurrenciesResponse} from "../../../interfaces/country.interface";
import {setCurrencies} from "../../currency/currenciesSlice";
import FormContainer from "./modals/FormContainer";
import {Picklist, Option} from "react-rainbow-components";
import TripPlanningInfo from "./TripPlanningInfo";
import FlightCard from "./cards/FlightCard";
import HotelCard from "./cards/HotelCard";
import VehicleCard from "./cards/VehicleCard";



const planningCategoryArray = [{
    id:"hotel",
    name:"Hotel",
    icon:HotelImg,
    aiSupport: false
},{
    id:"attraction",
    name:"Attraction",
    icon:LocationImg,
    aiSupport: true
},{
    id:"restaurant",
    name:"Restaurant",
    icon:RestaurantImg,
    aiSupport: true
},{
    id:"flight",
    name:"Flight",
    icon:PlaneImg,
    aiSupport: false
},{
    id:"rent-vehicle",
    name:"Rent vehicle",
    icon:RentVehicleImg,
    aiSupport: false
},{
    id:"taxi",
    name:"Taxi",
    icon:HireVehicleImg,
    aiSupport: false
},{
    id:"bus",
    name:"Bus",
    icon:BusImg,
    aiSupport: false
},{
    id:"train",
    name:"Train",
    icon:TrainImg,
    aiSupport: false
},{
    id:"pre-booked",
    name: "Pre-booked Activity",
    icon:PreBookImg,
    aiSupport: true
}]



type Props = {
    tripData:Trip,
    activeDestination?:TripDestination,
    updateTripDestination:any,
    updateActiveDestination:any,
    activeDate: string,
    activityFilter:{
        members: string[],
        categories: string[]
    }
}

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
function debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
}
const TripPlanning: FC<Props> = ({tripData,activeDestination,updateTripDestination,activeDate,activityFilter,updateActiveDestination}) => {

    const {  appBasic,currencies } = useSelector((state: RootState) => state);


    const [filteredActivities, setFilteredActivities] = useState<TripActivity[]>();


    const [showPlanningFormModal, setShowPlanningFormModal] = useState(false);
    const [showPlanningMap, setShowPlanningMap] = useState(false);
    const [planningCategory, setPlanningCategory] = useState<any>();
    const [activeActivity, setActiveActivity] = useState<TripActivity>();
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showDeleteLoading, setShowDeleteLoading] = useState(false);


    const dispatch = useAppDispatch();

    const { width } = useViewport();
    const breakpoint = 620;





    useEffect(() => {
        if(activeDestination && activeDestination?.activities){


            let filteredActivitiesArray = activeDestination?.activities?.filter(
                activity =>
                    activity.type === "flight" || activity.type === "hotel" ||
                    activity.type === "taxi" || activity.type === "rent-vehicle" ||
                    activity.type === "attraction" ||
                    activity.type === "restaurant" ||
                    activity.type === "checklist" || activity.type === "bus" ||
                    activity.type === "train" || activity.type === "pre-booked"
            )

            setFilteredActivities(filteredActivitiesArray)
        }
    }, [activeDestination?.activities,activeDate,activityFilter]);




    useEffect(() => {

        if (currencies === null) {

            getCurrencies()
        }


    }, []);


    const getCurrencies = () => {

        http
            .get<null, CurrenciesResponse>(currencies_code_url)
            .then((res) => {
                if (res) {

                    if(res.status === 200){
                        dispatch(setCurrencies(res))
                    }
                }

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {


            });


    };
    const deleteActivity = () => {

        setShowDeleteLoading(true)
        http
            .delete<null, TripDestinationResponse>(trip_url + "/" + tripData._id + "/destination/" + activeDestination?._id + "/activity/" + activeActivity?._id)
            .then((res) => {
                if (res) {

                    setShowDeleteLoading(false)

                    setShowDeleteConfirmModal(false)

                    //TripDestination
                    updateTripDestination(res.data)

                    setActiveActivity(undefined)
                    setShowPlanningFormModal(false)

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    }

    const handleActivityUpdate = (activity:TripActivity, type:string) => {


        if(activity){

            if(tripData.status !== 'completed' && type === "delete"){

                setActiveActivity(activity)

                setShowDeleteConfirmModal(true)


            }
            if(tripData.status !== 'completed' && type === "edit"){


                    setShowPlanningFormModal(true)
                    setActiveActivity(activity)

                    const activityType= planningCategoryArray.find((item)=> item.id === activity.type)
                    setPlanningCategory({
                        name:activityType?.id,
                        label:activityType?.name,
                    })

            }
        }



    }

    return (

            <div style={{ display:"contents"}}>

                <div className="col-xl-9 px-3 col-12 mb-5 mb-xl-0">
                    <div className="row">

                            <div className="card card-stretch bg-transparent hoverable shadow-none trip-list-select">

                                <div className="card-header align-items-center border-0 bg-white rounded mb-5 pb-3 pb-xl-0">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bolder text-dark">Planning</span>
                                        <span className="text-gray-400 mt-1  fs-7">
                                            {filteredActivities && filteredActivities.length> 0? `${filteredActivities?.length} plannings` : `No plannings` }
                                            </span>
                                    </h3>
                                    <div className={width >= 620?`card-toolbar d-flex`:` card-toolbar w-100 text-center d-flex  flex-wrap  flex-column-reverse`}>


                                        <div className={`trip-filter d-flex justify-content-end  ${width >= 620? '': 'mt-3'}`}>









                                        </div>



                                            <div className={`d-flex`}>

                                                <Picklist
                                                    onChange={(value) => {

                                                            setActiveActivity(undefined)
                                                            setShowPlanningFormModal(true)
                                                            setPlanningCategory(value)


                                                    }}
                                                    placeholder={`Add New Planning`}
                                                    borderRadius="semi-rounded"
                                                    className={`me-3`}
                                                >

                                                    {planningCategoryArray.map((planningCategory)=>
                                                        <Option name={planningCategory.id} label={planningCategory.name} icon={<img src={planningCategory.icon} width={20} />} />
                                                    )}

                                                </Picklist>
                                                <button className={`btn btn-primary me-2 ${tripData.status === 'completed'? 'disabled': ''}`} onClick={() => {

                                                    setShowPlanningMap(!showPlanningMap)
                                                }}>{!showPlanningMap?"Map View":"List View"}</button>


                                            </div>



                                    </div>
                                </div>

                                {showPlanningMap?


                                    <TripMap activities={filteredActivities} tripData={tripData} activeDestination={activeDestination} />

                                    :

                                    <div className={`row`}>
                                        <div className="col-xl-8 col-md-8">
                                            <div className="card-body d-flex flex-column p-0" style={{backgroundColor: "#f5f8fa"}}>

                                                <div id="tracking">
                                                    <div className="tracking-list planning-timeline">

                                                        {filteredActivities && filteredActivities.length?

                                                            <>
{/*
                                                                <Reorder.Group axis="y" layoutScroll values={filteredActivities} onReorder={handleReorder} >
*/}

                                                                    { filteredActivities.map(function (activity,key) {

                                                                        let iconImg:any = "";
                                                                        let renderCard:any =  "";


                                                                        if(activity.type == "flight"){
                                                                            iconImg = PlaneImg
                                                                            renderCard = <FlightCard width={width} tripStatus={tripData.status} tripOwner={tripData.userId._id} tripType={tripData.tripType} activity={activity} handleActivityUpdate={handleActivityUpdate} key={key}  activeCurrency={activeDestination?.activeCurrency}  />;
                                                                        }else if(activity.type == "hotel"){
                                                                            iconImg = HotelImg
                                                                            renderCard =  <HotelCard width={width} tripStatus={tripData.status} tripOwner={tripData.userId._id}  tripType={tripData.tripType}  activity={activity}  handleActivityUpdate={handleActivityUpdate} key={key}  activeCurrency={activeDestination?.activeCurrency}  />;
                                                                        }else if(activity.type === "taxi" || activity.type === "bus" || activity.type === "train" || activity.type === "ferry" || activity.type === "rent-vehicle"){
                                                                            iconImg = HireVehicleImg
                                                                            renderCard =  <VehicleCard width={width} tripStatus={tripData.status} tripOwner={tripData.userId._id}  tripType={tripData.tripType}  activity={activity} handleActivityUpdate={handleActivityUpdate} key={key}  activeCurrency={activeDestination?.activeCurrency} />
                                                                        }/*else if(activity.type == "rent-vehicle"){
                                                                            iconImg = RentVehicleImg
                                                                            renderCard =  <RentCarCard width={width} tripStatus={tripData.status} tripOwner={tripData.userId._id}  tripType={tripData.tripType}  activity={activity} handleActivityUpdate={handleActivityUpdate} key={key} activeCurrency={activeDestination?.activeCurrency}   />
                                                                        }else if(activity.type == "attraction"){
                                                                            iconImg = LocationImg
                                                                            renderCard =  <LocationCard width={width} tripType={tripData.tripType}  tripStatus={tripData.status} tripOwner={tripData.userId._id} activity={activity} handleActivityUpdate={handleActivityUpdate} key={key}   />
                                                                        }else if(activity.type == "website"){
                                                                            iconImg = WebsiteImg
                                                                            renderCard =  <WebsiteCard width={width} tripType={tripData.tripType} tripStatus={tripData.status} tripOwner={tripData.userId._id}  activity={activity} handleActivityUpdate={handleActivityUpdate}  key={key}   />
                                                                        }else if(activity.type == "restaurant"){
                                                                            iconImg = RestaurantImg
                                                                            renderCard =  <RestaurantCard width={width} tripType={tripData.tripType} tripStatus={tripData.status} tripOwner={tripData.userId._id}  activity={activity} handleActivityUpdate={handleActivityUpdate}  key={key}   />
                                                                        }else if(activity.type == "pre-booked"){
                                                                            iconImg = PreBookImg
                                                                            renderCard =  <PreBookedCard width={width} tripType={tripData.tripType} tripStatus={tripData.status} tripOwner={tripData.userId._id}  activity={activity} handleActivityUpdate={handleActivityUpdate}  key={key} activeCurrency={activeDestination?.activeCurrency}     />
                                                                        }*/



                                                                        var startDate = eSMoment(activity.addedDate);
                                                                        var endDate = eSMoment();

                                                                        var dayDiff = endDate.diff(startDate, 'days');


                                                                        const currentActivityDate = eSMoment(activity.activityDate, 'YYYY-MM-DD');
                                                                        const previousActivityDate = eSMoment(filteredActivities?.[key - 1]?.activityDate, 'YYYY-MM-DD');

                                                                        return(


                                                                            <>
                                                                            {key === 0 || ! currentActivityDate.isSame(previousActivityDate, 'day')?
                                                                                <div className={`d-flex justify-content-center  mb-5 mt-1`}>
                                                                                                <span className=" py-1 px-2 rounded-2 fs-7 text-white  text-center  " style={{ backgroundColor: '#ef3a48'}}  >
                                                                                                              { eSMoment(activity.activityDate).format("DD MMMM") }
                                                                                                </span>
                                                                                </div>
                                                                                : ""}

{/*
                                                                            <Reorder.Item key={activity.order} value={activity} className={`cursor-pointer`} onDragStart={() => setDraggedItem(activity)} >
*/}

                                                                                <div className="tracking-item" key={key}>

                                                                                    {iconImg?
                                                                                        <div className="tracking-icon cursor-pointer">


                                                                                            <OverlayTrigger  placement="right" overlay={
                                                                                                <Tooltip id={`tooltip-${key}`}  className={`card-timeline-tooltip`}>
                                                                                                    <div className="d-flex align-items-center">

                                                                                                        {activity._user.cover?
                                                                                                            <div className="symbol symbol-35px symbol-circle me-1 ">
                                                                                                                <img alt="Pic" src={activity._user.cover?.url} />

                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="symbol symbol-45px symbol-circle me-1 ">
                                                                                                                <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{activity._user._id?(activity._user.firstName).charAt(0): ""}</span>
                                                                                                            </div>
                                                                                                        }
                                                                                                        <div className="ms-0 text-start"><span className="fs-6 text-gray-900 text-hover-primary mb-2 cursor-pointer"><span className="fw-bolder">{activity._user.firstName} {activity._user.lastName} </span></span>
                                                                                                            <div className="fs-7 text-muted fw-bold mb-2"> <i className='bx bx-calendar-minus fs-8'></i>{dayDiff >= 1? eSMoment(activity.startDateTime).format("DD MMMM [at]  LT"): eSMoment(activity.startDateTime).fromNow()}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                            }>
                                                                                                <img src={iconImg} width={20}/>
                                                                                            </OverlayTrigger>

                                                                                        </div>
                                                                                        :''
                                                                                    }

                                                                                    <div className="tracking-content">

                                                                                        {renderCard}

                                                                                    </div>
                                                                                </div>




{/*
                                                                            </Reorder.Item>
*/}
                                                                        </>

                                                                        )

                                                                    })
                                                                    }
{/*
                                                                </Reorder.Group>
*/}
                                                            </>


                                                            :
                                                            <div className={`card py-5`}>
                                                                <div className="text-center p-2">
                                                                    <img src={EmptyPlanningsImg} width={100}/>
                                                                    <p className="fs-5 text-dark fw-bolder">No Plannings</p>
                                                                    <p className="text-gray-400">Please check after adding planning or activities from <span className="fw-bolder">destination dashboard</span>
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        }



                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                            <div className="col-xl-4 col-md-4">
                                                {activeDestination &&
                                                    <TripPlanningInfo destination={activeDestination} updateTripDestination={updateTripDestination} tripData={tripData} />
                                                }

                                            </div>
                                    </div>
                                }


                        </div>




                    </div>

                </div>

                {activeDestination && planningCategory &&(
                    <FormContainer tripData={tripData} category={planningCategory} updateTripDestination={updateTripDestination}   setShowModal={(e: boolean) => setShowPlanningFormModal(e)}
                                   showModal={showPlanningFormModal} activeDestination={activeDestination}  activeActivity={activeActivity}/>
                )}

                <Modal show={showDeleteConfirmModal}  className="" onHide={function () {

                    setShowDeleteConfirmModal(false)
                }} animation={true} centered>
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-5 fw-bolder text-dark">Delete activity</h5>
                    </div>
                    <div className="modal-body border-0 text-center">
                        <p className={`text-dark`}>Are you sure you want to delete this activity?</p>
                    </div>
                    <div className="modal-footer border-0">
                        <button type="button" className="btn btn-secondary" onClick={()=>{setShowDeleteConfirmModal(false)}}>Cancel </button>
                        <button type="button" className="btn btn-primary" disabled={showDeleteLoading} onClick={()=>{
                            if(!showDeleteLoading){
                                deleteActivity()
                            }
                        }}>{showDeleteLoading? 'Deleting...': 'Confirm'}</button>
                    </div>
                </Modal>
            </div>
    );
};
export default TripPlanning;
