import React, {FC, useEffect, useState} from 'react';
import {
    NavLink, useNavigate, useParams
} from "react-router-dom";

import './header.scss'
import DropdownButton from "react-bootstrap/cjs/DropdownButton";
import Dropdown from "react-bootstrap/cjs/Dropdown";
import dayjs from "dayjs";
import {Trip, TripCodeJoinResponse} from "../../interfaces/trip.interface";
import Loader from "../loader/Loader";
import Modal from "react-bootstrap/cjs/Modal";
import MemberItem from "../trips/cards/MemberItem";
import {User, UserResponse} from "../../interfaces/user.interface";
import http from "../../services/api";
import {authenticate_url, join_trip_url} from "../../services/api.utils";
import {showAlert} from "../../util";
import BackButtonImgIcon from "../../images/icons/back-button.svg";


type HeaderProps = {
    trip?: Trip;
    user:UserResponse|null,
    allInvitedTripCount?: number;
    handleEditModelShow:any,
    resetTripFilter:any
    isResponsive:boolean,
    hasSelectedTrip:boolean,
}
type ParamTypes =  {
    tripType: string,
}

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const TripHeader: FC <HeaderProps> = ({ trip,user ,handleEditModelShow,resetTripFilter,isResponsive,hasSelectedTrip,allInvitedTripCount}) => {

    const navigate = useNavigate();
    let { tripType } = useParams<ParamTypes>();

    const { width } = useViewport();
    const breakpoint = 620;
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [activeTab, setActiveTab] = useState(tripType);
    const [tripCode, setTripCode] = useState("");
    const [tripCodeStatus, setTripCodeStatus] = useState({
        status: 0,
        message: ""
    });



    const sentJoinRequest = () => {

        http
            .put<null, TripCodeJoinResponse>(join_trip_url, {code:tripCode})
            .then((res) => {
                if (res) {
                    setTripCodeStatus({message: res.message, status: res.status})

                    if(res.status == 200){
                        setTripCode("")

                        setShowJoinModal(false)
                        navigate("/trip/"+res.data.tripId);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setInterval(() => {
                    setTripCodeStatus({
                        status: 0,
                        message: ""
                    })
                },3000)
            });
    };

    return (
        <header className="header">

                <div className="d-flex justify-content-between">
                   <div className={`d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1  mt-3 mb-4`}>
                       <NavLink className="nav-link fs-5 ms-3 fw-bolder" to="/trips/upcoming" onClick={function () {
                           setActiveTab("upcoming")
                           resetTripFilter("upcoming")
                       }}  >
                           Upcoming Trips
                       </NavLink>
                       <NavLink className="nav-link fs-5 ms-3 fw-bolder" to="/trips/active" onClick={function () {
                           resetTripFilter("active")
                           setActiveTab("active")
                       }}   >
                           Active Trip
                       </NavLink>

                       <NavLink className="nav-link fs-5 ms-3 fw-bolder" to="/trips/completed" onClick={function () {
                           setActiveTab("completed")
                           resetTripFilter("completed")
                       }}  >
                           Completed Trips
                       </NavLink>
                       <NavLink className="nav-link nav-link-invited fs-5 ms-3 fw-bolder" to="/trips/invited" onClick={function () {
                           setActiveTab("invited")
                           resetTripFilter("invited")
                       }}  >
                           Invited Trips
                           {allInvitedTripCount && allInvitedTripCount > 0 &&
                               <span className="badge rounded-pill badge-primary"> {allInvitedTripCount} </span>
                           }

                       </NavLink>
                       <a className="nav-link fs-5 ms-3 fw-bolder"  onClick={function () {
                           setShowJoinModal(true)
                       }} >
                           Join Trip?
                       </a>
                   </div>

                    { user?.data.userType == "customer" && width > breakpoint ?
                        <div className={`mt-4`}>
                            <a className="nav-link fs-5 ms-3 fw-bolder" onClick={function () {
                                navigate('/new')
                            }}>
                                New Trip?
                            </a>
                        </div>
                        : ""}

                </div>


            <Modal show={showJoinModal} onHide={function () {

                setShowJoinModal(false)
            }} animation={false}>
                <Modal.Header className={`border-0 pb-0`}>


                    <div className="btn btn-icon btn-sm btn-active-light-primary ms-2 p-0" >
                        <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20}  onClick={function () {
                            setShowJoinModal(false)
                        }}/>
                    </div>
                    <Modal.Title className={`fw-bolder`}>Join trip</Modal.Title>

                    <div className="btn btn-icon btn-sm btn-active-light-primary  p-0" >

                    </div>

                </Modal.Header>
                <Modal.Body>
                    <input type="text"
                           className={"form-control form-control-lg form-control-solid"}
                           name="code"
                           value={tripCode}
                           onChange={function (e) {
                               setTripCode(e.target.value)
                           }}
                           placeholder="Join Code"
                    />
                    {tripCodeStatus.status == 200?
                        <p className="text-success">{tripCodeStatus.message}</p>
                        :
                        <p className="text-danger">{tripCodeStatus.message}</p>
                    }


                    <div className="modal-footer" style={{ border: "none"}}>
                        <button className=" btn btn-primary shadow-none" disabled={tripCode?false: true} onClick={sentJoinRequest} type="button">Join</button>
                    </div>

                </Modal.Body>
            </Modal>

        </header>
    );
};

export default TripHeader;
