import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {DashboardData} from "../../interfaces/dashboard.interface";

const dashboardData = createSlice({
    name: 'dashboardData',
    initialState: null as DashboardData | null,
    reducers: {
        setDashboardData(state, { payload }: PayloadAction<DashboardData | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setDashboardData } = dashboardData.actions;
export default dashboardData.reducer;
