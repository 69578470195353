import React, {FC, useEffect, useState} from 'react';
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import {useForm} from "react-hook-form";
import {User, UserResponse} from "../../interfaces/user.interface";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import http from "../../services/api";
import {NotificationsResponse} from "../../interfaces/notification.interface";
import {base_url, countries_url, notifications_url, register_url, upload_url, user_url} from "../../services/api.utils";
import {setUser} from "../account/userSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../rootReducer";
import {useAppDispatch} from "../../store";
import Loader from "../loader/Loader";
import {showAlert, toastMessage} from "../../util";
import Select from 'react-select';
import ReactCrop, { Crop } from 'react-image-crop'

import find from 'lodash/find';
import 'react-image-crop/dist/ReactCrop.css'
import './account.scss'
import {CountriesResponse, Country, CountryResponse} from "../../interfaces/country.interface";


const schema = Yup.object()
    .shape({
    firstName: Yup.string().required('Fast Name required'),
    lastName: Yup.string().required('Last Name required'),
    userName: Yup.string().required('User Name required'),
}) .required();


type OptionSchema ={
    name?: string
    value: string
    label?: string
}

type Props = {
    userDataProps: User,
    updateUserData:any
}
const AccountEdit: FC<Props> = ({userDataProps,updateUserData}) => {


/*    const { handleSubmit, register, reset,setFocus, formState: { errors } } = useForm<User>({
        resolver: yupResolver(schema),
    });*/
    const { handleSubmit, register, reset, setFocus, formState: { errors } } = useForm<User>({
        resolver: yupResolver(schema),
    });


    const { handleSubmit:handleAdvancedSubmit, register:registerAdvanced, formState: {errors:advancedErrors } } = useForm<User>();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state: RootState) => state);
    const [userData, setUserData] = useState<User>(userDataProps);
    const [countriesOptions, setCountriesOptions] = useState();
    const [timezonesOptions, setTimezonesOptions] = useState();
    const [countriesData, setCountriesData] = useState<CountriesResponse>();
    const [selectedCountry, setSelectedCountry] = useState<OptionSchema>();
    const [selectedTimezone, setSelectedTimezone] = useState<OptionSchema>();
    const [gender, setGender] = useState("");


    const dispatch = useAppDispatch();




    useEffect(() => {
        setLoading(true);


        if (countriesData == null) {
            http
                .get<null, CountriesResponse>(countries_url)
                .then((res) => {

                    let countries:any = []

                    res.data.countries.map(function (country) {
                        countries.push( { value: country._id, label: country.name })
                    })


                    setCountriesOptions(countries)
                    setCountriesData(res)

                }).catch((error) => {
                console.log(error);
            })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }


    }, [dispatch]);

    const selectCountry = (selectedCountry:any) => {

        if(selectedCountry){

            setSelectedCountry(selectedCountry)

            let selectedCountryData = find(countriesData?.data.countries, {_id:selectedCountry.value});


            let timezonesOptions:any = []
            selectedCountryData?.timezones.map(function (timezone) {
                timezonesOptions.push( { value: timezone._id, label: timezone.name })
            })

            setTimezonesOptions(timezonesOptions)
        }

    }


    const selectTimezone = (timezone:any) => {

        if(timezone){

            setSelectedTimezone(timezone)
        }

    }

    const submitForm = (data: User) => {
        setLoading(true);
        let userData={
            updateType:"basic",
            firstName:data.firstName,
            lastName: data.lastName,
            password: data.password,
            userName:data.userName,
        }
        http
            .put<User, UserResponse>(user_url, userData)
            .then((res) => {
                if (res) {
                    const { data,status,message} = res;



                    if(status == 200){


                        let resData = res
                        resData.data.accessToken = user?.data.accessToken
                        localStorage.setItem('user', JSON.stringify(resData));


                        updateUserData(resData.data)

                        dispatch(setUser(resData));
                        toastMessage('Your account updated successfully', 'success')
                    }else{

                        toastMessage(message, 'error')

                    }


                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const submitAdvancedForm = () => {

        if(gender && selectedCountry  && selectedTimezone ){

            setLoading(true);
            let userData = {
                updateType:"advanced",
                country: selectedCountry.value,
                timezone: selectedTimezone.value,
                gender: gender,
            }

            http
                .put<User, UserResponse>(user_url, userData)
                .then((res) => {
                    if (res) {
                        const { data,status,message} = res;


                        if(status == 200){
                            showAlert(message,"success")
                        }else{

                            showAlert(message,"error")

                        }


                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }else{
            showAlert("Please fill the form","error")
        }


    };

    return (
        <div className="row g-0 g-xl-5 g-xxl-8">
            <div className="col-xl-12 col-md-12">
                <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none trip-list-select">
                    <div className="card-body d-flex flex-column pt-5">
                        {userData?

                            <form className="form d-flex flex-center" onSubmit={handleSubmit(submitForm)} >
                                <div className="card-body mw-800px ">
                                    <div className="row">
{/*
                                        <h3 className="fw-bold mb-6 fs-3">Basic settings</h3>
*/}

                                    </div>
                                    <div className="row mb-8">
                                        <label className="col-lg-3 col-form-label">First Name</label>
                                        <div className="col-lg-9">
                                            <input {...register("firstName")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.firstName? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}
                                                   name="firstName"
                                                   placeholder="First Name"
                                                   value={userData.firstName}
                                                   onChange={(e) => {
                                                       setUserData({
                                                           ...userData,
                                                           firstName: e.target.value,
                                                       });

                                                   }}
                                            />
                                            {errors && errors.firstName && (
                                                <div className="invalid-feedback show"> {errors.firstName.message} </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mb-8">
                                        <label className="col-lg-3 col-form-label">Last Name</label>
                                        <div className="col-lg-9">
                                            <input {...register("lastName")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.lastName? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="lastName"
                                                   placeholder="Last Name"
                                                   value={userData.lastName}
                                                   onChange={(e) => {
                                                       setUserData({
                                                           ...userData,
                                                           lastName: e.target.value,
                                                       });

                                                   }}/>
                                            {errors && errors.lastName && (
                                                <div className="invalid-feedback show"> {errors.lastName.message} </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row mb-8">
                                        <label className="col-lg-3 col-form-label">User Name</label>
                                        <div className="col-lg-9">
                                            <input {...register("userName")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.userName? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="userName"
                                                   placeholder="you@example.com"
                                                   value={userData.userName}
                                                   onChange={(e) => {
                                                       setUserData({
                                                           ...userData,
                                                           userName: e.target.value,
                                                       });

                                                   }}/>
                                            {errors && errors.userName && (
                                                <div className="invalid-feedback show"> {errors.userName.message} </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row mb-8">
                                        <label className="col-lg-3 col-form-label">Email</label>
                                        <div className="col-lg-9">
                                            <span className="fw-bolder fs-6 ms-4">{userData.email}</span>
                                        </div>
                                    </div>

                                    <div className="row mb-8">
                                        <label className="col-lg-3 col-form-label">Password</label>
                                        <div className="col-lg-9">
                                            <input {...register("password")} type="password" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.password? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="password"
                                                   placeholder="*****"
                                                   onChange={(e) => {
                                                       setUserData({
                                                           ...userData,
                                                           password: e.target.value,
                                                       });

                                                   }}/>
                                            {errors && errors.password && (
                                                <div className="invalid-feedback show"> {errors.password.message} </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-3 col-form-label"></label>
                                        <div className="col-lg-9">
                                            {loading ?

                                                <span
                                                        className="btn btn-primary fw-bolder px-6 py-3 me-3">
                                                    Saving
                                                </span>
                                                :

                                                <button type="submit"
                                                        className="btn btn-primary fw-bolder px-6 py-3 me-3">Save
                                                    Changes
                                                </button>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </form>
                            :
                            <Loader cssClass="normal-loader"/>
                        }

                    </div>
                </div>
                {/*<div className="card card-stretch mb-5 bg-light-info hoverable shadow-none trip-list-select">
                    <div className="card-body d-flex flex-column pt-5">
                        {userData ?
                            <form className="form d-flex flex-center"
                                  onSubmit={handleAdvancedSubmit(submitAdvancedForm)}>
                                <div className="card-body mw-800px">
                                    <div className="row">
                                        <h3 className="fw-bold mb-6 fs-3">Advanced settings</h3>

                                    </div>
                                    <div className="row mb-8">
                                        <label className="col-lg-3 col-form-label">Country</label>
                                        <div className="col-lg-9">
                                            <Select
                                                value={selectedCountry}
                                                onChange={selectCountry}
                                                options={countriesOptions}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-8">
                                        <label className="col-lg-3 col-form-label">Timezone</label>
                                        <div className="col-lg-9">
                                            <Select
                                                value={selectedTimezone}
                                                onChange={selectTimezone}
                                                options={timezonesOptions}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-8">
                                        <label className="col-lg-3 col-form-label">Gender</label>
                                        <div className="col-lg-9 col-xl-6">
                                            <div
                                                className="form-check form-check-custom form-check-solid mb-3">
                                                <input className="form-check-input" type="radio"
                                                       name="gender" id="male"
                                                       value="male"
                                                       checked={gender == "male" ? true : false}
                                                       onClick={() => setGender('male')}/>
                                                <label
                                                    className="form-check-label fw-bold text-gray-600"
                                                    htmlFor="male">Male</label>
                                            </div>
                                            <div
                                                className="form-check form-check-custom form-check-solid mb-3">
                                                <input className="form-check-input" type="radio"
                                                       name="gender" value="female" id="female"
                                                       checked={gender == "female" ? true : false}
                                                       onClick={() => setGender('female')}/>
                                                <label
                                                    className="form-check-label fw-bold text-gray-600"
                                                    htmlFor="female">Female</label>
                                            </div>
                                            <div
                                                className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="radio"
                                                       id="prefernottosay"
                                                       name="gender" value="prefernottosay"
                                                       checked={gender == "prefernottosay" ? true : false}
                                                       onClick={() => setGender('prefernottosay')}/>
                                                <label
                                                    className="form-check-label fw-bold text-gray-600"
                                                    htmlFor="prefernottosay">Prefer not to
                                                    say</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <label className="col-lg-3 col-form-label"></label>
                                        <div className="col-lg-9">
                                            <button type="submit"
                                                    className="btn btn-primary fw-bolder px-6 py-3 me-3">Save
                                                Changes
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                            :
                            <Loader cssClass="normal-loader"/>
                        }
                    </div>
                </div>*/}
            </div>



        </div>



    );
};
export default AccountEdit;
