import React, {FC, useEffect, useState} from 'react';
import './preference.scss'
import http from "../../../services/api";
import {Address, PaymentAddress, User, UserResponse} from "../../../interfaces/user.interface";
import {countries_url, user_logs_url, user_url} from "../../../services/api.utils";
import {setLogs} from "../logs/logsSlice";
import {useAppDispatch} from "../../../store";
import {Log, LogsResponse} from "../../../interfaces/log.interface";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import {getCountries, showAlert, toastMessage} from "../../../util";
import Modal from "react-bootstrap/cjs/Modal";
import UserPackageContainer from "../../userPackages/UserPackageContainer";
import Select from "react-select";
import Loader from "../../loader/Loader";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CountriesResponse} from "../../../interfaces/country.interface";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {setUser} from "../userSlice";

type Props = {
    userData: User,
    updateUserData?:any

}


const schema = Yup.object().shape({
    unit: Yup.object().shape({
        label: Yup.string().required('Unit is required'),
        value: Yup.string().required('Unit is required'),
    }).required('Unit is required'),
    country:Yup.object().shape({
        label: Yup.string().required('Country is required'),
        value: Yup.string().required('Country is required'),
    }).required('Country is required')
});
const Preference: FC<Props> = ({userData,updateUserData}) => {

  const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState<any>();
    const [country, setCountry] = useState<any>(userData.country?{
        label:userData.country?.name,
        value:userData.country?.code,
    }:undefined);

    const [unit, setUnit] = useState<any>(  userData.unit?{ value: userData.unit, label: userData.unit}: { value: 'km', label: 'Kilometer(km)'});
    const { user } = useSelector((state: RootState) => state);


    const distanceUnits:any = [
        { value: 'km', label: 'Kilometers' },
        { value: 'miles', label: 'Miles' },
        // Add more options as needed
    ];
    useEffect(() => {

        let countriesList = getCountries()

        setCountries(countriesList)
    },[])

    const dispatch = useAppDispatch();



    const { handleSubmit,control, register, reset,setFocus, formState: { errors } } = useForm<{
        unit: {
            label: string;
            value: string;
        },  country: {
            label: string;
            value: string;
        }
    }>({
        resolver: yupResolver(schema),
        defaultValues: {
            unit: user?.data.unit? user?.data.unit == 'km'?{ value: 'km', label: 'Kilogram' }:{ value: 'miles', label: 'Miles' }:{ value: 'km', label: 'Kilometers' }, // Initial value for `unit`
            country: user?.data.country? { value: user?.data.country.code, label: user?.data.country.name }:{ value: '', label: '' }, // Initial value for `unit`
        }
    });
    const submitForm = (data:any) => {
        setLoading(true);


        let userData={
            updateType:"preference",
            country:{label:data.country.label ,value: data.country.value},
            unit: data.unit.value,
        }
        http
            .put<User, UserResponse>(user_url, userData)
            .then((res) => {
                if (res) {
                    const { data,status,message} = res;



                    if(status == 200){


                        console.log("res",res)

                        let resData = res
                        localStorage.setItem('user', JSON.stringify(resData));
                        updateUserData(resData.data)

                        dispatch(setUser(resData));
                        toastMessage('Your preference updated successfully', 'success')
                    }else{

                        toastMessage(message, 'error')

                    }


                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (



        <div className="row g-0 g-xl-5 g-xxl-8">
            <div className="col-xl-12 col-md-12">
                <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none trip-list-select">
                    <div className="card-body d-flex flex-column pt-5">
                        {userData?

                            <form className="form d-flex flex-center"  onSubmit={handleSubmit(submitForm)}  >
                                <div className="card-body mw-800px ">
                                    <div className="row">
                                        {/*
                                        <h3 className="fw-bold mb-6 fs-3">Basic settings</h3>
*/}

                                    </div>

                                    <div className="row mb-8">
                                        <label className="col-lg-3 col-form-label">Distance Unit</label>
                                        <div className="col-lg-9">
                                            <div style={{ backgroundColor: '#f5f8fa', borderRadius:'10px'}}>

                                                <Controller
                                                    name="unit"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={distanceUnits}
                                                            value={field.value}
                                                            onChange={val => field.onChange(val)}
                                                            placeholder={`Choose Unit`}
                                                            styles={{
                                                                control: (base):any => ({
                                                                    ...base,
                                                                    border: 'none',
                                                                    boxShadow:'none',
                                                                    color:'#5e6278',
                                                                    cursor:'pointer',
                                                                    backgroundColor:'transparent'
                                                                }),
                                                                placeholder: (base):any => ({
                                                                    ...base,
                                                                    color:'#5e6278',

                                                                }),
                                                                indicatorSeparator: (base):any => ({
                                                                    ...base,
                                                                    width: '0px',
                                                                }),

                                                                input: (base):any => ({
                                                                    ...base,
                                                                    color:'#5e6278',
                                                                }),

                                                                menu: (base):any => ({
                                                                    ...base,
                                                                    padding:'5px'
                                                                }),

                                                                option: (base, {isFocused, isSelected}):any => ({
                                                                    ...base,
                                                                    border: `none`,
                                                                    height: '100%',
                                                                    color:'#5e6278',
                                                                    fontSize: '16px',
                                                                    cursor:'pointer',
                                                                    backgroundColor: isFocused
                                                                        ? '#f5f8fa'
                                                                        : isSelected
                                                                            ? '#f5f8fa'
                                                                            : undefined,
                                                                    "&:active": {
                                                                        backgroundColor: "#f5f8fa"
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    )}
                                                />

                                            </div>
                                            {errors && errors.unit && (
                                                <div className="text-primary"> {errors.unit.label?.message} </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mb-8">
                                        <label className="col-lg-3 col-form-label">Country</label>
                                        <div className="col-lg-9">
                                            <div style={{ backgroundColor: '#f5f8fa', borderRadius:'10px'}}>

                                                <Controller
                                                    name="country"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={countries}
                                                            placeholder={`Choose Country`}
                                                            value={field.value}
                                                            onChange={val => field.onChange(val)}
                                                            styles={{
                                                                control: (base):any => ({
                                                                    ...base,
                                                                    border: 'none',
                                                                    boxShadow:'none',
                                                                    color:'#5e6278',
                                                                    cursor:'pointer',
                                                                    backgroundColor:'transparent'
                                                                }),
                                                                placeholder: (base):any => ({
                                                                    ...base,
                                                                    color:'#5e6278',

                                                                }),
                                                                indicatorSeparator: (base):any => ({
                                                                    ...base,
                                                                    width: '0px',
                                                                }),

                                                                input: (base):any => ({
                                                                    ...base,
                                                                    color:'#5e6278',
                                                                }),

                                                                menu: (base):any => ({
                                                                    ...base,
                                                                    padding:'5px'
                                                                }),

                                                                option: (base, {isFocused, isSelected}):any => ({
                                                                    ...base,
                                                                    border: `none`,
                                                                    height: '100%',
                                                                    color:'#5e6278',
                                                                    fontSize: '16px',
                                                                    cursor:'pointer',
                                                                    backgroundColor: isFocused
                                                                        ? '#f5f8fa'
                                                                        : isSelected
                                                                            ? '#f5f8fa'
                                                                            : undefined,
                                                                    "&:active": {
                                                                        backgroundColor: "#f5f8fa"
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    )}
                                                />


                                            </div>
                                            {errors && errors.country && (
                                                <div className="text-primary"> {errors.country.label?.message} </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className="col-lg-3 col-form-label"></label>
                                        <div className="col-lg-9">
                                            {loading ?

                                                <span
                                                    className="btn btn-primary fw-bolder px-6 py-3 me-3">
                                                    Saving
                                                </span>
                                                :

                                                <button type="submit"
                                                        className="btn btn-primary fw-bolder px-6 py-3 me-3">Save
                                                    Changes
                                                </button>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </form>
                            :
                            <Loader cssClass="normal-loader"/>
                        }

                    </div>
                </div>
            </div>



        </div>

    );
};
export default Preference;
