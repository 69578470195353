import React, { FC,useEffect,useState } from 'react';

import '../../trips/trips.scss'
import {Trip} from '../../../interfaces/trip.interface';


import dayjs from "dayjs";

type Props = {
    title: string,
    subTitle: string,
    icon: string,
    activeTab: string,
    updateActiveTab:any
}

const DashboardMenuItem: FC <Props> = ({title,subTitle,icon,updateActiveTab , activeTab }) => {



    return (
        <div className={` ${activeTab == title? 'active': "" } d-flex flex-stack py-2 px-2  mb-1 menu-item cursor-pointer`} onClick={function(){
            updateActiveTab(title)
        }}>
            <div className="d-flex align-items-center">
                <div className="symbol symbol-45px symbol-circle"><span
                    className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                    <img src={icon} width={20} className={``}/>
                </span>
                </div>
                <div className="ms-3"><a href="#" className="fs-5 fw-bolder title text-hover-primary mb-2">{title}</a>
                    <div className="fw-bold text-gray-400">{subTitle}
                    </div>
                </div>
            </div>
        </div>

    );
};
export default DashboardMenuItem;
