import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ActivityTypesResponse} from '../../../interfaces/activityType.interface';

const activityTypes = createSlice({
    name: 'ActivityTypes',
    initialState: null as ActivityTypesResponse | null,
    reducers: {
        setActivityTypes(state, { payload }: PayloadAction<ActivityTypesResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setActivityTypes } = activityTypes.actions;
export default activityTypes.reducer;
