import React, {FC, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";

import {
    GooglePlaceResponse,
    Trip, TripDataValidationResponse,
    TripDestination, TripDestinationResponse,
} from "../../../interfaces/trip.interface";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch} from "../../../store";
import {eSMoment, getDatesInRange, showAlert, toastMessage} from "../../../util";
import GooglePlaces from "../../googleplaces/GooglePlaces";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import '../trip.scss'

import NewTripDestinationItem from "../../trips/cards/NewTripDestinationItem";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {CurrenciesResponse, Currency} from "../../../interfaces/country.interface";
import http from "../../../services/api";
import {
    base_url,
    currencies_code_url,
    google_place_photo_url, trip_data_validation,
    trip_date_check_url,
    trip_url
} from "../../../services/api.utils";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import sortBy from "lodash/sortBy";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import CloseImgIcon from "../../../images/icons/close-button.svg";
import Modal from "react-bootstrap/cjs/Modal";
import BackButtonImgIcon from "../../../images/icons/back-button.svg";
import EditIconImg from "../../../images/icons/edit.svg";
import DeleteIconImg from "../../../images/icons/delete.svg";
import dayjs from "dayjs";
import LocationImgIcon from "../../../images/illustration-icons/location.svg";
import MemberItem from "../../trips/cards/MemberItem";
import {setTrip} from "../tripSlice";
import find from "lodash/find";
import {cloneDeep} from "lodash";
import loadingImg from "../../../images/loaders/earth.svg";
import ErrorInfoImg from "../../../images/icons/error.svg";
import {Helmet} from "react-helmet";
import CalenderImg from "../../../images/icons/calender.svg";
import Loader from "../../loader/Loader";

type ParamTypes =  {
    tripId: string,
    tripData:Trip,
    updateTripDestinationData:any,
    updateTripData:any,
    isModal?:boolean
}


type OptionSchema ={
    name?: string
    value: string
    label?: string
}

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const TripSettingsDestinations: FC <ParamTypes>= ({tripData, updateTripDestinationData,updateTripData,isModal} ) => {


    console.log("tripData",tripData)
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { handleSubmit:handleSubmitWizardTwo, register:registerWizardTwo, reset: resetWizardTwo , formState: { errors:errorsWizardTwo } } = useForm<TripDestination>();
    const { currencies } = useSelector((state: RootState) => state);

    const [tripBasicData, setTripBasicData] = useState(tripData);
    const [loading, setLoading] = useState(false);
    const [editDestinationKey, setEditDestinationKey] = useState<number | null>(null);
    const [previewDestinations, setPreviewDestinations] = useState(false);
    const [previewMembers, setPreviewMembers] = useState(false);

    const [calenderIsOpen, setCalenderIsOpen] = useState(false);

    const [currenciesData, setCurrenciesData] = useState<[Currency]|null>();
    const [currenciesOptions, setCurrenciesOptions] = useState();
    const [showEditErrorModal, setShowEditErrorModal] = useState(false);
    const [showDeleteErrorModal, setShowDeleteErrorModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState<OptionSchema>();
    const [selectedDestinationId, setSelectedDestinationId] = useState<any>();
    const [unavailableDates, setUnavailableDates] = useState();
    const [tripValidationData, setTripValidationData] = useState<TripDataValidationResponse>();
    const [tripValidationLoading, setTripValidationLoading] = useState(false);
    const [showAiSuggestionModal, setShowAiSuggestionModal] = useState(false);
    const [disableSaveTripButton, setDisableSaveTripButton] = useState(false);

    const { width } = useViewport();



    let excludeDatesArray:any = []
    tripData.destinations.map((destination)=> {
        let dates = getDatesInRange(destination.startDate,destination.endDate)


        console.log("dates",dates)
        excludeDatesArray.push(dates)
    })
    const [excludeDates, setExcludeDates] = useState(excludeDatesArray);

    useEffect(() => {

        if(currencies){
            let currenciesData:any = []

            currencies.data.currencies.map(function (currency) {
                currenciesData.push( { value: currency._id, label: currency.name+" - "+currency.code })
            })
            setCurrenciesOptions(currenciesData)

        }

    },[])

    useEffect(() => {

        if(tripData){
            setTripBasicData(tripData)

        }

    },[tripData])


    useEffect(() => {

        http
            .post<null, any>(trip_date_check_url)
            .then((res) => {
                if (res) {

                    if(res.status == 200){

                        let dateArray:any = []

                        res.data.map((date:any)=>{

                            dateArray.push( new Date(date))
                        })


                        setUnavailableDates(dateArray)


                    }
                }

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {


            });

    },[])

    const submitFormWizardTwo = (data: TripDestination) => {

      /*  if(editDestinationKey != null){

            if(tripBasicData){


                tripBasicData.destinations.map(function (destination, key) {

                    if(key == editDestinationKey){

                        if(startDate){
                            destination.startDate = startDate.toString();
                        }
                        if(endDate){
                            destination.endDate = endDate.toString();

                        }
                        if(place){

                            destination.name = place.name;
                            destination.lat = place.lat;
                            destination.lng = place.lng;
                            destination.placeId = place.placeId;
                            destination._country = place._country;
                            destination.currencyId =  selectedCurrency?.value?selectedCurrency?.value:"";

                        }
                    }else if(key > editDestinationKey){


                     /!*   let currentDestinationDateDifference = eSMoment(destination.endDate).diff(destination.startDate, 'days')


                        let nextDate = eSMoment(new Date(tripBasicData.destinations[key-1].endDate)).add(currentDestinationDateDifference, 'days').format('YYYY/MM/DD HH:mm:ss')
                        destination.startDate = tripBasicData?.destinations[key-1].endDate;
                        destination.endDate = nextDate;
*!/
                    }



                    return destination


                })
                let lastTripEndDate = new Date()
                let tripDestinationLength =   tripBasicData.destinations.length
                if(tripDestinationLength){
                    lastTripEndDate =  new Date( tripBasicData.destinations[tripDestinationLength-1].endDate);
                    nextDate = eSMoment(new Date(lastTripEndDate)).add(2, 'days').format('YYYY/MM/DD HH:mm:ss')
                }


                setStartDate(lastTripEndDate);
                setEndDate(new Date(nextDate));

                setAddress("")
                setEditDestinationKey(null)

                setTripBasicData({...tripBasicData})
            }


        }else{*/

            if(place){
                data.name = place.name;
                data.lat = place.lat;
                data.lng = place.lng;
                data.placeId = place.placeId;
                data._country = place._country;
                data.currencyId =  selectedCurrency?.value?selectedCurrency?.value:"";
                data.photoReference =place.photoReference;
                if(startDate){
                    data.startDate = startDate.toString();
                }


                if(endDate){
                    data.endDate = endDate.toString();

                    setMinDate(endDate)
                }

                let newDestinations = cloneDeep(tripBasicData.destinations)

                if(tripBasicData){


                    if(newDestinations){
                        newDestinations.push(data)
                    }else{
                        newDestinations = [data];
                    }


                    let destinationSortBy:any = sortBy(newDestinations,  function(destination) {
                        return new Date(destination.startDate);
                    },['asc']);



                    setTripBasicData({ ...tripBasicData, destinations: destinationSortBy })

                 //   updateTripDestinationData(destinationSortBy)

                }



                setStartDate(endDate);
                setEndDate(undefined);


                setAddress("")
                setPlace(undefined)
                resetWizardTwo()
            }else{
                showAlert("Destination missing...","error")
            }
       // }


    };
    let nextDate = eSMoment(new Date()).add(2, 'days').format('YYYY/MM/DD HH:mm:ss')

    let newStartDate = eSMoment(new Date(tripBasicData.destinations[(tripBasicData.destinations.length)-1].endDate)).format('YYYY/MM/DD HH:mm:ss')
    let newEndDate = eSMoment(new Date(newStartDate)).add(2, 'days').format('YYYY/MM/DD HH:mm:ss')


    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();
    const [minDate, setMinDate] = useState<Date | null>(new Date(newStartDate));
    const [maxDate, setMaxDate] = useState<Date | null>();

    const [address, setAddress] = useState("");
    const [place, setPlace] = useState<GooglePlaceResponse>();

    const handlePlaceChange = (address:any) => {

        setAddress(address)
    };

    const selectCurrency = (selectedCurrency:any) => {


        if(selectedCurrency){

            setSelectedCurrency(selectedCurrency)

        }

    }
    const DropdownIndicator = (
        props: DropdownIndicatorProps<OptionSchema, true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="bx bxs-dollar-circle" ></i>
            </components.DropdownIndicator>
        );
    };

    const handlePlaceSelect = (place:GooglePlaceResponse) => {

        setAddress(place.name)
        setPlace(place)
    };
    const areDatesInRange = (startDate: Date, endDate: Date, dateArray: string[]): boolean => {
        for (const dateString of dateArray) {
            const currentDate = new Date(dateString);
            if (currentDate >= startDate && currentDate <= endDate) {
                return true;
            }
        }
        return false;
    };

    const onChange = (end:any) => {
        //  const [start, end] = dates;


        if(unavailableDates && startDate){

            if(areDatesInRange(startDate, end, unavailableDates)){



                toastMessage("The dates you've selected for your upcoming trip overlap with another trip you are already a part of it.", 'error')

            }else{

                setEndDate(end);
                setCalenderIsOpen(false)
            }

        }

    };

    const deleteDestination = (key:any) => {




        let tripBasicDataClone = cloneDeep(tripBasicData)

        if(tripBasicDataClone){
            if(tripBasicDataClone.destinations.length > 1){

                let destination = tripBasicDataClone.destinations[key]

                tripBasicDataClone.destinations.splice(  key, 1);


                setTripBasicData({...tripBasicDataClone})


                setEditDestinationKey(null)


                let lastTripEndDate = new Date()
                let nextDate = eSMoment(new Date()).add(2, 'days').format('YYYY/MM/DD HH:mm:ss')

                lastTripEndDate =  new Date(tripBasicDataClone.destinations[tripBasicDataClone.destinations.length-1].endDate);

                setStartDate(lastTripEndDate);
                setEndDate(null);
                setMinDate(lastTripEndDate)

                setAddress("")


                setEditDestinationKey(null)


                if(destination._id){
                    updateTripDestinationData(tripBasicDataClone.destinations)
                }


            }else{
                toastMessage('Cannot remove all destinations','error')

            }
        }

    };
    const editDestination = (key:any) => {


        if(tripBasicData){
            setStartDate(new Date(tripBasicData.destinations[key].startDate));
            setEndDate(new Date(tripBasicData.destinations[key].endDate))
            setAddress(tripBasicData.destinations[key].name)
            setEditDestinationKey(key)


            let minimumDate = null
            if(key > 0){
                minimumDate = new Date(eSMoment(new Date(tripBasicData.destinations[key-1].endDate)).format('YYYY/MM/DD HH:mm:ss'))
            }
            setMinDate(minimumDate)
            if(key < tripBasicData.destinations.length -1 ){
                let maximumDate = new Date(eSMoment(new Date(tripBasicData.destinations[key+1].startDate)).format('YYYY/MM/DD HH:mm:ss'))

                setMaxDate(maximumDate)
            }
            let activeCurrency = tripBasicData.destinations[key].activeCurrency

            if(activeCurrency){
                setSelectedCurrency(
                    { value:activeCurrency._id, label: activeCurrency.name+" - "+activeCurrency.code }
                )
            }



        }

    };

    const deleteActivities = () => {

        http
            .delete<null, TripDestinationResponse>(trip_url+"/"+tripData._id+"/destination/"+selectedDestinationId+"/all/activities")
            .then((res) => {
                if (res) {


                    let tripDestinationsClone = tripBasicData.destinations


                    let index = tripDestinationsClone.findIndex(tripDestination => tripDestination._id == selectedDestinationId);


                    tripDestinationsClone[index] = res.data

                    updateTripData({
                        ...tripBasicData,
                        destinations:tripDestinationsClone
                    })

                    dispatch(setTrip({
                        ...tripBasicData,
                        destinations:tripDestinationsClone
                    }));

                    if(showEditErrorModal){
                        editDestination(index)
                    }

                    if(showDeleteErrorModal){
                        deleteDestination(index)
                    }

                   let  lastTripEndDate =  new Date(tripBasicData.destinations[tripBasicData.destinations.length-1].endDate);


                    setStartDate(lastTripEndDate)


                    setSelectedDestinationId(undefined)

                    setShowEditErrorModal(false)
                    setShowDeleteErrorModal(false)


                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    }

    const validateTripData = () => {

        setTripValidationLoading(true)
        setShowAiSuggestionModal(true)

        http
            .post<any, TripDataValidationResponse>(base_url + trip_data_validation, {destinations: tripBasicData?.destinations.filter((destination)=>destination._id == undefined)})
            .then((res) => {
                if (res) {
                    setTripValidationLoading(false)

                    if (res.status == 200) {


                        setTripValidationData(res)

                        /*


                        */


                    }

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });

    }

    return (

                <div className="row edit-destinations">



                    <div className="col-xl-12 col-md-12 form-wizard">



                        {/*<div className="card card-stretch mb-5 mb-xxl-8">

                            <div className="card-body">

                                <form onSubmit={handleSubmitWizardTwo(submitFormWizardTwo)} className="trip-form">

                                    <div className="row g-3 mb-3">
                                        <div className="col-12">
                                            <label htmlFor="destinationName" className="form-label">Destination Name </label>
                                            <GooglePlaces placeholder='Search Places ...' searchType={'geocode'} address={address}  lat={2} lng={1}  handlePlaceSelect={handlePlaceSelect} handlePlaceChange={handlePlaceChange} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="budget" className="form-label">Date </label>

                                            <div className="form-control form-control-lg form-control-solid new-destination-range">


                                                <DatePicker
                                                    minDate={minDate}
                                                    maxDate={maxDate}
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    excludeDates={excludeDates}
                                                    endDate={endDate}
                                                    selectsRange
                                                    dateFormat="dd/MM/yyyy"
                                                    wrapperClassName="new-trip-date-input"
                                                />

                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="budget" className="form-label">Currency </label>

                                            <div className="form-control form-control-solid new-destination-range">



                                                <Select
                                                    value={selectedCurrency}
                                                    onChange={selectCurrency}
                                                    options={currenciesOptions}
                                                    styles={{
                                                        control: (base) => ({
                                                            ...base,
                                                            border: 'none',
                                                            boxShadow:'none',
                                                            color:'#5e6278',
                                                            backgroundColor:'#f5f8fa'
                                                        }),
                                                        indicatorSeparator: (base) => ({
                                                            ...base,
                                                            width: '0px',
                                                        }),

                                                        option: (base, {isFocused, isSelected}) => ({
                                                            ...base,
                                                            border: `none`,
                                                            height: '100%',
                                                            color:'#5e6278',
                                                            fontSize: '16px',
                                                            backgroundColor: isFocused
                                                                ? '#f5f8fa'
                                                                : isSelected
                                                                    ? '#f5f8fa'
                                                                    : undefined,
                                                            "&:active": {
                                                                backgroundColor: "#f5f8fa"
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="modal-footer">


                                        <button className=" btn btn-primary shadow-none" type="submit" disabled={editDestinationKey != null? false: previewDestinations || place == undefined}> {editDestinationKey != null? "Update" :"Add"} Destination</button>



                                    </div>

                                </form>
                            </div>
                        </div>*/}

                        {tripData.status != 'completed'?
                            <div className="destination-search mb-10 rounded" style={width<=620?{backgroundColor: 'transparent'}:{}}>

                                <div className="row m-1 ">
                                    <form onSubmit={handleSubmitWizardTwo(submitFormWizardTwo)} className="trip-form d-flex flex-column flex-lg-row   p-3 p-sm-0 p-md-0 p-xl-0 p-lg-0 " style={{ backgroundColor:'#FFF'}}>
                                        <div className={`col-xl-5 p-3 position-relative ${width<= 620? 'bg-white rounded-4':''}`} >
                                            <div className={`position-absolute ` } style={{ backgroundColor:'#f5f8fa',borderRadius: '10px', width:'96%', padding:'2px 0px'}}>
                                                <GooglePlaces placeholder='Where do you want to go?' searchType={'(cities)'} address={address}  lat={2} lng={1}  handlePlaceSelect={handlePlaceSelect} handlePlaceChange={handlePlaceChange} />


                                            </div>
                                        </div>
                                        <div className={`col-xl-3 p-3 d-flex ${width<= 620? 'bg-white rounded-4 mt-3 pt-2 pb-3  justify-content-start':' justify-content-center'}`} >


                                            <div className={`px-3 me-3 cursor-pointer`} style={{ backgroundColor:'#f5f8fa',borderRadius: '10px'}}>
                                                <DatePicker
                                                    minDate={minDate}
                                                    selected={startDate}
                                                    onChange={(dateItem)=> {
                                                        setCalenderIsOpen(true)
                                                        setStartDate(dateItem)
                                                        setEndDate(null)
                                                    }}
                                                    onInputClick={()=>setCalenderIsOpen(false)}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    excludeDates={unavailableDates}
                                                    selectsStart
                                                    dateFormat="MMM d yyyy"
                                                    wrapperClassName={width<=620? 'new-trip-date-input-sm':'new-trip-date-input'}
                                                    customInput={React.createElement(function ({ value, onClick }: {value: string; onClick: any}){

                                                        return(
                                                            <div onClick={onClick} className={``}>
                                                                {startDate?
                                                                    <div className={`d-flex mt-1`}>
                                                                        <img src={CalenderImg} className={`me-2`} style={{ opacity: '0.5'}}  width={30}/>
                                                                        <div className={`d-flex flex-column`}>

                                                                            <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>{eSMoment(value).format('MMM D')}</span>
                                                                            <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>{eSMoment(value,'MMM D YYYY').format('YYYY')}</span>

                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className={`d-flex mt-1`}>
                                                                        <img src={CalenderImg} className={`me-2`}  style={{ opacity: '0.5'}}  width={30}/>
                                                                        <div className={`d-flex flex-column`}>

                                                                            <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>Start</span>
                                                                            <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>Date</span>

                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div>

                                                        )

                                                    })}
                                                />
                                            </div>


                                            <div className={`px-3 me-3 cursor-pointer`} style={{ backgroundColor:'#f5f8fa', borderRadius: '10px'}}>
                                                <DatePicker
                                                    minDate={startDate}
                                                    selected={endDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    excludeDates={unavailableDates}
                                                    open={calenderIsOpen}
                                                    selectsEnd
                                                    openToDate={startDate || new Date()} // Set the openToDate prop here
                                                    dateFormat="MMM d yyyy"
                                                    wrapperClassName={width<=620? 'new-trip-date-input-sm':'new-trip-date-input'}
                                                    customInput={React.createElement(function ({ value, onClick }: {value: string; onClick: any}){

                                                        console.log("endDateendDate",endDate)


                                                        return(
                                                            <div onClick={()=>{
                                                                setCalenderIsOpen(!calenderIsOpen)
                                                                onClick()
                                                            }}>
                                                                {endDate?
                                                                    <div className={`d-flex mt-1`}>
                                                                        <img src={CalenderImg} className={`me-2`} style={{ opacity: '0.5'}}  width={30}/>
                                                                        <div className={`d-flex flex-column`}>
                                                                            <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>{eSMoment(value).format('MMM D')}</span>
                                                                            <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>{eSMoment(value).format('YYYY')}</span>

                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className={`d-flex mt-1`}>
                                                                        <img src={CalenderImg} className={`me-2`} style={{ opacity: '0.5'}} width={30}/>
                                                                        <div className={`d-flex flex-column`}>

                                                                            <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>End</span>
                                                                            <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>Date</span>

                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div>

                                                        )

                                                    })}
                                                />
                                            </div>








                                        </div>
                                        <div className={`col-xl-3 col d-flex p-3 ps-0 ${width<= 620? 'bg-white rounded-4 mt-3 py-2 mb-5':''}`}>
                                            <div className="label currency-container" >


                                                <Select
                                                    value={selectedCurrency}
                                                    onChange={selectCurrency}
                                                    options={currenciesOptions}
                                                    components={{ DropdownIndicator }}
                                                    placeholder={`Choose Currency`}
                                                    styles={{
                                                        control: (base):any => ({
                                                            ...base,
                                                            border: 'none',
                                                            boxShadow:'none',
                                                            color:'#5e6278',
                                                            cursor:'pointer',
                                                            backgroundColor:'transparent'
                                                        }),
                                                        placeholder: (base):any => ({
                                                            ...base,
                                                            color:'#5e6278',

                                                        }),
                                                        indicatorSeparator: (base):any => ({
                                                            ...base,
                                                            width: '0px',
                                                        }),

                                                        input: (base):any => ({
                                                            ...base,
                                                            color:'#5e6278',
                                                        }),

                                                        menu: (base):any => ({
                                                            ...base,
                                                            padding:'5px',
                                                            boxShadow:'0px 0px 20px 0px #4c577d26'
                                                        }),

                                                        option: (base, {isFocused, isSelected}):any => ({
                                                            ...base,
                                                            border: `none`,
                                                            height: '100%',
                                                            color:'#5e6278',
                                                            fontSize: '16px',
                                                            cursor:'pointer',
                                                            backgroundColor: isFocused
                                                                ? '#f5f8fa'
                                                                : isSelected
                                                                    ? '#f5f8fa'
                                                                    : undefined,
                                                            "&:active": {
                                                                backgroundColor: "#f5f8fa"
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={`col-xl-1 pe-3  d-flex   justify-content-center ${width<= 620? 'col-12':''} `}>
                                            {place && selectedCurrency && startDate && endDate ?

                                                <button type="submit"
                                                        className={`btn btn-lg btn-primary shadow-none mt-3 w-100`}>
                                                    Add
                                                </button> :

                                                <button type="button"
                                                        className={`btn btn-lg btn-primary shadow-none mt-3 w-100`} disabled={true}>
                                                    Add
                                                </button>
                                            }
                                        </div>
                                    </form>

                                </div>
                            </div>
                        :''}

                        <div className="row g-3 mb-3 preview-destination">

                            {tripBasicData && tripBasicData.destinations && tripBasicData.destinations.length &&  tripBasicData?.destinations.map(function (destination,key) {


                                return (
                                    <div className="col-xl-6" key={key }>
                                        <div className="d-flex destination ">
                                            <div className="">
                                                <img className="place-image" src={`${google_place_photo_url}${destination.photoReference}`}
                                                     style={{width: "140px", height:"140px"}} />
                                            </div>
                                            <div className="card cursor-pointer w-100">
                                                <div className="card-header pt-3 border-0"><h3
                                                    className="card-title">{destination.name}</h3>
                                                    {tripData.status != 'completed'?
                                                        <div className="card-toolbar">

                                                            {destination && destination.activities && destination?.activities?.length > 0?
                                                                <div className="card-toolbar">
                                                             {/*       <span  onClick={() => {
                                                                        setShowEditErrorModal(true)
                                                                        setSelectedDestinationId(destination._id)
                                                                    } } className="mx-1 hover-opacity-btn cursor-pointer">
                                                                         <img src={EditIconImg} width={15}/>
                                                                    </span>*/}
                                                                    <span className=" hover-opacity-btn cursor-pointer"  onClick={() => {
                                                                        setShowDeleteErrorModal(true)
                                                                        setSelectedDestinationId(destination._id)
                                                                    }}>
                                                                        <img src={DeleteIconImg} width={15}/>
                                                                    </span>
                                                                </div>
                                                                :
                                                                <div className="card-toolbar">
                                                           {/*         <span  onClick={() =>editDestination(key)} className="mx-1 hover-opacity-btn cursor-pointer">
                                                                         <img src={EditIconImg}  width={15}/>
                                                                    </span>*/}
                                                                    <span  onClick={() =>{

                                                                        setEditDestinationKey(key)
                                                                        setShowDeleteConfirmationModal(true)

                                                                    }}  className=" hover-opacity-btn cursor-pointer">
                                                                        <img src={DeleteIconImg} width={15}/>
                                                                    </span>
                                                                </div>
                                                            }
                                                        </div>
                                                    :''}

                                                </div>
                                                <div className="card-body d-flex justify-content-between">
                                                    <div className="col"><span className="date-title">Starting On</span>
                                                        <div className="d-flex align-items-center">
                                                            <div className="symbol symbol-45px me-2"><span
                                                                className="align-items-end date">  {eSMoment(destination.startDate).format('D')}</span></div>
                                                            <div className="d-flex flex-column flex-grow-1">
                                                                        <span
                                                                            className="text-gray-800 text-hover-primary mb-1 fs-8 fw-bolder"> {eSMoment(destination.startDate).format('MMM YYYY')}</span><span
                                                                className="text-muted fs-9">{eSMoment().diff(destination.startDate, 'days') == 0? "Today": eSMoment(destination.startDate).format('dddd')}</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="pt-4"><span
                                                            className="text-muted fs-4 ">{eSMoment(destination.endDate).diff(destination.startDate, 'days')}D/{(eSMoment(destination.endDate).diff(destination.startDate, 'days'))-1}N</span></div>
                                                    </div>
                                                    <div className="col"><span
                                                        className="date-title">Ending On</span>
                                                        <div className="d-flex align-items-center">
                                                            <div className="symbol symbol-45px me-2"><span
                                                                className="align-items-end date">{eSMoment(destination.endDate).format('D')}</span></div>
                                                            <div className="d-flex flex-column flex-grow-1">
                                                                        <span
                                                                            className="text-gray-800 text-hover-primary mb-1 fs-8 fw-bolder">{eSMoment(destination.endDate).format('MMM YYYY')}</span><span
                                                                className="text-muted fs-9">{eSMoment().diff(destination.endDate, 'days') == 0? "Today": eSMoment(destination.startDate).format('dddd')}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}

                            <div className={`text-end`}>

                                { tripBasicData?.destinations.filter((destination)=>destination._id == undefined).length?

                                    <button type="button" name="backward"
                                            className=" btn btn-lg mb-10 mb-xl-0 btn-primary shadow-none"
                                            onClick={function () {

                                                validateTripData()

                                            }}>Validate Trip
                                    </button>

                                    :

                                    ""
                                }

                            </div>


                        </div>
                    </div>
                    <Modal show={showDeleteErrorModal || showEditErrorModal}  className="" onHide={function () {

                        setShowDeleteErrorModal(false)
                        setShowEditErrorModal(false)
                    }} animation={true} centered>
                        <Modal.Header className={`border-0 pb-0`}>


                            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2 p-0" >
                                <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>{

                                    setShowDeleteErrorModal(false)
                                    setShowEditErrorModal(false)
                                    setSelectedDestinationId(undefined)
                                }}/>
                            </div>
                            <Modal.Title className={`fw-bolder`}></Modal.Title>


                            <div></div>

                        </Modal.Header>
                        <Modal.Body>

                            <div className="text-center mb-10">
                                <h1 className="mb-3 fs-1 text-dark">You're about to delete all your activities</h1>
                                <div className="text-gray-400 fs-5">All your destination activities will be permenently removed and
                                    you won't be able to see them again.</div>
                            </div>

                            <div className="d-flex mt-5 justify-content-end">

                                <button type="button" name="backward"
                                        className=" btn btn-lg btn-light shadow-none me-3" onClick={() => {
                                    setShowDeleteErrorModal(false)
                                    setShowEditErrorModal(false)
                                    setSelectedDestinationId(undefined)
                                } }>Cancel
                                </button>
                                <button type="button" name="backward"
                                        className=" btn btn-lg btn-primary shadow-none" disabled={loading? true: false} onClick={() => {

                                    deleteActivities()


                                }}>Confirm
                                </button>

                            </div>

                        </Modal.Body>
                    </Modal>


                    <Modal show={showDeleteConfirmationModal}  className="" onHide={function () {

                        setShowDeleteConfirmationModal(false)
                    }} animation={true} centered>
                        <Modal.Header className={`border-0 pb-0`}>


                            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2 p-0" >
                                <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>{

                                    setShowDeleteConfirmationModal(false)

                                }}/>
                            </div>
                            <Modal.Title className={`fw-bolder`}></Modal.Title>


                            <div></div>

                        </Modal.Header>
                        <Modal.Body>

                            <div className="text-center mb-10">
                                <h1 className="mb-3 fs-1 text-dark">You're about to delete your destination</h1>
                                <div className="text-gray-400 fs-5">Removing your destination from the trip cannot be undone. All associated activities and documents will be permanently lost.</div>
                            </div>

                            <div className="d-flex mt-5 justify-content-end">

                                <button type="button" name="backward"
                                        className=" btn btn-lg btn-light shadow-none me-3" onClick={() => {
                                    setShowDeleteConfirmationModal(false)
                                } }>Cancel
                                </button>
                                <button type="button" name="backward"
                                        className=" btn btn-lg btn-primary shadow-none" disabled={loading? true: false} onClick={() => {


                                    deleteDestination(editDestinationKey)
                                    setShowDeleteConfirmationModal(false)


                                }}>Confirm
                                </button>

                            </div>

                        </Modal.Body>
                    </Modal>
                    <Modal show={showAiSuggestionModal}  onHide={function () {

                        setShowAiSuggestionModal(false)
                    }} animation={true} size={isModal?'xl':'lg'} centered  className={`suggestion-modal ${showAiSuggestionModal?'suggestion-modal-shadow':''}`}   >

                        <div className="modal-header pb-0 border-0">

                            <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {

                                setShowAiSuggestionModal(false)
                            }}/>

                        </div>
                        <div className="modal-body">

                            {tripValidationLoading ?

                                <div  className={`text-center mb-5 pb-5`}>

                                    <img src={loadingImg} width={100}/>
                                    <p className={`text-dark fs-4 mx-1 mx-lg-5 px-0 px-lg-5`}>We are currently in the process of verifying the information to ensure a smooth and accurate travel experience for you; this typically takes 15-60 seconds.</p>
                                </div>

                                :

                                <>
                                    <div className="text-center mt-0 mb-10">
                                        <h1 className="mb-3 fs-3 text-dark fw-bolder">{tripValidationData?.data.itinerary_status_reason}</h1>
                                        <h1 className="mb-3 fs-5 text-dark">{tripValidationData?.data.itinerary_status_description}</h1>
                                    </div>

                                    <div className="container">



                                        <div className="row g-3 mb-3 preview-destination">

                                            {tripValidationData?.data?.suggestion && tripValidationData?.data?.suggestion.map((destination, key)=>{



                                                let startDate = eSMoment(destination.start_date,'DD/MM/YYYY');
                                                let endDate = eSMoment(destination.end_date,'DD/MM/YYYY');
                                                return (

                                                    <div className="col-xl-6" key={key }>
                                                        <div className="d-flex destination ">
                                                            <div className="">
                                                                <img className="place-image" src={`${google_place_photo_url}${destination.photoReference}`}
                                                                     style={ width>=620? {width: "140px", height:"140px"}:{width: "100px", height:"140px"}} />
                                                            </div>
                                                            <div className="card cursor-pointer w-100">
                                                                <div className="card-header pt-3 border-0"><h3
                                                                    className="card-title">{destination.destination}</h3>
                                                                    <div className="card-toolbar">

                                                                        <OverlayTrigger  placement="right" overlay={
                                                                            <Tooltip id={`tooltip-${key}`}  className={``} >
                                                                                <div className="d-flex align-items-center">


                                                                                    <div className="ms-0 text-start">
                                                                                    <span className="fs-6 text-gray-900 text-hover-primary mb-2 cursor-pointer">

                                                                                        {destination.recommendation}
                                                                                    </span>

                                                                                    </div>
                                                                                </div>
                                                                            </Tooltip>
                                                                        }>
                                                                            <img src={ErrorInfoImg} width={15}/>
                                                                        </OverlayTrigger>


                                                                    </div>
                                                                </div>
                                                                <div className="card-body d-flex justify-content-between">
                                                                    <div className="col"><span className="date-title">Starting On</span>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="symbol symbol-45px me-2"><span
                                                                                className="align-items-end date">  {startDate.format('D')}</span></div>
                                                                            <div className="d-flex flex-column flex-grow-1">
                                                                        <span
                                                                            className="text-gray-800 text-hover-primary mb-1 fs-8 fw-bolder"> {startDate.format('MMM YYYY')}</span><span
                                                                                className="text-muted fs-9">{eSMoment().diff(startDate, 'days') == 0? "Today":startDate.format('dddd')}</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="pt-4"><span
                                                                            className="text-muted fs-4 ">{endDate.diff(startDate, 'days')}D/{(endDate.diff(startDate, 'days'))-1}N</span></div>
                                                                    </div>
                                                                    <div className="col"><span
                                                                        className="date-title">Ending On</span>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="symbol symbol-45px me-2"><span
                                                                                className="align-items-end date">{endDate.format('D')}</span></div>
                                                                            <div className="d-flex flex-column flex-grow-1">
                                                                        <span
                                                                            className="text-gray-800 text-hover-primary mb-1 fs-8 fw-bolder">{endDate.format('MMM YYYY')}</span><span
                                                                                className="text-muted fs-9">{eSMoment().diff(endDate, 'days') == 0? "Today": startDate.format('dddd')}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            }) }


                                        </div>



                                    </div>
                                    <div className="d-flex mt-3 justify-content-end">

                                        {tripValidationData?.data.itinerary_status?

                                            <button type="button" name="backward"  disabled={disableSaveTripButton}
                                                    className=" btn btn-lg btn-primary shadow-none me-3" onClick={()=>{
                                                setShowAiSuggestionModal(false)
                                                updateTripDestinationData(tripBasicData.destinations)

                                            }}>Save & Continue
                                            </button>

                                            :

                                            <>
                                                <button type="button" name="backward"  disabled={disableSaveTripButton}
                                                        className=" btn btn-lg btn-secondary shadow-none me-3" onClick={()=>{
                                                    setShowAiSuggestionModal(false)

                                                    updateTripDestinationData(tripBasicData.destinations)

                                                }}>Ignore and Save
                                                </button>
                                                <button type="button" name="backward"  disabled={disableSaveTripButton}
                                                        className=" btn btn-lg btn-primary shadow-none" onClick={()=>{


                                                    if(tripBasicData) {

                                                        let updatedDestinations = tripBasicData.destinations
                                                        tripValidationData?.data?.suggestion.map((suggestion)=>{


                                                            let index = updatedDestinations.findIndex(destination => destination.placeId === suggestion.placeId);

                                                            if(index >= 0){



                                                                updatedDestinations[index].startDate =  eSMoment(suggestion.start_date,'DD/MM/YYYY').toString();
                                                                updatedDestinations[index].endDate =  eSMoment(suggestion.end_date,'DD/MM/YYYY').toString();
                                                            }
                                                        })
                                                        setTripBasicData({
                                                            ...tripBasicData,
                                                            destinations:updatedDestinations
                                                        })
                                                        setShowAiSuggestionModal(false)
                                                        updateTripDestinationData(updatedDestinations)

                                                    }




                                                }}>Save & Continue
                                                </button>
                                            </>
                                        }


                                    </div>
                                </>
                            }

                        </div>
                    </Modal>
                </div>

    );
};
export default TripSettingsDestinations;
