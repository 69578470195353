import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const SpecialRequirements: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();

    return (
        <div>
            <h2>Special Requirements</h2>
            <Form.Group>
                <Form.Label>Fitness Level</Form.Label>
                <Controller
                    name="specialRequirements.fitnessLevel"
                    control={control}
                    render={({ field }) => <Form.Control {...field} isInvalid={!!errors.specialRequirements?.fitnessLevel} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.specialRequirements?.fitnessLevel?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Age Restrictions</Form.Label>
                <Controller
                    name="specialRequirements.ageRestrictions"
                    control={control}
                    render={({ field }) => <Form.Control {...field} isInvalid={!!errors.specialRequirements?.ageRestrictions} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.specialRequirements?.ageRestrictions?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Travel Documents</Form.Label>
                <Controller
                    name="specialRequirements.travelDocuments"
                    control={control}
                    render={({ field }) => <Form.Control {...field} isInvalid={!!errors.specialRequirements?.travelDocuments} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.specialRequirements?.travelDocuments?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );
};

export default SpecialRequirements;
