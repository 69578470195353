import React, {FC, useEffect, useState} from 'react';
import {
    NavLink, useNavigate, useParams
} from "react-router-dom";

import './header.scss'
import DropdownButton from "react-bootstrap/cjs/DropdownButton";
import Dropdown from "react-bootstrap/cjs/Dropdown";
import dayjs from "dayjs";
import {Trip, TripCodeJoinResponse} from "../../interfaces/trip.interface";
import Loader from "../loader/Loader";
import Modal from "react-bootstrap/cjs/Modal";
import MemberItem from "../trips/cards/MemberItem";
import {User, UserResponse} from "../../interfaces/user.interface";
import http from "../../services/api";
import {authenticate_url, join_trip_url} from "../../services/api.utils";
import {showAlert} from "../../util";
import BackButtonImgIcon from "../../images/icons/back-button.svg";


type HeaderProps = {

    message:string,
    action?:any
    actionMessage?:any
    subMessage?:any
}

const NotificationHeader: FC <HeaderProps> = ({message,action, actionMessage, subMessage }) => {


    return (
        <div className={`notification-header d-flex justify-content-center`}>
            <div>

                <span>{message}</span><br/>
                <span>{subMessage}</span>
            </div>
            {action?
                <button className={`btn btn-light report ms-3 fw-bolder primary-color`} onClick={action}>{actionMessage}</button>

                :''
            }
        </div>
    )


};

export default NotificationHeader;
