import React, {FC, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {NavLink, useNavigate, useParams} from "react-router-dom";

import './trips.scss'
import Sidebar from "../sidebar/Sidebar";
import filter from "lodash/filter";

import http from '../../services/api';


import { useSelector } from 'react-redux';
import { setTrips } from './tripsSlice';
import { setInvitedTrips } from './invitedTripsSlice';
import {ConvertToBaseValue, Trip, TripDestination, TripResponse, TripsResponse} from '../../interfaces/trip.interface';
import { useAppDispatch } from '../../store';
import { RootState } from '../../rootReducer';


import dayjs from "dayjs";
import {google_place_photo_url, s3_user_url, trip_url, trips_invite_url, trips_url} from "../../services/api.utils";
import Loader from "../loader/Loader";
import {
    eSMoment,
    formatBytes,
    getImgUrl,
    numberWithCommas,
    showAlert,
    showConfirmAlert,
    toastMessage
} from "../../util";
import ListItem from "./cards/ListItem";
import Empty from "../empty/Empty";
import TripSectionItem from "./cards/TripSectionItem";

import activitiesIcon from '../../images/illustration-icons/activities.svg';
import checklistIcon from '../../images/illustration-icons/checklist.svg';
import documentsIcon from '../../images/illustration-icons/documents.svg';
import membersIcon from '../../images/icons/members.svg';
import photosIcon from '../../images/trip/photos.svg';
import planningIcon from '../../images/trip/planning.svg';
import Dropdown from "react-bootstrap/cjs/Dropdown";
import DropdownButton from "react-bootstrap/cjs/DropdownButton";
import Modal from "react-bootstrap/cjs/Modal";
import NewTrip from "./newtrip/NewTrip";
import find from "lodash/find";
import SearchMemberItem from "./cards/SearchMemberItem";
import MemberItem from "./cards/MemberItem";
import TripHeader from "../header/TripHeader";
import GoogleMap from "../googleMap/GoogleMap";
import {QRCode} from "react-qrcode-logo";

import logoImage from '../../images/logo.png'
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import homeImage from "../../images/sidebar/home.svg";
import LocationImgIcon from "../../images/illustration-icons/location.svg";
import BackButtonImgIcon from "../../images/icons/back-button.svg";
import DownloadImgIcon from "../../images/icons/download.svg";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import PlanningImg from "../../images/illustration-icons/planning.svg";
import ActivitiesImg from "../../images/illustration-icons/activities.svg";
import InviteImg from "../../images/icons/invite.svg";
import MembersImg from "../../images/icons/members.svg";
import Chart from "react-apexcharts";
import EmptyTripsImg from "../../images/illustration-icons/empty-trips.svg";
import EmptyUpcomingTripsImg from "../../images/illustration-icons/empty-upcoming-trip.svg";
import PageNotFound from "../404/PageNotFound";
import SettingsImg from "../../images/icons/settings.svg";
import CloseWhiteButtonImgIcon from "../../images/icons/close-white.svg";
import RightArrowImgIcon from "../../images/icons/right-arrow-multi.svg";
import AcceptButtonImgIcon from "../../images/icons/accept.svg";
import AcceptWhiteButtonImgIcon from "../../images/icons/accept-white.svg";

type ParamTypes =  {
    tripType: string,
}


const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const tripSections = [
    { name: "Plannings", image: planningIcon},
    { name: "Checklist", image: checklistIcon},
    { name: "Documents", image: documentsIcon},
    { name: "Activities", image: activitiesIcon},
    { name: "Photos", image: photosIcon},
    { name: "Links", image: membersIcon} ]
const Trips: FC = () => {

    const { width } = useViewport();
    const breakpoint = 620;
    const navigate = useNavigate();

    const { tripType } = useParams<ParamTypes>()





    const { trips,trip,user, invitedTrips, appBasic } = useSelector((state: RootState) => state);

    const [allTripData, setAllTripData] = useState<[Trip]>();
    const [allInvitedTripData, setAllInvitedTripData] = useState<[Trip]>();

    const [tripData, updateTripData] = useState(trip as Trip);
    const [invitedTripData, updateInvitedTripData] = useState(trip as Trip);
    const [backgroundUrl, setBackgroundUrl] = useState("");
    const [tripFilter, setTripFilter] = useState("");

    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const [manageLoading, setManageLoading] = useState(false);
    const [listLoading, setListLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [invitedPage, setInvitedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalInvitedPages, setTotalInvitedPages] = useState(1);
    const tripContainerRef = useRef<HTMLInputElement>(null)
    const tripMainContainerRef = useRef<HTMLInputElement>(null)
    const [showDestinationMap, setShowDestinationMap] = useState(false);
    const [joinCodeCopyMessage, setJoinCodeCopyMessage] = useState("Click to copy");
    const [joinUrlCopyMessage, setJoinUrlCopyMessage] = useState("Click to copy");
    const [showInvitation, setShowInvitation] = useState(false);
    const [tripLoading, setTripLoading] = useState(false);
    const [inviteTripLoading, setInviteTripLoading] = useState(false);


    const [tripStat, setTripStat] = useState({
        activityBudget:0,
        planningBudget:0,
        currencyCode:"",
        totalPlanningsCount:0,
        totalActivitiesCount:0,
        totalDocumentsCount:0,
        totalDocumentsSize:0,
        totalDocumentsSizeFormat:"",
        totalCheckLists:0,
        totalCompletedCheckLists:0,
    });


    useEffect(() => {



        if(trips == null){

            getAllTrips(page)
        }else{

            let firstTrip = filter(trips.data.trips, { status: tripType }).map((tripItem, key) => {
                return tripItem
            })

            if(firstTrip.length){
                selectedTripData = filter(trips.data.trips, { status: tripType })[0]
            }


            setAllTripData(trips.data.trips)

            dispatch(setTrips(trips))

            updateTripData(selectedTripData)
            setLoading(false);
            setListLoading(false);
        }
        if(invitedTrips == null){

            getInvitedTrips(invitedPage)

        }else{

            let firstTrip = filter(invitedTrips.data.trips, { status: tripType }).map((tripItem, key) => {
                return tripItem
            })

            if(firstTrip.length){
                selectedTripData = filter(invitedTrips.data.trips, { status: tripType })[0]

            }
            updateInvitedTripData(selectedTripData)

            setAllInvitedTripData(invitedTrips.data.trips)
            setLoading(false);
            setListLoading(false);
        }


    }, []);

    useEffect(() => {

        if(tripData){
            getStat(tripData)

        }
    }, [tripData]);

    useEffect(() => {
        getAllTrips(page)
    }, [appBasic?.trip?.hasDeleted]);


    const getAllTrips = (page:number) => {

        setTripLoading(true)
        http
            .get<null,TripsResponse>(trips_url+"?page="+page+"&limit=50")
            .then((res) => {

                if(res.status == 200){
                    let allData = allTripData


                    if(allData){
                        res.data.trips.map(function (trip) {
                            allData?.push(trip)
                        })

                    }else{
                        allData = res?.data?.trips
                    }

                    setAllTripData(allData)

                    setTotalPages(parseInt(res.data.pages))
                    let firstTrip = filter(res.data.trips, { status: tripType }).map((tripItem, key) => {
                        return tripItem
                    })

                    if(firstTrip.length){
                        selectedTripData = filter(firstTrip, { status: tripType })[0]

                    }


                    updateTripData(selectedTripData)

                    dispatch(setTrips(res))


                }else{
                    setAllTripData(undefined)

                    dispatch(setTrips(null))
                }

                setTripLoading(false)
            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {
            });
    }
    const getInvitedTrips = (page:number) => {

        setInviteTripLoading(true)
        http
            .get<null,TripsResponse>(trips_invite_url+"?page="+page+"&limit=50")
            .then((res) => {

                if(res.status == 200){

                    setTotalInvitedPages(parseInt(res.data.pages))

                    let allData = allInvitedTripData


                    if(allData){
                        res.data.trips.map(function (trip) {
                            allData?.push(trip)
                        })

                    }else{
                        allData = res?.data?.trips
                    }

                    setAllInvitedTripData(allData)

                    updateInvitedTripData(res.data.trips[0])
                }

                setInviteTripLoading(false)

            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {

            });
    }

    const onTripListScroll = (event:any) => {

        const target = event.target;
        if(target.scrollHeight - target.scrollTop === target.clientHeight){

            if(tripType !== "invited"){

                if(totalPages> page){

                    let currentPage = page + 1;

                    setPage(currentPage)
                    setListLoading(true);
                    getAllTrips(currentPage)
                }

            }else{


            }

        }

    }

    const selectTrip = (trip:Trip) => {

        if(tripType === "invited"){
            updateInvitedTripData(trip)
        }else{

            updateTripData(trip)

        }

        setBackgroundUrl(trip.cover && trip.cover?.url?trip.cover?.url:"")

        if(width < breakpoint){


            if(tripContainerRef && tripContainerRef.current){
                tripContainerRef.current.scrollIntoView()
            }

            navigate(`/trip/${trip._id}`);


        }else{
            if(tripMainContainerRef && tripMainContainerRef.current){
                tripMainContainerRef.current.scrollIntoView()
            }


        }

    };



    const getStat = async (selectedTrip:Trip) => {
        let planningBudget = 0
        let activityBudget = 0
        let memberPaidPlanningBudget = 0
        let memberPaidActivityBudget = 0
        let memberSpendPlanningBudget = 0
        let memberSpendActivityBudget = 0
        let totalPlanningsCount = 0
        let totalActivitiesCount = 0
        let totalDocumentsCount = 0
        let totalDocumentsSize = 0

        let membersArray:any = []

        let currency = ""
        let currencyCode = ""
        let hasMultipleCurrency = false
        let hasMultipleCurrencyUpdated = false
        let currencyConversionValue = 1


        console.log("tripDatatripData-1")

        for await (const activeDestination of selectedTrip.destinations) {

          //  await Promise.all(selectedTrip?.destinations.map(function (activeDestination,key){

            console.log("tripDatatripData-2")
            var foundIndex = activeDestination.convertToBaseValues.findIndex(function (convertToBaseValue:ConvertToBaseValue){
                if(convertToBaseValue.userId){
                    return  (convertToBaseValue.userId).toString() == user?.data.userId
                }
            });

            if(foundIndex == -1){


                if(currency != ""){

                    if(currency != activeDestination.activeCurrency?._id){
                        hasMultipleCurrency = true

                    }
                }
            }else{
                hasMultipleCurrencyUpdated = true
            }



            if(activeDestination.activeCurrency?._id){
                currency = activeDestination.activeCurrency?._id
                currencyCode = activeDestination.activeCurrency?.code
            }




            if( activeDestination.convertToBaseValues &&  activeDestination.convertToBaseValues.length){

                let currencyConversionDestination = find( activeDestination.convertToBaseValues, {userId:user?.data.userId});

                if(currencyConversionDestination){
                    currencyConversionValue = parseFloat(currencyConversionDestination.value)
                    currencyCode = currencyConversionDestination.currencyId.code

                }

            }
            activeDestination?.activities?.map(function (activity){


                if(activity.type == 'live'){

                    activityBudget += activity.budget? (parseFloat(activity.budget)*currencyConversionValue): 0

                    totalActivitiesCount++



                }else{
                    totalPlanningsCount++
                    planningBudget += activity.budget? (parseFloat(activity.budget)*currencyConversionValue): 0
                }


                activity.documents?.map( (document) => {
                    totalDocumentsSize += parseInt(document.size)
                    totalDocumentsCount++
                })



            });
        }

        if(selectedTrip.documents && selectedTrip.documents.length){


            for await (const document of selectedTrip.documents) {


                if(document.size){

                    totalDocumentsSize += parseInt(document.size)
                    totalDocumentsCount++
                }
            }
        }


        let documentsSize = formatBytes(totalDocumentsSize).toString()

        let documentsSizeArray = documentsSize.split(' ')


        totalDocumentsCount += selectedTrip.documents?selectedTrip.documents.length:0


        let totalCheckLists = selectedTrip.checklists?selectedTrip.checklists.length:0
        let totalCompletedCheckLists = selectedTrip.checklists?  filter(selectedTrip.checklists, { status: 'completed' }).length:0

        setTripStat({
            activityBudget: activityBudget,
            planningBudget: planningBudget,
            currencyCode: currencyCode,
            totalPlanningsCount:totalPlanningsCount,
            totalActivitiesCount:totalActivitiesCount,
            totalDocumentsCount:totalDocumentsCount,
            totalDocumentsSize:parseInt(documentsSizeArray[0]),
            totalDocumentsSizeFormat:documentsSizeArray[1],
            totalCheckLists:totalCheckLists,
            totalCompletedCheckLists: totalCompletedCheckLists

        })
    }
    const manageTripInvite = (type:string,e:any) => {
        e.stopPropagation();

        setManageLoading(true)

        if(type == "accept"){

            setManageLoading(true)
        }else{

        }

        let data = {
            status: type
        }
        http
            .put<null,TripResponse>(`${trip_url}/${selectedTripData._id}/invite/manage`,data)
            .then((res) => {

                setManageLoading(false)
                if(res.status == 200){

                    toastMessage(res.message,'success')


                    if(type == "accept"){
                        navigate(`/trip/${selectedTripData._id}`);
                    }else{
                        navigate(`/trips/invited`);
                    }
                }else{

                    toastMessage(res.message,'error')

                }

            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {
                setLoading(false);
            });


    };


    let tripsData = tripType === "invited"? allInvitedTripData : allTripData
    let tripList:any = [];
    let selectedTripData:Trip = trip as Trip;

    if(tripType === "invited"){

        if(invitedTripData){
            selectedTripData = invitedTripData
        }else if(tripsData){
            selectedTripData = tripsData[0]
        }
        tripList =  selectedTripData && tripsData?.map((tripItem, key) => {
            return <ListItem tripItem={tripItem} width={width} tripId={selectedTripData._id} tripStatus="invited" selectTrip={selectTrip} key={key} keyItem={key} manageTripInvite={manageTripInvite} />
        })
    }else{
        if(tripData){
            selectedTripData = tripData
        }else if(tripsData){

            let firstTrip = filter(tripsData, { status: tripType === 'upcoming'? 'pending':tripType }).map((tripItem, key) => {
                return tripItem
            })

            if(firstTrip.length){
                selectedTripData = filter(tripsData, { status: 'upcoming'? 'pending':tripType })[0]
            }
        }

        tripList = selectedTripData && filter(tripsData, { status: 'upcoming'? 'pending':tripType }).filter((tripsData ) =>   (tripsData.name).toLowerCase().includes(tripFilter.toLowerCase()) ).map((tripItem, key) => {
            return <ListItem tripItem={tripItem} width={width} tripId={selectedTripData._id} tripStatus="accepted" selectTrip={selectTrip} key={key} keyItem={key} manageTripInvite={manageTripInvite} />
        })
    }


    const [showMembersModel, setMembersModel] = useState(false);

    const handleMembersModelClose = () => setMembersModel(false);
    const handleMembersModelShow = () => setMembersModel(true);


    const [showEdit, setShowEdit] = useState(false);
    const [editType, setEditType] = useState("basic");

    const handleEditModelClose = () => {
        setShowEdit(false);
    }
    const handleEditModelShow = (type:string) =>  {
        setEditType(type);
        setShowEdit(true);
    }

    const setTripDestinations = (tripResponse:TripResponse) =>  {
        selectedTripData.destinations = tripResponse.data.destinations

    }


    const resetTripFilter = (tripType:string) =>  {
        setTripFilter("")
        let tripsData = tripType == "invited"? allInvitedTripData : allTripData

        if(tripType == "invited") {

            if (invitedTripData) {
                selectedTripData = invitedTripData
            } else if (tripsData) {
                selectedTripData = tripsData[0]
            }

            if(selectedTripData){

                setBackgroundUrl(selectedTripData.cover && selectedTripData.cover?.url ?selectedTripData.cover?.url:"")

                updateInvitedTripData(selectedTripData)
            }
        }else{

            let firstTrip = filter(tripsData, { status: tripType === "upcoming"?'pending':tripType }).map((tripItem, key) => {
                return tripItem
            })


            if(firstTrip.length){
                selectedTripData = filter(tripsData, { status:  tripType === "upcoming"?'pending':tripType })[0]

                setBackgroundUrl(selectedTripData.cover && selectedTripData.cover?.url?selectedTripData.cover?.url:"")

                updateTripData(selectedTripData)

            }

        }

    }

    let locations:any = []
    selectedTripData?.destinations?.map(function (destination, key){
        locations.push({
            lat: destination.lat,
            lng: destination.lng,
            text: destination.name,
        })
    })


    return (
        <main className="col-md-11 ms-sm-auto col-lg-11 px-md-4 container-main trips-container" style={tripList && tripList.length?backgroundUrl? { background:"linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("+backgroundUrl+")"  } :{backgroundColor:""}:{backgroundColor:""} }>

            <TripHeader user={user} allInvitedTripCount={allInvitedTripData?.length} isResponsive={width < breakpoint? true: false} trip={selectedTripData} hasSelectedTrip={tripList && tripList.length ? true: false} resetTripFilter={resetTripFilter} handleEditModelShow={handleEditModelShow}/>

            <div className={`row`}>


                {tripType !== 'active'?

                    <div>
                    {tripLoading?
                            <div  className="card card-stretch bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '87vh'}}>
                                <div className="card-body d-flex flex-column pb-5 pt-3 mb-5 justify-content-center">
                                    <Loader cssClass="normal-loader"/>
                                </div>
                            </div>
                            :
                        tripsData && tripsData.length?
                            <div className={`row`}>
                                <div className="col-xl-4 col-md-4 pe-2">
                                    <div className="card card-stretch mb-5 mb-xxl-8">
                                        <div className="card-header card-search-header align-items-center border-0">
                                            <form className="">
                                                <input  type="text" className= "form-control form-control-lg form-control-solid"  name="tripName"
                                                        placeholder="search by trip name" value={tripFilter} onChange={function (e) {
                                                    setTripFilter(e.target.value)
                                                }} />
                                            </form>
                                        </div>

                                        {tripList && tripList.length?
                                            <div className="card-body pt-0 trip-list" onScroll={onTripListScroll}>
                                                { tripList}
                                            </div>
                                            :
                                            <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '87vh'}}>
                                                <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-15px'}}>

                                                    <div className="text-center p-2 mt-1 mb-0">
                                                        <p className="fs-5 text-dark fw-bolder">No trips available</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                                <div className="col-xl-8 col-md-8 ps-2">
                                    {tripList && tripList.length && selectedTripData?

                                            width > breakpoint?
                                                <>
                                                    <div className="d-flex flex-row justify-content-between pb-3 pt-3 mb-4 ps-3 header-container">

                                                        <div className="d-flex flex-column col-8 align-items-start justify-content-center flex-wrap me-1"  onClick={function () {
                                                            navigate("/trip/" + selectedTripData._id);

                                                        }}>
                                                            <h3 className="header-title">{(selectedTripData.name).length > 40? (selectedTripData.name).substring(0,40) + '...' : selectedTripData.name}</h3>
                                                            <span className="bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                                                   {dayjs(selectedTripData.destinations[0].startDate).format('DD MMMM YY')+ " - " +dayjs(selectedTripData.destinations[selectedTripData.destinations.length -1].endDate).format('DD MMMM YY')}
                                                </span>
                                                        </div>
                                                        {tripType !== "invited"?

                                                            <span className="d-inline-flex me-2  col-4  mt-md-0 ms-md-auto cursor-pointer hover-color align-items-center justify-content-end pe-3">
                                                                   <span className="fw-bolder hover-color fs-7 me-1 text-dark" onClick={function () {

                                                                           navigate("/trip/" + selectedTripData._id);


                                                                   }}>  Trip Dashboard </span><img src={RightArrowImgIcon} className={``} width={15}/>

                                                            </span>
                                                            :

                                                            <div className={`mt-3 me-3`}>


                                                                <button type="button" className={`btn btn-sm btn-primary shadow-none me-3  `} disabled={manageLoading?true:false }  onClick={(e) =>{
                                                                    if(!manageLoading) {

                                                                        manageTripInvite("reject",e)
                                                                    }
                                                                }}>
                                                                    <img src={CloseWhiteButtonImgIcon} className={``} width={15}
                                                                    />
                                                                    {"Reject" }
                                                                </button>
                                                                <button type="button" className={`btn btn-sm btn-success shadow-none `}  disabled={manageLoading?true:false }  onClick={(e) => {
                                                                    if(!manageLoading) {

                                                                        manageTripInvite("accept",e)
                                                                    }
                                                                }}>
                                                                    <img src={AcceptWhiteButtonImgIcon} className={` `} width={15}
                                                                    />
                                                                    { "Accept" }

                                                                </button>
                                                            </div>

                                                        }


                                                    </div>
                                                    <div className="card card-stretch mb-5 mb-xxl-8" >
                                                        {tripType == "invited"?

                                                            <div className={`fs-3 fw-bolder text-primary`} style={{
                                                                position: 'absolute',
                                                                paddingTop: '20%',
                                                                zIndex: '9999',
                                                                paddingLeft: '24%',
                                                                backgroundColor: '#ffffffc7',
                                                                width: '100%',
                                                                height: '100%'
                                                            }}>

                                                                Please accept the invitation to contribute and be a part of the trip.
                                                            </div>
                                                            :''}


                                                        <div className="card-header align-items-center border-0">
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-45px symbol-circle">
                                                                    {selectedTripData.userId.cover?
                                                                        <img alt="Pic" src={getImgUrl(selectedTripData.userId?.cover?.generatedName,selectedTripData.userId._id,'50x')} />
                                                                        :
                                                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-capitalize">{(selectedTripData.userId.firstName).charAt(0)}</span>
                                                                    }
                                                                </div>
                                                                <div className="ms-2">
                                                                    <a className="fs-5 fw-bolder text-dark text-hover-primary mb-2 cursor-pointer">{selectedTripData.userId.firstName} {selectedTripData.userId.lastName}</a>
                                                                    <div className="fw-bold text-gray-400 fs-7">{dayjs(selectedTripData.createdAt).format('DD MMMM YY')}</div>
                                                                </div>
                                                            </div>
                                                            {selectedTripData.tripType != "solo"?
                                                                <div className="d-flex align-items-center justify-content-sm-end text-end my-2">
                                                                    <div className="symbol-group symbol-hover me-3">

                                                                        <span  onClick={handleMembersModelShow}>
                                                                            {selectedTripData.members.slice(0,3).map(function (member,key) {

                                                                                return(
                                                                                    <div className="symbol symbol-35px symbol-circle cursor-pointer" key={key}>
                                                                                        {member?.userId?.cover?
                                                                                            <img alt="Pic" src={getImgUrl(member.userId?.cover?.generatedName,member.userId._id,'50x')} />
                                                                                            :
                                                                                            <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-uppercase">{member.userId ? (member?.userId?.firstName).charAt(0): ""}</span>

                                                                                        }
                                                                                    </div>
                                                                                )

                                                                            })}
                                                                            {selectedTripData.members.length> 3 ?
                                                                                <a className="symbol symbol-35px symbol-circle cursor-pointer">
                                                                                    <span className="symbol-label fs-8 fw-bolder" >+{(selectedTripData.members.length)-1}</span>
                                                                                </a>
                                                                                : ""}
                                                                        </span>

                                                                    </div>

                                                                    {selectedTripData.userId._id == user?.data.userId?

                                                                            <OverlayTrigger  placement="top" overlay={
                                                                                <Tooltip id={`tooltip-${22}`}  className={``}>
                                                                                    <div className="d-flex align-items-center">
                                                                                        Settings
                                                                                    </div>
                                                                                </Tooltip>
                                                                            }>

                                                                                <div className="symbol-group symbol-hover">
                                                                                    <div className="symbol symbol-35px symbol-circle cursor-pointer">
                                                                              <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-uppercase" onClick={()=>{
                                                                                  if(tripType !== "invited") {
                                                                                      navigate(`/trip/${selectedTripData._id}/settings`);
                                                                                  }
                                                                              }}>

                                                                                  <img src={SettingsImg} width={15} className={``} />
                                                                              </span>

                                                                                    </div>

                                                                                </div>
                                                                            </OverlayTrigger>
                                                                        :''}
                                                                </div>
                                                                :"" }


                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="d-flex flex-wrap flex-xxl-nowrap justify-content-center justify-content-md-start pt-4">
                                                                {selectedTripData.description}
                                                            </div>
                                                            <div className="d-flex flex-stack mt-3 mb-0 mx-2">
                                                                <div className="d-flex align-items-center">
                                                                    <span className="fs-5 fw-bolder text-dark text-hover-primary mb-2">Destinations({selectedTripData.destinations.length})</span>
                                                                </div>




                                                            </div>
                                                            <div className="col-12 destinations-container">

                                                                <ul className="list-unstyled row">
                                                                    {selectedTripData.destinations.map(function (destination,key) {

                                                                        return(
                                                                            <li className="list-item col-xl-6 py-2" key={key} onClick={function () {

                                                                                if(tripType !== "invited") {
                                                                                    navigate(`/trip/${selectedTripData._id}/${destination._id}`);
                                                                                }
                                                                            }}>
                                                                                <div className="d-flex align-items-center">

                                                                                    <div className="symbol symbol-45px symbol-circle me-2">
                                                                                        {destination.photoReference?
                                                                                            <img src={`${google_place_photo_url}${destination.photoReference}`} width={30} />
                                                                                            :
                                                                                            <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                                                                <img src={LocationImgIcon} width={30}/>
                                                                                            </span>
                                                                                        }

                                                                                    </div>

                                                                                    <div
                                                                                        className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-4">
                                                                                        <a
                                                                                            className="text-dark fw-bolder text-hover-primary fs-6 cursor-pointer">  {destination.name}</a>
                                                                                        <span className="text-muted fw-bold fs-7 my-1">{dayjs(destination.startDate).format('DD MMMM YY')} to {dayjs(destination.endDate).format('DD MMMM YY')}</span>
                                                                                    </div>
                                                                                </div>

                                                                            </li>
                                                                        )

                                                                    })}


                                                                </ul>

                                                                <div className="row">
                                                                    <div className="card card-flush h-lg-100">

                                                                        <div className="card-header border-0 pt-0">

                                                                            <h3 className="card-title align-items-start flex-column">
                                                                                <span className="card-label fw-bolder text-dark">Quick Stats</span>
                                                                                <span className="text-gray-400 mt-1 fw-semibold fs-6">Expenses from {tripStat.totalPlanningsCount} plannings and {tripStat.totalActivitiesCount} activities</span>
                                                                            </h3>

                                                                        </div>


                                                                        <div className="card-body align-items-end pt-6 px-2">


                                                                            <div className="row align-items-center mx-0 w-100 g-3">

                                                                                <div className="col-xl-6 mt-0">
                                                                                    <div
                                                                                        className="d-flex border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                        <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                            <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={PlanningImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                            </div>


                                                                                            <div className="me-2">
                                                                                                <span
                                                                                                   className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalPlanningsCount} Plannings</span>
                                                                                                <span className="text-gray-400 fw-bold d-block fs-7">Strategically arranging a successful and enjoyable upcoming journey.</span>
                                                                                            </div>

                                                                                        </div>


                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{numberWithCommas(tripStat.planningBudget)}</span>  <span className=" fw-bolder fs-6 ">{tripStat.currencyCode}</span></span>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div
                                                                                        className="d-flex border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                        <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                            <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={ActivitiesImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                            </div>


                                                                                            <div className="me-2">
                                                                                                <span
                                                                                                   className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalActivitiesCount}  Activities</span>
                                                                                                <span className="text-gray-400 fw-bold d-block fs-7">Organizing exciting experiences for an enjoyable and memorable trip.</span>
                                                                                            </div>

                                                                                        </div>


                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{numberWithCommas(tripStat.activityBudget)}</span>  <span className=" fw-bolder fs-6 ">{tripStat.currencyCode}</span></span>
                                                                                        </div>

                                                                                    </div>


                                                                                </div>

                                                                                <div className="col-xl-6 mt-0">
                                                                                    <div
                                                                                        className="d-flex border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                        <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                            <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={PlanningImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                            </div>


                                                                                            <div className="me-2">
                                                                                                <span
                                                                                                   className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalDocumentsCount} Documents</span>
                                                                                                <span className="text-gray-400 fw-bold d-block fs-7">Travel-related paperwork and important identification materials.</span>
                                                                                            </div>

                                                                                        </div>


                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{tripStat.totalDocumentsSize}</span>  <span className=" fw-bolder fs-6 ">{tripStat.totalDocumentsSizeFormat}</span></span>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div
                                                                                        className="d-flex border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                        <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                            <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={ActivitiesImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                            </div>


                                                                                            <div className="me-2">
                                                                                                <span
                                                                                                   className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalCheckLists}  Checklists</span>
                                                                                                <span className="text-gray-400 fw-bold d-block fs-7">Essential items for organized, worry-free travel.</span>
                                                                                            </div>

                                                                                        </div>


                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{tripStat.totalCompletedCheckLists}</span>  <span className=" fw-bolder fs-6 ">Completed</span></span>
                                                                                        </div>

                                                                                    </div>


                                                                                </div>


                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                                : ""

                                            :
                                            <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '87vh'}}>
                                                <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-15px'}}>

                                                    <div className="text-center p-2 mt-1 mb-0">
                                                        <p className="fs-5 text-dark fw-bolder">No trips available</p>
                                                        <p className="text-gray-400">Please create a new trip to list here. </p>
                                                        <button className="btn btn-primary mt-3" onClick={()=> navigate('/new')}>Create New Trip</button>
                                                    </div>
                                                </div>
                                            </div>
                                    }


                                </div>
                            </div>
                            :
                            <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '87vh'}}>
                                <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5">

                                    <div className="text-center p-2 mt-1 mb-0">
                                        <img src={tripType == 'invited'?EmptyTripsImg:EmptyUpcomingTripsImg} width={100}/>
                                        <p className="fs-5 text-dark fw-bolder">{`${tripType == 'invited'? 'No Invited trips':tripType == 'pending'?'No Pending trips':'No Completed trips' }`}</p>
                                        <p className="text-gray-400">Please create a new trip to list here. </p>
                                        <button className="btn btn-primary mt-3" onClick={()=> navigate('/new')}>Create New Trip</button>
                                    </div>
                                </div>
                            </div>
                    }

                    </div>
                :

                    <>
                        {width > breakpoint?
                            <div className="col-xl-12 col-md-12">
                                {tripLoading?
                                    <div  className="card card-stretch bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '87vh'}}>
                                        <div className="card-body d-flex flex-column pb-5 pt-3 mb-5 justify-content-center">
                                            <Loader cssClass="normal-loader"/>
                                        </div>
                                    </div>
                                    :
                                    tripList && tripList.length && selectedTripData?

                                        width > breakpoint?
                                            <>
                                                <div className="d-flex flex-row justify-content-between pb-3 pt-3 mb-4 ps-3 header-container col-12">

                                                    <div className="d-flex flex-column col-8 align-items-start justify-content-center flex-wrap me-1"  onClick={function () {
                                                        navigate("/trip/" + selectedTripData._id);

                                                    }}>
                                                        <h3 className="header-title">{(selectedTripData.name).length > 40? (selectedTripData.name).substring(0,40) + '...' : selectedTripData.name}</h3>
                                                        <span className="bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                                                   {dayjs(selectedTripData.destinations[0].startDate).format('DD MMMM YY')+ " to " +dayjs(selectedTripData.destinations[selectedTripData.destinations.length -1].endDate).format('DD MMMM YY')}
                                                </span>
                                                    </div>
                                                    <span className="d-inline-flex me-2  col-4  mt-md-0 ms-md-auto cursor-pointer hover-color align-items-center justify-content-end pe-3">
                                                           <span className="fw-bolder hover-color fs-7 me-1 text-dark" onClick={function () {

                                                               navigate("/trip/" + selectedTripData._id);


                                                           }}>  Trip Dashboard </span><img src={RightArrowImgIcon} className={``} width={15}/>

                                                    </span>


                                                </div>

                                                <div className={`row`}>

                                                    <div className={`col-6`}>
                                                        <div className="card card-stretch mb-5 mb-xxl-8">
                                                            <div className="card-header align-items-center border-0">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="symbol symbol-45px symbol-circle">
                                                                        {selectedTripData.userId.cover?
                                                                            <img alt="Pic" src={getImgUrl(selectedTripData.userId?.cover?.generatedName,selectedTripData.userId._id,'50x')} />
                                                                            :
                                                                            <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-capitalize">{(selectedTripData.userId.firstName).charAt(0)}</span>
                                                                        }
                                                                    </div>
                                                                    <div className="ms-2">
                                                                        <a className="fs-5 fw-bolder text-dark text-hover-primary mb-2 cursor-pointer">{selectedTripData.userId.firstName} {selectedTripData.userId.lastName}</a>
                                                                        <div className="fw-bold text-gray-400">{dayjs(selectedTripData.createdAt).format('DD MMMM YY')}</div>
                                                                    </div>
                                                                </div>
                                                                {selectedTripData.tripType != "solo"?
                                                                    <div className="d-flex align-items-center justify-content-sm-end text-end my-2">
                                                                        <div className="symbol-group symbol-hover">

                                                    <span  onClick={handleMembersModelShow}>
                                                        {selectedTripData.members.slice(0,3).map(function (member,key) {

                                                            return(
                                                                <div className="symbol symbol-35px symbol-circle cursor-pointer" key={key}>
                                                                    {member?.userId?.cover?
                                                                        <img alt="Pic" src={getImgUrl(member.userId?.cover?.generatedName,member.userId._id,'50x')} />
                                                                        :
                                                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-uppercase">{member.userId ? (member?.userId?.firstName).charAt(0): ""}</span>

                                                                    }
                                                                </div>
                                                            )

                                                        })}
                                                        {selectedTripData.members.length> 3 ?
                                                            <a className="symbol symbol-35px symbol-circle cursor-pointer">
                                                                <span className="symbol-label fs-8 fw-bolder" >+{(selectedTripData.members.length)-1}</span>
                                                            </a>
                                                            : ""}
                                                    </span>

                                                                        </div>
                                                                    </div>
                                                                    :"" }
                                                            </div>
                                                            <div className="card-body pt-0 pb-5">
                                                                <div className="d-flex flex-wrap flex-xxl-nowrap justify-content-center justify-content-md-start pt-4">
                                                                    {selectedTripData.description}
                                                                </div>
                                                                <div className="d-flex flex-stack mt-3 mb-0 mx-2">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="fs-5 fw-bolder text-dark text-hover-primary mb-2">Destinations({selectedTripData.destinations.length})</span>
                                                                    </div>


                                                                </div>
                                                                <div className="col-12 destinations-container">

                                                                    <ul className="list-unstyled row">
                                                                        {selectedTripData.destinations.map(function (destination,key) {

                                                                            return(
                                                                                <li className="list-item col-xl-6 py-2" key={key} onClick={function () {

                                                                                    navigate(`/trip/${selectedTripData._id}/${destination._id}`);
                                                                                }}>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="symbol symbol-45px symbol-circle me-2">
                                                                                            {destination.photoReference?
                                                                                                <img src={`${google_place_photo_url}${destination.photoReference}`} width={30} />
                                                                                                :
                                                                                                <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                                                                    <img src={LocationImgIcon} width={30}/>
                                                                                                </span>
                                                                                            }
                                                                                        </div>

                                                                                        <div
                                                                                            className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-4">
                                                                                            <a
                                                                                                className="text-dark fw-bolder text-hover-primary fs-6 cursor-pointer">  {destination.name}</a>
                                                                                            <span className="text-muted fw-bold fs-7 my-1">{dayjs(destination.startDate).format('DD MMMM YY')} to {dayjs(destination.endDate).format('DD MMMM YY')}</span>
                                                                                        </div>
                                                                                    </div>

                                                                                </li>
                                                                            )

                                                                        })}


                                                                    </ul>



                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-6`}>
                                                        <div className="">
                                                            <div className="card card-flush h-lg-100">

                                                                <div className="card-header border-0 pt-0">

                                                                    <h3 className="card-title align-items-start flex-column">
                                                                        <span className="card-label fw-bolder text-dark">Quick Stats</span>
                                                                        <span className="text-gray-400 mt-1 fw-semibold fs-6">Expenses from {tripStat.totalPlanningsCount} plannings and {tripStat.totalActivitiesCount} activities</span>
                                                                    </h3>

                                                                </div>


                                                                <div className="card-body align-items-end pt-6 px-2">


                                                                    <div className="row align-items-center mx-0 w-100 g-3 mb-2">

                                                                        <div className="col-xl-6 mt-0">
                                                                            <div
                                                                                className=" border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                    <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={PlanningImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                    </div>


                                                                                    <div className="me-2">
                                                                                        <a href="#"
                                                                                           className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalPlanningsCount} Plannings</a>
                                                                                        <span className="text-gray-400 fw-bold d-block fs-7">Great, you always attending class. keep it up</span>
                                                                                    </div>

                                                                                </div>


                                                                                <div className="d-flex justify-content-center mt-3">
                                                                                    <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{numberWithCommas(tripStat.planningBudget)}</span>  <span className=" fw-bolder fs-6 ">{tripStat.currencyCode}</span></span>
                                                                                </div>

                                                                            </div>
                                                                            <div
                                                                                className=" border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                    <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={ActivitiesImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                    </div>


                                                                                    <div className="me-2">
                                                                                        <a href="#"
                                                                                           className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalActivitiesCount}  Activities</a>
                                                                                        <span className="text-gray-400 fw-bold d-block fs-7">Great, you always attending class. keep it up</span>
                                                                                    </div>

                                                                                </div>


                                                                                <div className="d-flex justify-content-center mt-3">
                                                                                    <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{numberWithCommas(tripStat.activityBudget)}</span>  <span className=" fw-bolder fs-6 ">{tripStat.currencyCode}</span></span>
                                                                                </div>

                                                                            </div>


                                                                        </div>

                                                                        <div className="col-xl-6 mt-0">
                                                                            <div
                                                                                className=" border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                    <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={PlanningImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                    </div>


                                                                                    <div className="me-2">
                                                                                        <a href="#"
                                                                                           className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalDocumentsCount} Documents</a>
                                                                                        <span className="text-gray-400 fw-bold d-block fs-7">Great, you always attending class. keep it up</span>
                                                                                    </div>

                                                                                </div>


                                                                                <div className="d-flex justify-content-center mt-3">
                                                                                    <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{tripStat.totalDocumentsSize}</span>  <span className=" fw-bolder fs-6 ">{tripStat.totalDocumentsSizeFormat}</span></span>
                                                                                </div>

                                                                            </div>
                                                                            <div
                                                                                className=" border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                    <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={ActivitiesImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                    </div>


                                                                                    <div className="me-2">
                                                                                        <a href="#"
                                                                                           className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalCheckLists}  Checklists</a>
                                                                                        <span className="text-gray-400 fw-bold d-block fs-7">Great, you always attending class. keep it up</span>
                                                                                    </div>

                                                                                </div>


                                                                                <div className="d-flex justify-content-center mt-3">
                                                                                    <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{tripStat.totalCompletedCheckLists}</span>  <span className=" fw-bolder fs-6 ">Completed</span></span>
                                                                                </div>

                                                                            </div>


                                                                        </div>


                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                            : ""

                                        :
                                        <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '87vh'}}>
                                            <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" >

                                                <div className="text-center p-2 mt-1 mb-0">
                                                    <img src={EmptyTripsImg} width={100}/>
                                                    <p className="fs-5 text-dark fw-bolder">No active trip</p>
                                                    <p className="text-gray-400">Please create a new trip to list here. </p>
                                                    <button className="btn btn-primary mt-3" onClick={()=> navigate('/new')}>Create New Trip</button>
                                                </div>
                                            </div>
                                        </div>
                                }


                            </div>
                            :
                            <div className="col-xl-12 col-md-12">
                                {tripLoading?
                                    <a  className="card card-stretch mb-5 bg-light-info hoverable shadow-none trip-list-select cursor-pointer">

                                        <div className="card-body d-flex flex-column pt-5">

                                            <Loader cssClass="normal-loader"/>
                                        </div>
                                    </a>
                                    :
                                    tripList && tripList.length && selectedTripData?

                                            <>
                                                <div className="d-flex flex-row justify-content-between pb-3 pt-3 mb-4 ps-3 header-container col-12">

                                                    <div className="d-flex flex-column col-8 align-items-start justify-content-center flex-wrap me-1"  onClick={function () {
                                                        navigate("/trip/" + selectedTripData._id);

                                                    }}>
                                                        <h3 className="header-title">{(selectedTripData.name).length > 40? (selectedTripData.name).substring(0,40) + '...' : selectedTripData.name}</h3>
                                                        <span className="bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                                                   {dayjs(selectedTripData.destinations[0].startDate).format('DD MMMM YY')+ " to " +dayjs(selectedTripData.destinations[selectedTripData.destinations.length -1].endDate).format('DD MMMM YY')}
                                                </span>
                                                    </div>
                                                    <span className="d-inline-flex me-2  col-4  mt-md-0 ms-md-auto cursor-pointer hover-color align-items-center justify-content-end pe-3">
                                                                   <span className="fw-bolder hover-color fs-7 me-1 text-dark" onClick={function () {

                                                                       navigate("/trip/" + selectedTripData._id);


                                                                   }}>  Trip Dashboard </span><img src={RightArrowImgIcon} className={``} width={15}/>

                                                            </span>


                                                </div>

                                                <div className={`row`}>

                                                    <div className={`col-12`}>
                                                        <div className="card card-stretch mb-5 mb-xxl-8">
                                                            <div className="card-header align-items-center border-0">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="symbol symbol-45px symbol-circle">
                                                                        {selectedTripData.userId.cover?
                                                                            <img alt="Pic" src={getImgUrl(selectedTripData.userId?.cover?.generatedName,selectedTripData.userId._id,'50x')} />
                                                                            :
                                                                            <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-capitalize">{(selectedTripData.userId.firstName).charAt(0)}</span>
                                                                        }
                                                                    </div>
                                                                    <div className="ms-2">
                                                                        <a className="fs-5 fw-bolder text-dark text-hover-primary mb-2 cursor-pointer">{selectedTripData.userId.firstName} {selectedTripData.userId.lastName}</a>
                                                                        <div className="fw-bold text-gray-400">{dayjs(selectedTripData.createdAt).format('DD MMMM YY')}</div>
                                                                    </div>
                                                                </div>
                                                                {selectedTripData.tripType != "solo"?
                                                                    <div className="d-flex align-items-center justify-content-sm-end text-end my-2">
                                                                        <div className="symbol-group symbol-hover">

                                                    <span  onClick={handleMembersModelShow}>
                                                        {selectedTripData.members.slice(0,3).map(function (member,key) {

                                                            return(
                                                                <div className="symbol symbol-35px symbol-circle cursor-pointer" key={key}>
                                                                    {member?.userId?.cover?
                                                                        <img alt="Pic" src={getImgUrl(member.userId?.cover?.generatedName,member.userId._id,'50x')} />
                                                                        :
                                                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-uppercase">{member.userId ? (member?.userId?.firstName).charAt(0): ""}</span>

                                                                    }
                                                                </div>
                                                            )

                                                        })}
                                                        {selectedTripData.members.length> 3 ?
                                                            <a className="symbol symbol-35px symbol-circle cursor-pointer">
                                                                <span className="symbol-label fs-8 fw-bolder" >+{(selectedTripData.members.length)-1}</span>
                                                            </a>
                                                            : ""}
                                                    </span>

                                                                        </div>
                                                                    </div>
                                                                    :"" }
                                                            </div>
                                                            <div className="card-body pt-0 pb-5">
                                                                <div className="d-flex flex-wrap flex-xxl-nowrap justify-content-center justify-content-md-start pt-4">
                                                                    {selectedTripData.description}
                                                                </div>
                                                                <div className="d-flex flex-stack mt-3 mb-0 mx-2">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="fs-5 fw-bolder text-dark text-hover-primary mb-2">Destinations({selectedTripData.destinations.length})</span>
                                                                    </div>




                                                                </div>
                                                                <div className="col-12 destinations-container">

                                                                    <ul className="list-unstyled row">
                                                                        {selectedTripData.destinations.map(function (destination,key) {

                                                                            return(
                                                                                <li className="list-item col-xl-6 py-2" key={key} onClick={function () {

                                                                                    navigate(`/trip/${selectedTripData._id}/${destination._id}`);
                                                                                }}>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div
                                                                                            className="symbol symbol-45px symbol-circle mx-2">
                                                                            <span
                                                                                className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-capitalize">
                                                                                 <img src={LocationImgIcon} width={30} />

                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-4">
                                                                                            <a
                                                                                                className="text-dark fw-bolder text-hover-primary fs-6 cursor-pointer">  {destination.name}</a>
                                                                                            <span className="text-muted fw-bold fs-7 my-1">{dayjs(destination.startDate).format('DD MMMM YY')} to {dayjs(destination.endDate).format('DD MMMM YY')}</span>
                                                                                        </div>
                                                                                    </div>

                                                                                </li>
                                                                            )

                                                                        })}


                                                                    </ul>



                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-12`}>
                                                        <div className="">
                                                            <div className="card card-flush h-lg-100">

                                                                <div className="card-header border-0 pt-0">

                                                                    <h3 className="card-title align-items-start flex-column">
                                                                        <span className="card-label fw-bolder text-dark">Quick Stats</span>
                                                                        <span className="text-gray-400 mt-1 fw-semibold fs-6">Expenses from {tripStat.totalPlanningsCount} plannings and {tripStat.totalActivitiesCount} activities</span>
                                                                    </h3>

                                                                </div>


                                                                <div className="card-body align-items-end pt-6 px-2">


                                                                    <div className="row align-items-center mx-0 w-100 g-3 mb-2">

                                                                        <div className="col-xl-6 mt-0">
                                                                            <div
                                                                                className=" border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                    <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={PlanningImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                    </div>


                                                                                    <div className="me-2">
                                                                                        <a href="#"
                                                                                           className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalPlanningsCount} Plannings</a>
                                                                                        <span className="text-gray-400 fw-bold d-block fs-7">Great, you always attending class. keep it up</span>
                                                                                    </div>

                                                                                </div>


                                                                                <div className="d-flex justify-content-center mt-3">
                                                                                    <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{numberWithCommas(tripStat.planningBudget)}</span>  <span className=" fw-bolder fs-6 ">{tripStat.currencyCode}</span></span>
                                                                                </div>

                                                                            </div>
                                                                            <div
                                                                                className=" border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                    <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={ActivitiesImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                    </div>


                                                                                    <div className="me-2">
                                                                                        <a href="#"
                                                                                           className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalActivitiesCount}  Activities</a>
                                                                                        <span className="text-gray-400 fw-bold d-block fs-7">Great, you always attending class. keep it up</span>
                                                                                    </div>

                                                                                </div>


                                                                                <div className="d-flex justify-content-center mt-3">
                                                                                    <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{numberWithCommas(tripStat.activityBudget)}</span>  <span className=" fw-bolder fs-6 ">{tripStat.currencyCode}</span></span>
                                                                                </div>

                                                                            </div>


                                                                        </div>

                                                                        <div className="col-xl-6 mt-0">
                                                                            <div
                                                                                className=" border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                    <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={PlanningImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                    </div>


                                                                                    <div className="me-2">
                                                                                        <a href="#"
                                                                                           className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalDocumentsCount} Documents</a>
                                                                                        <span className="text-gray-400 fw-bold d-block fs-7">Great, you always attending class. keep it up</span>
                                                                                    </div>

                                                                                </div>


                                                                                <div className="d-flex justify-content-center mt-3">
                                                                                    <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{tripStat.totalDocumentsSize}</span>  <span className=" fw-bolder fs-6 ">{tripStat.totalDocumentsSizeFormat}</span></span>
                                                                                </div>

                                                                            </div>
                                                                            <div
                                                                                className=" border border-gray-300 border-dashed rounded p-3 mb-3">

                                                                                <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                                                    <div className="symbol symbol-50px me-4">
                                                                                <span className="symbol-label">

                                                                                    <span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                                                                        <img src={ActivitiesImg} width={20}/>
                                                                                    </span>

                                                                                </span>
                                                                                    </div>


                                                                                    <div className="me-2">
                                                                                        <a href="#"
                                                                                           className="text-gray-800 text-hover-primary fs-6 fw-bold">{tripStat.totalCheckLists}  Checklists</a>
                                                                                        <span className="text-gray-400 fw-bold d-block fs-7">Great, you always attending class. keep it up</span>
                                                                                    </div>

                                                                                </div>


                                                                                <div className="d-flex justify-content-center mt-3">
                                                                                    <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{tripStat.totalCompletedCheckLists}</span>  <span className=" fw-bolder fs-6 ">Completed</span></span>
                                                                                </div>

                                                                            </div>


                                                                        </div>


                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>

                                        :
                                        <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '87vh'}}>
                                            <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-40px'}}>

                                                <div className="text-center p-2 mt-1 mb-0">
                                                    <p className="fs-5 text-dark fw-bolder">No active trip</p>
                                                    <p className="text-gray-400">Please create a new trip to list here. </p>
                                                    <button className="btn btn-primary mt-3" onClick={()=> navigate('/new')}>Create New Trip</button>
                                                </div>
                                            </div>
                                        </div>
                                }


                            </div>}


                    </>
                }





                <Modal show={showMembersModel} onHide={()=>{
                    handleMembersModelClose()
                    setShowInvitation(false)
                }} animation={false}>
                    <Modal.Header className={`border-0 pb-0`}>


                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2 p-0" >
                            <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>{
                                handleMembersModelClose()
                                setShowInvitation(false)
                            }}/>
                        </div>
                        <Modal.Title className={`fw-bolder`}>{showInvitation? `Invite to trip`: `Members`}</Modal.Title>

                        <div className=" p-0" >
                            {selectedTripData && selectedTripData.userId._id ==  user?.data.userId  ?
                                showInvitation?
                                    <img src={MembersImg} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>setShowInvitation(false)}/>
                                    :
                                    selectedTripData.status != 'completed'?
                                        <img src={InviteImg} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>setShowInvitation(true)}/>
                                        : ''

                                :""
                            }

                        </div>

                    </Modal.Header>
                    <Modal.Body className={`pt-0`}>
                        {showInvitation && selectedTripData?
                            <div className=" invitation">
                                <div className="row text-center">
                                    <span className="text-muted fw-bold fs-7 my-1">Scan or use code to join</span>
                                </div>
                                <div className="row justify-content-center text-center">
                                    <QRCode
                                        value={window.location.origin + "/join/" + selectedTripData.invitation.joinCode}
                                        bgColor="#7d7c7c" fgColor="#f5f8fa" logoImage={logoImage}
                                        logoWidth={100} logoHeight={95} logoOpacity={0.2}/>
                                    <OverlayTrigger placement="top" overlay={<Tooltip
                                        id="tooltip-code">{joinCodeCopyMessage}</Tooltip>}>

                                        <a className="fs-4 mt-5 fw-bolder text-hover-primary mb-2 cursor-pointer code"
                                           onClick={function () {
                                               navigator.clipboard.writeText(selectedTripData.invitation.joinCode)
                                               setJoinCodeCopyMessage("Copied!")
                                               setInterval(() => {
                                                   setJoinCodeCopyMessage("Click to copy")
                                               }, 2000)
                                           }}>{selectedTripData.invitation.joinCode}</a>

                                    </OverlayTrigger>

                                    <OverlayTrigger placement="top" overlay={<Tooltip
                                        id="tooltip-dashboard">{joinUrlCopyMessage}</Tooltip>}>

                                        <a className="fs-8 fw-bolder text-hover-primary mb-2 cursor-pointer url"
                                           onClick={function () {
                                               navigator.clipboard.writeText(window.location.origin + "/join/" + selectedTripData.invitation.joinCode)
                                               setJoinUrlCopyMessage("Copied!")
                                               setInterval(() => {
                                                   setJoinUrlCopyMessage("Click to copy")
                                               }, 2000)
                                           }}
                                        >{window.location.origin + "/join/" + selectedTripData.invitation.joinCode}</a>

                                    </OverlayTrigger>


                                </div>

                            </div>

                            :
                            selectedTripData?.members.map(function (user,key) {
                                return(
                                    <MemberItem user={user}key={key} tripType={selectedTripData.tripType} keyValue={key}/>
                                )
                            })
                        }

                    </Modal.Body>
                </Modal>
            </div>

        </main>



    );
};
export default Trips;
