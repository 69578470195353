import React from "react";
import Options from "../options/options";

const TripOptions = (props) => {
  const options = [
    {
      name: "Active",
      handler: props.actionProvider.handleAbout,
      id: 1,
    },
    {
      name: "Pending",
      handler: props.actionProvider.handleTrips,
      id: 2,
    },
    {
      name: "Status",
      handler: props.actionProvider.handleResume,
      id: 3,
    },
    {
      name: "Ened",
      handler: props.actionProvider.handleProjects,
      id: 5,
    },
  ];

  return <Options options={options} />;
};

export default TripOptions;