import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {TransactionsResponse} from '../../../interfaces/transactions.interface';

const transactions = createSlice({
    name: 'transactions',
    initialState: null as TransactionsResponse | null,
    reducers: {
        setTransactions(state, { payload }: PayloadAction<TransactionsResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setTransactions } = transactions.actions;
export default transactions.reducer;
