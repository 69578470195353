import React from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const ContactInfo: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();

    const { fields: phoneFields, append: appendPhone, remove: removePhone } = useFieldArray({
        control,
        name: 'contactInfo.phoneNumbers' as any,
    });

    const { fields: emailFields, append: appendEmail, remove: removeEmail } = useFieldArray({
        control,
        name: 'contactInfo.emailAddresses' as any,
    });

    const { fields: officeFields, append: appendOffice, remove: removeOffice } = useFieldArray({
        control,
        name: 'contactInfo.officeLocations' as any,
    });

    return (
        <div>
            <h2>Contact Info</h2>

            <h3>Phone Numbers</h3>
            {phoneFields.map((field, index) => (
                <Form.Group key={field.id}>
                    <Controller
                        name={`contactInfo.phoneNumbers.${index}`}
                        control={control}
                        render={({ field }) => <Form.Control {...field} isInvalid={!!errors.contactInfo?.phoneNumbers?.[index]} />}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.contactInfo?.phoneNumbers?.[index]?.message}
                    </Form.Control.Feedback>
                    <Button variant="danger" onClick={() => removePhone(index)}>Remove</Button>
                </Form.Group>
            ))}
            <Button variant="success" onClick={() => appendPhone('')}>Add Phone Number</Button>

            <h3>Email Addresses</h3>
            {emailFields.map((field, index) => (
                <Form.Group key={field.id}>
                    <Controller
                        name={`contactInfo.emailAddresses.${index}`}
                        control={control}
                        render={({ field }) => <Form.Control {...field} isInvalid={!!errors.contactInfo?.emailAddresses?.[index]} />}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.contactInfo?.emailAddresses?.[index]?.message}
                    </Form.Control.Feedback>
                    <Button variant="danger" onClick={() => removeEmail(index)}>Remove</Button>
                </Form.Group>
            ))}
            <Button variant="success" onClick={() => appendEmail('')}>Add Email Address</Button>

            <h3>Office Locations</h3>
            {officeFields.map((field, index) => (
                <Form.Group key={field.id}>
                    <Controller
                        name={`contactInfo.officeLocations.${index}`}
                        control={control}
                        render={({ field }) => <Form.Control {...field} isInvalid={!!errors.contactInfo?.officeLocations?.[index]} />}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.contactInfo?.officeLocations?.[index]?.message}
                    </Form.Control.Feedback>
                    <Button variant="danger" onClick={() => removeOffice(index)}>Remove</Button>
                </Form.Group>
            ))}
            <Button variant="success" onClick={() => appendOffice('')}>Add Office Location</Button>
        </div>
    );
};

export default ContactInfo;
