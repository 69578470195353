import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Trip, TripResponse} from '../../interfaces/trip.interface';
import {UserResponse} from "../../interfaces/user.interface";

const trip = createSlice({
    name: 'trip',
   // initialState: JSON.parse(JSON.stringify(tripData)) as Trip | null,
    initialState: null as Trip | null,
    reducers: {
        setTrip(state, { payload }: PayloadAction<Trip | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setTrip } = trip.actions;
export default trip.reducer;
