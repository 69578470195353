import React from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import {Button, Col, Form, Row} from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const InclusionsExclusions: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();

    // Directly type cast the names to bypass strict typing issues in a simple manner
    const { fields: inclusionFields, append: appendInclusion, remove: removeInclusion } = useFieldArray({
        control,
        name: 'inclusions' as any
    });

    const { fields: exclusionFields, append: appendExclusion, remove: removeExclusion } = useFieldArray({
        control,
        name: 'exclusions' as any
    });

    return (
        <div>
            <h2>Inclusions</h2>
            {inclusionFields.map((field, index) => (

                <Form.Group key={field.id}>

                    <Row>
                        <Col>
                            <Form.Label>Inclusion</Form.Label>
                            <Controller
                                name={`inclusions.${index}`}
                                control={control}
                                render={({ field }) => <Form.Control {...field} isInvalid={!!errors.inclusions?.[index]} />}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.inclusions?.[index]?.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col>
                            <Button variant="danger" onClick={() => removeInclusion(index)}>Remove</Button>

                        </Col>
                    </Row>

                </Form.Group>
            ))}
            <Button variant="success" onClick={() => appendInclusion('')}>Add Inclusion</Button>

            <h2>Exclusions</h2>
            {exclusionFields.map((field, index) => (
                <Form.Group key={field.id}>
                    <Form.Label>Exclusion</Form.Label>
                    <Controller
                        name={`exclusions.${index}`}
                        control={control}
                        render={({ field }) => <Form.Control {...field} isInvalid={!!errors.exclusions?.[index]} />}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.exclusions?.[index]?.message}
                    </Form.Control.Feedback>
                    <Button variant="danger" onClick={() => removeExclusion(index)}>Remove</Button>
                </Form.Group>
            ))}
            <Button variant="success" onClick={() => appendExclusion('')}>Add Exclusion</Button>
        </div>
    );
};

export default InclusionsExclusions;
