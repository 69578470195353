import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {TripsResponse} from '../../interfaces/trip.interface';

const invitedTrips = createSlice({
    name: 'trips',
    initialState: null as TripsResponse | null,
    reducers: {
        setInvitedTrips(state, { payload }: PayloadAction<TripsResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setInvitedTrips } = invitedTrips.actions;
export default invitedTrips.reducer;
