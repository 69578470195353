import React, {FC, useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import http from "../../services/api";
import {User, UserResponse} from "../../interfaces/user.interface";
import {register_url} from "../../services/api.utils";
import {showAlert, toastMessage} from "../../util";
import AuthHeader from "./AuthHeader";
import logo from "../../images/logo.png";
import Loader from "../loader/Loader";
import googleLogo from "../../images/login/google-logo.svg";
import appleLogo from "../../images/login/apple-logo.svg";
import Modal from "react-bootstrap/cjs/Modal";
import BackButtonImgIcon from "../../images/icons/back-button.svg";
import UserPackageContainer from "../userPackages/UserPackageContainer";

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}


const Verify: FC = () => {


    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');

    const navigate = useNavigate();

    const { width } = useViewport();
    const breakpoint = 620;

    useEffect(() => {
        if(token){

            let data = {
                token: token
            }
            callVerificationAPI(data)

        }else{
            toastMessage("Invalid verification token found", 'error')
            navigate("/");
        }


    },[])


    const callVerificationAPI = (data:any) => {

        http
            .post<User, UserResponse>(`${register_url}/verify-token`, data)
            .then((res) => {
                if (res) {
                    const { data,status,message} = res;


                        if(status == 200 && data.userId){

                            if(data.accessToken){

                                http.defaults.headers.common.Authorization = 'Bearer '+data.accessToken;

                            }

                            localStorage.setItem('user', JSON.stringify(res));
                            let redirect = localStorage.getItem("redirect");

                            toastMessage("Verification successful", '')

                            if(redirect){
                                localStorage.removeItem("redirect");

                                navigate(redirect);
                            }else{
                                navigate("/dashboard");
                            }
                        }else{
                            toastMessage("Invalid verification token found", 'error')
                            navigate("/");

                        }

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    }
    return (
        <div className="wrapper" style={{ height: '100vh'}}>

            <div className="">

                {width > breakpoint ?
                    <AuthHeader/>
                    : ""}
                <div className="px-4 py-5 my-5 text-center">
                        <h1 className="display-5 fw-bold text-body-emphasis  mt-4">Verification</h1>
                        <div className="col-lg-6 mx-auto">
                            <p className="lead mb-4">Thank you for registering. We are currently verifying your email address. This process is important to ensure the security of your account and to confirm that you have access to the email address provided.</p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                <div  className="card card-stretch bg-light-info hoverable shadow-none cursor-pointer">

                                    <div className="card-body d-flex flex-column pb-5 pt-3 mb-5">
                                        <div className={`mb-3`}>

                                            <Loader cssClass="normal-loader"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>

        </div>


    );
};
export default Verify;
