// in config.js
import { createChatBotMessage } from 'react-chatbot-kit';
// import './chatbot.css'
import GeneralWidget from './widgets/GeneralWidget/GeneralWidget';
import Contacts from './widgets/contacts/Contacts';
import TripsWidget from './widgets/Trips/TripsWidget';

const botName = `Soul`;

const config = {
  initialMessages: [
    createChatBotMessage(
      `Hello, I'm ${botName}. I'm here to assist you.`
    ),
    createChatBotMessage(
      "Welcome! to explore soul. I'm here to guide you through all the functionality.",
      {
        withAvatar: false,
        delay: 500,
        widget: 'GeneralWidget',
      }
    ),
  ],
  botName: botName,
  widgets: [
    {
      widgetName: 'GeneralWidget',
      widgetFunc: (props) => <GeneralWidget {...props} />,
    },
    {
      widgetName: 'Trips',
      widgetFunc: (props) => <TripsWidget {...props} />,
    },
    {
      widgetName: 'Contacts',
      widgetFunc: (props) => <Contacts {...props} />,
    },
  ],

  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
};

export default config;
