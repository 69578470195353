// in MessageParser.jsx
import React from 'react';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    const lowerCaseMessage=message.toLowerCase();
    if (lowerCaseMessage.includes('hello')||lowerCaseMessage.includes('helo')||lowerCaseMessage.includes('hlo')||lowerCaseMessage.includes('hi')) {
      actions.handleHello();
      return;
    }

    if (lowerCaseMessage.includes('about')) {
      actions.handleAbout();
      return;

    }
    if (lowerCaseMessage.includes('project')||lowerCaseMessage.includes('projects')) {
      actions.handleProject();
      return;

    }
    if (lowerCaseMessage.includes('resume')) {
      actions.handleResume();
      return;

    }
    if (lowerCaseMessage.includes('experience')) {
      actions.handleExperience();
      return;

    }
    if (lowerCaseMessage.includes('help')) {
      actions.handleHelp();
      return;

    }
    if (lowerCaseMessage.includes('contact')) {
      actions.handleContact();
      return;

    }
    if (lowerCaseMessage.includes('salary')||lowerCaseMessage.includes('ctc')||lowerCaseMessage.includes('marriage')||lowerCaseMessage.includes('martial')) {
      actions.handleAdditonal();
      return;

    }
    actions.handleDefault();
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;