import React from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const Destinations: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'destinations' as 'destinations'
    });

    return (
        <div>
            <h2>Destinations</h2>
            {fields.map((field, index) => (
                <div key={field.id}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Destination Name</Form.Label>
                                <Controller
                                    name={`destinations.${index}.name`}
                                    control={control}
                                    render={({ field }) => (
                                        <Form.Control
                                            type="text"
                                            {...field}
                                            isInvalid={!!errors.destinations?.[index]?.name}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.destinations?.[index]?.name?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Latitude</Form.Label>
                                <Controller
                                    name={`destinations.${index}.latitude`}
                                    control={control}
                                    render={({ field }) => (
                                        <Form.Control
                                            type="number"
                                            {...field}
                                            isInvalid={!!errors.destinations?.[index]?.latitude}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.destinations?.[index]?.latitude?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Longitude</Form.Label>
                                <Controller
                                    name={`destinations.${index}.longitude`}
                                    control={control}
                                    render={({ field }) => (
                                        <Form.Control
                                            type="number"
                                            {...field}
                                            isInvalid={!!errors.destinations?.[index]?.longitude}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.destinations?.[index]?.longitude?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant="danger" onClick={() => remove(index)} style={{ marginTop: '32px' }}>
                                Remove
                            </Button>
                        </Col>
                    </Row>
                </div>
            ))}
            <Button variant="primary" onClick={() => append({ name: '', latitude: 0, longitude: 0 })}>
                Add Destination
            </Button>
        </div>
    );
};

export default Destinations;
