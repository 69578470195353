import React, { FC,useEffect,useState } from 'react';

import '../trips.scss'
import {Trip, TripMember, TripMemberPopulated} from '../../../interfaces/trip.interface';
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import {eSMoment, getImgUrl} from "../../../util";
import {s3_user_url} from "../../../services/api.utils";

import InfoRed from '../../../images/icons/information-red.svg'


type ItemProps = {
    user: TripMemberPopulated,
    keyValue: any,
    tripType: string,

}

const MemberItem: FC <ItemProps> = ({ user,keyValue,tripType}) => {



    console.log("user",user)
    return (


        <div className={`d-flex flex-stack py-3 px-2` } key={keyValue}>
            <div className="d-flex align-items-center" style={ user.status == "pending"? {opacity: "0.5"}: {}}>
                <div className="symbol symbol-45px symbol-circle ">
                    {user.userId?.cover?
                        <img alt="Pic" src={getImgUrl(user.userId?.cover?.generatedName,user.userId._id,'50x')} />
                        :
                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{user.userId?(user.userId?.firstName).charAt(0): ""}</span>
                    }

                </div>
                <div className="ms-3">
                    <a  className="fs-6 fw-bolder text-gray-900 text-hover-primary mb-2 ">{user.userId?.firstName} {user.userId?.lastName}




                    </a>
                    <div className="fw-bold text-gray-400">{eSMoment(user.joinDate).format('MMMM DD, YYYY') }</div>
                </div>
            </div>
            <div className="d-flex flex-column align-items-end ms-2 member-manage">
                {user.status == "pending"?
                    <OverlayTrigger  placement="top" overlay={<Tooltip id="tooltip-dashboard">Member has not yet accepted your trip invitation</Tooltip>}>

                        <span className={`fw-normal text-primary fs-7 cursor-pointer`}><img src={InfoRed} width={20}/></span>
                    </OverlayTrigger>
                    :
                    user.role == "admin" && tripType =="group"? "Admin": ""
                }

            </div>
        </div>

    );
};
export default MemberItem;
