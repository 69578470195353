import React, {FC, useEffect, useState} from 'react';

import logo from "../../images/logo.png";
import {NavLink} from "react-router-dom";
import {Toaster} from "react-hot-toast";


const AuthHeader: FC = () => {

    return (
        <header className="p-3 border-bottom bg-white">
            <div className="container-fluid">
                <div
                    className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <a href="/"
                       className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">

                        <img src={logo} width="40" />
                    </a>

            {/*        <ul className="nav col-12 col-lg-auto mb-2 justify-content-center mb-md-0">
                        <li>
                            <NavLink className="nav-link px-2 fw-bolder link-secondary cursor-pointer hover-color" to="/login" >
                                Sign in
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="nav-link px-2  fw-bolder link-secondary cursor-pointer hover-color" to="/register"  >
                                Sign up
                            </NavLink>
                        </li>




                    </ul>*/}

                </div>
            </div>
            <Toaster />
        </header>
    );
};

export default AuthHeader;
