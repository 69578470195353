import React, { FC,useEffect,useState } from 'react';

import {TripActivity} from "../../../../interfaces/trip.interface";

import Dropdown from "react-bootstrap/cjs/Dropdown";
import {useSelector} from "react-redux";
import {RootState} from "../../../../rootReducer";
import OptionsIconImg from '../../../../images/icons/options.svg';
import Tooltip from "react-bootstrap/cjs/Tooltip";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";


import EditImg from '../../../../images/icons/edit.svg'
import DeleteImg from '../../../../images/icons/delete.svg'
import LockImg from '../../../../images/icons/lock.svg'
import UnLockImg from '../../../../images/icons/unlock.svg'
import ShareImg from '../../../../images/icons/share.svg'
import {toastMessage} from "../../../../util";

type Props = {
    activity:TripActivity,
    handleActivityUpdate:any,
    tripOwner:any,
    width:number,
    tripStatus?:string
    tripType?:string
}

const EditDropDownCard: FC <Props> = ({activity,handleActivityUpdate, tripOwner, width,tripStatus,tripType}) => {

    const { user } = useSelector((state: RootState) => state);
    const userId =user?.data.userId



    return (
        <Dropdown className="dropdown-dots" style={{ display: "unset"}}  onSelect={function (item) {

            if(activity._id){
                if(tripType == 'solo'){
                    if(item ==  'private' || item ==  'share-message'){

                        toastMessage('This feature is not available for solo trip','error')
                        return 0
                    }
                }

                handleActivityUpdate(activity,item)

            }
        }} >
            <Dropdown.Toggle variant="primary" id="dropdown-basic">


                {width >= 620 ?

                    <OverlayTrigger  placement="top" overlay={
                        <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                            <span  className={`text-dark`}>Manage</span>
                        </Tooltip>
                    }>
                        <img src={OptionsIconImg} width={28} className={`option`}/>

                    </OverlayTrigger>

                    :
                    <img src={OptionsIconImg} width={28} className={`option`}/>

                }


            </Dropdown.Toggle>


            <Dropdown.Menu>
                {user?.data.userId === activity._user._id?  <Dropdown.Item eventKey="edit" className={`${tripStatus == 'completed'? 'disabled': ''}`}>
                    <img src={EditImg} className="hover-opacity-btn cursor-pointer me-1" width="15" /> Edit</Dropdown.Item>: "" }
                {user?.data.userId === activity._user._id? <Dropdown.Item eventKey="delete"  className={`${tripStatus == 'completed'? 'disabled': ''}`}>
                    <img src={DeleteImg} className="hover-opacity-btn cursor-pointer me-1" width="15" />Delete</Dropdown.Item>: "" }


{/*
                {user?.data.userId === activity._user._id?  <Dropdown.Item eventKey="private"  className={`${tripStatus == 'completed'? 'disabled': ''}`}>{activity.privacy? <><img src={UnLockImg} className="hover-opacity-btn cursor-pointer me-1" width="15" /> Make Public </>: <><img src={LockImg} className="hover-opacity-btn cursor-pointer me-1" width="15" />Make Private</> }</Dropdown.Item>: "" }
*/}
                {/*
                 <Dropdown.Item eventKey="share-message"><img src={ShareImg} className="hover-opacity-btn cursor-pointer me-1" width="15" />Share in Message</Dropdown.Item>
*/}


                {/*
                 <Dropdown.Item eventKey="share-message"><img src={ShareImg} className="hover-opacity-btn cursor-pointer me-1" width="15" />Share in Message</Dropdown.Item>
*/}
            </Dropdown.Menu>
        </Dropdown>

    );
};
export default EditDropDownCard;
