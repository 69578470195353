import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {CalenderTripsResponse, TripsResponse} from '../../interfaces/trip.interface';

const calenderTrips = createSlice({
    name: 'calenderTrips',
    initialState: null as CalenderTripsResponse | null,
    reducers: {
        setCalenderTrips(state, { payload }: PayloadAction<CalenderTripsResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setCalenderTrips } = calenderTrips.actions;
export default calenderTrips.reducer;
