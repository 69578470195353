import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {LogsResponse} from '../../../interfaces/log.interface';

const logs = createSlice({
    name: 'logs',
    initialState: null as LogsResponse | null,
    reducers: {
        setLogs(state, { payload }: PayloadAction<LogsResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setLogs } = logs.actions;
export default logs.reducer;
