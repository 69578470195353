import React, { FC } from 'react';
import './loader.css'


type LoaderProps = {
    cssClass: string;
}

const Loader: FC <LoaderProps> = ({ cssClass }) => {
    return (
        <div className={`d-flex justify-content-center ${cssClass === "normal-loader" && " mt-3 mb-3"} `}>
            <div className={cssClass}></div>
        </div>
    );
};

export default Loader;
