import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {UserPackagesResponse} from "../../interfaces/userPackage.interface";

const userPackages = createSlice({
    name: 'userPackages',
    initialState: null as UserPackagesResponse | null,
    reducers: {
        setUserPackages(state, { payload }: PayloadAction<UserPackagesResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setUserPackages } = userPackages.actions;
export default userPackages.reducer;
