import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ChecklistResponse} from '../../../interfaces/checklist.interface';

const checkList = createSlice({
    name: 'checkList',
    initialState: null as ChecklistResponse | null,
    reducers: {
        setCheckList(state, { payload }: PayloadAction<ChecklistResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setCheckList } = checkList.actions;
export default checkList.reducer;
