import { combineReducers } from '@reduxjs/toolkit';


import tripsReducer from './features/trips/tripsSlice';
import tripReducer from './features/trip/tripSlice';
import invitedTripsReducer from './features/trips/invitedTripsSlice';

import searchUsersReducer from './features/trips/searchUsersSlice';
import notificationsReducer from './features/notifications/notificationsSlice';


import checkListSliceReducer from './features/trip/checklist/checkListSlice';
import checkListsSliceReducer from './features/trip/checklist/checkListsSlice';

import messagesSliceReducer from './features/messages/messagesSlice';

import userReducer from './features/account/userSlice';
import userPackagesReducer from './features/userPackages/userPackagesSlice';

import calenderTripsReducer from './features/calender/tripsCalenderSlice';
import dashboardDataReducer from './features/dashboard/dashboardDataSlice';

import activityTypesSliceReducer from './features/trip/activity/activityTypesSlice';

import logsReducer from './features/account/logs/logsSlice';
import transactionsReducer from './features/account/transactions/transactionsSlice';
import currenciesReducer from './features/currency/currenciesSlice';
import appBasicReducer from './app/appBasicSlice';


const rootReducer = combineReducers({
    trip:tripReducer,
    trips:tripsReducer,
    invitedTrips:invitedTripsReducer,
    searchUsers:searchUsersReducer,
    notifications:notificationsReducer,
    user:userReducer,
    checkList:checkListSliceReducer,
    checkLists:checkListsSliceReducer,
    messages:messagesSliceReducer,
    userPackages:userPackagesReducer,
    calenderTrips:calenderTripsReducer,
    activityTypes:activityTypesSliceReducer,
    logs:logsReducer,
    transactions:transactionsReducer,
    appBasic:appBasicReducer,
    currencies:currenciesReducer,
    dashboardData:dashboardDataReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
