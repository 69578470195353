import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const AccommodationMeals: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();

    return (
        <div>
            <h2>Accommodation and Meals</h2>
            <Form.Group>
                <Form.Label>Accommodation Details</Form.Label>
                <Controller
                    name="accommodationMeals.accommodationDetails"
                    control={control}
                    render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.accommodationMeals?.accommodationDetails} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.accommodationMeals?.accommodationDetails?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Meals</Form.Label>
                <Controller
                    name="accommodationMeals.meals"
                    control={control}
                    render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.accommodationMeals?.meals} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.accommodationMeals?.meals?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );
};

export default AccommodationMeals;
