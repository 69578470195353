import React, {FC, forwardRef, useCallback, useEffect, useRef, useState, CSSProperties} from 'react';


import './newaitrip.scss'
import {Helmet} from "react-helmet";
import GooglePlaces from "../../../googleplaces/GooglePlaces";
import {
    GooglePlaceAiResponse, Trip,
    TripAiDataValidationResponse,
    TripDataValidationResponse, TripMember, TripResponse
} from "../../../../interfaces/trip.interface";


import EmptyAiImage from '../../../../images/icons/empty-ai.svg';
import GooglePlacesAi from "../../../googleplaces/GooglePlacesAi";
import {useSelector} from "react-redux";
import {RootState} from "../../../../rootReducer";
import Modal from "react-bootstrap/cjs/Modal";
import MemberItem from "../../cards/MemberItem";
import Preference from "../../../account/preference/Preference";
import TripIconImg from "../../../../images/icons/trip.svg";
import MembersIconImg from "../../../../images/icons/members.svg";
import StorageIconImg from "../../../../images/icons/storage.svg";
import {eSMoment, formatBytes, getImgUrl, toastMessage} from "../../../../util";
import CashIconImg from "../../../../images/icons/cash.svg";
import BeachIconImg from "../../../../images/icons/beach.svg";
import CityIconImg from "../../../../images/icons/city.svg";
import TrekkingIconImg from "../../../../images/icons/trekking.svg";
import NextArrowIconImg from "../../../../images/icons/next-arrow.svg";
import PrevArrowIconImg from "../../../../images/icons/prev-arrow.svg";
import Loader from "../../../loader/Loader";
import http from "../../../../services/api";
import {
    ai_trip_data_validation, ai_trip_logged_data_validation,
    base_url, currencies_code_url, get_place_details,
    google_place_photo_url,
    trip_data_validation, trip_date_check_url, trips_ai_url, trips_url, user_search_url, user_url
} from "../../../../services/api.utils";
import BackButtonImgIcon from "../../../../images/icons/back-button.svg";
import loadingImg from "../../../../images/loaders/earth.svg";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import EmptyImg from "../../../../images/empty/empty-image.svg";
import DownArrowImg from "../../../../images/icons/down-arrow.svg";
import UpArrowImg from "../../../../images/icons/up-arrow.svg";
import { motion } from 'framer-motion';
import DeleteIconImg from "../../../../images/icons/delete.svg";
import ErrorIconImg from "../../../../images/icons/error.svg";
import DatePicker from "react-datepicker";
import CalenderImg from "../../../../images/icons/calender.svg";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {CurrenciesResponse} from "../../../../interfaces/country.interface";
import {setCurrencies} from "../../../currency/currenciesSlice";
import {useAppDispatch} from "../../../../store";
import {useNavigate} from "react-router-dom";
import CloseImgIcon from "../../../../images/icons/close.svg";
import find from "lodash/find";
import SearchMemberItem from "../../cards/SearchMemberItem";
import EmptyGroupImg from "../../../../images/illustration-icons/empty-group.svg";
import FamilyMemberItem from "../../cards/FamilyMemberItem";
import EmptyFamilyImg from "../../../../images/illustration-icons/empty-family.svg";
import CloseRedImgIcon from "../../../../images/icons/close-red.svg";
import ReactTagInput from "@pathofdev/react-tag-input";
import {MembersResponse, UserResponse, UserSearch, UsersSearchResponse} from "../../../../interfaces/user.interface";
import remove from "lodash/remove";
import {Gallery} from "react-grid-gallery";
import ImageGallery from "react-image-gallery";


import 'react-image-gallery/styles/css/image-gallery.css'
import {setUser} from "../../../account/userSlice";
const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}


interface Activity {
    id: number;
    name: string;
    icon: string;
}
type OptionSchema ={
    name?: string
    value: string
    label?: string
}
const activities = [
    {
        id:1,
        name:"Beach",
        icon:BeachIconImg,
    },
    {
        id:2,
        name:"City Sightseeing",
        icon:CityIconImg,
    },
    {
        id:3,
        name:"Outdoor adventures",
        icon:TrekkingIconImg,
    }
]

const tripTypes = [
    {
        id:'solo',
        name:"Solo",
        icon:BeachIconImg,
    },
    {
        id:'family',
        name:"Family",
        icon:CityIconImg,
    },
    {
        id:'group',
        name:"Group",
        icon:TrekkingIconImg,
    }
]



const NewAiTrip: FC = () => {

    const { width } = useViewport();
    const { user,currencies,searchUsers } = useSelector((state: RootState) => state);


    console.log("useruseruseruseruser",user)


    const [address, setAddress] = useState("");
    const [destinations, setDestinations] = useState<GooglePlaceAiResponse[]>();
    const [place, setPlace] = useState<GooglePlaceAiResponse>();
    const [step, setStep] = useState("one");
    const [tripValidationLoading, setTripValidationLoading] = useState(false);
    const [showAiSuggestionModal, setShowAiSuggestionModal] = useState(false);
    const [tripValidationData, setTripValidationData] = useState<TripAiDataValidationResponse>()
    const [currenciesOptions, setCurrenciesOptions] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState<OptionSchema|null>(null);
    const [searchKey, setSearchKey] = useState("");
    const [loading, setLoading] = useState(false);

    const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
    const [members, setMembers] = useState(searchUsers as UsersSearchResponse| undefined);
    const [addedMembers, setAddedMembers] = useState<UsersSearchResponse>();
    const [tags, setTags] = useState<string[]>([])
    const [photos, setPhotos] = useState<[{
        original:string,
        thumbnail:string,
        originalHeight:number,
    }]>()

    const [activePhoto, setActivePhoto] = useState<{
        original:string,
        thumbnail:string,
        originalHeight:number,
    }>()


    const [selectedMembers, setSelectedMembers] = useState<TripMember[]>([]);
    const [familyMembers, setFamilyMembers] = useState<MembersResponse>();

    const [selectedActivities, setSelectedActivities] = useState<Activity[]>([]);
    const [selectDates, setSelectDates] = useState(false);

    const [showCountryModal, setShowCountryModal] = useState(false);
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [previewMembers, setPreviewMembers] = useState(false);

    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();
    const [maxDate, setMaxDate] = useState<Date | null>();
    const [minDate, setMinDate] = useState<Date | null>( new Date());
    const [calenderIsOpen, setCalenderIsOpen] = useState(false);
    const [unavailableDates, setUnavailableDates] = useState();
    const [selectedAttraction, setSelectedAttraction] = useState<{
        placeId: string,
        name: string,
        description: string,
        address: string,
    }>();
    const [disableSaveTripButton, setDisableSaveTripButton] = useState(false);
    const [tripType, setTripType] = useState('solo');

    const [days, setDays] = useState(1);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const increment = () => setDays(days < 10 ?days+1: days);
    const decrement = () => setDays(days > 1 ? days - 1 : 1);


    console.log("user.data.country",user?.data.country)


    const handlePlaceChange = (address:any) => {



        setAddress(address)
    };
    useEffect(() => {

        if (familyMembers == undefined) {
            getFamilyMembers()
        }

    },[])
/*    useEffect(() => {


        setShowCountryModal(user && user.data.country && user.data.country.name? false:true)

    },[user])*/
    const getFamilyMembers = () => {

        http
            .get<null, MembersResponse>(`${user_url}/${user?.data.userId}/family`)
            .then((res) => {

                setFamilyMembers(res)


            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {
                setLoading(false);
            });
    }
    useEffect(() => {

        http
            .post<null, any>(trip_date_check_url)
            .then((res) => {
                if (res) {

                    if(res.status == 200){

                        let dateArray:any = []

                        res.data.map((date:any)=>{

                            dateArray.push( new Date(date))
                        })


                        setUnavailableDates(dateArray)


                    }
                }

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {


            });

    },[])

    useEffect(() => {

        if (currencies == null) {

            getCurrencies()
        }


    }, []);


    const getCurrencies = () => {

        http
            .get<null, CurrenciesResponse>(currencies_code_url)
            .then((res) => {
                if (res) {

                    if(res.status == 200){
                        dispatch(setCurrencies(res))
                    }
                }

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {


            });


    };

    useEffect(() => {

        if(currencies){
            let currenciesData:any = []

            currencies.data.currencies.map(function (currency) {
                currenciesData.push( { value: currency._id, label: currency.name+" - "+currency.code })
            })

            setCurrenciesOptions(currenciesData)

        }
    },[currencies])

    const haversineDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
        const R = 6371; // Earth radius in kilometers
        const dLat = ((lat2 - lat1) * Math.PI) / 180;
        const dLon = ((lon2 - lon1) * Math.PI) / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in kilometers
    };

    const doViewportsOverlap = (viewport1:any, viewport2:any) => {
        // Check if viewport1 is to the left of viewport2
        const isToLeft = viewport1.northeast.lng < viewport2.southwest.lng;
        // Check if viewport1 is to the right of viewport2
        const isToRight = viewport1.southwest.lng > viewport2.northeast.lng;
        // Check if viewport1 is above viewport2
        const isAbove = viewport1.southwest.lat > viewport2.northeast.lat;
        // Check if viewport1 is below viewport2
        const isBelow = viewport1.northeast.lat < viewport2.southwest.lat;

        // If none of the above conditions are true, the viewports overlap
        return !(isToLeft || isToRight || isAbove || isBelow);
    }




    const handlePlaceSelect = async (place:GooglePlaceAiResponse) => {

        if(destinations && destinations.length > 4){
            toastMessage("Oops! It seems you've exceeded your destination limit. No worries though! You can always add more destinations after creating your trip. Happy planning", 'error')

        }else{

            const placeIdExists = destinations && destinations.length? destinations.some(destination => destination.placeId === place.placeId): false;


            if(placeIdExists){
                toastMessage("The selected destination has already been added. To continue, please choose another destination. ", 'error')

            }else{

                    let updateddestinations = destinations && destinations.length? [...destinations, place]:[place]

                    setDestinations(updateddestinations)
                    setAddress("")
                    setPlace(place)

                    const res = await http.get<null,any>(`${get_place_details}/${place.placeId}`)

                    if(res && res.data && res.data.formatted_address){


                        let overlapFound = false;


                        if(destinations && destinations?.length){



                            // Check the new destination against all existing destinations for overlap
                            destinations.forEach(dest => {
                                if (doViewportsOverlap(dest.viewport, res.data.geometry.viewport)) {
                                    console.log(`Overlap found with ${dest.name}`);
                                    overlapFound = true;
                                }
                            });
                        }


                        if(overlapFound){

                               let updatedDestinations = destinations && destinations.filter(destination => destination.placeId !== place.placeId);

                            setDestinations(updatedDestinations)


                            toastMessage("Overlap alert: Please verify or adjust destination to avoid confusion.", 'error')

                        }else{

                            let updatedPlace:any = {
                                name:place.name,
                                formattedAddress: res.data.formatted_address,
                                placeId: place.placeId,
                                lat: res.data.geometry.location.lat.toString(),
                                lng: res.data.geometry.location.lng.toString(),
                                photoReference: res.data.photos && res.data.photos.length? res.data.photos[0].photo_reference: "",
                                _country: "",
                                viewport: res.data.geometry.viewport
                            }

                            let updatedDestinations:any = destinations && destinations.length? [...destinations, updatedPlace]:[updatedPlace]




                            if(updatedDestinations && updatedDestinations.length){

                                // Use the first destination as the reference point
                                const referenceLat = parseFloat(updatedDestinations[0].lat);
                                const referenceLong = parseFloat(updatedDestinations[0].lng);

                                const sorted = updatedDestinations.slice(1).sort((a:any, b:any) => {
                                    const distA = haversineDistance(referenceLat, referenceLong, parseFloat(a.lat), parseFloat(a.lng));
                                    const distB = haversineDistance(referenceLat, referenceLong, parseFloat(b.lat), parseFloat(b.lng));
                                    return distA - distB;
                                });


                                // Prepend the reference destination to the sorted array
                                setDestinations([updatedDestinations[0], ...sorted]);
                            }else{

                                setDestinations(updatedDestinations)
                            }

                            setPlace(updatedPlace)
                        }


                    }



            }
        }



    };

    console.log("updatedDestinations-- 1",JSON.stringify(destinations))



    const saveTrip = () => {



        setDisableSaveTripButton(true)


        if(tripValidationData?.data?.itinerary){



            http
                .post<Trip, TripResponse>(trips_ai_url, {
                    itineraryId:tripValidationData?.data?.itineraryId,
                    startDate:startDate,
                    endDate:endDate,
                    currency:selectedCurrency,
                    tripType:tripType
                })
                .then((res) => {
                    if (res) {

                        const { data, status} = res;


                        if(status == 200){


                            http
                                .get<null, UserResponse>(user_url)
                                .then((res) => {


                                    let resData = res
                                    resData.data.accessToken = user?.data.accessToken
                                    localStorage.setItem('user', JSON.stringify(resData));

                                    dispatch(setUser(res));


                                    toastMessage("Trip created successfully", 'success')

                                    navigate(`/trip/${data._id}`);

                                }).catch((error) => {
                                console.log(error);
                            })
                                .finally(() => {
                                    setLoading(false);
                                });

                        }else{
                            toastMessage("Trip creation failed. Please try later", 'error')
                        }


                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {

                });

        }

    }
    const addMember = (user:UserSearch) => {
            let tripMember:TripMember = {
                notifications: {
                    planning: true,
                    checklist: true,
                    document: true,
                    activity: true,
                    photo: true
                },
                status: "pending",
                joinType: "web",
                canAddData: false,
                role: "member",
                budget: "",
                userId: user._id,
                parentId: user.parentId ? user.parentId : "",
            };

            // Use the state setter function to update the selected members



            setSelectedMembers(prevMembers => [...prevMembers, tripMember]);

            // Update the user's total trips and role if necessary
            if (!user.totalTrips) {
                user.totalTrips = 0;
            }
            if (!user.role) {
                user.role = "member";
            }

            // Assuming addedMembers logic remains the same
            // Ensure this logic also aligns with your state management strategy
            let addedMembersClone = addedMembers;
            if (addedMembersClone && addedMembersClone.data) {
                addedMembersClone.data.push(user);
            } else {
                addedMembersClone = { data: [user] };
            }
            setAddedMembers(addedMembersClone);
            // Ensure tripBasicData is updated if necessary elsewhere in your component
    };

    const removeMember = (user:UserSearch) => {
            // Update the selectedMembers state to filter out the member to remove
            setSelectedMembers(prevMembers =>
                prevMembers.filter(member => member.userId !== user._id)
            );

            // Assuming addedMembers logic remains the same
            if (addedMembers) {
                const filteredData:any = addedMembers.data.filter(member => member._id !== user._id);
                setAddedMembers({ ...addedMembers, data: filteredData });

                if (filteredData.length < 2) {
                    setPreviewMembers(false);
                }
            }

    };

    const deletePlaceByIndex = (indexToDelete:any) => {
        if (destinations && destinations.length > 0) {
            const updatedDestinations = destinations.filter((_, index) => index !== indexToDelete);
            setDestinations(updatedDestinations);
        }
    };
    let formSteps = [
        {
            step: 1,
            question: 'Where you want to visit?'
        }
    ]



    const validateTripData = () => {

        setTripValidationLoading(true)
        setShowAiSuggestionModal(true)

        http
            .post<any, TripAiDataValidationResponse>(base_url+ai_trip_logged_data_validation, {
                destinations:destinations,
                days:days,
                activities:selectedActivities
            })
            .then((res) => {
                if (res) {
                   // setShowAiSuggestionModal(false)

                    setTripValidationLoading(false)

                    if(res.status == 200){




                        setTripValidationData(res)


                        http
                            .get<null, UserResponse>(user_url)
                            .then((res) => {


                                let resData = res
                                resData.data.accessToken = user?.data.accessToken
                                localStorage.setItem('user', JSON.stringify(resData));


                                dispatch(setUser(res));


                            }).catch((error) => {
                            console.log(error);
                        })
                            .finally(() => {
                                setLoading(false);
                            });

                    }else{

                        toastMessage(res.message, 'error')
                    }



            }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });


    }
    const selectCurrency = (selectedCurrency:any) => {

        if(selectedCurrency){

            setSelectedCurrency(selectedCurrency)

        }

    }
    const areDatesInRange = (startDate: Date, endDate: Date, dateArray: string[]): boolean => {
        for (const dateString of dateArray) {
            const currentDate = new Date(dateString);
            if (currentDate >= startDate && currentDate <= endDate) {
                return true;
            }
        }
        return false;
    };
    const onChange = (end:any) => {
        //  const [start, end] = dates;


        if(unavailableDates && startDate){

            if(areDatesInRange(startDate, end, unavailableDates)){



                toastMessage("The dates you've selected for your upcoming trip overlap with another trip you are already a part of it.", 'error')

            }else{

                setEndDate(end);
                setCalenderIsOpen(false)
            }

        }

    };
    const selectActivities = (activityItem: Activity): void => {
        const selectedIndex = selectedActivities.findIndex(item => item.id === activityItem.id);

        if (selectedIndex === -1) {
            setSelectedActivities([...selectedActivities, activityItem]);
        } else {
            const updatedSelectedActivities = [...selectedActivities];
            updatedSelectedActivities.splice(selectedIndex, 1);
            setSelectedActivities(updatedSelectedActivities);
        }
    };
    const DropdownIndicator = (
        props: DropdownIndicatorProps<OptionSchema, true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="bx bxs-dollar-circle" ></i>
            </components.DropdownIndicator>
        );
    };

    const searchMembers = (keyword?:string) => {


        if(searchKey || keyword){
            setLoading(true)
            http
                .post<null, UsersSearchResponse>(user_search_url,{key:keyword})
                .then((res) => {
                    if (res) {
                        setMembers(res)
                    }

                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }else{
            setMembers(undefined)
        }



    };

    const getPlaceDetails = async (attraction:any) => {


        setSelectedAttraction(attraction)
        const res = await http.get<null,any>(`${get_place_details}/${attraction.placeId}`)


        let photosArray:any = []
        res.data.photos.map((photo:any)=>{

            photosArray.push({
                original: google_place_photo_url+''+photo.photo_reference+`&height=200&width=400`,
                originalHeight:450,
                thumbnail: google_place_photo_url+''+photo.photo_reference+`&height=150&width=250`,
            })
        })

        setPhotos(photosArray);

    };

    return (
        <main className=" ms-sm-auto col-lg-11 px-md-4 container-main trips-container ai-container-main ">
            <div className="  h-100   ">

                <div className={`row`}>

                    <div className="container  mt-20">
                        <div className="col-md-8 mx-auto text-center mb-10">
                            <h1 className="mb-3 fw-semibold fs-1 text-dark">What Type of Trip do you need?</h1>
                            <p className=" mb-4 fs-7">
                                Choose your ideal journey: Solo trips offer personal freedom, group adventures foster camaraderie, and family vacations create cherished moments for all. What type of trip suits you?
                            </p>
                        </div>

                    </div>

                    <div className={`col-10 row  mx-auto `}>
                        <div className="col-6  justify-content-center">


                            <div  className="card card-stretch bg-light-info hoverable shadow-none cursor-pointer pt-2">
                                <div className="card-header align-items-center border-0 pb-0 ">
                                    <div className="card-title align-items-start flex-column"><h3
                                        className="fw-bolder fs-5 mb-1">Where you want to visit?</h3>

                                    </div>
                                    <div className="card-toolbar">

                                    </div>
                                </div>
                                <div className="card-body pt-0 d-flex flex-column ">

                                    <div className={`container`}>
                                        <form action="#" className={``}>

                                            <GooglePlacesAi placeholder='Where do you want to go?' searchType={'all-location'} address={address}  lat={2} lng={1}  handlePlaceSelect={handlePlaceSelect} handlePlaceChange={handlePlaceChange} />

                                        </form>
                                    </div>

                                </div>
                            </div>


                            {destinations && destinations.length?
                                <div className="row g-3 mb-3 ai-preview-destination preview-destination mt-3">
                                    {destinations?.map((destination,key)=>{

                                        return(
                                            <div className="destination" key={key }>
                                                <div className="d-flex  ">
                                                    <div className="z-1">
                                                        <img className="place-image" src={destination.photoReference?`${google_place_photo_url}${destination.photoReference}`: EmptyImg}
                                                             style={ width>=620? {width: "70px", height:"70px", borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px'}:{width: "100px", height:"140px"}} />
                                                    </div>
                                                    <div className="card  w-100 z-0">
                                                        <div className="card-header pt-3 border-0">
                                                            <div className={`card-title d-flex m-0 flex-column`}>

                                                                <span className="">{destination.name}</span>
                                                                <span className="fs-8">{destination.formattedAddress}</span>
                                                            </div>
                                                            <div className="card-toolbar">

                                                                <span className=" hover-opacity-btn cursor-pointer delete-btn"  onClick={() => {

                                                                    deletePlaceByIndex(key)
                                                                }}>
                                                               <img src={DeleteIconImg} width={15}/>
                                                            </span>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })}

                                </div>
                            :""}





                        </div>


                        <div className="col-6  justify-content-center">

                            <div  className="card card-stretch bg-light-info hoverable shadow-none cursor-pointer pt-2">
                                <div className="card-header align-items-center border-0 pb-0 ">
                                    <div className="card-title align-items-start flex-column"><h3
                                        className="fw-bolder fs-5 mb-1">How many days are you planning to travel?</h3>

                                    </div>
                                    <div className="card-toolbar">

                                    </div>
                                </div>
                                <div className="card-body pt-0 d-flex flex-column ">

                                    <div className="container mt-4">
                                        <div className="d-flex justify-content-between">

                                            <span>
                                                Day
                                            </span>

                                            <div>

                                                <button
                                                    className="btn btn-secondary me-2"
                                                    onClick={decrement}
                                                >
                                                    -
                                                </button>
                                                <span className="fw-bolder">{days}</span>
                                                <button
                                                    className="btn btn-secondary ms-2"
                                                    onClick={increment}
                                                >
                                                    +
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div  className="card card-stretch bg-light-info hoverable shadow-none cursor-pointer pt-2 mt-3">
                                <div className="card-header align-items-center border-0 pb-0 ">
                                    <div className="card-title align-items-start flex-column"><h3
                                        className="fw-bolder fs-5 mb-1">What Type of Trip do you need?</h3>

                                    </div>
                                    <div className="card-toolbar">

                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className={` d-flex gap-3`}>

                                        {tripTypes.map((tripTypeItem)=>{

                                            return(
                                                <div  className={`box border border-gray-300 rounded col p-2 ${tripType === tripTypeItem.id ? 'active' : ''}`} key={tripTypeItem.id} onClick={() => setTripType(tripTypeItem.id)}>
                                                    <div className="d-flex flex-column">
                                                        <img src={tripTypeItem.icon} alt="Money Icon" className="mb-2 opacity-50" width={25} />
                                                        <span className="mb-1 fw-bolder">{tripTypeItem.name}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}


                                    </div>

                                </div>
                            </div>
                            <div  className="card card-stretch bg-light-info hoverable shadow-none cursor-pointer pt-2 mt-3">
                                <div className="card-header align-items-center border-0 pb-0 ">
                                    <div className="card-title align-items-start flex-column"><h3
                                        className="fw-bolder fs-5 mb-1">Which activities are you interested in?</h3>

                                    </div>
                                    <div className="card-toolbar">

                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className={` d-flex gap-3`}>

                                        {activities.map((activityItem)=>{

                                            return(
                                                <div  className={`box border border-gray-300 rounded col p-2 ${selectedActivities.some(item => item.id === activityItem.id) ? 'active' : ''}`} key={activityItem.id} onClick={() => selectActivities(activityItem)}>
                                                    <div className="d-flex flex-column">
                                                        <img src={activityItem.icon} alt="Money Icon" className="mb-2 opacity-50" width={25} />
                                                        <span className="mb-1 fw-bolder">{activityItem.name}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}


                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={`text-end my-5`}>

                            {destinations && destinations.length && selectedActivities && selectedActivities.length?
                                <button className={`btn btn-primary`}   onClick={ validateTripData}>Get Suggestions</button>

                                :
                                <button className={`btn btn-primary disabled`} disabled={true} >Get Suggestions</button>

                            }

                        </div>
                    </div>



                </div>


            </div>
            <Modal show={showCountryModal} animation={true} >
                <Modal.Header>
                    <Modal.Title>Members</Modal.Title>
                    <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={()=>{
                        setShowCountryModal(false)
                    }}>
                        <i className="bi bi-x-circle fs-2 primary-color"></i>
                    </div>
                </Modal.Header>
                <Modal.Body>

                    {user?
                        <Preference userData={user.data} />
                    :""}


                </Modal.Body>
            </Modal>
            <Modal show={showGalleryModal} className={`gallery-modal`} fullscreen={true} animation={true} >
                <Modal.Header>
                    <Modal.Title className={`text-dark fw-bolder`}>{selectedAttraction?.name}</Modal.Title>
                    <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={()=>{
                        setShowGalleryModal(false)

                        setPhotos(undefined)
                        setActivePhoto(undefined)
                    }}>
                        <img src={CloseImgIcon} className={`hover-opacity-btn`} width={20}/>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#4c577d21'}}>

                    {photos && photos.length?

                        <div className={`row mt-4`}>
                            <ImageGallery
                                renderLeftNav={(onClick, disabled) => {
                                return (
                                    <button
                                        className="image-gallery-icon image-gallery-left-nav"
                                        disabled={disabled}
                                        onClick={onClick}
                                        aria-label="Next Slide"
                                    >
                                        {/* Customize with your own styling or icon */}
                                        <img src={PrevArrowIconImg} width={50}/>
                                    </button>
                                );
                            }}
                                renderRightNav={(onClick, disabled) => {
                                return (
                                    <button
                                        className="image-gallery-icon image-gallery-right-nav"
                                        disabled={disabled}
                                        onClick={onClick}
                                        aria-label="Next Slide"
                                    >
                                        {/* Customize with your own styling or icon */}
                                        <img src={NextArrowIconImg} width={50}/>
                                    </button>
                                );
                            }} items={photos} showPlayButton={false} showFullscreenButton={false}  />;

                        </div>


                    :""}


                </Modal.Body>
            </Modal>
            <Modal show={showAiSuggestionModal}  onHide={function () {

                setShowAiSuggestionModal(false)
            }} animation={false} size={'lg'}   className={`ai-suggestion-modal`}  backdrop="static">

                <div className="modal-header pb-0 border-0">

                    <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {

                        setShowAiSuggestionModal(false)
                    }}/>

                </div>
                <div className="modal-body">



                    {tripValidationLoading ?

                        <div  className={`text-center mb-5 pb-5`}>

                            <img src={loadingImg} width={100}/>
                            <p className={`text-dark fs-4 mx-1 mx-lg-5 px-0 px-lg-5`}>We are currently in the process of verifying the information to ensure a smooth and accurate travel experience for you; this typically takes 15-60 seconds.</p>
                        </div>

                        :

                        <>
                            <div className="text-center mt-0 mb-10">
                                <h1 className="mb-3 fs-3 text-dark fw-bolder">{tripValidationData?.data.tripTitle}</h1>
                                <h1 className="mb-3 fs-5 text-dark">{tripValidationData?.data.tripDescription}</h1>
                            </div>

                            <div className="container">



                                {selectDates && unavailableDates?
                                    <div className="col-md-10 mx-auto form-wizard">
                                        <form  className="trip-form d-flex flex-column destination-search flex-lg-row   p-3 p-sm-0 p-md-0 p-xl-0 p-lg-0 " style={{ backgroundColor:'#FFF'}}>

                                                <div className={`col-xl-6 p-3 d-flex ${width<= 620? 'bg-white rounded-4 mt-3 pt-2 pb-3  justify-content-start':' justify-content-center'}`} >


                                                    <div className={`px-3 me-3 cursor-pointer`} style={{ backgroundColor:'#f5f8fa',borderRadius: '10px'}}>
                                                        <DatePicker
                                                            minDate={minDate}
                                                            selected={startDate}
                                                            onChange={(dateItem)=> {
                                                                setCalenderIsOpen(true)
                                                                setStartDate(dateItem)
                                                                setEndDate(null)
                                                                if(dateItem) {
                                                                    const endDate = new Date(dateItem);
                                                                    endDate.setDate(endDate.getDate() + days); // Add 5 days to the selected date
                                                                    setMaxDate(endDate); // Set this new date as the end date


                                                                    console.log("endDate---1",endDate)

                                                                }
                                                            }}
                                                            onInputClick={()=>setCalenderIsOpen(false)}
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            excludeDates={unavailableDates}
                                                            selectsStart
                                                            dateFormat="MMM d yyyy"
                                                            wrapperClassName={width<=620? 'new-trip-date-input-sm':'new-trip-date-input'}
                                                            customInput={React.createElement(function ({ value, onClick }: {value: string; onClick: any}){

                                                                return(
                                                                    <div onClick={onClick} className={``}>
                                                                        {startDate?
                                                                            <div className={`d-flex mt-2`}>
                                                                                <img src={CalenderImg} className={`me-2`} style={{ opacity: '0.5'}}  width={30}/>
                                                                                <div className={`d-flex flex-column`}>

                                                                                    <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>{eSMoment(value).format('MMM D')}</span>
                                                                                    <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>{eSMoment(value,'MMM D YYYY').format('YYYY')}</span>

                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className={`d-flex mt-2`}>
                                                                                <img src={CalenderImg} className={`me-2`}  style={{ opacity: '0.5'}}  width={30}/>
                                                                                <div className={`d-flex flex-column`}>

                                                                                    <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>Start</span>
                                                                                    <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>Date</span>

                                                                                </div>
                                                                            </div>
                                                                        }

                                                                    </div>

                                                                )

                                                            })}
                                                        />
                                                    </div>


                                                    <div className={`px-3 me-3 cursor-pointer`} style={{ backgroundColor:'#f5f8fa', borderRadius: '10px'}}>
                                                        <DatePicker
                                                            minDate={startDate}
                                                            maxDate={maxDate}
                                                            selected={endDate}
                                                            onChange={onChange}
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            excludeDates={unavailableDates}
                                                            open={calenderIsOpen}
                                                            selectsEnd
                                                            openToDate={startDate || new Date()} // Set the openToDate prop here
                                                            dateFormat="MMM d yyyy"
                                                            wrapperClassName={width<=620? 'new-trip-date-input-sm':'new-trip-date-input'}
                                                            customInput={React.createElement(function ({ value, onClick }: {value: string; onClick: any}){

                                                                console.log("endDateendDate",endDate)


                                                                return(
                                                                    <div onClick={()=>{
                                                                        setCalenderIsOpen(!calenderIsOpen)
                                                                        onClick()
                                                                    }}>
                                                                        {endDate?
                                                                            <div className={`d-flex mt-2`}>
                                                                                <img src={CalenderImg} className={`me-2`} style={{ opacity: '0.5'}}  width={30}/>
                                                                                <div className={`d-flex flex-column`}>
                                                                                    <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>{eSMoment(value).format('MMM D')}</span>
                                                                                    <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>{eSMoment(value).format('YYYY')}</span>

                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className={`d-flex mt-2`}>
                                                                                <img src={CalenderImg} className={`me-2`} style={{ opacity: '0.5'}} width={30}/>
                                                                                <div className={`d-flex flex-column`}>

                                                                                    <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>End</span>
                                                                                    <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>Date</span>

                                                                                </div>
                                                                            </div>
                                                                        }

                                                                    </div>

                                                                )

                                                            })}
                                                        />
                                                    </div>

                                                </div>
                                                <div className={`col-xl-6 col d-flex p-3 ps-0 ${width<= 620? 'bg-white rounded-4 mt-3 py-2 mb-5':''}`}>
                                                    <div className="label currency-container" >


                                                        <Select
                                                            value={selectedCurrency}
                                                            onChange={selectCurrency}
                                                            options={currenciesOptions}
                                                            components={{ DropdownIndicator }}
                                                            placeholder={`Choose Currency`}
                                                            styles={{
                                                                control: (base):any => ({
                                                                    ...base,
                                                                    border: 'none',
                                                                    boxShadow:'none',
                                                                    color:'#5e6278',
                                                                    cursor:'pointer',
                                                                    backgroundColor:'transparent'
                                                                }),
                                                                placeholder: (base):any => ({
                                                                    ...base,
                                                                    color:'#5e6278',

                                                                }),
                                                                indicatorSeparator: (base):any => ({
                                                                    ...base,
                                                                    width: '0px',
                                                                }),

                                                                input: (base):any => ({
                                                                    ...base,
                                                                    color:'#5e6278',
                                                                }),

                                                                menu: (base):any => ({
                                                                    ...base,
                                                                    boxShadow:'none',
                                                                    padding:'5px'
                                                                }),

                                                                option: (base, {isFocused, isSelected}):any => ({
                                                                    ...base,
                                                                    border: `none`,
                                                                    height: '100%',
                                                                    color:'#5e6278',
                                                                    fontSize: '16px',
                                                                    cursor:'pointer',
                                                                    backgroundColor: isFocused
                                                                        ? '#f5f8fa'
                                                                        : isSelected
                                                                            ? '#f5f8fa'
                                                                            : undefined,
                                                                    "&:active": {
                                                                        backgroundColor: "#f5f8fa"
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                        </form>
                                    </div>


                                    :




                                <div className="row g-3 mb-3 preview-destination">


                                    <ul className={`timeline-tour`}>
                                        {tripValidationData?.data?.itinerary && tripValidationData?.data?.itinerary?.map((itineraryItem)=>{

                                            return(
                                                <li className={`d-flex`}>
                                                    <img className="tour-before bg-primary" src={itineraryItem.photoReference?`${google_place_photo_url}${itineraryItem.photoReference}`: EmptyImg}/>
                                                    <div className={`timeline-data ms-3`}>
                                                        <div>
                                                            <div className={`d-flex justify-content-between`}>
                                                                <h3 className="card-title font-weight-semibold2 mt-1 text-dark">Day {itineraryItem.day} : {itineraryItem.title}</h3>
{/*
                                                                <img className="" src={DownArrowImg} width={20}/>
*/}

                                                            </div>
                                                            <div className="leading-loose text-dark">{itineraryItem.description}</div>
                                                        </div>
                                                        <div className={`ms-4`}>
                                                            {itineraryItem.attractions && itineraryItem.attractions.map((attraction, key:number)=>{
                                                                return(
                                                                    <div className={`mt-3 d-flex justify-content-between`}>
                                                                        <div>

                                                                            <div className="fs-16 font-weight-semibold">
                                                                                <span className="text-secondary">{attraction.entry_time}-{attraction.exit_time}</span> (<span>{attraction.duration}</span>)
                                                                            </div>
                                                                            <h3 className="card-title text-dark font-weight-semibold2 mt-1">{attraction.name}</h3>
                                                                            <div className="leading-loose">{attraction.description}</div>

                                                                        </div>
                                                                        <div className={`bg-image cursor-pointer`} onClick={()=>{
                                                                            setShowGalleryModal(true)
                                                                            getPlaceDetails(attraction)


                                                                        }} style={{ backgroundImage: `url(${google_place_photo_url}${attraction.photoReference})`}}> </div>
                                                                    </div>
                                                                )
                                                            }) }
                                                        </div>
                                                    </div>

                                                </li>
                                            )

                                        }) }


                                    </ul>



                                </div>
                                }




                            </div>
                            <div className="d-flex mt-3 justify-content-end">

                                {selectDates?
                                    <button type="button" onClick={()=>{

                                        if(tripType == "solo"){
                                            saveTrip()
                                        }else{
                                            setShowAiSuggestionModal(false)

                                            setShowInviteMemberModal(true)
                                        }
                                    }}  className={`btn btn-primary`}  name="backward" >Create Trip! </button>

                                    :
                                    <button type="button" onClick={()=> setSelectDates(true)} className={`btn btn-primary`}  name="backward" >Continue creating trip!</button>

                                }


                            </div>
                        </>
                    }

                </div>
            </Modal>
            <Modal show={showInviteMemberModal}  onHide={function () {

                setShowInviteMemberModal(false)
            }} animation={false}  backdrop="static">

                <div className="modal-header pb-0 border-0">

                    <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {

                        setDisableSaveTripButton(false)

                        setShowInviteMemberModal(false)
                    }}/>

                </div>
                <div className="modal-body">


                    <div className="text-center mt-0 mb-10">
                        <h1 className="mb-3 fs-1 text-dark">Invite members to this trip</h1>
                        <div className="text-muted fw-semibold fs-5 text-dark">Invite existing members or add new ones.</div>
                    </div>

                    <div className="">

                        { tripType == "group" ?
                            <>
                                <h3 className="text-muted fw-semibold mt-0 mb-3 fs-5">ExploreSoul members</h3>

                                <div  className="w-100 position-relative mb-5 d-flex justify-content-end"
                                >



                                    <input type="text" className="form-control form-control-lg form-control-solid pe-5"
                                           name="search" value={searchKey}
                                           placeholder="Search by username, full name or email..."  onChange={(e)=>{
                                        setSearchKey(e.target.value)
                                        searchMembers(e.target.value)
                                    }} onKeyDown={(e:any)=>{
                                        if(e.key == "Enter") {

                                            searchMembers()
                                        }

                                    }}  />

                                    {members?.data?
                                        <span
                                            className="svg-icon svg-icon-2 svg-icon-lg-1 cursor-pointer svg-icon-gray-500 position-absolute top-50 me-2 translate-middle-y"
                                            onClick={()=>{

                                                setSearchKey("")
                                                setMembers(undefined)
                                            }}>


                                            <img src={CloseImgIcon} className={`hover-opacity-btn`} width={20}/>
                                    </span>
                                        :
                                        searchKey?
                                            <span
                                                className="svg-icon svg-icon-2 svg-icon-lg-1 cursor-pointer svg-icon-gray-500 position-absolute top-50 me-2 translate-middle-y"
                                                onClick={()=>{
                                                    searchMembers()
                                                }}>

                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                                      transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                <path
                                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        </span>
                                            :''

                                    }

                                </div>


                                {loading?
                                    <Loader cssClass="normal-loader"/>

                                    : members?.data && members.data.length ?
                                        <div className="pt-0">

                                            { members?.data.map(function (userItem, key) {

                                                let isAdded = false
                                                let isAdminUser = false
                                                if (selectedMembers && selectedMembers.length) {

                                                    if (find(selectedMembers, {userId: userItem._id})) {

                                                        isAdded = true
                                                        //   return false
                                                    }
                                                }

                                                if (userItem._id ==  user?.data.userId) {
                                                    isAdminUser = true
                                                }
                                                return (
                                                    <SearchMemberItem canManage={true} user={userItem} isAdminUser={isAdminUser}
                                                                      key={key} isAdded={isAdded} keyValue={key}
                                                                      addMember={addMember} removeMember={removeMember}/>
                                                )

                                            }) }
                                        </div>
                                        :

                                        searchKey?

                                            <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-0" >

                                                <div className="text-center p-2 mt-1 mb-0">
                                                    <img src={EmptyGroupImg} width={100}/>
                                                    <p className="fs-5 text-dark fw-bolder">No user found</p>
                                                    <p className="text-gray-400">Please try with different keyword
                                                        or <span className="fw-bolder cursor-pointer" > invite via email</span>
                                                    </p>
                                                </div>
                                            </div>
                                            :""
                                }
                            </>
                            : ""}



                        {tripType == "family" || tripType == "group" ?
                            <div className="pt-0">

                                <div className="">

                                    <h3 className="text-muted fw-semibold mt-0 mb-3 fs-5">Family members</h3>

                                    {familyMembers?.data.length?

                                        <div className="mh-375px scroll-y me-n7 pe-7">
                                            {familyMembers?.data.map(function (userItem, key) {
                                                let isAdded = false
                                                if (selectedMembers && selectedMembers.length) {

                                                    if (find(selectedMembers, {userId: userItem._id})) {

                                                        isAdded = true
                                                        //   return false
                                                    }
                                                }

                                                return(
                                                    <FamilyMemberItem isAdded={isAdded} user={userItem} key={key} keyValue={key} addMember={addMember} removeMember={removeMember} />
                                                )
                                            })}
                                        </div>
                                        :

                                        <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-40px'}}>

                                            <div className="text-center p-2 mt-1 mb-0">
                                                <img src={EmptyFamilyImg} width={100}/>
                                                <p className="fs-5 text-dark fw-bolder">No data available</p>
                                                <p className="text-gray-400">Please add family member
                                                    from <span className="fw-bolder cursor-pointer" onClick={()=>{navigate('/account')}}>profile</span>
                                                </p>
                                            </div>
                                        </div>
                                    }

                                </div>


                            </div>
                            : ""
                        }


                    </div>
                    <div>
                        <h3 className="text-muted fw-semibold mt-0 mb-3 fs-5">Selected members</h3>

                        {addedMembers && addedMembers.data.length? addedMembers && addedMembers.data.map(function (userItem,key) {
                            let isAdded = false
                            let isAdminUser = false


                            if(userItem.userId && userItem.userId._id ==  user?.data.userId){
                                isAdminUser = true
                            }
                            return(
                                <div className="chip m-1">
                                    <div className="chip-head">
                                        {userItem.cover?.generatedName?
                                            <img alt="Pic" src={getImgUrl(userItem.cover?.generatedName,userItem._id,'50x')} />
                                            :
                                            <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{(userItem.firstName).charAt(0)}</span>
                                        }
                                    </div>
                                    <div className="chip-content fw-bolder ps-3 pe-1">{userItem.parentId? userItem.firstName: userItem.userName}</div>
                                    <div className="chip-close" onClick={() => {
                                        if(! isAdminUser){
                                            removeMember(userItem)
                                        }
                                    }}>
                                        {! isAdminUser ?
                                            <img src={CloseRedImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20}/>
                                            :
                                            ""}


                                    </div>
                                </div>
                            )
                        }) :
                            <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-40px'}}>

                                <div className="text-center p-2 mt-1 mb-0">
                                    <p className="fs-5 text-dark fw-bolder">No members selected</p>
                                    <p className="text-gray-400">
                                        Utilize the search option to choose members.
                                    </p>
                                </div>
                            </div>}
                    </div>
                    {tripType == "group" ?

                        <div>
                            <div className="text-muted fw-semibold mt-0 mb-3 fs-5">Not a exploresoul member? Type their email below:</div>
                            <ReactTagInput
                                tags={tags}

                                removeOnBackspace={true}
                                onChange={ (newTags) => {

                                        setTags(newTags)

                                }}
                                validator={(value) => {

                                    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

                                    const isEmail = regexExp.test(value)
                                    if (!isEmail) {
                                        // alert("Please enter an e-mail address");
                                    }
                                    const isDuplicate  = tags.includes(value);

                                    // Return boolean to indicate validity
                                    return isEmail && !isDuplicate;
                                }}
                            />

                        </div>
                        :""
                    }


                    <div className="d-flex mt-3 justify-content-end">
                        <button type="button" name="backward"  disabled={disableSaveTripButton}
                                className=" btn btn-lg btn-primary shadow-none" onClick={saveTrip}>Let's start!
                        </button>

                    </div>
                </div>
            </Modal>
        </main>




    );
};
export default NewAiTrip;
