import React, {FC, useEffect, useState} from 'react';
import './family.scss'
import http from "../../../services/api";
import {
    Address, FamilyMember,
    MembersResponse,
    PaymentAddress,
    User,
    UserResponse,
    UsersResponse
} from "../../../interfaces/user.interface";
import {countries_url, user_logs_url, user_url} from "../../../services/api.utils";
import {setLogs} from "../logs/logsSlice";
import {useAppDispatch} from "../../../store";
import {Log, LogsResponse} from "../../../interfaces/log.interface";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import {eSMoment, showAlert, toastMessage} from "../../../util";
import Modal from "react-bootstrap/cjs/Modal";
import UserPackageContainer from "../../userPackages/UserPackageContainer";
import Select from "react-select";
import Loader from "../../loader/Loader";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CountriesResponse} from "../../../interfaces/country.interface";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {setUser} from "../userSlice";
import BackButtonImgIcon from "../../../images/icons/back-button.svg";

type Props = {
    userData: User,
    updateUserData?: any

}

type OptionSchema = {
    name?: string
    value: string
    label?: string
}

const schema = Yup.object().shape({
    firstName: Yup.string().matches(/^[A-Za-z]+$/, 'Invalid characters').required('First Name required'),
    lastName: Yup.string().matches(/^[A-Za-z]+$/, 'Invalid characters').required('Last Name required'),
    age: Yup.number().typeError('Age must be a number').required('Age required').min(1, 'Must be at least 1').max(99, 'Must be at most 99'),
    relation: Yup.string().required('Relationship required'),
});
const Family: FC<Props> = ({userData, updateUserData}) => {

    const {handleSubmit, register, reset, setFocus, formState: {errors},} = useForm<FamilyMember>({
        resolver: yupResolver(schema),
        shouldUnregister: true,
    });
    const [loading, setLoading] = useState(false);
    const [showFamilyModal, setShowFamilyModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [familyMembers, setFamilyMembers] = useState<MembersResponse>();
    const [activeFamilyMember, setActiveFamilyMember] = useState<FamilyMember>();


    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setLoading(true);


        if (familyMembers == undefined) {
            http
                .get<null, MembersResponse>(`${user_url}/${userData._id}/family`)
                .then((res) => {

                    setFamilyMembers(res)


                }).catch((error) => {
                console.log(error);
            })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }

    }, []);
    const submitForm = (formData: FamilyMember) => {

        if (activeFamilyMember) {
            apiPUTCall(formData)
        } else {
            apiPOSTCall(formData)
        }

        reset()
    };

    const apiPOSTCall = (formData: FamilyMember) => {
        setLoading(true)
        if (formData.email == "") {
            delete formData.email;
        }

        if (formData.userName == "") {
            delete formData.userName;
        }

        http
            .post<User, MembersResponse>(`${user_url}/${userData._id}/family`, formData)
            .then((res) => {
                if (res) {
                    const {data, status, message} = res;

                    setFamilyMembers(res)
                    setLoading(false)
                    setShowFamilyModal(false)
                    if (status == 200) {
                        toastMessage('New family member has been added successfully', 'success')
                    } else {
                        toastMessage("Adding family member failed. Please try later", 'error')
                    }


                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const apiPUTCall = (formData: FamilyMember) => {
        setLoading(true)

        http
            .put<User, MembersResponse>(`${user_url}/${userData._id}/family/${activeFamilyMember?._id}`, formData)
            .then((res) => {
                if (res) {
                    const {data, status, message} = res;
                    setFamilyMembers(res)
                    setLoading(false)
                    setShowFamilyModal(false)
                    if (status == 200) {
                        toastMessage('Family member updated successfully', 'success')
                    } else {
                        toastMessage("Family member change failed. Please try later", 'error')
                    }



                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const apiDELETECall = () => {
        setDeleteLoading(true)

        http
            .delete<User, MembersResponse>(`${user_url}/${userData._id}/family/${activeFamilyMember?._id}`)
            .then((res) => {
                if (res) {
                    const {data, status, message} = res;
                    setFamilyMembers(res)
                    setDeleteLoading(false)
                    setShowFamilyModal(false)
                    setActiveFamilyMember(undefined)
                    reset()

                    if (status == 200) {
                        toastMessage('Family member deleted successfully', 'success')
                    } else {
                        toastMessage("Family member deletion failed. Please try later", 'error')
                    }


                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (

        <div className="row card family-container">

            <div className="container">

                <div className="d-flex flex-wrap flex-stack mb-6">

                    <h3 className="text-dark text-hover-primary fs-3 fw-bolder my-2">Members
                        {familyMembers? <span className="fs-6 text-gray-400 fw-semibold ms-1">({familyMembers?.data.length})</span>: ""} </h3>


                    <div className="d-flex my-2">
                        <button className=" btn btn-primary btn-sm shadow-none" onClick={function () {
                            setShowFamilyModal(true)

                        }}>Add New Member
                        </button>

                    </div>

                </div>

            </div>
            <div className="container">
                <div className="row">
                    {familyMembers? familyMembers.data.length? familyMembers.data.map(function (member, key) {

                        return (
                            <div className=" col-xl-4 mb-3" key={key}>

                                <div className="d-flex align-items-center family-item">

                                    <div className="symbol symbol-circle mx-3">
                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">A</span>
                                    </div>


                                    <div className="flex-grow-1">
                                        <span  className="text-dark fw-bolder text-hover-primary fs-6">{member?.firstName} {member?.lastName}</span>
                                        <span className="text-muted d-block fw-bold">{member?.relation}</span>
                                        <span className="text-muted fw-semibold d-block pt-1">{member?.age} yrs Old</span>
                                    </div>
                                    <div className="d-flex  align-items-center mx-3">
                                        <button className="btn btn-sm btn-primary manage-btn" onClick={function () {
                                            setActiveFamilyMember((prevState) => member)

                                            setShowFamilyModal(true)

                                        }}> Manage
                                        </button>
                                    </div>
                                </div>

                            </div>

                        )
                    }):
                            <div className={`card py-5`}>
                                <div className="text-center p-2">
                                    <p className="fs-5 text-dark fw-bolder">No Family</p>
                                    <p className="text-gray-400">Please check after adding planning or activities from <span className="fw-bolder">destination dashboard</span>
                                    </p>
                                </div>

                            </div>

                        : <a  className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" >

                        <div className="card-body d-flex flex-column pt-5">

                            <Loader cssClass="normal-loader"/>
                        </div>
                    </a>}
                </div>
            </div>


            <Modal backdrop="static" keyboard={false} centered show={showFamilyModal} animation={false}
                   onExit={function () {
                       setActiveFamilyMember(undefined)
                   }}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className={`row p-3`}>

                        <div className={`col-4`}>
                            <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20}  onClick={() => {
                                setShowFamilyModal(false)
                                setActiveFamilyMember(undefined)
                                reset()
                            }}/>
                        </div>
                        <div className={`col-4 text-md-center fs-5 fw-bolder text-dark `}>
                            {activeFamilyMember ? "Edit Member" : "Add Member"}
                        </div>
                        <div className={`col-4 text-end`}>
                            <div className=" ms-2">


                                {activeFamilyMember &&
                                    <button className="save-button  shadow-none" disabled={deleteLoading ? true : false}
                                            type="button" onClick={()=>{   apiDELETECall()}}>{deleteLoading ? "Deleting..." : "Delete"}</button>}



                                                <button className="save-button  shadow-none" disabled={loading ? true : false}
                                            type="submit">{loading ? "Saving...": "Save"}</button>
                            </div>
                        </div>


                    </div>


                    <Modal.Body>

                        <div className="row g-3">
                            <div className="col-12">
                                <label htmlFor="firstName" className="text-dark fs-6">First Name </label>
                                <input {...register("firstName")} type="text" value={activeFamilyMember?.firstName}
                                       onChange={function (e) {

                                           if (activeFamilyMember) {

                                               setActiveFamilyMember({
                                                   ...activeFamilyMember,
                                                   firstName: e.target.value
                                               })
                                           }
                                       }}
                                       className={Object.keys(errors).length === 0 ? "form-control form-control-lg form-control-solid" : errors.firstName ? "form-control form-control-lg form-control-solid is-invalid" : "form-control form-control-lg form-control-solid is-valid"}
                                       name="firstName"
                                       placeholder="John"/>
                                {errors && errors.firstName && (
                                    <div className="invalid-feedback show"> {errors.firstName.message} </div>
                                )}
                            </div>
                            <div className="col-12">
                                <label htmlFor="lastName" className="text-dark fs-6">Last Name </label>
                                <input {...register("lastName")} value={activeFamilyMember?.lastName}
                                       onChange={function (e) {
                                           if (activeFamilyMember) {

                                               setActiveFamilyMember({
                                                   ...activeFamilyMember,
                                                   lastName: e.target.value
                                               })
                                           }
                                       }} type="text"
                                       className={Object.keys(errors).length === 0 ? "form-control form-control-lg form-control-solid" : errors.lastName ? "form-control form-control-lg form-control-solid is-invalid" : "form-control form-control-lg form-control-solid is-valid"}
                                       name="lastName"
                                       placeholder="Doe"/>
                                {errors && errors.lastName && (
                                    <div className="invalid-feedback show"> {errors.lastName.message} </div>
                                )}
                            </div>


                            <div className="col-12">
                                <label htmlFor="relation" className="text-dark fs-6">Relation </label>
                                <input {...register("relation")} value={activeFamilyMember?.relation}
                                       onChange={function (e) {

                                           if (activeFamilyMember) {

                                               setActiveFamilyMember({
                                                   ...activeFamilyMember,
                                                   relation: e.target.value
                                               })
                                           }
                                       }} type="text"
                                       className={Object.keys(errors).length === 0 ? "form-control form-control-lg form-control-solid" : errors.relation ? "form-control form-control-lg form-control-solid is-invalid" : "form-control form-control-lg form-control-solid is-valid"}
                                       name="relation"
                                       placeholder="Spouse, Children, father etc..."/>
                                {errors && errors.relation && (
                                    <div className="invalid-feedback show"> {errors.relation.message} </div>
                                )}
                            </div>
                            <div className="col-12">
                                <label htmlFor="age" className="text-dark fs-6">Age </label>
                                <input {...register("age")} value={activeFamilyMember?.age} onChange={function (e) {

                                    if (activeFamilyMember) {

                                        setActiveFamilyMember({
                                            ...activeFamilyMember,
                                            age: e.target.value
                                        })
                                    }
                                }} type="number"
                                       className={Object.keys(errors).length === 0 ? "form-control form-control-lg form-control-solid" : errors.age ? "form-control form-control-lg form-control-solid is-invalid" : "form-control form-control-lg form-control-solid is-valid"}
                                       name="age"
                                       placeholder="Age"/>
                                {errors && errors.age && (
                                    <div className="invalid-feedback show"> {errors.age.message} </div>
                                )}
                            </div>

                        </div>

                    </Modal.Body>
                </form>
            </Modal>

        </div>


    );
};
export default Family;
