import React, { FC,useEffect,useState } from 'react';

import {UserSearch} from "../../../../interfaces/user.interface";
import {TripMemberPopulated} from "../../../../interfaces/trip.interface";
import {getImgUrl} from "../../../../util";
import RemoveUserIconImg from "../../../../images/icons/close-red.svg";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import Tooltip from "react-bootstrap/cjs/Tooltip";

type ItemProps = {
    user: TripMemberPopulated,
    keyValue: any,
    addMember:any,
    removeMember:any,
    isAdded:boolean
    isAdminUser:boolean,
    canManage:boolean

}

const SettingsSearchMemberItem: FC <ItemProps> = ({ canManage,user,keyValue ,addMember,removeMember,isAdded,isAdminUser}) => {



    console.log("user",user)

    return (


        <div className={`d-flex flex-stack py-1 px-2` } key={keyValue}  onClick={() => addMember &&  addMember(user)}>
            <div className={`d-flex align-items-center ${user.parentId == undefined?user.status == "pending"?'opacity-50':'':''}`}>
                <div className="symbol symbol-45px symbol-circle">
                    {user.userId?.cover?.generatedName?
                        <img alt="Pic" src={getImgUrl(user.userId?.cover?.generatedName,user.userId._id,'50x')} />
                        :
                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{user?.userId?(user?.userId?.firstName).charAt(0): ""}</span>
                    }

                </div>
                <div className="ms-3">
                    <span
                        className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">{user.userId?.firstName} {user.userId?.lastName}

                        {user.parentId == undefined && user.status == "pending"?

                            <OverlayTrigger  placement="top" overlay={<Tooltip id="tooltip-dashboard">Member has not yet accepted your trip invitation</Tooltip>}>

                                <span className={`fw-normal text-primary fs-7 cursor-pointer`}>(Pending)</span>
                            </OverlayTrigger>
                       :'' }





                    </span>
                    <div className="fw-bold text-gray-400">{user.userId?.relation? user.userId?.relation: user.userId?.userName}</div>
                </div>
            </div>

            {canManage?

                <div className="d-flex flex-column align-items-end ms-2 member-manage">

                    {removeMember ? isAdminUser? "":
                        <span className="text-muted fs-2 mb-1 cursor-pointer active"  onClick={() => removeMember(user)} >
                        <img src={RemoveUserIconImg} width={20} className={`hover-opacity-btn`}/>

                    </span> :""
                    }
                </div>
                :''
            }
        </div>


    );
};
export default SettingsSearchMemberItem;
