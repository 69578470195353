import React, {FC, ReactElement, useEffect, useState} from 'react';
import Sidebar from "./sidebar/Sidebar";
import {User, UserResponse} from "../interfaces/user.interface";
import io from "socket.io-client";
import {currencies_code_url, messages_url, notifications_url, wss_url} from "../services/api.utils";
import http from "../services/api";
import {MessageInterface, MessagesResponse, NewMessageInterface} from "../interfaces/message.interface";
import {useAppDispatch} from "../store";
import {setMessages} from "./messages/messagesSlice";
import {setAppBasic} from "../app/appBasicSlice";
import {setCurrencies} from "./currency/currenciesSlice";
import {useSelector} from "react-redux";
import {RootState} from "../rootReducer";
import {cloneDeep} from "lodash";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import find from "lodash/find";
import {Notification, NotificationsResponse} from "../interfaces/notification.interface";
import {setNotifications} from "./notifications/notificationsSlice";
import filter from "lodash/filter";
import {setTrip} from "./trip/tripSlice";
import {Trip} from "../interfaces/trip.interface";
import {CurrenciesResponse} from "../interfaces/country.interface";
import {Toaster} from "react-hot-toast";
import {toastMessage} from "../util";
import Chatbot from "./chatbot/chatbot";

const socket = io(wss_url?wss_url:"");

interface pageInterface {
    title:string,
    path:string
}

const pages = [
/*    {
        title: 'Dashboard',
        path: '/dashboard'
    },*/
    {
        title: 'Pending trips',
        path: '/trips/upcoming'
    },
    {
        title: 'Active trips',
        path: '/trips/active'
    },

    {
        title: 'Completed trips',
        path: '/trips/completed'
    },
    {
        title: 'New trip',
        path: '/new'
    },
    {
        title: 'Notifications',
        path: '/notifications'
    },
    {
        title: 'Messages',
        path: '/messages'
    },
    {
        title: 'Calender',
        path: '/calender'
    },
    {
        title: 'Account',
        path: '/account'
    }
]


const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const FeatureContainer: FC<{userData:User,children:ReactElement}> = ({userData, children }) => {


    const location = useLocation();
    const { messages, user, trip, appBasic, notifications, currencies } = useSelector((state: RootState) => state);
    const [newMessage, setNewMessage] = useState<NewMessageInterface>();
    const [newNotification, setNewNotification] = useState<Notification>();
    const [hasNewMessage, setHasNewMessage] = useState(false);
    const [messageContents, setMessageContents] = useState<[MessageInterface]>();
    const [tripContents, setTripContents] = useState<Trip>();




    const userId =user?.data.userId

    const dispatch = useAppDispatch();




    useEffect(() => {

        socket.on("connect", () => {
            console.log("connected"); // ok

            socket.emit('join', {userId :userData.userId}, (response:any) => {
                console.log("join",response); // ok
            });

        });

    }, []);


    useEffect(() => {

        socket.on('message', function (message:any){
            if(location.pathname != "/messages"){
                dispatch(setAppBasic({
                    ...appBasic,
                    message:{
                        hasNotification:true
                    }}
                ));
            }
            if(message){
                setNewMessage((oldMessage) => JSON.parse(message));
            }
        });
        socket.on('notification', function (notification:any){


            if(location.pathname != "/notifications"){
                dispatch(setAppBasic({
                    ...appBasic,
                    notification:{
                        hasNotification:true
                    }}
                ));
            }


            let notificationsArray:any = notifications?.data.notifications

            if(notificationsArray && notification){

                notificationsArray.unshift(JSON.parse(notification))
                dispatch(setNotifications(notificationsArray));

            }
        });
        socket.on('dashboard-data', function (notification:any){


            dispatch(setAppBasic({
                ...appBasic,
                dashboard:{
                    hasNewTrip:true
                }}
            ));
        });
        socket.on('trip-delete', function (notification:any){


            dispatch(setAppBasic({
                ...appBasic,
                trip:{
                    hasDeleted:true
                }}
            ));
        });
        socket.on('activity', function (activityData:any){

            let tripData = cloneDeep(trip)



            if(tripData && tripData._id == activityData.tripId){

                let foundIndex = tripData.destinations.findIndex(destination => destination._id == activityData.destinationId);

                if(foundIndex >= 0){

                    tripData.destinations[foundIndex].activities?.push(JSON.parse(activityData.activity))

                    dispatch(setTrip(tripData));
                }


            }

        });
        socket.on('activities', function (activitiesData:any){

            let tripData = cloneDeep(trip)


            if(tripData && tripData._id == activitiesData.tripId){

                let foundIndex = tripData.destinations.findIndex(destination => destination._id == activitiesData.destinationId);

                if(foundIndex >= 0){

                    tripData.destinations[foundIndex].activities = JSON.parse(activitiesData.activities)

                    dispatch(setTrip(tripData));
                }


            }

        });



        socket.on('ai-attractions', function (attractionData:any){



            let tripData = cloneDeep(trip)



            if(tripData && tripData._id == attractionData.tripId){

                let foundIndex = tripData.destinations.findIndex(destination => destination._id == attractionData.destinationId);

                if(foundIndex >= 0){

                    tripData.destinations[foundIndex] = attractionData.destination

                    toastMessage(`AI Suggestion generation completed for ${tripData.destinations[foundIndex].name} in ${tripData.name} `,'')


                    try {


                        dispatch(setTrip(tripData));
                        dispatch(
                            setAppBasic({
                                ...appBasic,
                                aiWaiting: {
                                    _trip: attractionData.tripId,
                                    _destination: attractionData.destinationId,
                                    category: attractionData.category,
                                    status: 'completed',
                                },
                            })
                        );
                    } catch (error) {
                        console.error('Error dispatching actions:', error);
                    }

                }


            }

        });

        socket.on('ai-checklists', function (checklistsData:any){



            let tripData = cloneDeep(trip)


            if(tripData && tripData._id == checklistsData.tripId){

                tripData.checkListSuggestions = checklistsData.suggestions



                toastMessage(`AI Checklist generation completed  in ${tripData.name} `,'')



                dispatch(setTrip(tripData));

                dispatch(setAppBasic({
                    ...appBasic,
                    aiCheckListWaiting:{
                        _trip:tripData._id,
                        status:'completed',
                    }}
                ));


            }

        });
        return () => {
            socket.off("message");
            socket.off("notification");
            socket.off("activity");
            socket.off("activities");
            socket.off("ai-attractions");
            socket.off("ai-checklists");
        }


    }, );



    useEffect(() => {

        if (messages && newMessage) {


            let messageContentsClone = cloneDeep(messages)

            let hasNotification = false
            let newMessageContent: any = messageContentsClone.map(function (messageContent) {

                if (messageContent._id === newMessage._id) {


                    let hasNewMessageCount = messageContent.hasNewMessageCount ? messageContent.hasNewMessageCount + 1 : 1

                    if(appBasic?.message?.activeMessageId == newMessage._id){
                        hasNewMessageCount = 0
                    }
                    if(hasNewMessageCount){
                        hasNotification = true
                    }

                    let conversations = messageContent.conversations
                    conversations.push(newMessage.conversation)

                    return ({
                        ...messageContent,
                        hasNewMessageCount: hasNewMessageCount,
                        conversations: conversations,
                    })
                } else {
                    return messageContent
                }

            });


            if(location.pathname != "/messages"){
                dispatch(setAppBasic({
                    ...appBasic,
                    message:{
                        hasNotification:hasNotification
                    }}
                ));
            }

            setMessageContents(newMessageContent)

        }
    }, [newMessage]);



    useEffect(() => {
        if(messageContents){


            dispatch(setMessages(messageContents));
        }
    }, [messageContents]);



    let page = find(pages, {path:location.pathname});


    const { width } = useViewport();
    const breakpoint = 620;
    return (

        <div className="container-fluid">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ page?.title}</title>
            </Helmet>
            <div className="row">
                {

                    width < breakpoint && location.pathname.includes("/trip/")?

                        ""
                        :

                        <Sidebar socket={socket} userData={userData}/>

                }
                {React.cloneElement(children, {socket: socket})}



                {process.env.REACT_APP_MODE == "local"?
                    <Chatbot/> : ''
                }



            </div>
            <Toaster />

        </div>

    );
};
export default FeatureContainer;
