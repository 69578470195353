import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {CurrenciesResponse} from '../../interfaces/country.interface';

const currencies = createSlice({
    name: 'currencies',
    initialState: null as CurrenciesResponse | null,
    reducers: {
        setCurrencies(state, { payload }: PayloadAction<CurrenciesResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setCurrencies } = currencies.actions;
export default currencies.reducer;
