import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const Media: React.FC = () => {
    const { control, setValue, getValues, formState: { errors } } = useFormContext<TourPackage>();

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {


    };

    const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {


    };

    const renderImageUploads = () => {


    };

    const renderVideoUploads = () => {


    };

    return (
        <div>
            <h2>Media</h2>

            <Button onClick={() => setValue('media.images', [...getValues('media.images'), ''])}>Add Image</Button>
            <Button onClick={() => setValue('media.videos', [...getValues('media.videos'), ''])}>Add Video</Button>
        </div>
    );
};

export default Media;
