import React, {FC, useEffect, useState} from 'react';
import './logs.scss'
import http from "../../../services/api";
import {User, UserResponse} from "../../../interfaces/user.interface";
import {user_logs_url} from "../../../services/api.utils";
import {setLogs} from "../logs/logsSlice";
import {useAppDispatch} from "../../../store";
import {Log, LogsResponse} from "../../../interfaces/log.interface";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import {eSMoment} from "../../../util";

const Logs: FC = () => {
    const { logs} = useSelector((state: RootState) => state);

    const [loading, setLoading] = useState(false);
    const [logsData, setLogsData] = useState<LogsResponse>();
    const dispatch = useAppDispatch();


    useEffect(() => {
        setLoading(true);


        if (logs == null) {
            http
                .get<null, LogsResponse>(user_logs_url)
                .then((res) => {
                    if(res.status == 200){
                        setLogsData(res)
                        dispatch(setLogs(res));
                    }
                }).catch((error) => {
                console.log(error);
            })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLogsData(logs)
            setLoading(false);
        }

    }, [dispatch]);


    return (

        <div className="card mb-5 mb-lg-10 log-container">

            <div className="card-header border-0">

                <div className="card-title">
                    <h3 className="text-dark text-hover-primary fs-3 fw-bolder me-1">Login Sessions</h3>
                </div>


                <div className="card-toolbar">


                </div>

            </div>


            <div className="card-body p-0">

                {!loading?
                    <div className="table-responsive">

                        <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">

                            <thead className="border-gray-200 fs-5 text-gray-700  ">
                            <tr>
                                <th className="">Location</th>
                                <th className="">Device</th>
                                <th className="">IP Address</th>
                                <th className="">Time</th>
                            </tr>
                            </thead>


                            <tbody className="fw-6  text-gray-600">

                            {logsData?.data.logs.map(function (logItem, key){

                                var startDate = eSMoment(logItem.createdAt, );
                                var endDate = eSMoment();

                                var dayDiff = endDate.diff(startDate, 'days');


                                return(
                                    <tr key={key}>
                                        <td>
                                            <a href="#" className="text-hover-primary text-gray-600">{logItem.ip.city}, {logItem.ip.country}</a>
                                        </td>
                                        <td>{logItem.userAgent.browser} - {logItem.userAgent.os}</td>
                                        <td>{logItem.ip.ipAddress}</td>
                                        <td>{dayDiff >= 1? eSMoment(logItem.createdAt).format("DD MMMM [at]  LT"): eSMoment(logItem.createdAt).fromNow()}</td>
                                    </tr>

                                )
                            })}

                            </tbody>

                        </table>

                    </div>

                :

                "Loading..."}


            </div>

        </div>

    );
};
export default Logs;
