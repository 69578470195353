import React, {FC, useEffect, useRef, useState} from 'react';
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";

import './messages.css'
import MessagesSidebar from "./MessagesSidebar";
import http from "../../services/api";
import {message_url, messages_url, trip_url, user_url} from "../../services/api.utils";
import {useSelector} from "react-redux";
import {RootState} from "../../rootReducer";
import {
    Conversation,
    MessageInterface,
    MessagesResponse,
    NewMessageInterface
} from "../../interfaces/message.interface";
import {setMessages} from "./messagesSlice";
import {useAppDispatch} from "../../store";
import {MinimalUser, User, UserResponse} from "../../interfaces/user.interface";
import {eSMoment, getImgUrl, showAlert} from "../../util";
import dayjs from "dayjs";
import Modal from "react-bootstrap/cjs/Modal";
import MemberItem from "../trips/cards/MemberItem";
import {useNavigate, useLocation} from "react-router-dom";
import QuillEditor from "../editor/QuillEditor";
import {Socket} from "socket.io-client";
import {cloneDeep} from "lodash";
import {setAppBasic} from "../../app/appBasicSlice";
import Loader from "../loader/Loader";


import EmptyMessagesImg from "../../images/illustration-icons/empty-messages.svg";
import EmptyTripsImg from "../../images/illustration-icons/empty-trips.svg";

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}


const Messages: FC = () => {

    const { messages } = useSelector((state: RootState) => state);
    const { user } = useSelector((state: RootState) => state);
    const userId =user?.data.userId

    const location = useLocation();

    const dispatch = useAppDispatch();
    const [temp, setTemp] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [showOptions, setShowOptions] = useState("");
    const [newMessageContent, setNewMessageContent] = useState<string|null>("");
    const [messageContents, setMessageContents] = useState<[MessageInterface]|null>(messages);
    const [activeMessage, setActiveMessage] = useState<MessageInterface>();
    const [activeMessageUsers, setActiveMessageUsers] = useState<[MinimalUser]>();
    const [replyConversation, setReplyConversation] = useState<Conversation>();

    const [showMembersModel, setMembersModel] = useState(false);

    const bottomRef = useRef<null | HTMLDivElement>(null);


    const navigate = useNavigate();
    const { width } = useViewport();
    const breakpoint = 620;

    useEffect(() => {

        if(messages == null){
            getMessagesData()
        }

    }, []);

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [activeMessage]);

    useEffect(() => {



        setMessageContents(messages)

    }, [messages]);

    useEffect(() => {

        if(messages){
           // setMessageContents(messages)
            let index = 0


            if(activeMessage){

                let index = messages.findIndex(messageItem => messageItem._id == activeMessage._id);
                setActiveMessage(messages[index])

                dispatch(setAppBasic({message:{
                        activeMessageId:messages[index]._id
                    }}
                ));

                updateMessageUnReadStatus(messages[index])
            }

            let users:any = [];
            messages[index]._trip.members.map(function (member){

                users.push({
                    _id: member._id,
                    firstName: member.userId?.firstName,
                    lastName: member.userId?.lastName,
                    userName: member.userId?.userName,
                    cover : {
                        "originalName": member.userId?.cover?.originalName,
                        "generatedName": member.userId?.cover?.generatedName,
                        "folder": member.userId?.cover?.folder,
                        "url": member.userId?.cover?.url,
                    }
                })

            })
            setActiveMessageUsers(users)

        }

    }, [messageContents]);

    const handleMembersModelClose = () => setMembersModel(false);
    const handleMembersModelShow = () => setMembersModel(true);



    const getMessagesData = () => {

        http
            .get<null,MessagesResponse>(messages_url)
            .then((res) => {

                if(res.status == 200){


                    let messageContentsClone = cloneDeep(res.data.messages)


                    if(messageContentsClone.length){

                        let hasNotification = false
                        let messageContentsCloneUpdated:any = messageContentsClone.map(function (messageContent,key){

                            let count = 0;
                            messageContent.conversations.map(function (conversation){

                                conversation.users.map(function (user){

                                    console.log("userId",userId)
                                    console.log("userId user._user",user._user)
                                    if(userId == user._user && user.unread){
                                        count++;
                                        hasNotification = true
                                    }
                                })
                            })
                            messageContent.hasNewMessageCount= count

                            return messageContent
                        })


                        if(location.pathname != "/messages"){

                            dispatch(setAppBasic({message:{
                                    hasNotification:hasNotification
                                }}
                            ));
                        }

                        dispatch(setMessages(messageContentsCloneUpdated));
                        setMessageContents(messageContentsCloneUpdated)
                    }else{

                        dispatch(setMessages(null));

                    }



                }

            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {


                setLoading(false);

            });

    }

    const updateActiveMessage = (message: MessageInterface) => {

        setNewMessageContent("")

        let users:any = [];
            message._trip.members.map(function (member){


                if(member.userId?._id !== userId){

                    users.push({
                        _id: member.userId?._id,
                        firstName: member.userId?.firstName,
                        lastName: member.userId?.lastName,
                        userName: member.userId?.userName,
                        cover : {
                            "originalName": member.userId?.cover?.originalName,
                            "generatedName": member.userId?.cover?.generatedName,
                            "folder": member.userId?.cover?.folder,
                            "url": member.userId?.cover?.url,
                        }
                    })
                }


        })
        if(messages){
            let messageContentsClone = cloneDeep(messages)

            let foundIndex = messageContentsClone.findIndex(messageItem => messageItem._id == message._id);

            if(messageContentsClone[foundIndex].hasNewMessageCount){
                messageContentsClone[foundIndex].hasNewMessageCount = 0
                dispatch(setMessages(messageContentsClone));
            }
            setActiveMessage(messageContentsClone[foundIndex])
            dispatch(setAppBasic({message:{
                    activeMessageId:messageContentsClone[foundIndex]._id
                }}
            ));


        }

        setActiveMessage(message)



         setActiveMessageUsers(users)

        updateMessageUnReadStatus(message)
    }

/*    useEffect(()=>{


        if(newMessageContent && newMessageContent?.length > 0){
            sendNewMessage()
        }
    },[temp])*/


    const updateMessageUnReadStatus = (message: MessageInterface) => {

        http
            .put<null, MessagesResponse>(`${message_url}/${message._id}`, {})
            .then((res) => {
                if (res) {

                    let messageContentsClone = cloneDeep(messages)

                    if(messageContentsClone){

                        let hasNotification = false


                        messageContentsClone.map(function (messageContent,key){

                            if(messageContent._id != message._id){

                                messageContent.conversations.map(function (conversation){

                                    conversation.users.map(function (user){

                                        if(userId == user._user){
                                            hasNotification = true
                                        }
                                    })
                                })
                            }else{
                                hasNotification = false
                            }
                        })

                        if(location.pathname != "/messages"){
                            dispatch(setAppBasic({message:{
                                    hasNotification:hasNotification
                                }}
                            ));

                        }

                        if(activeMessage){
/*

                            let index = messageContentsClone.findIndex(messageItem => messageItem._id == activeMessage._id);
                            messageContentsClone[index].hasNewMessageCount = 0
                            dispatch(setMessages(messageContentsClone));

*/

                        }

                    }



                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

                setSending(false)
            });
    };


    const sendNewMessage = (data:string) => {


        console.log("newMessageContent",data)
        console.log("newMessageContent sending",sending)

        if(data && sending == false){

            setSending(true)
            let messageData={
                message: data,
                parentMessage: replyConversation? replyConversation.message: "",
                conversationsType: replyConversation? "reply":"direct"
            }
            http
                .post<null, MessagesResponse>(message_url+"/"+activeMessage?._id, messageData)
                .then((res) => {
                    if (res) {
                        const { data,status,message} = res;


                        if(status == 200){
                            setReplyConversation(undefined)
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                    setSending(false)
                    setNewMessageContent("")
                });
        }
    };




    return (

        <main className="col-md-11 ms-sm-auto col-lg-11 px-md-4 container-main">
            <Header title="Message" />
            <div className="chartjs-size-monitor">
                <div className="chartjs-size-monitor-expand">
                    <div className=""></div>
                </div>
                <div className="chartjs-size-monitor-shrink">
                    <div className=""></div>
                </div>
            </div>

            {loading?


                <div  className="card card-stretch bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '88vh'}}>
                    <div className="card-body d-flex flex-column pb-5 pt-3 mb-5 justify-content-center">
                        <Loader cssClass="normal-loader"/>
                    </div>
                </div>
                :

                messages && messages.length?

                    <div className="row">
                        <div className="col-md-4">


                                {loading?


                                    <div  className="card card-stretch bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '88vh'}}>
                                        <div className="card-body d-flex flex-column pb-5 pt-3 mb-5 justify-content-center">
                                            <Loader cssClass="normal-loader"/>
                                        </div>
                                    </div>
                                    :

                                    messages && messages.length?
                                        <div className="card"  style={{ height: '88vh'}}>
                                            <MessagesSidebar activeMessage={activeMessage} messages={messages}
                                                             updateActiveMessage={updateActiveMessage}/>

                                        </div>


                               :

                                    <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '80vh'}}>
                                        <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-40px'}}>

                                            <div className="text-center p-2 mt-1 mb-0">
                                                <p className="fs-5 text-dark fw-bolder">No data available</p>
                                                <p className="text-gray-400">Please check after adding planning or activities
                                                    from <span className="fw-bolder">destination dashboard</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        <div className="col-md-8">
                            <div className="card">
                                {messages && activeMessage?
                                    <>

                                        <div className="py-2 px-4 d-none d-lg-block" style={{  borderBottom: '1px dashed #e4e6ef'}}>
                                            <div className="d-flex align-items-center py-1">
                                                <div className="position-relative  cursor-pointer" onClick={function () {
                                                    navigate("/trip/"+activeMessage?._trip._id);
                                                }}>
                                                    {activeMessage._trip.cover ?
                                                        <img src={activeMessage._trip.cover.url}
                                                             className="rounded-circle mr-1"
                                                             alt={activeMessage._trip.name} width="40" height="40"/>
                                                        :
                                                        <div className="symbol symbol-45px symbol-circle"><span
                                                            className="symbol-label bg-light-danger text-danger fs-6 fw-bolder  text-capitalize">{(activeMessage._trip.name).charAt(0)}</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="flex-grow-1 px-3 cursor-pointer"  onClick={function () {
                                                    navigate("/trip/"+activeMessage?._trip._id);
                                                }}>
                                                    <strong>{(activeMessage._trip.name).length > 40? (activeMessage._trip.name).substring(0,40) + '...' : activeMessage._trip.name}</strong>
                                                    <div
                                                        className="text-muted small">{dayjs(activeMessage._trip.destinations[0].startDate).format('DD MMMM YY')} - {dayjs(activeMessage._trip.destinations[activeMessage._trip.destinations.length-1].endDate).format('DD MMMM YY')}</div>
                                                </div>
                                                <div className="symbol-group symbol-hover"  onClick={handleMembersModelShow}>

                                                    {activeMessage._trip.members.slice(0,3).map(function (member,key) {

                                                        return(
                                                            <div className="symbol symbol-35px symbol-circle cursor-pointer" key={key}>
                                                                {member?.userId?.cover?
                                                                    <img alt="Pic" src={getImgUrl(member.userId.cover?.generatedName,member.userId._id,'50x')} />
                                                                    :
                                                                    <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-uppercase">{member.userId ? (member?.userId?.firstName).charAt(0): ""}</span>

                                                                }
                                                            </div>
                                                        )

                                                    })}
                                                    {activeMessage._trip.members.length> 3 ?
                                                        <a className="symbol symbol-35px symbol-circle cursor-pointer">
                                                            <span className="symbol-label fs-8 fw-bolder" >+{(activeMessage._trip.members.length)-1}</span>
                                                        </a>
                                                        : ""}


                                                </div>
                                            </div>
                                        </div>


                                        <div className="position-relative"  style={{ minHeight: "67vh"}}>
                                            <div className="chat-messages p-4 text-dark" style={{
                                                backgroundColor: '#f5f8fa',
                                                margin: '10px',
                                                borderRadius: '15px',
                                            }}>
                                                <div className="quill">
                                                    <div className="ql-snow">
                                                        <div className="ql-editor" style={{minHeight: 'auto'}}>
                                                            {activeMessage.conversations?.map(function (conversation, key) {

                                                                if(userId && userId == conversation._user._id){

                                                                    if(conversation.conversationsType == "direct"){
                                                                        return( <div className="chat-message-right pb-4"  onMouseOver={function () {
                                                                            setShowOptions(conversation._id)
                                                                        }} onMouseOut={function () {
                                                                            setShowOptions("")
                                                                        }}>
                                                                            <div className="symbol symbol-45px symbol-circle">
                                                                                {conversation?._user?.cover ?
                                                                                    <img
                                                                                        src={getImgUrl(conversation?._user?.cover?.generatedName,conversation?._user?._id,'50x')}
                                                                                        className="rounded-circle mr-1"
                                                                                        alt={conversation?._user?.firstName} width="40"
                                                                                        height="40"/>
                                                                                    :
                                                                                    <span className="symbol-label bg-white text-danger fs-6 fw-bolder text-uppercase">{(conversation?._user?.firstName).charAt(0)}</span>

                                                                                }
                                                                                <div className="text-muted small text-nowrap mt-2">{eSMoment(conversation.createdAt).format('hh.mm a')}</div>
                                                                            </div>
                                                                            <div className="flex-shrink-1 bg-white rounded py-2 px-3 mx-3 message-content">
                                                                                <div className="font-weight-bold mb-1"><strong>You</strong></div>
                                                                                <div dangerouslySetInnerHTML={{__html: conversation?.message}} />
                                                                            </div>
                                                                            <div className={`flex-shrink-1 conversation-options py-4  ${showOptions == conversation._id? "show": "hide"}`}>
                                                                                <i className="bi bi-reply-fill fs-3 cursor-pointer" onClick={function () {
                                                                                    setReplyConversation(conversation)
                                                                                }}></i>
                                                                            </div>
                                                                        </div>)
                                                                    }else if(conversation.conversationsType == "reply"){
                                                                        return( <div className="chat-message-right-reply"  onMouseOver={function () {
                                                                            setShowOptions(conversation._id)
                                                                        }} onMouseOut={function () {
                                                                            setShowOptions("")
                                                                        }}>
                                                                            <div className="chat-message-right pb-3"
                                                                                 style={{borderRight: "3px solid #ccc",paddingRight:"10px" }} >
                                                                                <div
                                                                                    className="flex-shrink-1 bg-light rounded py-2 px-3">
                                                                                    <div className=" mb-1">Replied to
                                                                                    </div>
                                                                                    <div dangerouslySetInnerHTML={{__html: conversation?.parentMessage}} />

                                                                                </div>
                                                                                <div
                                                                                    className="flex-shrink-1 conversation-options py-4  hide"></div>
                                                                            </div>
                                                                            <div className="chat-message-right pb-4">
                                                                                <div className="symbol symbol-45px symbol-circle">
                                                                                    {conversation?._user?.cover ?
                                                                                        <img
                                                                                            src={getImgUrl(conversation?._user?.cover?.generatedName,conversation?._user?._id,'50x')}
                                                                                            className="rounded-circle mr-1"
                                                                                            alt={conversation?._user?.firstName} width="40"
                                                                                            height="40"/>
                                                                                        :
                                                                                        <span className="symbol-label bg-white text-danger fs-6 fw-bolder text-uppercase">{(conversation?._user?.firstName).charAt(0)}</span>

                                                                                    }
                                                                                    <div className="text-muted small text-nowrap mt-2">{eSMoment(conversation.createdAt).format('hh.mm a')}</div>
                                                                                </div>
                                                                                <div
                                                                                    className="flex-shrink-1 bg-white rounded py-2 px-3 mx-3">
                                                                                    <div
                                                                                        className="font-weight-bold mb-1">
                                                                                        <strong>You</strong></div>
                                                                                    <div dangerouslySetInnerHTML={{__html: conversation?.message}} />
                                                                                </div>
                                                                                <div className={`flex-shrink-1 conversation-options py-4  ${showOptions == conversation._id? "show": "hide"}`}>
                                                                                    <i className="bi bi-reply-fill fs-3 cursor-pointer" onClick={function () {
                                                                                        setReplyConversation(conversation)
                                                                                    }}></i>
                                                                                </div>
                                                                            </div>

                                                                        </div>)
                                                                    }else{


                                                                        if(conversation.activity){
                                                                            return(   <div className="chat-message-right pb-4"  onMouseOver={function () {
                                                                                    setShowOptions(conversation._id)
                                                                                }} onMouseOut={function () {
                                                                                    setShowOptions("")
                                                                                }}>
                                                                                    <div className="symbol symbol-45px symbol-circle">
                                                                                        {conversation?._user?.cover ?
                                                                                            <img
                                                                                                src={getImgUrl(conversation?._user?.cover?.generatedName,conversation?._user?._id,'50x')}
                                                                                                className="rounded-circle mr-1"
                                                                                                alt={conversation?._user?.firstName} width="40"
                                                                                                height="40"/>
                                                                                            :
                                                                                            <span className="symbol-label bg-white text-danger fs-6 fw-bolder text-uppercase">{(conversation?._user?.firstName).charAt(0)}</span>

                                                                                        }

                                                                                        <div className="text-muted small text-nowrap mt-2">{eSMoment(conversation.createdAt).format('hh.mm a')}</div>
                                                                                    </div>
                                                                                    <div className="flex-shrink-1 bg-white rounded py-2 px-3 mx-3 planning-timeline">
                                                                                        <div className="font-weight-bold mb-1"><strong>You</strong></div>
                                                                                        <div className=" mb-1">{conversation.message}</div>


                                                                                    </div>
                                                                                    <div className={`flex-shrink-1 conversation-options py-4  ${showOptions == conversation._id? "show": "hide"}`}>
                                                                                        <i className="bi bi-reply-fill fs-3 cursor-pointer" onClick={function () {
                                                                                            setReplyConversation(conversation)
                                                                                        }}></i>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }

                                                                    }


                                                                }else{

                                                                    if(conversation.conversationsType == "direct"){
                                                                        return (<div className="chat-message-left pb-4"  onMouseOver={function () {
                                                                                setShowOptions(conversation._id)
                                                                            }} onMouseOut={function () {
                                                                                setShowOptions("")
                                                                            }}>
                                                                                <div className="symbol symbol-45px symbol-circle">
                                                                                    {conversation?._user?.cover ?
                                                                                        <img
                                                                                            src={getImgUrl(conversation?._user?.cover?.generatedName,conversation?._user?._id,'50x')}
                                                                                            className="rounded-circle mr-1"
                                                                                            alt={conversation?._user?.firstName} width="40"
                                                                                            height="40"/>
                                                                                        :
                                                                                        <span className="symbol-label bg-white text-danger fs-6 fw-bolder text-uppercase">{(conversation?._user?.firstName).charAt(0)}</span>

                                                                                    }
                                                                                    <div className="text-muted small text-nowrap mt-2">{eSMoment(conversation.createdAt).format('hh.mm a')}</div>
                                                                                </div>
                                                                                <div className="flex-shrink-1 bg-white  py-2 px-3 mx-3 message-content">
                                                                                    <div className="font-weight-bold mb-1"><strong>{conversation._user.firstName} {conversation._user.lastName}</strong></div>

                                                                                    <div className={``} dangerouslySetInnerHTML={{__html:  conversation.message}} />
                                                                                </div>
                                                                                <div className={`flex-shrink-1 conversation-options py-4  ${showOptions == conversation._id? "show": "hide"}`}>
                                                                                    <i className="bi bi-reply-fill fs-3 cursor-pointer" onClick={function () {
                                                                                        setReplyConversation(conversation)
                                                                                    }}></i>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }else if(conversation.conversationsType == "reply"){
                                                                        return (
                                                                            <div className="chat-message-left-reply">
                                                                                <div className="chat-message-left pb-3"
                                                                                     style={{borderLeft: "3px solid #ccc",paddingLeft:"10px" }} >
                                                                                    <div
                                                                                        className="flex-shrink-1 bg-white rounded py-2 px-3">
                                                                                        <div className=" mb-1">Replied to
                                                                                        </div>
                                                                                        <div dangerouslySetInnerHTML={{__html: conversation.parentMessage}} />
                                                                                    </div>
                                                                                    <div
                                                                                        className="flex-shrink-1 conversation-options py-4  hide"></div>
                                                                                </div>
                                                                                <div className="chat-message-left pb-4">
                                                                                    <div className="symbol symbol-45px symbol-circle">
                                                                                        {conversation?._user?.cover ?
                                                                                            <img
                                                                                                src={getImgUrl(conversation?._user?.cover?.generatedName,conversation?._user?._id,'50x')}
                                                                                                className="rounded-circle mr-1"
                                                                                                alt={conversation?._user?.firstName} width="40"
                                                                                                height="40"/>
                                                                                            :
                                                                                            <span className="symbol-label bg-white text-danger fs-6 fw-bolder text-uppercase">{(conversation?._user?.firstName).charAt(0)}</span>

                                                                                        }
                                                                                        <div className="text-muted small text-nowrap mt-2">{eSMoment(conversation.createdAt).format('hh.mm a')}</div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="flex-shrink-1 bg-white rounded py-2 px-3 mx-3">
                                                                                        <div
                                                                                            className="font-weight-bold mb-1">
                                                                                            <strong>{conversation?._user?.firstName}</strong></div>
                                                                                        <div dangerouslySetInnerHTML={{__html: conversation?.message}} />
                                                                                    </div>
                                                                                    <div className={`flex-shrink-1 conversation-options py-4  ${showOptions == conversation._id? "show": "hide"}`}>
                                                                                        <i className="bi bi-reply-fill fs-3 cursor-pointer" onClick={function () {
                                                                                            setReplyConversation(conversation)
                                                                                        }}></i>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }else{

                                                                        if(conversation.activity){
                                                                            return(


                                                                                <div className="chat-message-left pb-4"  onMouseOver={function () {
                                                                                    setShowOptions(conversation._id)
                                                                                }} onMouseOut={function () {
                                                                                    setShowOptions("")
                                                                                }}>
                                                                                    <div className="symbol symbol-45px symbol-circle">
                                                                                        {conversation?._user?.cover ?
                                                                                            <img
                                                                                                src={getImgUrl(conversation?._user?.cover?.generatedName,conversation?._user?._id,'50x')}
                                                                                                className="rounded-circle mr-1"
                                                                                                alt={conversation?._user?.firstName} width="40"
                                                                                                height="40"/>
                                                                                            :
                                                                                            <span className="symbol-label bg-white text-danger fs-6 fw-bolder text-uppercase">{(conversation?._user?.firstName).charAt(0)}</span>

                                                                                        }
                                                                                        <div className="text-muted small text-nowrap mt-2">{eSMoment(conversation.createdAt).format('hh.mm a')}</div>
                                                                                    </div>
                                                                                    <div className="flex-shrink-1 bg-white rounded py-2 px-3 mx-3 planning-timeline">
                                                                                        <div className="font-weight-bold mb-1"><strong>{conversation._user.firstName} {conversation._user.lastName}</strong></div>
                                                                                        {conversation.message}


                                                                                    </div>
                                                                                    <div className={`flex-shrink-1 conversation-options py-4  ${showOptions == conversation._id? "show": "hide"}`}>
                                                                                        <i className="bi bi-reply-fill fs-3 cursor-pointer" onClick={function () {
                                                                                            setReplyConversation(conversation)
                                                                                        }}></i>
                                                                                    </div>
                                                                                </div>

                                                                            )
                                                                        }

                                                                    }


                                                                }

                                                            })}



                                                        </div>
                                                    </div>
                                                    <div ref={bottomRef} />

                                                </div>

                                            </div>
                                        </div>

                                        <div className="flex-grow-0 py-3 px-4 " style={{ bottom: "0px", position: "sticky", backgroundColor: "#fff",  borderTop: '1px dashed #e4e6ef', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px'}}>

                                            {replyConversation ?
                                                <div><span>Replying to</span>
                                                    <div className="alert alert-reply-message alert-dismissible"
                                                         role="alert">
                                                        <div dangerouslySetInnerHTML={{__html: replyConversation.message}} />

                                                        <button type="button" className="btn-close"
                                                                data-bs-dismiss="alert" aria-label="Close" onClick={function () {

                                                            setReplyConversation(undefined)
                                                        }}></button>
                                                    </div>

                                                </div>
                                                :
                                                ""}




                                            {activeMessage && activeMessageUsers?
                                                <div className="input-group d-flex send-message-container flex-nowrap">
                                                    <QuillEditor users={activeMessageUsers}

                                                                 type={`chat`}
                                                                 placeholder={`Write something...`}
                                                                 initialValue={newMessageContent}
                                                                 onEditorChange={ (value: any, usersIds: any, key: string) => {

                                                                     setNewMessageContent(value)
                                                                 }}
                                                    />
                                                    <button className="btn btn-primary" onClick={()=>{
                                                        if(newMessageContent){
                                                            sendNewMessage(newMessageContent)
                                                        }
                                                    }}>{sending? "Sending...": "Send"}</button>
                                                </div>
                                                :""}

                                        </div>
                                        <Modal show={showMembersModel} onHide={handleMembersModelClose} animation={false}>
                                            <Modal.Header>
                                                <Modal.Title>Members</Modal.Title>
                                                <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={handleMembersModelClose}>
                                                    <i className="bi bi-x-circle fs-2 primary-color"></i>
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {activeMessage?._trip.members.map(function (user,key) {
                                                    return(
                                                        <MemberItem user={user} key={key} tripType={activeMessage?._trip.tripType} keyValue={key}/>
                                                    )
                                                })}

                                            </Modal.Body>
                                        </Modal>
                                    </>
                                    :


                                    <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '86vh'}}>
                                        <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-40px'}}>

                                            <div className="text-center p-2 mt-1 mb-0">

                                                <img src={EmptyMessagesImg} width={100}/>
                                                <p className="fs-5 text-dark fw-bolder">No message seleted</p>
                                                <p className="text-gray-400">Please check after adding planning or activities
                                                    from <span className="fw-bolder">destination dashboard</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>:
                    <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '80vh'}}>
                        <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-40px'}}>

                            <div className="text-center p-2 mt-1 mb-0">
                                <img src={EmptyTripsImg} width={100}/>
                                <p className="fs-5 text-dark fw-bolder">No data available</p>
                                <p className="text-gray-400">Please check after adding planning or activities
                                    from <span className="fw-bolder">destination dashboard</span>
                                </p>
                            </div>
                        </div>
                    </div> }
        </main>
    );
};
export default Messages;
