import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const BasicInfo: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();

    return (
        <div>
            <h2>Basic Information</h2>
            <Form.Group>
                <Form.Label>Package Name</Form.Label>
                <Controller
                    name="packageName"
                    control={control}
                    render={({ field }) => <Form.Control {...field} isInvalid={!!errors.packageName} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.packageName?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Description</Form.Label>
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.description} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.description?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Duration</Form.Label>
                <Controller
                    name="duration"
                    control={control}
                    render={({ field }) => <Form.Control {...field} isInvalid={!!errors.duration} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.duration?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );
};

export default BasicInfo;
