import React, {FC, useEffect, useState} from 'react';

import './joinTrip.css'

import iconLogo from "../../../images/logo.png";
import http from "../../../services/api";
import {TripCodeJoinResponse} from "../../../interfaces/trip.interface";
import {join_trip_url} from "../../../services/api.utils";
import {useNavigate, useParams} from "react-router-dom";
import Modal from "react-bootstrap/cjs/Modal";

type ParamTypes =  {
    joinCode: string
}
const JoinTrip: FC = () => {

    const [tripCode, setTripCode] = useState("");

    const [tripCodeStatus, setTripCodeStatus] = useState({
        status: 0,
        message: "",
        tripId: ""
    });

    const { joinCode } = useParams<ParamTypes>()
    const navigate = useNavigate();


    const sentJoinRequest = () => {

        http
            .put<null, TripCodeJoinResponse>(join_trip_url, {code:joinCode})
            .then((res) => {
                if (res) {
                    setTripCodeStatus({message: res.message, status: res.status, tripId: res.data.tripId})

                    if(res.status == 200){
                        setTripCode("")
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    };

    useEffect(() => {

        if(joinCode){
            sentJoinRequest()
        }

    },[])

    return (
        <main className="join-trip">
            <div className="text-center">

                <img className="d-block mx-auto mb-4" src={iconLogo} alt="" width="72" height="72" />

                {tripCodeStatus.status == 200?
                    <p className="text-success"> <h4>You have successfully added to trip</h4></p>
                    :
                    <p className="text-danger">{tripCodeStatus.message}</p>
                }

                <p className="cursor-pointer" onClick={function () {
                    navigate(tripCodeStatus.status == 200?"/trip/"+tripCodeStatus.tripId: "/dashboard");

                }}> Go to home</p>
            </div>
        </main>
    );
};
export default JoinTrip;
