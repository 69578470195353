import React from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const OptionalAddOns: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'optionalAddOns'
    });

    return (
        <div>
            <h2>Optional Add-Ons</h2>
            {fields.map((field, index) => (
                <div key={field.id}>
                    <Form.Group>
                        <Form.Label>Additional Activities</Form.Label>
                        <Controller
                            name={`optionalAddOns.${index}.additionalActivities`}
                            control={control}
                            render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.optionalAddOns?.[index]?.additionalActivities} />}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.optionalAddOns?.[index]?.additionalActivities?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Extra Services</Form.Label>
                        <Controller
                            name={`optionalAddOns.${index}.extraServices`}
                            control={control}
                            render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.optionalAddOns?.[index]?.extraServices} />}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.optionalAddOns?.[index]?.extraServices?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="danger" onClick={() => remove(index)}>Remove Add-On</Button>
                </div>
            ))}
            <Button variant="success" onClick={() => append({ additionalActivities: [], extraServices: [] })}>Add Add-On</Button>
        </div>
    );
};

export default OptionalAddOns;
