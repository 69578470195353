import React, {FC, useEffect, useState} from 'react';
/*

import './userPackages.scss'
import http from "../../services/api";
import {
    base_url,
    create_payment_intent,
    register_url,
    trips_url,
    user_packages,
    user_url
} from "../../services/api.utils";
import {setUserPackages} from "./userPackagesSlice";
import {
    UserPackage,
    UserPackageResponse,
    UserPackagesResponse,
    UserPopulatedPackage
} from "../../interfaces/userPackage.interface";
import {useSelector} from "react-redux";
import {RootState} from "../../rootReducer";
import {useAppDispatch} from "../../store";
import {useNavigate} from "react-router-dom";

import {showAlert} from "../../util";
import StripeSplitForm from "../payment/StripeSplitForm";
import filter from "lodash/filter";
import AddressContainer from "../payment/AddressContainer";
import {setUser} from "../account/userSlice";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
const stripePromise = loadStripe('pk_test_51L01j6SAbjDG3NmHqxmtwEU72wooQVJsgRJaKp1zRbR8DZ33dvFovmAk9c1aKYOFq0XOcHQVUtALIPBKLAWXrB2O00U0TJvPMi');


*/

import {Address, PaymentAddress, User, UserResponse} from "../../interfaces/user.interface";

type Props = {
    userData: User,
    fromSettings:boolean,
    updateUserData?:any
}
const UserPackageContainer: FC<Props> = ({userData,fromSettings,updateUserData}) => {
   /* const [loading, setLoading] = useState(true);
    const { userPackages } = useSelector((state: RootState) => state);
    const [selectedPackage, setSelectedPackage] = useState<UserPopulatedPackage>();
    const [isMonthly, setIsMonthly] = useState(true);
    const [selectedAddress, setSelectedAddress] = useState<PaymentAddress>();
    const [showAddress, setShowAddress] = useState(false);

    const [showCheckoutScreen, setShowCheckoutScreen] = useState(false);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();


    useEffect(() => {

        if(userPackages == null){

            setLoading(true)
            getPackages()
        }else{
            setSelectedPackage(userPackages.data.userPackages[0])

        }
    },[])

    console.log("userData",userData)

    const getPackages = ( ) => {
        http
            .get<null,UserPackagesResponse>(user_packages)
            .then((res) => {


                if(res.status == 200){


                    dispatch(setUserPackages(res))

                    setSelectedPackage(res.data.userPackages[0])

                }



            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {

                setLoading(false);
            });
    }


    let buttonWidth = userPackages?.data? (90/userPackages?.data?.userPackages.length)+"%" : "300px"

    const exitStripeForm = ( ) => {
        setShowCheckoutScreen(false)
        setSelectedPackage(userPackages?userPackages.data.userPackages[0]:undefined)

    }

    const selectAddress = ( addressData:PaymentAddress) => {
        setSelectedAddress(addressData)
    }

    const setAddressShow = (addressShowType:boolean) => {
        setSelectedAddress(undefined)
        setShowCheckoutScreen(false)
        setShowAddress(addressShowType)
    }
    const setShowCheckOut = () => {
        setShowCheckoutScreen(true)
        setShowAddress(false)
    }


    const updateFreePackage = () => {
        let userId = userData.userId?userData.userId:userData._id

        let data ={
            userPackageId:selectedPackage?._id,
            planType:"monthly",
            userId:userId,
        }
        http
            .put<User, UserResponse>(`${user_url}/${userId}/package`, data)
            .then((res) => {


                if(res.status == 200){

                    const userLocalStorage = localStorage.getItem("user");

                    let response = res
                    if(userLocalStorage){
                        let userLocalStorageData = JSON.parse(userLocalStorage)
                        response.data.accessToken = userLocalStorageData.data.accessToken
                    }


                    if(updateUserData){
                        updateUserData(res.data)
                    }

                    dispatch(setUser(response))

                    localStorage.setItem('user', JSON.stringify(response));
                    if(userData.userId){
                        navigate("/dashboard");
                    }else{

                        exitStripeForm()
                    }

                }



            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {

            });


    }


*/

        return (
        <div className="content flex-row-fluid packages-container" id="kt_content">
           {/*
            <>


                {showCheckoutScreen && selectedPackage?

                    showAddress ?
                        <AddressContainer setAddressShow={setAddressShow} setShowCheckOut={setShowCheckOut} userData={userData} selectAddress={selectAddress} updateUserData={updateUserData} />
                        :

                        <Elements stripe={stripePromise}>
                            <StripeSplitForm selectedAddress={selectedAddress} planType={isMonthly?"monthly":"yearly"} exitStripeForm={exitStripeForm} updateUserData={updateUserData} userData={userData} packageItem={selectedPackage} />
                        </Elements>
                    :
                    <div className="card" id="kt_pricing">

                        <div className="card-body p-lg-17">

                            <div className="d-flex flex-column">

                                <div className="row justify-content-center mb-5">
                                    <div className="col-lg-6">
                                        <div className="text-center ">
                                            <h4 className="fs-1 fw-bolder text-dark mb-5">Choose your Pricing plan</h4>
                                            <p className="text-muted">To achieve this, it would be necessary to have uniform
                                                grammar, pronunciation and more common words If several languages
                                                coalesce</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="nav-group nav-group-outline mx-auto mb-15" data-kt-buttons="true">
                                    <button  className={` ${isMonthly ? "active": ""} btn text-gray-400 fw-bolder px-6 py-3 me-2 `} onClick={() => {
                                        setIsMonthly(true)
                                    }}>Monthly
                                    </button>
                                    <button className={` ${isMonthly ? "": "active"} btn text-gray-400 fw-bolder px-6 py-3 me-2 `}  onClick={() => {
                                        setIsMonthly(false)
                                    }}>Annual
                                    </button>
                                </div>


                                <div className="row g-10 package-container">
                                    <div className={`mb-10 mb-lg-0 ${fromSettings? "col-5": "col-6"}`}>
                                        <div className="nav flex-column">
                                            {
                                                userPackages?.data.userPackages?.map(function (userPackage, key) {


                                                    return(<label
                                                        className={`${selectedPackage?._id == userPackage._id? "active": ""} nav-link  d-flex flex-stack text-start p-6 mb-6`} key={userPackage._id}>

                                                        <div className="d-flex align-items-center me-2">

                                                            <div
                                                                className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                                                                <input className="form-check-input" type="radio" name="plan"
                                                                       value={userPackage._id} checked={selectedPackage?._id == userPackage._id} onClick={() => {
                                                                    setSelectedPackage(userPackage)
                                                                }} />
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div
                                                                    className="d-flex align-items-center fs-2 fw-bolder flex-wrap">{userPackage.name}
                                                                </div>
                                                                <div className="text-gray-400">{userPackage.description}</div>
                                                            </div>

                                                        </div>
                                                        <div className="ms-5">
                                                            <span className="mb-2">{userPackage.currencyId.code}</span>
                                                            <span className="fs-3x fw-bold" data-kt-plan-price-month="39"
                                                                  data-kt-plan-price-annual="399">{isMonthly? userPackage.monthlyOfferPrice: userPackage.yearlyOfferPrice}</span>
                                                            <span className="fs-7 text-gray-400">/
                                        <span data-kt-element="period">Mon</span></span>
                                                        </div>

                                                    </label>)
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className={` ${fromSettings? "col-7": "col-6"}`}>


                                        <div className="tab-content rounded h-100 bg-light p-10" >
                                            <div className="tab-pane fade active show" id="kt_upgrade_plan_advanced"
                                                 role="tabpanel">

                                                {selectedPackage &&
                                                    <div className="pb-5">
                                                        <h2 className="fw-bolder text-dark fs-2">{selectedPackage.featureTitle} </h2>
                                                        <div className="text-muted ">{selectedPackage.featureDescription}</div>
                                                    </div>
                                                }

                                                { selectedPackage?
                                                    <>

                                                        <div className="pt-1" >
                                                            <div className="d-flex align-items-center mb-7">
                                                                <span className="fw-bold fs-5 text-gray-700 flex-grow-1">Total members can be added to trips</span>
                                                                <span className=" primary-color"> {selectedPackage.maxMembers}  </span>
                                                            </div>
                                                        </div>
                                                        <div className="pt-1" >
                                                            <div className="d-flex align-items-center mb-7">
                                                                <span className="fw-bold fs-5 text-gray-700 flex-grow-1">Total family members can be added to a account</span>
                                                                <span className=" primary-color"> {selectedPackage.maxFamily}  </span>
                                                            </div>
                                                        </div>
                                                        <div className="pt-1" >
                                                            <div className="d-flex align-items-center mb-7">
                                                                <span className="fw-bold fs-5 text-gray-700 flex-grow-1">Total trip can be added to a account</span>
                                                                <span className=" primary-color"> {selectedPackage.maxTrip}  </span>
                                                            </div>
                                                        </div>
                                                        <div className="pt-1" >
                                                            <div className="d-flex align-items-center mb-7">
                                                                <span className="fw-bold fs-5 text-gray-700 flex-grow-1">Total destination can be added to a trip</span>
                                                                <span className=" primary-color"> {selectedPackage.maxDestination}  </span>
                                                            </div>
                                                        </div>
                                                        <div className="pt-1" >
                                                            <div className="d-flex align-items-center mb-7">
                                                                <span className="fw-bold fs-5 text-gray-700 flex-grow-1">Total storage can be added to a account</span>
                                                                <span className=" primary-color"> {selectedPackage.maxStorage}  </span>
                                                            </div>
                                                        </div>

                                                    </>
                                                    : ""}
                                            </div>
                                        </div>



                                    </div>








                                </div>
                                <div className="row">


                                    <div
                                        className="w-100 d-flex flex-column flex-center rounded-3 pt-5 px-3" style={{ justifyContent: "flex-start"}}>


                                        {userData.userPackage?.packageId ?

                                            <button
                                                className={`${userData.userPackage?.packageId && userData.userPackage?.packageId._id == selectedPackage?._id ? "disabled" : ""}  btn btn-primary`}
                                                type="button" onClick={function () {

                                                if (userData.userPackage?.packageId && userData.userPackage?.packageId._id != selectedPackage?._id) {

                                                    setShowCheckoutScreen(true)
                                                }
                                            }} style={{width: buttonWidth}}>
                                                {userData.userPackage?.packageId && userData.userPackage?.packageId._id == selectedPackage?._id ? "Active plan": "  Upgrade Plan" }
                                            </button>

                                            :

                                            <button
                                                className={`  btn btn-primary`}
                                                type="button" onClick={function () {


                                                if(selectedPackage?.monthlyOfferPrice != "0" ||selectedPackage?.yearlyOfferPrice != "0"){

                                                    setShowAddress(true)
                                                    setShowCheckoutScreen(true)

                                                }else{


                                                    updateFreePackage()
                                                }

                                            }} style={{width: buttonWidth}}>
                                                Select Plan
                                            </button>
                                        }

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                }


                </>*/}

        </div>

    );
};
export default UserPackageContainer;
