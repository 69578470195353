import React, {FC, useEffect, useState} from 'react';
import '../trip.scss'
import DashboardMenuItem from "../cards/DashboardMenuItem";
import SearchMemberItem from "../../trips/cards/SearchMemberItem";
import http from "../../../services/api";
import {Trip, TripMember, TripMemberPopulated, TripResponse} from "../../../interfaces/trip.interface";
import {base_url, trip_url, upload_url, user_search_url} from "../../../services/api.utils";
import {setTrip} from "../tripSlice";
import filter from "lodash/filter";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch} from "../../../store";
import {UserSearch, UsersSearchResponse} from "../../../interfaces/user.interface";
import remove from "lodash/remove";
import SettingsSearchMemberItem from "../cards/settings/SettingsSearchMemberItem";
import {ImageUpload} from "../../fileupload/ImageUpload";
import Loader from "../../loader/Loader";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {UploadResponse} from "../../../interfaces/upload.interface";
import {showAlert} from "../../../util";

type ParamTypes =  {
    tripId: string,
    tripData:Trip,
    updateTripBasicData:any
}
const tripBasicSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[-a-zA-Z0-9\s]+$/, "Please enter a name with only spaces, hyphens, letters, and numbers")
        .min(5, "Please enter a name more than 5 characters")
        .required('Name required'),
    description: Yup.string()
});

const TripSettingsBasic: FC <ParamTypes>= ({tripData ,updateTripBasicData} ) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    const { handleSubmit:handleSubmitWizardOne, register:registerWizardOne, formState: { errors:errorsWizardOne } } = useForm<Trip>({
        resolver: yupResolver(tripBasicSchema),
    });
    const [tripBasicData, setTripBasicData] = useState(tripData);
    const [loading, setLoading] = useState(false);


    const [cover, setCover] = useState({} as UploadResponse);


    const [loadedCover, setLoadedCover] = useState({
        meta: {
            previewUrl: tripData && tripData.cover && tripData.cover.url?tripData.cover.url: ""
        }
    });
    const submitFormWizardOne = async (data: Trip) => {


        setLoading(true)
        await updateTripBasicData(data)
        setLoading(false)
    };


    const uploadCallback = (data:any) => {



        setLoadedCover(data)

        var formData = new FormData();
        formData.append("file", data.file);
        formData.append("folder", "cover");

        http
            .post<null, UploadResponse>(base_url+upload_url, formData)
            .then((res) => {
                if (res) {

                    const { data} = res;

                    setCover(res)

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });

    }


    return (

                    <form onSubmit={handleSubmitWizardOne(submitFormWizardOne)} className="trip-form">
                        <div className="row">

                            <div className="col-xl-12 col-md-12">

                                <div className="card card-stretch mb-5 mb-xxl-8">

                                    <div className="card-header align-items-center border-0 bg-white rounded mb-0">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bolder text-dark">Basic</span>
                                            <span className="text-gray-400 mt-1  fs-7">
                                                Change trip name and description
                                            </span>
                                        </h3>
                                        <div className="card-toolbar">

                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div className="card-body p-0 dashboard-menu ">
                                                <div className="row g-3  mb-5">


                                                    <div className="col-12">
                                                        <label htmlFor="name" className="form-label">Trip Name </label>
                                                        <input {...registerWizardOne("name")} type="text" value={tripBasicData.name} className={Object.keys(errorsWizardOne).length === 0? "form-control form-control-lg form-control-solid": errorsWizardOne.name? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="name"
                                                               placeholder="Awaiting Europe trip..."
                                                               onChange={(e) => {

                                                                   console.log("e.target.value,",e.target.value)
                                                                   setTripBasicData({
                                                                       ...tripBasicData,
                                                                       name: e.target.value,
                                                                   });

                                                               }}
                                                        />
                                                        {errorsWizardOne && errorsWizardOne.name && (
                                                            <div className="invalid-feedback show"> {errorsWizardOne.name.message} </div>
                                                        )}
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="email" className="form-label">Short description </label>
                                                        <textarea  {...registerWizardOne("description")} value={tripBasicData.description}  className="form-control form-control-lg form-control-solid" name="description" id="exampleFormControlTextarea1"
                                                                   onChange={(e) => {
                                                                       setTripBasicData({
                                                                           ...tripBasicData,
                                                                           description: e.target.value,
                                                                       });

                                                                   }}
                                                        ></textarea>
                                                    </div>


                                                </div>

                                                <div className="modal-footer">
                                                    <button className=" btn btn-primary shadow-none" disabled={loading?true : false} type="submit">{loading? "Saving..." :"Save" }</button>

                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-xl-5 col-md-5">
                                <div className="card card-stretch mb-5 mb-xxl-8">

                                    <div className="card-header align-items-center border-0 bg-white rounded mb-0">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bolder text-dark">Notifications</span>
                                            <span className="text-gray-400 mt-1  fs-7">
                                                Configure how you receive notifications
                                            </span>
                                        </h3>
                                        <div className="card-toolbar">

                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div className="card-body p-0 dashboard-menu ">
                                            <div className="row g-3  mb-5">
                                                <div className="col-12">
                                                    <div className="switch-toggle">
                                                        <label className="switch">
                                                            <input type="checkbox" />
                                                            <span className="slider"></span>

                                                        </label>
                                                        <div className={`text-container ms-2 cursor-pointer`}>
                                                            <p className={`title`}>App</p>
                                                            <p className={`description`}> Receive emails notifications</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row g-3  mb-5">
                                                <div className="col-12">
                                                    <div className="switch-toggle">
                                                        <label className="switch">
                                                            <input type="checkbox" />
                                                            <span className="slider"></span>

                                                        </label>
                                                        <div className={`text-container ms-2 cursor-pointer`}>
                                                            <p className={`title`}>Marketing</p>
                                                            <p className={`description`}> Enable marketing emails</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
*/}
                        </div>
                    </form>

    );
};
export default TripSettingsBasic;
