import React, {FC, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";


const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}

const TourPackages: FC = () => {


    const navigate = useNavigate();


    return (
        <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 ">
            <header className="header mx-2">

                <div
                    className="d-flex flex-column justify-content-between flex-md-row align-items-center p-3  mb-4 mt-3 header-container">

                    <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                        <h3 className="text-dark fw-bolder fs-4">Tour Packages</h3>
                    </div>
                    <div className="d-flex ">
                        <button className={`btn btn-primary`}
                                onClick={() => navigate('/business/package/create')}>Create New Package
                        </button>
                    </div>
                </div>

            </header>

            <div className={`container`}>

                <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer"
                     style={{height: '87vh'}}>
                    <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5">

                        <div className="text-center p-2 mt-1 mb-0">
                            <p className="text-gray-400">Please create a new trip to list here. </p>

                        </div>
                    </div>
                </div>
            </div>

        </main>


    );
};
export default TourPackages;
