import React, {FC, useEffect, useState} from 'react';
import './transactions.scss'
import http from "../../../services/api";
import {User, UserResponse} from "../../../interfaces/user.interface";
import {user_logs_url, user_transactions_url} from "../../../services/api.utils";
import {setTransactions} from "./transactionsSlice";
import {useAppDispatch} from "../../../store";
import {Log, LogsResponse} from "../../../interfaces/log.interface";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import {eSMoment} from "../../../util";
import {TransactionsResponse} from "../../../interfaces/transactions.interface";

const Transactions: FC = () => {
    const { transactions} = useSelector((state: RootState) => state);

    const [loading, setLoading] = useState(false);
    const [transactionsData, setTransactionsData] = useState<TransactionsResponse>();
    const dispatch = useAppDispatch();


    useEffect(() => {
        setLoading(true);


        if (transactions == null) {
            http
                .get<null, TransactionsResponse>(user_transactions_url)
                .then((res) => {
                    if(res.status == 200){
                        setTransactionsData(res)
                        dispatch(setTransactions(res));
                    }
                }).catch((error) => {
                console.log(error);
            })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setTransactionsData(transactions)
            setLoading(false);
        }

    }, [dispatch]);


    return (

        <div className="card mb-5 mb-lg-10 log-container">

            <div className="card-header border-0">

                <div className="card-title">
                    <h3 className="text-dark text-hover-primary fs-3 fw-bolder me-1">Transaction history</h3>
                </div>


                <div className="card-toolbar">


                </div>

            </div>


            <div className="card-body p-0">

                {!loading?
                    <div className="table-responsive">

                        <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">

                            <thead className="border-gray-200 fs-5 text-gray-700  ">
                            <tr>
                                <th className="">Transaction ID</th>
                                <th className="">Package</th>
                                <th className="">Plan</th>
                                <th className="">Amount</th>
                                <th className="">Status</th>
                                <th className="">Purchased on</th>
                            </tr>
                            </thead>

                            <tbody className="fw-6  text-gray-600">

                            {transactionsData?.data.transactions.map(function (transactionItem, key){

                                var startDate = eSMoment(transactionItem.createdAt, );
                                var endDate = eSMoment();

                                var dayDiff = endDate.diff(startDate, 'days');

                                return(
                                    <tr key={key}>
                                        <td>
                                            {transactionItem._id}
                                        </td>
                                        <td>{transactionItem.packageId.name}</td>
                                        <td>{transactionItem.transactionPlan}</td>
                                        <td>{transactionItem.transactionAmount}{transactionItem.transactionCurrency}</td>
                                        <td>
                                            <span className={`badge ${transactionItem.status == "pending"? "badge-light-warning":transactionItem.status == "completed"? "badge-light-success":"badge-light-danger" } fw-bolder px-4 py-3`}>{transactionItem.status}</span>

                                            </td>
                                        <td>{dayDiff >= 1? eSMoment(transactionItem.createdAt).format("DD MMMM [at]  LT"): eSMoment(transactionItem.createdAt).fromNow()}</td>
                                    </tr>

                                )
                            })}

                            </tbody>

                        </table>

                    </div>

                :

                "Loading..."}


            </div>

        </div>

    );
};
export default Transactions;
