import React, {FC, useState} from 'react';
import {DateTimePicker, Picklist, PicklistOption, TimePicker} from 'react-rainbow-components';

import './components.scss'

const ES_DateTimePicker: FC<{type:string, datetime:any}> = ({type, datetime}) => {


    const [dateTimeData, setDateTimeData] = useState( new Date(datetime));
    const [timeData, setTimeData] = useState({ time: '13:32' });


    if(type === "date-time"){
        return (
            <DateTimePicker
                value={dateTimeData}
                onChange={value => setDateTimeData(value)}
                formatStyle="large"
                className={`es-date-timepicker`}
            />
        )

    }else if(type === "time"){
        return (
            <TimePicker
                value={timeData.time}
                onChange={value => setTimeData({ time: value })}
                className={`es-date-timepicker`}
                style={{
                    color:'red'
                }}
            />
        )

    }else{
        return(
            <div>
               type  not found
            </div>
        )
    }


}

export default ES_DateTimePicker;