import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const TourGuide: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();

    return (
        <div>
            <h2>Tour Guide</h2>
            <Form.Group>
                <Form.Label>Guide Name</Form.Label>
                <Controller
                    name="tourGuide.guideName"
                    control={control}
                    render={({ field }) => <Form.Control {...field} isInvalid={!!errors.tourGuide?.guideName} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.tourGuide?.guideName?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Guide Experience</Form.Label>
                <Controller
                    name="tourGuide.guideExperience"
                    control={control}
                    render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.tourGuide?.guideExperience} />}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.tourGuide?.guideExperience?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    );
};

export default TourGuide;
