import React, { FC,useEffect,useState } from 'react';


import {TripActivity} from "../../../../interfaces/trip.interface";
import {eSMoment, getImgUrl} from "../../../../util";
import Dropdown from "react-bootstrap/cjs/Dropdown";
import EditDropDownCard from "./EditDropDownCard";
import dayjs from "dayjs";
import {Currency} from "../../../../interfaces/country.interface";
import filter from "lodash/filter";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import LoaderLayout from "../../../loader/LoaderLayout";

import LocationStart from "../../../../images/illustration-icons/location-start.svg"
import LocationEnd from "../../../../images/illustration-icons/location-end.svg"
import BudgetIconImg from "../../../../images/icons/budget.svg";
import DownloadIconImg from "../../../../images/icons/download.svg";
import SecureIconImg from "../../../../images/icons/secure.svg";
import NotesIconImg from "../../../../images/icons/notes.svg";


type Props = {
    activity:TripActivity,
    handleActivityUpdate?:any,
    tripOwner?:any,
    activeCurrency?:Currency,
    tripType?:string,
    width:number,
    tripStatus?:string,
}
const TaxiCard: FC <Props> = ({activity, handleActivityUpdate, tripOwner, activeCurrency, tripType,width,tripStatus}) => {

    const checkOutDateString = eSMoment(activity.startDateTime).format('YYYY-MM-DD')
    const checkInDateString = eSMoment(activity.endDateTime).format('YYYY-MM-DD')

    const checkOutDate = dayjs(checkOutDateString)
    const checkInDate = dayjs(checkInDateString)
    let totalNights = checkOutDate.diff(checkInDate, 'day')
    let totalDays = totalNights+1


    const [updateType, setUpdateType] = useState("");



    return (
        <div className="card taxi" >

            <div className=" p-4 pt-2 flex-fill col-12">
                {handleActivityUpdate?
                    <div className="d-flex position-absolute toolbar">

                        <div className="me-n3 ">

                            {activity.privacy?

                                width >= 620 ?
                                    <OverlayTrigger  placement="top" overlay={
                                        <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                            <span  className={`text-dark`}>You can only view this content</span>
                                        </Tooltip>
                                    }>
                                        <img src={SecureIconImg} width={28} style={{ padding: '5px' }} className={`option`}/>
                                    </OverlayTrigger>
                                    :
                                    <img src={SecureIconImg} width={28} style={{ padding: '5px' }} className={`option`}/>

                                :""}
                            <span className={`card-options`}>
                                {activity.notes ?

                                    width >= 620 ?
                                        <OverlayTrigger  placement="top"  overlay={
                                            <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                                <span  className={`text-dark`}>View Notes</span>
                                            </Tooltip>
                                        }>
                                            <img src={NotesIconImg} width={28} className={`option`} onClick={(e) => {
                                                if(handleActivityUpdate){

                                                    handleActivityUpdate(activity,"notes")
                                                }
                                            }}/>
                                        </OverlayTrigger>
                                        :
                                        <img src={NotesIconImg} width={28} className={`option`} onClick={(e) => {
                                            if(handleActivityUpdate){

                                                handleActivityUpdate(activity,"notes")
                                            }
                                        }}/>
                                    :""}
                                {activity.budget ?

                                    width >= 620 ?
                                        <OverlayTrigger  placement="top" overlay={
                                            <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                                <span  className={`text-dark`}>View budget</span>
                                            </Tooltip>
                                        }>
                                            <img src={BudgetIconImg} width={28} className={`option`} onClick={(e) => {
                                                if(handleActivityUpdate){

                                                    handleActivityUpdate(activity,"budget")
                                                }
                                            }}/>
                                        </OverlayTrigger>
                                        :
                                        <img src={BudgetIconImg} width={28} className={`option`} onClick={(e) => {
                                            if(handleActivityUpdate){

                                                handleActivityUpdate(activity,"budget")
                                            }
                                        }}/>


                                    :""}
                                {activity.invoices?.length ?

                                    width >= 620 ?
                                        <OverlayTrigger  placement="top" overlay={
                                            <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                                <span className={`text-dark`}>Download Files</span>
                                            </Tooltip>
                                        }>
                                            <img src={DownloadIconImg} width={28} className={`option`}  onClick={(e) => {
                                                if(handleActivityUpdate){

                                                    handleActivityUpdate(activity,"download")
                                                }
                                            }}/>
                                        </OverlayTrigger>
                                        :
                                        <img src={DownloadIconImg} width={28} className={`option`}  onClick={(e) => {
                                            if(handleActivityUpdate){

                                                handleActivityUpdate(activity,"download")
                                            }
                                        }}/>
                                    :""}

                                <EditDropDownCard width={width}  activity={activity} tripStatus={tripStatus}  handleActivityUpdate={handleActivityUpdate} tripOwner={tripOwner} />

                                    </span>

                        </div>

                    </div>
                    :""}
                <div className="row m-1 mt-5">
                    <div className=" col-xl-6 col-12 p-0 mb-3 mb-xl-0 rounded ">

                        <div className="timeline ms-n1">

                            <div className="timeline-item align-items-center mb-4">

                                <div className="timeline-line"></div>


                                <div className="timeline-icon pt-1" >
                                    <img src={LocationStart} width={`20`}/>
                                </div>


                                <div className="timeline-content m-0">

                                    <span className="fs-8 fw-bolder text-muted">Source</span>
                                    <span className="fs-6 text-gray-800 fw-bolder d-block text-hover-primary">{activity.placeOne?.name}</span>
                                    {activity.placeOne && activity.placeOne.formattedAddress? <span className="fw-semibold text-gray-400"><i className="bi bi-geo-alt-fill"></i>{activity.placeOne?.formattedAddress}</span>: '' }

                                </div>

                            </div>


                            <div className="timeline-item align-items-center">

                                <div className="timeline-line"></div>


                                <div className="timeline-icon pt-1">

                                    <img src={LocationEnd} width={`20`}/>
                                </div>


                                <div className="timeline-content m-0">

                                    <span className="fs-8 fw-bolder text-muted">Destination</span>


                                    <span className="fs-6 text-gray-800 fw-bolder d-block text-hover-primary">{ activity.placeTwo?.name}</span>


                                    {activity.placeTwo && activity.placeTwo.formattedAddress? <span className="fw-semibold text-gray-400"><i className="bi bi-geo-alt-fill"></i>{activity.placeTwo?.formattedAddress}</span>: '' }

                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="  col-xl-6 col-12">

                        <div className="d-flex align-items-center mt-9 rounded py-2" style={{backgroundColor: '#f6f8fa'}} >

                            <div className={'col-5 text-center'}>
                                <h5 className="mb-0 mr-2 fs-5 primary-color fw-bolder">{eSMoment(activity.startDateTime).format('DD MMM YY')}</h5>
                                <h5 className="mb-0 mr-2 fs-7 fw-bolder">{eSMoment(activity.startDateTime).format('hh.mma')}</h5>

                            </div>
                            <div className="p-2 flex-fill direction text-center  col-md-2"><i className="bi bi-arrow-right"></i><br/>
                                {totalDays} {totalDays > 1? "Days": "Day"}
                            </div>
                            <div className={'col-5 text-center'}>
                                <h5 className="mb-0 mr-2 fs-5 primary-color fw-bolder">{eSMoment(activity.endDateTime).format('DD MMM YY')}</h5>
                                <h5 className="mb-0 mr-2 fs-7 fw-bolder">{eSMoment(activity.endDateTime).format('hh.mma')}</h5>

                            </div>

                        </div>

                        <div className="row ">

                            <div className="d-flex justify-content-between  mt-1">
                                <div>
                                    <span className="fs-8 fw-bolder text-muted">Vehicle Number</span>
                                    <span className="fs-6 text-gray-800 fw-bolder d-block text-hover-primary">1111</span>
                                </div>
                                <div>
                                    <span className="fs-8 fw-bolder text-muted ">Contact</span>
                                    <span className="fs-6 text-gray-800 fw-bolder d-block text-end text-hover-primary">222</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row px-4 pt-3" style={{borderTop: '1px dotted rgb(210 214 216)'}} >
                    <div className={`col-8`}>
                        {tripType != 'solo'?
                            <div className="d-flex ms-2">
                                <div className={`symbol-group symbol-hover`}>
                                    {activity && activity.members? filter(activity.members, (member)=>{ return member.amount != ""? true: false}).slice(0,3).map(function (member,key) {

                                        return(
                                            <OverlayTrigger  placement="top"  overlay={
                                                <Tooltip id={`tooltip-${key}`} className={`card-members-tooltip`}>
                                                    <div className="text-start" >
                                                        <div className="fs-7 fw-bolder">{member.user?.firstName} {member.user?.lastName}</div>
                                                        <div className=" me-2 fs-7"><i className='bx bx-money'></i> {member.amount} {activeCurrency?.code}</div>
                                                    </div>
                                                </Tooltip>
                                            }>
                                                <div className="symbol symbol-25px symbol-circle cursor-pointer" key={key}>
                                                    {member.user?.cover?
                                                        <img alt="Pic" className={`avatar`} src={getImgUrl(member.user?.cover?.generatedName,member.user._id,'50x')}  />
                                                        :
                                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder avatar">{member.user?._id?(member.user?.firstName).charAt(0): ""}</span>
                                                    }

                                                </div>
                                            </OverlayTrigger>
                                        )

                                    }): ""}
                                </div>

                            </div>
                            :""
                        }

                    </div>
                    <div className={`col-4 p-0`}>
                        <h3 className="fs-4 fw-bolder primary-color text-end">{activity.budget} {activeCurrency?.code}</h3>

                    </div>


                </div>
            </div>

        </div>



    );
};
export default TaxiCard;
