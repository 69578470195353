import React, {useState} from 'react';
import GooglePlaces from "../../../features/googleplaces/GooglePlaces";
import {GooglePlaceResponse} from "../../../interfaces/trip.interface";
import Picker from 'rc-picker';
import 'rc-picker/assets/index.css';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import enUS from 'rc-picker/lib/locale/en_US';
import ES_DateTimePicker from "../../components/ES_DateTimePicker";
import {google_place_photo_url} from "../../../services/api.utils";
import {RadioGroup} from "react-rainbow-components";
import QuillEditor from "../../editor/QuillEditor"; // Import locale

const dinningOptions = [
    { value: 'both', label: 'both' },
    { value: 'veg', label: 'veg' },
    { value: 'non', label: 'non' },
];

const NewDay: React.FC = () => {


    const [accommodation, setAccommodation] = useState("");
    const [restaurant, setRestaurant] = useState("");



    const [restaurants, setRestaurants] = useState<GooglePlaceResponse[]>([]);
    const [accommodations, setAccommodations] = useState<GooglePlaceResponse[]>([]);



    const [dinningOption, setDinningOption] = useState('anonymous');

    const handleOnDinningChange = (event:any) => {
        setDinningOption(event.target.value);
    }


    const [time, setTime] = useState(null);

    const onChange = (value:any) => {
        setTime(value);
    };
    const [note, setNote] = useState("");



    const handlePlaceSelect = (newPlace:GooglePlaceResponse, type: string) => {

        if(type === "accommodation"){
            setAccommodations((prevPlaces) => [...prevPlaces, newPlace]);
        }else if(type === "restaurant"){
            setRestaurants((prevPlaces) => [...prevPlaces, newPlace]);
        }

    };



    return (
        <div className={`card m-3`}>
            <div className=" m-3">
                <label className="form-label"
                       htmlFor="ecommerce-product-sku">Description</label>
                <div className={`m-1`}>

                    <QuillEditor initialValue={note} onEditorChange={function (value: any) {
                        setNote(value)
                    }}/>
                </div>
            </div>
            <div className="m-3">
                <label className="form-label" htmlFor="ecommerce-product-name">Add Accommodation</label>
                <GooglePlaces placeholder='Where do you want to go?'
                              searchType={'all-location'} address={accommodation} lat={2}
                              lng={1}
                              handlePlaceSelect={(data: any) => handlePlaceSelect(data, 'accommodation')}
                              handlePlaceChange={(value: string) => setAccommodation(value)}/>

                {accommodations?.length ?
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Place</th>
                            <th scope="col">Entry Time</th>
                            <th scope="col">Exit Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {accommodations.map((item: any, key: number) => {

                            return (
                                <tr key={key}>
                                    <td>
                                        <div className="d-flex justify-content-start align-items-center product-name">
                                            <div className="symbol symbol-45px symbol-circle me-1 ">
                                            <span
                                                className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{item.name ? (item.name).charAt(0) : ""}</span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <h6 className="text-body text-nowrap mb-0">{item.name}</h6>
                                                <small
                                                    className="text-muted text-truncate d-none d-sm-block">{item.formattedAddress}</small>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <ES_DateTimePicker type={'time'} datetime={'13:32'}/>
                                    </td>
                                    <td>
                                        <ES_DateTimePicker type={'time'} datetime={'13:32'}/>
                                    </td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>

                    : ""}
            </div>

            <div className="m-3">
                <label className="form-label" htmlFor="ecommerce-product-name">Add Restaurants</label>
                <GooglePlaces placeholder='Where do you want to go?'
                              searchType={'all-location'} address={restaurant} lat={2}
                              lng={1}
                              handlePlaceSelect={(data: any) => handlePlaceSelect(data, 'restaurant')}
                              handlePlaceChange={(value: string) => setRestaurant(value)}/>

                {restaurants?.length ?
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Place</th>
                            <th scope="col">Entry Time</th>
                            <th scope="col">Exit Time</th>
                            <th scope="col">Dining Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {restaurants.map((item: any, key: number) => {

                            return (
                                <tr key={key}>
                                    <td>
                                        <div className="d-flex justify-content-start align-items-center product-name">
                                            <div className="symbol symbol-45px symbol-circle me-1 ">
                                            <span
                                                className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{item.name ? (item.name).charAt(0) : ""}</span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <h6 className="text-body text-nowrap mb-0">{item.name}</h6>
                                                <small
                                                    className="text-muted text-truncate d-none d-sm-block">{item.formattedAddress}</small>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <ES_DateTimePicker type={'time'} datetime={'13:32'}/>
                                    </td>
                                    <td>
                                        <ES_DateTimePicker type={'time'} datetime={'13:32'}/>
                                    </td>
                                    <td>
                                        <RadioGroup
                                            id="radio-group-component-1"
                                            options={dinningOptions}
                                            value={dinningOption}
                                            onChange={handleOnDinningChange}
                                            orientation="horizontal"
                                        /></td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>

                    : ""}

            </div>


        </div>
    );
};

export default NewDay;
