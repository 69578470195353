import React, {FC, useEffect, useRef, useState} from 'react';
import './destinationexpresnes.scss'
import {
    Trip,
    TripDestination,
    TripSettlement, TripSettlementDestination, TripSettlementResponse,
} from "../../../interfaces/trip.interface";
import {eSMoment, numberWithCommas} from "../../../util";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import Modal from "react-bootstrap/cjs/Modal";
import Select from "react-select";
import http from "../../../services/api";
import {CurrenciesConvertResponse, CurrenciesResponse, Currency} from "../../../interfaces/country.interface";
import {currencies_code_url, currencies_convert_url, trip_url} from "../../../services/api.utils";
import find from 'lodash/find';
import Chart from "react-apexcharts";
import ApexOptions = ApexCharts.ApexOptions;
import Tooltip from "react-bootstrap/cjs/Tooltip";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import PlanningImg from "../../../images/illustration-icons/planning.svg";
import ActivitiesImg from "../../../images/illustration-icons/activities.svg";

import HotelImg from "../../../images/illustration-icons/hotel.svg";
import HireVehicleImg from "../../../images/illustration-icons/taxi.svg";
import RentVehicleImg from "../../../images/illustration-icons/rental.svg";
import LocationImg from "../../../images/illustration-icons/attraction.svg";


import CurrencyConversionImg from "../../../images/icons/currency-conversion-btn.svg";
import BackButtonImgIcon from "../../../images/icons/back-button.svg";
import EmptyExpenseImg from "../../../images/illustration-icons/empty-expense.svg";
import EmptyWalletImg from "../../../images/illustration-icons/empty-wallet.svg";

type Props = {
    tripData:Trip,
    activeDestinationItem:TripDestination
    settlementData?:[TripSettlementDestination],
    activeDate: string,
    activityFilter:{
        members: string[],
        categories: string[]
    }
}


type OptionSchema ={
    name?: string
    value: string
    code?: string
    label?: string
    _id?: string
}
type CurrencyConvertSchema ={
    code: string
    value: string
    destinationId: string
    currencyId: string,

}
const DestinationExpenses: FC<Props> = ({tripData,activeDestinationItem,settlementData,activeDate,activityFilter}) => {

    const [activeTab, setActiveTab] = useState("all");
    const [activeMember, setActiveMember] = useState("");
    const [showConvertModal, setShowConvertModal] = useState(false);

    const { user, currencies } = useSelector((state: RootState) => state);


    const [activeDestination, setActiveDestination] = useState<TripDestination>(activeDestinationItem);
    const [currenciesData, setCurrenciesData] = useState<[Currency]|null>();
    const [currenciesOptions, setCurrenciesOptions] = useState();
    const [convertCurrencyValue, setConvertCurrencyValue] = useState(1);
    const [convertValue, setConvertValue] = useState(1);
    const [convertAPILoaded, setConvertAPILoaded] = useState(false);
    const [destinationSettlementData, setDestinationSettlementData] = useState<TripSettlementDestination>();
    const [selectedCurrency, setSelectedCurrency] = useState<OptionSchema>({
        name: activeDestinationItem.activeCurrency?.name,
        value:  activeDestinationItem.activeCurrency?._id?activeDestinationItem.activeCurrency?._id:"",
        label:  activeDestinationItem.activeCurrency?.code,
        _id:  activeDestinationItem.activeCurrency?._id
    });
    const [convertedCurrencyValues, setConvertedCurrencyValues] = useState< [CurrencyConvertSchema]>([{
        code: activeDestinationItem.activeCurrency?.code?activeDestinationItem.activeCurrency?.code:"",
        value: "1",
        destinationId:  activeDestinationItem._id?activeDestinationItem._id:"",
        currencyId:  activeDestinationItem.activeCurrency?._id? activeDestinationItem.activeCurrency?._id:"",
    }]);



    let planningBudget = 0
    let activityBudget = 0
    let memberPaidPlanningBudget = 0
    let memberPaidActivityBudget = 0
    let memberSpendPlanningBudget = 0
    let memberSpendActivityBudget = 0
    let totalPlanningsCount = 0
    let totalActivitiesCount = 0

    const [donutChartOptions, setDonutChartOptions] = useState<ApexOptions>({
        chart: {
            type: 'donut',
            toolbar: {
                show: false
            },
        },
        dataLabels: {
            enabled: false,
        },
        labels: ['Plannings','Activities'],
        legend:{
            show: false
        },
        plotOptions: {

            pie: {
                startAngle: 0,
                expandOnClick: true,
                offsetX: 0,
                offsetY: 0,
                customScale: 1,
                dataLabels: {
                    offset: 0,
                    minAngleToShowLabel: 10,
                },
                donut: {
                    size: "60%",
                    background: "transparent",
                    labels: {
                        show: true,
                        value: {
                            show: true,
                            fontSize: "16px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 400,
                            color: undefined,
                            offsetY: -10,
                            formatter: function (val) {
                                return val
                            },
                        },
                        total: {
                            show: true,
                            label: "",
                        },
                    },
                },
            },
        }
    })

    let tripMembers:any = []

    tripData.members.map((member)=>
        tripMembers.push(member.userId?.userName)
    )
    let defaultActivityTypes:any  = ["hotel","flight","taxi","bus","train","rent-vehicle","live"]

    useEffect(() => {

        getExpenses(activeDate)
    },[activeDate])
    useEffect(() => {

        getExpenses(activeDate)
    },[activityFilter])
    useEffect(() => {

        if(currencies){
            let currenciesData:any = []

            currencies.data.currencies.map(function (currency) {
                currenciesData.push( { value: currency._id, label: currency.name+" - "+currency.code, code: currency.code })
            })
            setCurrenciesOptions(currenciesData)

        }

        if(settlementData){


            let foundIndex = settlementData.findIndex(settlementItem => settlementItem.destinationId == activeDestination?._id);

            if(foundIndex >= 0){
                setDestinationSettlementData(settlementData[foundIndex])

            }
        }


    },[])

    const getExpenses = ( activeDate: string) => {


        setDestinationSettlementData(undefined)

        let url = `${trip_url}/${tripData._id}/settlement/${activeDate}`;

        if(activityFilter.categories.length){
            url = `${trip_url}/${tripData._id}/settlement/${activeDate}?categories=${activityFilter.categories.toString()}`;
        }



        console.log("url",url)
        http
            .get<null,TripSettlementResponse>(url)
            .then((res) => {


                if(res.status == 200){

                    if(res.data.destinations){


                        let foundIndex = res.data.destinations.findIndex(settlementItem => settlementItem.destinationId == activeDestination?._id);

                        if(foundIndex >= 0){
                            setDestinationSettlementData(res.data.destinations[foundIndex])

                        }
                    }

                }



            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {


            });
    }

    let membersArray:any = []

    activeDestination.activities?.filter(
        (activity) =>{


            if(activityFilter.categories.length && activity.type){

                return activityFilter.categories.includes(activity.type)

            }else{
                return true
            }

        }
    ).map(function (activity){
        if(activity.type == 'live'){

            totalActivitiesCount++
            filter(activity.paidMembers, function(member) {

                if(member.user?._id && user?.data.userId ){


                    return (member.user?._id).toString() == (user?.data.userId).toString()
                }else{
                    return false
                }

            }).map(function (member,key){


                if(member.paidAmount){
                    memberPaidActivityBudget += (parseFloat(member.paidAmount)*convertValue)


                }
            })
            filter(activity.members, function(member) {

                if(member.user?._id && user?.data.userId ){


                    return (member.user?._id).toString() == (user?.data.userId).toString()
                }else{
                    return false
                }

            }).map(function (member,key){

                if(member.amount){
                    memberSpendActivityBudget += (parseFloat(member.amount)*convertValue)

                }
            })
            activityBudget += (activity.budget?parseFloat(activity.budget): 0)*convertValue




        }else{
            totalPlanningsCount++
            filter(activity.paidMembers, function(member) {

                if(member.user?._id && user?.data.userId ){


                    return (member.user?._id).toString() == (user?.data.userId).toString()
                }else{
                    return false
                }

            }).map(function (member,key){

                if(member.paidAmount){
                    memberPaidPlanningBudget += (parseFloat(member.paidAmount)*convertValue)

                }
            })
            filter(activity.members, function(member) {

                if(member.user?._id && user?.data.userId ){


                    return (member.user?._id).toString() == (user?.data.userId).toString()
                }else{
                    return false
                }

            }).map(function (member,key){

                if(member.amount){
                    memberSpendPlanningBudget += parseFloat(member.amount)

                }
            })
            planningBudget += (activity.budget?parseFloat(activity.budget): 0)*convertValue


        }


        //Members
        activity?.members?.map(function (member,key){
            if(membersArray.length && member._id){

                var foundIndex = membersArray.findIndex(function (membersItem:any){
                    if(member._id){
                        return  (membersItem._id).toString() == (member._id).toString()
                    }
                });

                if(foundIndex < 0){
                    let amount = member.amount? parseFloat(member.amount)*convertValue: 0
                    membersArray.push({
                        _id: member._id,
                        firstName: member.user?.firstName,
                        lastName: member.user?.lastName,
                        userName: member.user?.userName,
                        relation: member.user?.relation,
                        amount: amount,
                        cover:{
                            originalName:member.user?.cover?member.user?.cover.originalName: "",
                            generatedName:member.user?.cover?member.user?.cover.generatedName: "",
                            folder:member.user?.cover?member.user?.cover.folder: "",
                            url:member.user?.cover?member.user?.cover.url: "",
                        },
                        paidAmount: 0,
                    })
                }else{

                    if(member.amount){

                        let newPaidAmount = parseFloat(membersArray[foundIndex].amount)+parseFloat(member.amount)
                        membersArray[foundIndex].amount = newPaidAmount*convertValue
                    }
                }

            }else{

                let amount = member.amount? parseFloat(member.amount)*convertValue: 0

                membersArray.push({
                    _id: member._id,
                    firstName: member.user?.firstName,
                    lastName: member.user?.lastName,
                    userName: member.user?.userName,
                    amount: amount,
                    cover:{
                        originalName:member.user?.cover?member.user?.cover.originalName: "",
                        generatedName:member.user?.cover?member.user?.cover.generatedName: "",
                        folder:member.user?.cover?member.user?.cover.folder: "",
                        url:member.user?.cover?member.user?.cover.url: "",
                    },
                    paidAmount: 0
                })
            }
        })

        //Paid Members
        activity?.paidMembers?.map(function (member,key){

            if(membersArray.length && member._id){

                var foundIndex = membersArray.findIndex(function (membersItem:any){
                    if(member._id){
                        return  (membersItem._id).toString() == (member._id).toString()
                    }
                });

                if(foundIndex < 0){
                    membersArray.push({
                        _id: member._id,
                        firstName: member.user?.firstName,
                        lastName: member.user?.lastName,
                        userName: member.user?.userName,
                        paidAmount: member.paidAmount,
                        cover:{
                            originalName:member.user?.cover?member.user?.cover.originalName: "",
                            generatedName:member.user?.cover?member.user?.cover.generatedName: "",
                            folder:member.user?.cover?member.user?.cover.folder: "",
                            url:member.user?.cover?member.user?.cover.url: "",
                        },
                        amount:0
                    })
                }else{

                    if(member.paidAmount){

                        let newPaidAmount = parseFloat(membersArray[foundIndex].paidAmount)+parseFloat(member.paidAmount)
                        membersArray[foundIndex].paidAmount = newPaidAmount*convertValue
                    }
                }

            }else{

                membersArray.push({
                    _id: member._id,
                    firstName: member.user?.firstName,
                    lastName: member.user?.lastName,
                    userName: member.user?.userName,
                    paidAmount: member.paidAmount,
                    cover:{
                        originalName:member.user?.cover?member.user?.cover.originalName: "",
                        generatedName:member.user?.cover?member.user?.cover.generatedName: "",
                        folder:member.user?.cover?member.user?.cover.folder: "",
                        url:member.user?.cover?member.user?.cover.url: "",
                    },
                    amount:0
                })
            }
        })

    });


    let newMembersArray =  membersArray.map(function (member:any){

        member.balance = (member.paidAmount - member.amount)*convertValue
        return member
    })
    membersArray = orderBy(newMembersArray, ['balance'],['desc'])


    /*let activitiesInfos:any = []

    defaultActivityTypes.map(function (defaultActivityType:any){


        let amount = 0
        let count = 0
        filter(activeDestination?.activities, { type: defaultActivityType }).filter((activity)=>{

                if(activeDate){
                    activeDate = eSMoment(activeDate).format('YYYY-MM-DD');

                    if( activity.type == "flight" ){


                        let arrivalDate = eSMoment(activity.departure).format('YYYY-MM-DD');


                        return(eSMoment(arrivalDate).isSame(activeDate, 'day'))
                    }else if( activity.type == "hotel" ){


                        let checkInDate = eSMoment(activity.checkInDate).format('YYYY-MM-DD');

                        return(eSMoment(checkInDate).isSame(activeDate, 'day'))

                    }else if( activity.type == "taxi" || activity.type == "rent-vehicle"  ){
                        let pickUpDate = eSMoment(activity.pickUpDate).format('YYYY-MM-DD');

                        return(eSMoment(pickUpDate).isSame(activeDate, 'day'))

                    }else if( activity.type == "checklist" || activity.type == "website"  || activity.type == "location" ){

                        return true
                    }

                }else{
                    return true
                }
            }

        ).map(function (activity){


            if(activity.budget){
                amount += parseFloat(activity.budget)
                count++;
            }

        })

        activitiesInfos.push({
            type: defaultActivityType == "taxi"? "Taxi / Bus / Train / Metro" : defaultActivityType == "rent-vehicle"? "rent": defaultActivityType == "location"? "Attraction / Place":defaultActivityType == "live"? "Activity": defaultActivityType,
            amount:amount,
            count:count,
            icon: defaultActivityType == "taxi"? HireVehicleImg : defaultActivityType == "rent-vehicle"? RentVehicleImg: defaultActivityType == "location"?  LocationImg: defaultActivityType == "flight"? PlanningImg:  defaultActivityType == "hotel"? HotelImg:ActivitiesImg,

        })

    })*/



    const selectCurrency = (selectedCurrency:any,item:any) => {



        if(selectedCurrency){

            setSelectedCurrency(selectedCurrency)

            http
                .post<null, CurrenciesConvertResponse>(currencies_convert_url, {
                    _id: tripData?._id,
                    baseCurrency: selectedCurrency,
                })
                .then((res) => {
                    if (res) {

                        const { data, status} = res;

                        let convertData = find(data, {destinationId:activeDestination._id});

                        if(convertData){

                            setConvertedCurrencyValues([{
                                code: convertData.code,
                                currencyId:convertData.currencyId,
                                destinationId: convertData.destinationId,
                                value:convertData.value
                            }])

                            setConvertAPILoaded(true)

                            setConvertCurrencyValue(parseFloat(convertData.value))
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                });


        }

    }


    return (

        <div style={{ display: "contents"}}>
            <div className="col-xl-9 col-md-9 destination-expenses-container px-3">
                <div className="row mb-3">
                    <div className="col-xl-12">
                        <div className="card card-flush h-lg-100">

                            <div className="card-header border-0 pt-0">

                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bolder text-dark">Expenses</span>
                                    <span className="text-gray-400 mt-1 fs-7">Expenses from {totalPlanningsCount} plannings and {totalActivitiesCount} activities</span>
                                </h3>

{/*
                                <div className="card-toolbar d-flex  align-items-end flex-column pt-2">
                                    <OverlayTrigger  placement="top" overlay={<Tooltip id="tooltip-currency">Click here for currency conversion</Tooltip>}>

                                                 <span className="text-danger cursor-pointer fw-bolder" onClick={() => {
                                                     setShowConvertModal(true)
                                                 }}><img src={CurrencyConversionImg} width={20} className={`hover-opacity-btn`} /></span>
                                    </OverlayTrigger>
                                </div>*/}
                            </div>


                            <div className="card-body align-items-end pt-6">

                                <div className="row align-items-center mx-0 w-100">

                                    <div className="col-xl-7 px-0">
                                        <div
                                            className="d-flex border border-gray-300 border-dashed rounded p-3 mb-3">

                                            <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                <div className="symbol symbol-50px me-4">
																				<span className="symbol-label">

																					<span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
																						<img src={PlanningImg} width={20}/>
																					</span>

																				</span>
                                                </div>


                                                <div className="me-2">
                                                    <span
                                                       className="text-gray-800 text-hover-primary fs-6 fw-bold">Plannings</span>
                                                    <span className="text-gray-400 fw-bold d-block fs-7">Great, you always attending class. keep it up</span>
                                                </div>

                                            </div>


                                            <div className="d-flex align-items-center">
                                                <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{numberWithCommas(planningBudget*convertValue)}</span>  <span className=" fw-bolder fs-6 ">{activeDestination.activeCurrency?.code}</span></span>
                                            </div>

                                        </div>
                                        <div
                                            className="d-flex border border-gray-300 border-dashed rounded p-3 mb-3">

                                            <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                                                <div className="symbol symbol-50px me-4">
																				<span className="symbol-label">

																					<span
                                                                                        className="svg-icon svg-icon-2qx svg-icon-primary">
																						<img src={ActivitiesImg} width={20}/>
																					</span>

																				</span>
                                                </div>


                                                <div className="me-2">
                                                    <span
                                                       className="text-gray-800 text-hover-primary fs-6 fw-bold">Activities</span>
                                                    <span className="text-gray-400 fw-bold d-block fs-7">Great, you always attending class. keep it up</span>
                                                </div>

                                            </div>


                                            <div className="d-flex align-items-center">
                                                <span className="text-dark text-nowrap"><span className=" fw-bolder fs-2 primary-color">{numberWithCommas(activityBudget*convertValue)}</span>  <span className=" fw-bolder fs-6 ">{activeDestination.activeCurrency?.code}</span></span>
                                            </div>

                                        </div>


                                    </div>


                                    <div className="col-xl-5 d-flex justify-content-center px-0">

                                        {activityBudget > 0 || planningBudget > 0?
                                            <Chart options={{
                                                chart: {
                                                    type: 'donut',
                                                    toolbar: {
                                                        show: false
                                                    },
                                                },
                                                dataLabels: {
                                                    enabled: false,
                                                },
                                                labels: ['Plannings','Activities'],
                                                legend:{
                                                    show: false
                                                },
                                                plotOptions: {

                                                    pie: {
                                                        startAngle: 0,
                                                        expandOnClick: true,
                                                        offsetX: 0,
                                                        offsetY: 0,
                                                        customScale: 1,
                                                        dataLabels: {
                                                            offset: 0,
                                                            minAngleToShowLabel: 10,
                                                        },
                                                        donut: {
                                                            size: "60%",
                                                            background: "transparent",
                                                            labels: {
                                                                show: true,
                                                                value: {
                                                                    show: true,
                                                                    fontSize: "12px",
                                                                    fontFamily: "Helvetica, Arial, sans-serif",
                                                                    fontWeight: 400,
                                                                    color: undefined,
                                                                    offsetY: -10,
                                                                    formatter: function (val) {
                                                                        let amount = numberWithCommas(val)
                                                                        return `${amount} ${activeDestination.activeCurrency?.code}`
                                                                    },
                                                                },
                                                                total: {
                                                                    show: true,
                                                                    fontSize: "12px",
                                                                    formatter: function (w) {
                                                                        let amount = numberWithCommas(planningBudget+activityBudget)
                                                                        return `${amount} ${activeDestination.activeCurrency?.code}`
                                                                    }
                                                                },
                                                            },
                                                        },
                                                    },
                                                }
                                            }} series={[(planningBudget),(activityBudget)]} type="donut" width={200} height={200} />
                                            :
                                            <div className="text-center p-2">
                                                <img src={EmptyExpenseImg} width={100}/>
                                                <p className="fs-5 text-dark fw-bolder">No data available</p>
                                                <p className="text-gray-400">Please check after adding planning or activities from destination dashboard</p>
                                            </div>
                                        }
                                    </div>

                                </div>
                                <div className="row d-flex">
                                    <div className="d-flex">

                                        <div
                                            className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-3 mb-3">
                                            <span className="fs-6 text-gray fw-bolder">{(filter(activeDestination.activities,  (activity)=>{

                                                return activity.type != "live"
                                            })).length }</span>

                                            <div className="fw-semibold text-gray-400">Plannings</div>
                                        </div>

                                        <div
                                            className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3">
                                            <span className="fs-6 text-gray fw-bolder">{(filter(activeDestination.activities, { type: "live" })).length }</span>

                                            <div className="fw-semibold text-gray-400">Activities</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/*<div className="col-xl-4">
                        <div className="card highlight-card">
                            <div className="card-header">
                                <h3
                                className="card-title align-items-start flex-column"><span
                                className="card-label fw-bolder text-dark">Summary</span><span
                                className="text-gray-400 mt-1  fs-6">Discover destination plans and activities summarized here.</span>
                            </h3>
                            </div>
                            <div className="card-body pt-2 pb-2 d-flex align-items-center">
                                <div className="row">
                                    {activitiesInfos.map(function (activitiesInfo:any,key:any){
                                        return(
                                            <div className={`col-3`} key={key}>
                                                <div className="passenger-card-item d-flex  flex-wrap cursor-pointer">
                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <div className="symbol symbol-50px me-2"><span
                                                            className="symbol-label">
                                                                <img src={activitiesInfo.icon} width={20} />
                                                        </span>
                                                        </div>
                                                        <div className=""><a className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 text-capitalize ">{activitiesInfo.type}</a>
                                                            <div className="fw-bold text-gray-400"> {activitiesInfo.count} {activitiesInfo.count > 1? "records": "record" } added</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-end ms-2 member-manage"><span
                                                        className="my-3 fs-5 px-2 fw-bolder primary-color">{numberWithCommas(parseFloat(activitiesInfo.amount)*convertValue)} {activeDestination.activeCurrency?.code}</span></div>
                                                </div>
                                                {key < activitiesInfos.length-1?
                                                    <div className="separator separator-dashed my-2"></div>
                                                    :""
                                                }

                                            </div>

                                        )
                                    })}


                                </div>
                            </div>
                        </div>
                    </div>*/}
                </div>


                <div className="card intro card-stretch mb-3 bg-light-info hoverable shadow-none pb-3  mb-9 mb-xl-0">
                    <div className="card-header align-items-center border-0"><h3
                        className="card-title align-items-start fw-bolder flex-column">My Expenses</h3>
                        <div className="card-toolbar"></div>
                    </div>
                    <div className="card-body row ">
                        <div className="col-xl-4" style={{borderRight: " 2px #dcdbdb dotted"}}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="">
                                        <p className={` mb-2 fs-2 fw-bolder ${((memberPaidPlanningBudget*convertValue)- (memberSpendPlanningBudget*convertValue)) < 0? 'text-danger': 'text-success'}`}>{numberWithCommas((memberPaidPlanningBudget*convertValue)- (memberSpendPlanningBudget*convertValue))}<span className=" fw-bolder fs-6 ms-1">{activeDestination.activeCurrency?.code}</span></p>
                                        <h3 className="mb-0 number-font">Your Planning Balance </h3>
                                    </div>
                                    <div className="mt-3">
                                        <p className="mb-1 d-flex">
                                            <span className=""><i className="bi bi-cash text-muted me-2 fs-16"></i></span>
                                            <span className="fs-13 font-weight-normal text-muted me-2">Spent Amount </span> :
                                            <span className="ms-auto fs-14 text-danger">- {numberWithCommas(memberSpendPlanningBudget*convertValue)}<span className=" fw-bolder fs-6 ms-1">{activeDestination.activeCurrency?.code}</span></span>
                                        </p>
                                        <p className="mb-1 d-flex">
                                            <span className=""><i className="bi bi-credit-card me-2 fs-16 text-muted"></i></span>
                                            <span className="fs-13 font-weight-normal text-muted me-2">Paid Amount </span> :
                                            <span className="ms-auto fs-14  text-success">+ {numberWithCommas(memberPaidPlanningBudget*convertValue)}<span className=" fw-bolder fs-6 ms-1">{activeDestination.activeCurrency?.code}</span></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4" style={{borderRight: " 2px #dcdbdb dotted"}}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="">
                                        <p className={` mb-2 fs-2 fw-bolder ${((memberPaidActivityBudget*convertValue)- (memberSpendActivityBudget*convertValue)) < 0? 'text-danger': 'text-success'}`}>{numberWithCommas(((memberPaidActivityBudget*convertValue)- (memberSpendActivityBudget*convertValue)))}<span className=" fw-bolder fs-6 ms-1">{activeDestination.activeCurrency?.code}</span></p>
                                        <h3 className="mb-0 number-font">Your Activity Balance </h3>
                                    </div>
                                    <div className="mt-3">
                                        <p className="mb-1 d-flex">
                                            <span className=""><i className="bi bi-cash text-muted me-2 fs-16"></i></span>
                                            <span className="fs-13 font-weight-normal text-muted me-2">Spent Amount </span> :
                                            <span className="ms-auto fs-14 text-danger">- {numberWithCommas(memberSpendActivityBudget*convertValue)}
                                                <span className=" fw-bolder fs-6 ms-1">{activeDestination.activeCurrency?.code}</span>
                                            </span>
                                        </p>
                                        <p className="mb-1 d-flex">
                                            <span className=""><i className="bi bi-credit-card me-2 fs-16 text-muted"></i></span>
                                            <span className="fs-13 font-weight-normal text-muted me-2">Paid Amount </span> :
                                            <span className="ms-auto fs-14  text-success">+ {numberWithCommas(memberPaidActivityBudget*convertValue)}
                                                <span className=" fw-bolder fs-6 ms-1">{activeDestination.activeCurrency?.code}</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center mt-8"><p
                                        className={`mb-2 fs-2x fw-bolder ${(((memberPaidActivityBudget*convertValue)- (memberSpendActivityBudget*convertValue)) + ((memberPaidPlanningBudget*convertValue)- (memberSpendPlanningBudget*convertValue))) < 0? "text-danger": "text-success"}`}>{numberWithCommas(((memberPaidActivityBudget*convertValue)- (memberSpendActivityBudget*convertValue)) + ((memberPaidPlanningBudget*convertValue)- (memberSpendPlanningBudget*convertValue)))}<span className=" fw-bolder fs-6 ms-1">{activeDestination.activeCurrency?.code}</span></p><h3
                                        className="mb-0 number-font">Your destination Balance </h3></div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {destinationSettlementData == undefined ?
                        <div className={`text-center p-2`}>
                            <p className={`fs-5 text-dark fw-bolder`}>Loading</p>
                            <p className={`text-gray-400`}>Please wait till we are fetching your settlement</p>
                        </div>

                        : destinationSettlementData?.settlementData.length ?

                            <div>

                                {filter(destinationSettlementData?.settlementData, function (settlementDataItem) {
                                    if (settlementDataItem.user._id == user?.data.userId) {
                                        return true
                                    } else {
                                    }
                                    return false
                                }).length ?
                                    <div className="card-body ">

                                        <table className="table table-borderless">
                                            <thead>
                                            <tr>
                                                <th scope="col" className={` fw-bolder fs-6`}>You Owe</th>
                                                <th scope="col" className={` fw-bolder fs-6`}>Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {filter(destinationSettlementData?.settlementData, function (member) {
                                                if (member.user._id == user?.data.userId) {
                                                    return true
                                                } else {
                                                }
                                                return false
                                            }).map(function (member, key: number) {
                                                if (member.user._id == user?.data.userId) {

                                                    return (

                                                        <tr className="py-3" key={key}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div
                                                                        className="symbol symbol-45px symbol-circle me-2 ">
                                                                        {member.payUser.cover?.url ?
                                                                            <img alt="Pic"
                                                                                 src={member.payUser.cover?.url}/>
                                                                            :
                                                                            <span
                                                                                className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{(member.payUser.firstName).charAt(0)}</span>
                                                                        }

                                                                    </div>
                                                                    <div className=""><a
                                                                        className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">{member.payUser.firstName} {member.payUser.lastName}</a>
                                                                        <div className="fw-bold text-gray-400">{member.payUser?.relation? member.payUser?.relation : member.payUser?.userName}</div>

                                                                </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex flex-column member-manage"><span
                                                                    className="my-3 fs-5 fw-bolder text-gray-900">{numberWithCommas(parseFloat(member.amount) * convertValue)} {activeDestination.activeCurrency?.code}</span>
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                }

                                            })}

                                            </tbody>
                                        </table>
                                    </div>
                                    : ""}

                                {filter(destinationSettlementData?.settlementData, function (member) {
                                    if (member.payUser._id == user?.data.userId) {
                                        return true
                                    } else {
                                    }
                                    return false
                                }).length ?
                                    <div className="card-body">
                                        <table className="table table-borderless">
                                            <thead>
                                            <tr>
                                                <th scope="col" className={` fw-bolder fs-6`}>Receive From</th>
                                                <th scope="col" className={` fw-bolder fs-6`}>Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {filter(destinationSettlementData?.settlementData, function (member) {
                                                if (member.payUser._id == user?.data.userId) {
                                                    return true
                                                }

                                                return false
                                            }).map(function (member, key: number) {


                                                return (

                                                    <tr className="py-3" key={key}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-45px symbol-circle me-2">
                                                                    {member.user.cover?.url ?
                                                                        <img alt="Pic" src={member.user.cover?.url}/>
                                                                        :
                                                                        <span
                                                                            className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{(member.user.firstName).charAt(0)}</span>
                                                                    }

                                                                </div>
                                                                <div className=""><a
                                                                    className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">{member.user.firstName} {member.user.lastName}</a>
                                                                    <div
                                                                        className="fw-bold text-gray-400">{member.user?.relation? member.user?.relation : member.user?.userName}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex flex-column member-manage"><span
                                                                className="my-3 fs-5 fw-bolder text-success ">{numberWithCommas(Math.abs(parseFloat(member.amount) * convertValue))} {activeDestination.activeCurrency?.code}</span>
                                                            </div>
                                                        </td>
                                                    </tr>)

                                            })}

                                            </tbody>
                                        </table>
                                    </div>
                                    : ""}
                            </div> :

                            <div className={`text-center p-2`}>
                                <img src={EmptyWalletImg} width={100}/>
                                <p className={`fs-5 text-dark fw-bolder`}>No data available</p>
                                <p className={`text-gray-400`}>Please check after adding planning or activities from
                                    destination dashboard</p>
                            </div>
                    }

                </div>


                {tripData?.tripType != "solo" && user?.data.userId == tripData.userId._id?

                    <>
                        <div className="card intro card-stretch mb-5 bg-light-info hoverable shadow-none">

                            <div className="card-header align-items-center border-0">
                                <h3 className="card-title align-items-start title fw-bolder flex-column">
                                    Members Account
                                </h3>
                                <div className="card-toolbar">


                                </div>
                            </div>

                            <div className="card-body px-3 ">

                                <table className="table table-borderless">
                                    <thead>
                                    <tr>
                                        <th scope="col" className={`fw-bolder text-dark`}>Name</th>
                                        <th scope="col" className={`fw-bolder text-dark`}>Spent</th>
                                        <th scope="col" className={`fw-bolder text-dark`}>Paid</th>
                                        <th scope="col" className={`fw-bolder text-dark`}>Balance</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    { membersArray.map(function (member:any, key:number){

                                        console.log("member---1",member)
                                        return(

                                            <tr className="py-3"  key={key}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="symbol symbol-45px symbol-circle me-2">
                                                            {member.cover?.url?
                                                                <img alt="Pic" src={member.cover?.url} />
                                                                :
                                                                <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{member._id?(member?.firstName).charAt(0): ""}</span>
                                                            }

                                                        </div>
                                                        <div className=""><a
                                                            className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">{member?.firstName} {member.lastName}</a>
                                                            <div className="fw-bold text-gray-400">{member.relation? member.relation : member?.userName}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-column member-manage"><span
                                                        className="my-3 fs-5 fw-bolder text-danger">{numberWithCommas(member.amount)} {activeDestination.activeCurrency?.code}</span></div>
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-column  member-manage"><span
                                                        className="my-3 fs-5 fw-bolder text-success">{numberWithCommas(member.paidAmount)} {activeDestination.activeCurrency?.code}</span></div>
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-column member-manage"><span
                                                        className={`${(member.balance) < 0? "primary-color": "success-color" } my-3 fs-5 fw-bolder`}>{numberWithCommas((parseFloat(member.balance))*convertValue)} {activeDestination.activeCurrency?.code}</span></div>
                                                </td>
                                            </tr> )

                                    } ) }

                                    </tbody>
                                </table>

                            </div>

                        </div>

                            <div className="card intro card-stretch mb-5 bg-light-info hoverable shadow-none">

                                <div className="card-header align-items-center border-0">
                                    <h3 className="card-title align-items-start title fw-bolder flex-column">
                                        Members Settlement
                                    </h3>
                                    <div className="card-toolbar">


                                    </div>
                                </div>

                                <div className="card-body px-3 ">

                                    <table className="table table-borderless">
                                        <thead>
                                        <tr>
                                            <th scope="col" className={`fw-bolder text-dark`}>Name</th>
                                            <th scope="col" className={`fw-bolder text-dark`}>Owe</th>
                                            <th scope="col" className={`fw-bolder text-dark`}>Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { destinationSettlementData?.settlementData.map(function (member, key:number){
                                            return(

                                                <tr className="py-3"  key={key}>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="symbol symbol-45px symbol-circle me-2">
                                                                {member.user.cover?.url?
                                                                    <img alt="Pic" src={member.user.cover?.url} />
                                                                    :
                                                                    <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{(member.user.firstName).charAt(0)}</span>
                                                                }

                                                            </div>
                                                            <div className=""><a
                                                                className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">{member.user.firstName} {member.user.lastName}</a>
                                                                <div className="fw-bold text-gray-400">{member.user.relation?member.user.relation:member.user?.userName}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="symbol symbol-45px symbol-circle me-2">
                                                                {member.payUser.cover?.url?
                                                                    <img alt="Pic" src={member.payUser.cover?.url} />
                                                                    :
                                                                    <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{(member.payUser.firstName).charAt(0)}</span>
                                                                }

                                                            </div>
                                                            <div className=""><a
                                                                className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">{member.payUser.firstName} {member.payUser.lastName}</a>
                                                                <div className="fw-bold text-gray-400">{member.payUser?.userName}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex flex-column member-manage"><span
                                                            className="my-3 fs-5 fw-bolder text-danger">{member.amount? numberWithCommas(Math.abs(parseFloat(member.amount)*convertValue)): 0} {activeDestination.activeCurrency?.code}</span></div>
                                                    </td>
                                                </tr> )

                                        } ) }

                                        </tbody>
                                    </table>

                                </div>

                            </div>
                    </>


                :""}

            </div>
            <Modal show={showConvertModal} className="planning-form-modal" onHide={function () {

            }}  onExited={function () {

            }} animation={true}>

                <Modal.Body>
                    <div className="d-flex text-center header">
                        <div className="p-2 flex-fill text-start">
                            <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {
                                setShowConvertModal(false)
                            }}/>

                        </div>
                        <div className="p-2 flex-fill "></div>
                        {selectedCurrency?
                            <div className="p-2 flex-fill text-end link save"  onClick={() => {

                                if(convertAPILoaded){

                                    setConvertAPILoaded(false)
                                    setShowConvertModal(false)
                                    setConvertValue(convertCurrencyValue)

                                    if(selectedCurrency){

                                        setActiveDestination({
                                            ...activeDestination,
                                            activeCurrency:{
                                                _id: selectedCurrency._id?selectedCurrency._id:"",
                                                name:selectedCurrency.name?selectedCurrency.name:"",
                                                code: selectedCurrency.code?selectedCurrency.code:"",
                                            }
                                        })

                                    }
                                }
                            }} ><span className="text-primary">Save</span></div>

                            :
                            <div className="p-2 flex-fill text-end " ><span className="text-muted">Save</span></div>

                        }
                    </div>
                    <div className="col-12">
                        <div className="mb-3 text-center"><h1 className="mb-3 fs-2"> Convert currency</h1>
                            <div className="text-gray-400 fw-semibold fs-5">Helps to convert currency
                            </div>
                            <div className="text-gray-400 fw-semibold fs-7">If you need more info, please check<a
                                href="" className="fw-bold link"> Support Guidelines</a>.
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <label htmlFor="budget" className="form-label">Currency </label>

                        <div className="form-control form-control-solid new-destination-range">



                            <Select
                                classNamePrefix='currency-filter'

                                value={selectedCurrency}
                                onChange={selectCurrency}
                                options={currenciesOptions}
                                styles={{
                                    control: (base):any => ({
                                        ...base,
                                        border: 0,
                                        backgroundColor: "#f5f8fa",
                                        boxShadow: 'none'
                                    }),
                                    indicatorSeparator:  (base):any => ({
                                        ...base,
                                        borderColor: "#f5f8fa",
                                        backgroundColor: "#f5f8fa",
                                    }),
                                }}
                            />
                        </div>
                    </div>

                    <p>{activeDestination.activeCurrency?.code} <input type="number" className="form-control form-control-lg form-control-solid"

                                                                       onChange={function (e){

                                                                           const { value } = e.target;
                                                                               setConvertAPILoaded(parseFloat(value) !== convertCurrencyValue?true:false)


                                                                           setConvertCurrencyValue(parseFloat(value))
                                                                       }}
                                                                       value={convertCurrencyValue}/></p>



                </Modal.Body>
            </Modal>

        </div>
    );
};
export default DestinationExpenses;
