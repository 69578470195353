import React, {FC, forwardRef, useCallback, useEffect, useRef, useState, CSSProperties} from 'react';



import 'react-image-gallery/styles/css/image-gallery.css'
import iconLogo from "../../../images/icons/logo.svg";
import {useNavigate} from "react-router-dom";
import BackArrowIcon from "../../../images/icons/back-arrow.svg";
import Modal from "react-bootstrap/cjs/Modal";
import CloseImgIcon from "../../../images/icons/close.svg";
import ImageGallery from "react-image-gallery";
import InfoGraphicImg from "../../../images/icons/info_graphic.svg";

import {register_url, wait_list_url, wp_url} from "../../../services/api.utils";
import Loader from "../../loader/Loader";
import {User, UserResponse} from "../../../interfaces/user.interface";
import http from "../../../services/api";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";


import './header.scss'
import {toastMessage} from "../../../util";
import {Toaster} from "react-hot-toast";



const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}



const schema = Yup.object().shape({
    firstName: Yup.string()
        .required('First Name required')
        .matches(/^[a-zA-Z]+$/, 'First Name should contain only letters'),
    lastName: Yup.string()
        .required('Last Name required')
        .matches(/^[a-zA-Z]+$/, 'Last Name should contain only letters'),
    email: Yup.string().required('Email required').email('Please provide a valid email address (abc@xy.z)')
});


const Header: FC<{enableBackBtn:boolean,showSubscribeModal:boolean,manageShowSubscribeModal:any}> = ({enableBackBtn,showSubscribeModal,manageShowSubscribeModal}) => {


    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);


    const { handleSubmit, register, reset,setFocus, formState: { errors } } = useForm<User>({
        resolver: yupResolver(schema),
    });

    const { width } = useViewport();



    const submitForm = (data: User) => {

        setLoading(true)
        http
            .post<User, any>(wait_list_url, data)
            .then((res) => {
                if (res) {


                    if(res.status == 200){
                        reset()
                        manageShowSubscribeModal(false)
                        toastMessage(`Thank you for joining our waitlist! You've secured your spot for early access. Keep an eye on your inbox for exclusive updates and the next steps. Welcome to the journey!`, '');

                    }else{


                        toastMessage(res.message, 'error');

                    }

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false)
            });
    };



    return (

        <>
            <header>
                <nav className="navbar d-flex flex-nowrap ">
                    <div className="container">
                        <img src={iconLogo} width={50} className={`cursor-pointer`} onClick={()=>{ navigate('/') }} />

                    </div>

                    <div className="justify-content-end me-3">

                        {enableBackBtn?
                            <div className="d-flex">
                                <img src={BackArrowIcon} className={`hover-opacity-btn cursor-pointer`} width={25} onClick={()=>{ navigate('/') }}/>
                            </div>
                            :
                            ""
                        }

                    </div>
                </nav>
            </header>
            <Modal show={showSubscribeModal} className={``} size={`lg`} centered={true} animation={true} >


                <Modal.Body style={{ padding: '0px'}}>

                    <div className={`row`}>

                        {width >= 620?
                            <div className={`col-6`} style={{    backgroundColor: '#d1454b',
                                borderTopLeftRadius: '10px',
                                borderBottomLeftRadius: '10px'}}>
                                <div className="content-left-wrapper">
                                    <div>
                                        <figure><img src={InfoGraphicImg} alt="" className="img-fluid" width="270" height="270" /></figure>
                                        <h2>Get Early Access</h2>
                                        <p>Secure your spot for early access and be among the first to explore the future with ExploreSoul. Experience the cutting-edge before anyone else, and take advantage of exclusive perks reserved for our early adopters.</p>

                                    </div>
                                </div>
                            </div>
                        :

                            ""
                        }

                        <div className={`col-12 col-xl-6`}>
                            <div className={`d-flex justify-content-between p-3 `} >

                                <Modal.Title className={`text-dark fs-3 fw-bolder`}>Join the Waitlist Now</Modal.Title>
                                <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={()=>{
                                    manageShowSubscribeModal(false)
                                }}>
                                    <img src={CloseImgIcon} className={`hover-opacity-btn`} width={20}/>
                                </div>

                            </div>

                            <div className="container" style={{ height: '60vh'}}>
                                <div className="row justify-content-center align-items-center h-100">
                                    <form onSubmit={handleSubmit(submitForm)} className="">
                                        <div className="container g-3">

                                            <div className="col-12">
                                                <label htmlFor="firstName" className="text-dark fs-6">First Name </label>
                                                <input {...register("firstName")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.firstName? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="firstName"
                                                       placeholder="John" />
                                                {errors && errors.firstName && (
                                                    <div className="invalid-feedback show"> {errors.firstName.message} </div>
                                                )}
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="lastName" className="text-dark fs-6">Last Name </label>
                                                <input {...register("lastName")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.lastName? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="lastName"
                                                       placeholder="Doe" />
                                                {errors && errors.lastName && (
                                                    <div className="invalid-feedback show"> {errors.lastName.message} </div>
                                                )}
                                            </div>


                                            <div className="col-12">
                                                <label htmlFor="email" className="text-dark fs-6">Email </label>
                                                <input {...register("email")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.email? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="email"
                                                       placeholder="you@example.com" />
                                                {errors && errors.email && (
                                                    <div className="invalid-feedback show"> {errors.email.message} </div>
                                                )}
                                            </div>

                                        </div>

                                        <div className={`container d-flex justify-content-end`}>
                                            <button className=" btn btn-primary  shadow-none mt-5" disabled={loading?true : false} type="submit">{loading?<Loader cssClass="button-sm-loader"/> :"Join Waitlist" }</button>

                                        </div>


                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>




                </Modal.Body>
            </Modal>
            <Toaster />
        </>

    );
}
export default Header;
