import React, {FC, useEffect, useState} from 'react';
import './addresses.scss'
import http from "../../../services/api";
import {Address, PaymentAddress, User, UserResponse} from "../../../interfaces/user.interface";
import {countries_url, user_logs_url, user_url} from "../../../services/api.utils";
import {setLogs} from "../logs/logsSlice";
import {useAppDispatch} from "../../../store";
import {Log, LogsResponse} from "../../../interfaces/log.interface";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import {eSMoment, showAlert} from "../../../util";
import Modal from "react-bootstrap/cjs/Modal";
import UserPackageContainer from "../../userPackages/UserPackageContainer";
import Select from "react-select";
import Loader from "../../loader/Loader";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CountriesResponse} from "../../../interfaces/country.interface";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";

type Props = {
    userData: User,
    updateUserData?:any

}

type OptionSchema ={
    name?: string
    value: string
    label?: string
}

const schema = Yup.object().shape({
    firstName: Yup.string().required('Fast Name required'),
    lastName: Yup.string().required('Last Name required'),
    addressLine1: Yup.string().required('Address line 1 required'),
    addressLine2: Yup.string().required('Address line 2 required'),
    city: Yup.string().required('City required'),
    stateProvince: Yup.string().required('State /Province required'),
    postcode: Yup.string().required('Postal code required'),
});

const Addresses: FC<Props> = ({userData,updateUserData}) => {

    const { handleSubmit, register, reset,setFocus, formState: { errors },  } = useForm<PaymentAddress>({
        resolver: yupResolver(schema),
        shouldUnregister: true,
    });
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteAddressId, setDeleteAddressId] = useState("");
    const [countriesOptions, setCountriesOptions] = useState();
    const [countriesData, setCountriesData] = useState<CountriesResponse>();
    const [selectedCountry, setSelectedCountry] = useState<OptionSchema>();
    const [isCountryEmpty, setIsCountryEmpty] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false);

    const initialAddress = {
        firstName:"",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city:"",
        stateProvince:"",
        postcode:"",
        country:{
            _id: "",
            name: ""
        },
        countryId:"",
    }
    const [activeAddress, setActiveAddress] = useState<Address>(initialAddress);

    const [addNewAddress, setAddNewAddress] = useState(userData.addresses && userData.addresses.length? false: true);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    useEffect(() => {
        setLoading(true);


        if (countriesData == null) {
            http
                .get<null, CountriesResponse>(countries_url)
                .then((res) => {

                    let countries:any = []

                    res.data.countries.map( (country) => {
                        countries.push( { value: country._id, label: country.name })
                    })

                    setCountriesOptions(countries)
                    setCountriesData(res)

                }).catch((error) => {
                console.log(error);
            })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }


    }, [dispatch]);

    const submitForm = (formData: PaymentAddress) => {

        if(selectedCountry !== undefined){
            setIsCountryEmpty(false)

            let formDataClone = formData
            formDataClone.country = selectedCountry.label?selectedCountry.label:""
            formDataClone.countryId = selectedCountry.value

            let addressData = {
                address:formDataClone,
                updateType: "add-address",
                addressId: ""
            }

            if(activeAddress.firstName){
                addressData.updateType ="edit-address"
                addressData.addressId = activeAddress._id?activeAddress._id:""
            }else{
                addressData.updateType ="add-address"
            }

            console.log("addressData",addressData.updateType)

            apiCall(addressData)



        }else{
            setIsCountryEmpty(true)
        }
    };

    const apiCall = (addressData: any) => {

        if(addressData.updateType !== "delete-address"){

            setLoading(true)
        }

        http
            .put<User, UserResponse>(user_url, addressData)
            .then((res) => {
                if (res) {
                    const { data,status,message} = res;
                    updateUserData(data)
                    setLoading(false)
                    setDeleteAddressId("")
                    setConfirmDeleteModal(false)
                    setShowAddressModal(false)
                    setActiveAddress(initialAddress)
                    setSelectedCountry(undefined)
                    setDeleteLoading(false)
                    if(status == 200){
                        showAlert(message,"success")
                    }else{
                        showAlert(message,"error")
                    }


                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const selectCountry = (selectedCountry:any) => {

        if(selectedCountry){

            setSelectedCountry(selectedCountry)
        }

    }

    return (



        <div className="row address-container">

            <div className="container">

                <div className="d-flex flex-wrap flex-stack mb-6">

                    <h3 className="text-dark text-hover-primary fs-3 fw-bolder my-2">Address
                        <span className="fs-6 text-gray-400 fw-semibold ms-1">({userData?.addresses?.length})</span></h3>


                    <div className="d-flex my-2">
                        <button className=" btn btn-primary btn-sm shadow-none" onClick={ ()=>{
                            setActiveAddress(initialAddress)
                            setShowAddressModal(true)

                        }} >Add New Address</button>

                    </div>

                </div>

            </div>

                    <div className="container">
                        <div className="row">


                                {userData.addresses?.map( (address, key) => {

                                    return(
                                        <div className="col-xl-6 address-item" key={key}>
                                            <div className="card card-dashed  h-xl-100 flex-row flex-stack flex-wrap p-6 cursor-pointer mb-6">
                                                <div className="d-flex flex-column py-2">
                                                    <div className="d-flex align-items-center fs-5 fw-bold mb-3">Address {key+1}</div>
                                                    <div className="fs-6 fw-semibold text-gray-600"> {address.firstName} {address.lastName}
                                                        <br/> {address.addressLine1}, {address.addressLine2}
                                                        <br/> {address.city} {address.postcode}
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center manage-btn py-2">
                                                    <button
                                                        className="btn btn-primary me-3" >
                                                         <span className="indicator-label" onClick={()=>{

                                                             if(address?._id){

                                                                 setDeleteAddressId(address._id)
                                                                 setConfirmDeleteModal(true)
                                                             }

                                                            }}>Delete</span>

                                                    </button>
                                                    <button className="btn btn-light btn-active-light-primary" onClick={() => {
                                                        setActiveAddress((prevState)=>address)

                                                        setSelectedCountry({
                                                            name: address.country.name,
                                                            label: address.country.name,
                                                            value:  address.country._id,
                                                        })


                                                        setShowAddressModal(true)
                                                    }} >Edit </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}


                        </div>
                    </div>
            <Modal size="lg" backdrop="static" keyboard={false} centered show={showAddressModal}  animation={false} onExit={ () => {
                setActiveAddress(initialAddress)
                setSelectedCountry(undefined)

            }}>
                <form  onSubmit={handleSubmit(submitForm)} >
                    <Modal.Header>
                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                            <span className="mx-3 close"  onClick={ () => setShowAddressModal(false)} >Cancel</span>
                        </div>
                        <Modal.Title>Add New address</Modal.Title>
                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                            {loading? <span  className="save">Saving...</span>: <button className="save-button shadow-none" disabled={loading?true : false} type="submit">{loading?<Loader cssClass="button-loader"/> :"Save" }</button> }
                        </div>
                    </Modal.Header>


                    <Modal.Body>



                            <div>


                                <div className="row mb-5">

                                    <div className="col-md-6 fv-row fv-plugins-icon-container">

                                        <label className="required fs-5 fw-semibold mb-2">First name</label>

                                        <input {...register("firstName")} value={activeAddress.firstName} onChange={ (e) => {

                                            if(activeAddress){

                                                setActiveAddress({
                                                    ...activeAddress,
                                                    firstName:e.target.value
                                                })
                                            }

                                        }}  type="text"  className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.firstName? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  placeholder=""
                                               name="firstName" />

                                        {errors && errors.firstName && (
                                            <div className="invalid-feedback show"> {errors.firstName.message} </div>
                                        )}
                                    </div>


                                    <div className="col-md-6 fv-row fv-plugins-icon-container">

                                        <label className="required fs-5 fw-semibold mb-2">Last name</label>
                                        <input {...register("lastName")}   value={activeAddress.lastName}   onChange={ (e) => {
                                            if(activeAddress){
                                                setActiveAddress({
                                                    ...activeAddress,
                                                    lastName:e.target.value
                                                })
                                            }


                                        }}  type="text"  className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.lastName? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  placeholder=""
                                               name="lastName" />

                                        {errors && errors.lastName && (
                                            <div className="invalid-feedback show"> {errors.lastName.message} </div>
                                        )}
                                    </div>

                                </div>


                                <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container"
                                     data-select2-id="select2-data-358-xa5w">

                                    <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                                        <span className="required">Country</span>

                                    </label>

                                    <Select
                                        value={selectedCountry}
                                        onChange={selectCountry}
                                        options={countriesOptions}
                                    />
                                    {isCountryEmpty && (
                                        <div className="invalid-feedback show"> Country required</div>
                                    )}

                                </div>


                                <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">

                                    <label className="required fs-5 fw-semibold mb-2">Address Line 1</label>


                                    <input {...register("addressLine1")} value={activeAddress.addressLine1}   onChange={ (e) => {

                                        if(activeAddress){

                                            setActiveAddress({
                                                ...activeAddress,
                                                addressLine1:e.target.value
                                            })
                                        }

                                    }} type="text"  className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.addressLine1? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  placeholder=""
                                           name="addressLine1" />

                                    {errors && errors.addressLine1 && (
                                        <div className="invalid-feedback show"> {errors.addressLine1.message} </div>
                                    )}

                                </div>


                                <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">

                                    <label className="required fs-5 fw-semibold mb-2">Address Line 2</label>
                                    <input {...register("addressLine2")}  type="text" value={activeAddress.addressLine2} onChange={ (e) => {
                                        if(activeAddress){

                                            setActiveAddress({
                                                ...activeAddress,
                                                addressLine2:e.target.value
                                            })

                                        }
                                    }}   className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.addressLine1? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  placeholder=""
                                           name="addressLine2" />

                                    {errors && errors.addressLine2 && (
                                        <div className="invalid-feedback show"> {errors.addressLine2.message} </div>
                                    )}

                                </div>


                                <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">

                                    <label className="fs-5 fw-semibold mb-2">City</label>

                                    <input {...register("city")}  type="text"  value={activeAddress.city} onChange={ (e) =>{

                                        if(activeAddress){

                                            setActiveAddress({
                                                ...activeAddress,
                                                city:e.target.value
                                            })
                                        }

                                    }}  className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.city? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  placeholder=""
                                           name="city" />

                                    {errors && errors.city && (
                                        <div className="invalid-feedback show"> {errors.city.message} </div>
                                    )}

                                </div>


                                <div className="row g-9 mb-5">

                                    <div className="col-md-6 fv-row fv-plugins-icon-container">

                                        <label className="fs-5 fw-semibold mb-2">State / Province</label>

                                        <input {...register("stateProvince")} value={activeAddress.stateProvince} onChange={ (e) =>{

                                            if(activeAddress){

                                                setActiveAddress({
                                                    ...activeAddress,
                                                    stateProvince:e.target.value
                                                })
                                            }

                                        }}   type="text"  className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.stateProvince? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  placeholder=""
                                               name="stateProvince" />

                                        {errors && errors.stateProvince && (
                                            <div className="invalid-feedback show"> {errors.stateProvince.message} </div>
                                        )}


                                    </div>


                                    <div className="col-md-6 fv-row fv-plugins-icon-container">

                                        <label className="fs-5 fw-semibold mb-2">Post Code</label>


                                        <input {...register("postcode")}  type="text" value={activeAddress.postcode}  onChange={ (e) =>{
                                            if(activeAddress){

                                                setActiveAddress({
                                                    ...activeAddress,
                                                    postcode:e.target.value
                                                })
                                            }

                                        }}  className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.postcode? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  placeholder=""
                                               name="postcode" />

                                        {errors && errors.postcode && (
                                            <div className="invalid-feedback show"> {errors.postcode.message} </div>
                                        )}
                                    </div>

                                </div>


                            </div>

                    </Modal.Body>
                </form>
            </Modal>
            <Modal  backdrop="static" keyboard={false} centered show={showConfirmDeleteModal}  animation={false} onExit={() => {

            }}>

                <Modal.Header>
                    <div className="btn btn-icon btn-sm btn-active-light-primary ms-2 cursor-pointer"
                         onClick={ ()  =>  setConfirmDeleteModal(false) }>
                        Cancel
                    </div>
                    <Modal.Title>{"Delete"}</Modal.Title>
                    <div className="btn btn-icon btn-sm btn-active-light-primary ms-2">

                        {deleteLoading ? <span className="save">Deleting...</span> :
                            <span className="mx-3 close primary-color" onClick={ () => {


                                let addressData = {
                                    updateType: "delete-address",
                                    addressId:  deleteAddressId
                                }

                                setDeleteLoading(true)
                                apiCall(addressData)

                            }}>Delete</span>}
                    </div>
                </Modal.Header>

                    <Modal.Body>

                            <div className=" mb-5 text-center fs-4">
                                Are you sure you want to delete this address?
                            </div>

                    </Modal.Body>
            </Modal>
        </div>

    );
};
export default Addresses;
