import React, {FC, useState} from 'react';

import './messages.css'
import {MessageInterface} from "../../interfaces/message.interface";

type Props = {
    messages: [MessageInterface],
    activeMessage?: MessageInterface,
    updateActiveMessage:any
}

const MessagesSidebar: FC <Props> = ({ messages,updateActiveMessage,activeMessage}) => {

    const [filterKey, setFilterKey] = useState("");

    return (

        <>
            <div className=" d-none d-md-block">
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1 m-3">
                        <input type="text" className="form-control form-control-lg form-control-solid "
                               placeholder="Search..." value={filterKey} onChange={function (e){
                            setFilterKey(e.target.value)
                        }}  />
                    </div>
                </div>
            </div>
            <div className="messages-sidebar px-3">

                {messages.filter((messageData ) =>   (messageData._trip.name).toLowerCase().includes(filterKey.toLowerCase()) ).map(function (message, key) {
                    return(
                        <span key={key} className={`list-group-item list-group-item-action mb-2 p-3  border-0 cursor-pointer  text-dark ${activeMessage ?message._id == activeMessage._id? "active": "": ""}`} onClick={
                            function () {
                                updateActiveMessage(message)
                            }
                        }>
{/*
                        <div className="badge bg-primary float-right">5</div>
*/}
                            <div className="d-flex align-items-start">
                             {message._trip.cover ?
                                 <img src={message._trip.cover.url}
                                      className="rounded-circle mr-1"
                                      alt={message._trip.name} width="40" height="40"/>
                                 :
                                 <div className="symbol symbol-45px symbol-circle"><span
                                     className="symbol-label bg-light-danger text-danger fs-6 fw-bolder  text-capitalize">{(message._trip.name).charAt(0)}{((message._trip.name).split(" ")[1]).charAt(0)}</span>
                                 </div>
                             }
                                <div className="flex-grow-1 mx-3">
                                    <strong>{(message._trip.name).length > 40? (message._trip.name).substring(0,40) + '...' : message._trip.name}</strong>
                                    <div className="small"><span
                                        className="fas fa-circle chat-online"></span>
                                        {message._trip.status}
                                    </div>
                                </div>
                                <div className="flex-grow-1 mt-3">
                                    {message.hasNewMessageCount?
                                        <span className="badge bg-primary rounded-pill float-end">{message.hasNewMessageCount}</span>
                                    :""}
                                </div>
                        </div>
                    </span>
                    )
                })}

                <hr className="d-block d-lg-none mt-1 mb-0" />
            </div>
        </>

    );
};
export default MessagesSidebar;
