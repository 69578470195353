import React, { FC, useState } from 'react';
import QuillEditor from "../editor/QuillEditor";
import {BsCardList} from "react-icons/bs";
import GooglePlaces from "../../features/googleplaces/GooglePlaces";
import {GooglePlaceResponse} from "../../interfaces/trip.interface";
import {google_place_photo_url} from "../../services/api.utils";
import DeleteIconImg from "../../images/icons/delete.svg";
import {eSMoment} from "../../util";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import ErrorIconImg from "../../images/icons/error.svg";
import BackButtonImgIcon from "../../images/icons/back-button.svg";
import SoloTravelIcon from "../../images/illustration-icons/solo-travel.svg";
import DotRedImg from "../../images/icons/dot-red.svg";
import FamilyTravelIcon from "../../images/illustration-icons/family-travel.svg";
import GroupTravelIcon from "../../images/illustration-icons/group-travel.svg";
import Modal from "react-bootstrap/cjs/Modal";
import NewDay from "./components/NewDay";
import {ActivityTimeline, TimelineMarker} from "react-rainbow-components";
import NewDestination from "./components/NewDestination";



const NewTourPackage: FC = () => {


    const [note, setNote] = useState("");
    const [activeStep, setActiveStep] = useState('basic');



    const [showAddModal, setShowAddModal] = useState(false);
    const [modalType, setModalType] = useState('');

    return (
        <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4">
            <header className="header mx-2">
                <div className="d-flex flex-column justify-content-between flex-md-row align-items-center p-3 mb-4 mt-3 header-container">
                    <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                        <h3 className="text-dark fw-bolder fs-4">Create Tour Package</h3>
                    </div>
                </div>
            </header>

            <div className={`container business-container `}>
                <div className={`my-5`}>
                    <h1 className={`fs-2 fw-bolder text-dark`}>Add Package</h1>
                    <span className={`text-gray-400`}>Lorem ipsum dolor sit amet, consectetur.</span>
                </div>


                <div className={`row add-package-container`}>
                    <div className="col-4">
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className={`wizard-steps`}>
                                    <div className={`step d-flex align-items-center`} onClick={()=>setActiveStep('basic')}>

                                        <button className={`step-trigger`}>
                                             <span className={`box`}>
                                                <BsCardList size={20}/>
                                            </span>
                                            <span className={`details d-flex flex-column`}>
                                                <span className={`title`}>Basic</span>
                                                <span className={`sub-title`}>basic information</span>
                                            </span>
                                        </button>

                                    </div>
                                    <div className={`step d-flex align-items-center`}  onClick={()=>setActiveStep('destination')}>

                                        <button className={`step-trigger`}>
                                             <span className={`box`}>
                                                <BsCardList size={20}/>
                                            </span>
                                            <span className={`details d-flex flex-column`}>
                                                <span className={`title`}>Destination</span>
                                                <span className={`sub-title`}>Destination information</span>
                                            </span>
                                        </button>

                                    </div>
                                    <div className={`step d-flex align-items-center`}  onClick={()=>setActiveStep('itinerary')}>

                                        <button className={`step-trigger`}>
                                             <span className={`box`}>
                                                <BsCardList size={20}/>
                                            </span>
                                            <span className={`details d-flex flex-column`}>
                                                <span className={`title`}>Itinerary</span>
                                                <span className={`sub-title`}>Itinerary information</span>
                                            </span>
                                        </button>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        {activeStep === 'basic' && (
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Basic information</h5>
                                </div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="ecommerce-product-name">Name</label>
                                        <input type="text" className="form-control"
                                               placeholder="Package Name" name="productTitle"
                                               aria-label="Product title"/>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="form-label"
                                               htmlFor="ecommerce-product-sku">Description</label>
                                        <div className={`m-1`}>

                                            <QuillEditor initialValue={note} onEditorChange={function (value: any) {
                                                setNote(value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label"
                                               htmlFor="ecommerce-product-discount-price">Duration</label>
                                        <input type="number" className="form-control"
                                               placeholder="Duration" name="productDiscountedPrice"
                                               aria-label="Duration"/>
                                    </div>
                                </div>
                            </div>

                        )}
                        {activeStep === 'destination' && (
                            <div>
                                <div className="card mb-4">
                                    <div className="card-header pt-3">
                                        <h3 className="card-title">Destination</h3>
                                        <div className="card-toolbar">
                                            <button className={`btn btn-primary`} onClick={()=> {
                                                setShowAddModal(true)

                                                setModalType('destination')
                                            }}>Add Destination</button>
                                        </div>
                                    </div>
                                    <div className="card-body">



                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Itinerary</h5>
                                        <p>Providers that enable you to accept payment methods at a rate set by the
                                            third-party.
                                            An additional fee will apply to new orders once you select a plan</p>

                                    </div>
                                    <div className="card-body">
                                        <div className="col-12" >

                                            <ActivityTimeline>
                                                <TimelineMarker
                                                    label="User Sign Up."
                                                    icon={  <BsCardList size={20}/>}
                                                    datetime="Yesterday"
                                                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua."
                                                />
                                            </ActivityTimeline>
                                        </div>

                                    </div>
                                </div>

                            </div>


                        )}
                        {activeStep === 'itinerary' && (
                            <div>
                                <div className="card mb-4">
                                    <div className="card-header pt-3">
                                        <h3 className="card-title">Itinerary</h3>
                                        <div className="card-toolbar">
                                            <button className={`btn btn-primary`} onClick={()=> {
                                                setShowAddModal(true)
                                                setModalType('itinerary')
                                            }}>Add Day</button>
                                        </div>
                                    </div>
                                    <div className="card-body">



                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Itinerary</h5>
                                        <p>Providers that enable you to accept payment methods at a rate set by the
                                            third-party.
                                            An additional fee will apply to new orders once you select a plan</p>

                                    </div>
                                    <div className="card-body">
                                        <div className="col-12" >

                                            <ActivityTimeline>
                                            <TimelineMarker
                                                label="User Sign Up."
                                                icon={  <BsCardList size={20}/>}
                                                datetime="Yesterday"
                                                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua."
                                            />
                                        </ActivityTimeline>
                                        </div>

                                    </div>
                                </div>

                            </div>


                        )}

                    </div>

                </div>

            </div>

            <Modal show={showAddModal}  onHide={function () {

                setShowAddModal(false)
            }} animation={false} size={`lg`}  className={`suggestion-modal`} >

                <div className="modal-header pb-0 border-0">

                    <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {

                        setShowAddModal(false)
                    }}/>

                    <span className={`fw-bolder fs-4 text-dark text-capitalize`}>
                          {
                              modalType === 'destination' ?

                                  <span>Add Destination</span>

                                  : modalType === 'itinerary' ?

                                      <span>Add Day</span>

                                      : ''
                          }
                    </span>
                    <span></span>

                </div>
                <div className="modal-body">

                    {
                        modalType === 'destination' ?

                            <NewDestination/>

                        :modalType === 'itinerary' ?

                            <NewDay/>

                            :''
                    }

                </div>
            </Modal>
        </main>
    );
};

export default NewTourPackage;
