import React, { FC,useEffect,useState } from 'react';


import {TripActivity, TripDestination} from "../../../../interfaces/trip.interface";
import {eSMoment, getGooglePhotoUrl, getImgUrl} from "../../../../util";
import ReactRating from 'react-rating'
import dayjs from "dayjs";
import Dropdown from "react-bootstrap/cjs/Dropdown";
import EditDropDownCard from "./EditDropDownCard";
import {Currency} from "../../../../interfaces/country.interface";
import EmptyLocationImg from '../../../../images/empty/location.png'
import filter from "lodash/filter";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import LoaderLayout from "../../../loader/LoaderLayout";



import SecureIconImg from '../../../../images/icons/secure.svg';
import BudgetIconImg from '../../../../images/icons/budget.svg';
import DownloadIconImg from '../../../../images/icons/download.svg';
import NotesIconImg from "../../../../images/icons/notes.svg";
import EsRating from "../../../esRating/EsRating";
type Props = {
    activity:TripActivity,
    handleActivityUpdate?:any,
    tripOwner?:any,
    activeCurrency?:Currency,
    tripType?:string,
    width: number,
    tripStatus?:string,
}

const HotelCard: FC <Props> = ({activity, handleActivityUpdate,tripOwner, activeCurrency, tripType, width ,tripStatus}) => {

    const [clipboardText, setClipboardText] = useState("Copy Text to Clipboard");

    const checkOutDateString = eSMoment(activity.startDateTime).format('YYYY-MM-DD')
    const checkInDateString = eSMoment(activity.endDateTime).format('YYYY-MM-DD')

    const checkOutDate = dayjs(checkOutDateString)
    const checkInDate = dayjs(checkInDateString)
    let totalNights = checkOutDate.diff(checkInDate, 'day')

    const [updateType, setUpdateType] = useState("");


    return (
        <div className="card hotel flex-row" >
            <div className="ribbon-2"></div>

            <div className={`d-flex col-4 place-card-photo-container`} >
                <img className={`place-card-photo`} src={activity.placeOne?.photoReference? getGooglePhotoUrl(activity.placeOne?.photoReference, 300, 300):EmptyLocationImg }/>
            </div>

            <div className="hotel-card_info p-4 pt-2 flex-fill col-xl-8 col-12">
                <div className="d-flex justify-content-between mb-0">
                    <div className={`col-xl-8 col-6 pt-3`}   >
                        <h5 className="mb-0 mr-2 fs-5 fw-bolder">{activity.placeOne?.name}</h5>
                        <div className="rating">
                            {activity.placeOne?.rating?
                                <span  className={`d-flex`}><span className="count">{activity.placeOne?.rating} </span></span>

                                : "No rating" }
                        </div>
                        <div className="text-muted mb-2"><i className="bi bi-geo-alt-fill"></i> {activity.placeOne?.formattedAddress}</div>
                    </div>
                    <div className="d-flex">

                        <div className="me-n3 ">

                            {activity.privacy?

                                width >= 620 ?
                                    <OverlayTrigger  placement="top" overlay={
                                        <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                            <span  className={`text-dark`}>You can only view this content</span>
                                        </Tooltip>
                                    }>
                                        <img src={SecureIconImg} width={28} style={{ padding: '5px' }} className={`option`}/>
                                    </OverlayTrigger>
                                    :
                                    <img src={SecureIconImg} width={28} style={{ padding: '5px' }} className={`option`}/>

                                :""}
                            {handleActivityUpdate?
                                <span className={`card-options`}>
                                            {activity.notes ?

                                                width >= 620 ?
                                                    <OverlayTrigger  placement="top" overlay={
                                                        <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                                            <span  className={`text-dark`}>View Notes</span>
                                                        </Tooltip>
                                                    }>
                                                        <img src={NotesIconImg} width={28} className={`option`} onClick={(e) => {
                                                            if(handleActivityUpdate){

                                                                handleActivityUpdate(activity,"notes")
                                                            }
                                                        }}/>
                                                    </OverlayTrigger>
                                                    :
                                                    <img src={NotesIconImg} width={28} className={`option`} onClick={(e) => {
                                                        if(handleActivityUpdate){

                                                            handleActivityUpdate(activity,"notes")
                                                        }
                                                    }}/>
                                                :""}
                                    {activity.budget ?

                                        width >= 620 ?
                                            <OverlayTrigger  placement="top" overlay={
                                                <Tooltip id={`tooltip-1`}   className={`card-options-tooltip`}>
                                                    <span  className={`text-dark`}>View budget</span>
                                                </Tooltip>
                                            } >
                                                <img src={BudgetIconImg} width={28} className={`option`}   onClick={(e) => {
                                                    if(handleActivityUpdate){

                                                        handleActivityUpdate(activity,"budget")
                                                    }
                                                }}/>
                                            </OverlayTrigger>

                                            :
                                            <img src={BudgetIconImg} width={28} className={`option`}   onClick={(e) => {
                                                if(handleActivityUpdate){

                                                    handleActivityUpdate(activity,"budget")
                                                }
                                            }}/>


                                        :""}
                                    {activity.invoices?.length ?

                                        width >= 620 ?
                                            <OverlayTrigger  placement="top" overlay={
                                                <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                                    <span className={`text-dark`}>Download Files</span>
                                                </Tooltip>
                                            }>
                                                <img src={DownloadIconImg} width={28} className={`option`}  onClick={(e) => {
                                                    if(handleActivityUpdate){

                                                        handleActivityUpdate(activity,"download")
                                                    }
                                                }}/>
                                            </OverlayTrigger>
                                            :
                                            <img src={DownloadIconImg} width={28} className={`option`}  onClick={(e) => {
                                                if(handleActivityUpdate){

                                                    handleActivityUpdate(activity,"download")
                                                }
                                            }}/>
                                        :""}

                                    <EditDropDownCard width={width} tripType={tripType} tripStatus={tripStatus} activity={activity}  handleActivityUpdate={handleActivityUpdate} tripOwner={tripOwner} />

                                    </span>
                                : "" }

                        </div>

                    </div>
                </div>
                <div className="row hotel-card_details ">

                    <div className="d-flex justify-content-between  mt-1">
                        <div>
                            <span className="fs-8 fw-bolder text-muted">Check In</span>
                            <span className="fs-6 text-gray-800 fw-bolder d-block text-hover-primary">{eSMoment(activity.startDateTime).format('hh:mm A, DD MMM YYYY')}</span>
                        </div>
                        <div>
                            <span className="fs-8 fw-bolder text-muted ">Check Out</span>
                            <span className="fs-6 text-gray-800 fw-bolder d-block text-hover-primary">{eSMoment(activity.endDateTime).format('hh:mm A, DD MMM YYYY')}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between  mt-1">
                        <div>
                            <span className="fs-8 fw-bolder text-muted">Booked For</span>
                            <span className="fs-6 text-gray-800 fw-bolder d-block text-hover-primary">{`${totalNights+1} Day${totalNights+1>1?'s':''}/${totalNights} Night${totalNights > 1? 's': ''}`}</span>
                        </div>
                        <div>
                            <span className="fs-8 fw-bolder text-muted ">Room Number</span>
                            <span className="fs-6 text-gray-800 fw-bolder d-block text-end text-hover-primary">1</span>
                        </div>
                    </div>
                </div>
                <div className="row  mt-3"  onClick={() => {
                    if(handleActivityUpdate){
                        // handleActivityUpdate(activity,"detail")
                    }
                }}>
                    <div className={`col-8`}>
                        {tripType != 'solo'?
                            <div className="d-flex ms-2">
                                <div className={`symbol-group symbol-hover`}>
                                    {activity && activity.members? filter(activity.members, (member)=>{ return member.amount != ""? true: false}).slice(0,3).map(function (member,key) {

                                        return(
                                            <OverlayTrigger  placement="top"  overlay={
                                                <Tooltip id={`tooltip-${key}`} className={`card-members-tooltip`}>
                                                    <div className="text-start" >
                                                        <div className="fs-7 fw-bolder">{member.user?.firstName} {member.user?.lastName}</div>
                                                        <div className=" me-2 fs-7"><i className='bx bx-money'></i> {member.amount} {activeCurrency?.code}</div>
                                                    </div>
                                                </Tooltip>
                                            }>
                                                <div className="symbol symbol-25px symbol-circle cursor-pointer" key={key}>
                                                    {member.user?.cover?
                                                        <img alt="Pic" className={`avatar`} src={getImgUrl(member.user?.cover?.generatedName,member.user._id,'50x')}  />
                                                        :
                                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder avatar">{member.user?._id?(member.user?.firstName).charAt(0): ""}</span>
                                                    }

                                                </div>
                                            </OverlayTrigger>
                                        )

                                    }): ""}
                                </div>

                            </div>
                            :""
                        }

                    </div>
                    <div className={`col-4  p-0`}>
                        <h3 className="fs-4 fw-bolder primary-color text-end">{activity.budget} {activeCurrency?.code}</h3>

                    </div>

                </div>
            </div>
        </div>


    );
};
export default HotelCard;
