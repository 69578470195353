import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {NotificationsResponse} from '../../interfaces/notification.interface';

const notifications = createSlice({
    name: 'notifications',
    initialState: null as NotificationsResponse | null,
    reducers: {
        setNotifications(state, { payload }: PayloadAction<NotificationsResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setNotifications } = notifications.actions;
export default notifications.reducer;
