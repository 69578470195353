import React, {FC, useEffect, useState} from 'react';
import Dropdown from 'react-bootstrap/cjs/Dropdown';
import Loader from "../../loader/Loader";
import Modal from "react-bootstrap/cjs/Modal";
import http from "../../../services/api";
import {
    AiResponse,
    Trip,
    TripDestination, TripDestinationResponse,
    TripMember,
    TripMemberPopulated,
    TripResponse
} from "../../../interfaces/trip.interface";
import {
    authenticate_url,
    checklist_url,
    get_ai_checklist_suggestions,
    get_ai_suggestions, google_place_photo_url,
    trip_url,
    user_url
} from "../../../services/api.utils";
import {setTrip} from "../tripSlice";
import filter from "lodash/filter";
import {Checklist, ChecklistCheckBox, ChecklistItem, ChecklistsResponse} from "../../../interfaces/checklist.interface";

import './checklist.scss'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {useForm} from "react-hook-form";
import {eSMoment, getImgUrl, showAlert, toastMessage} from "../../../util";
import QuillEditor from "../../editor/QuillEditor";
import {cloneDeep} from "lodash";


import CheckboxAll from '../../../images/illustration-icons/checkbox-all.svg';
import CheckboxCheck from '../../../images/illustration-icons/checkbox-check.svg';
import CheckboxNoCheck from '../../../images/illustration-icons/checkbox-no-check.svg';
import BackButtonImgIcon from "../../../images/icons/back-button.svg";
import OptionsIconImg from "../../../images/icons/options.svg";

import EmptyChecklistImg from "../../../images/illustration-icons/empty-checklist.svg";
import AIIconImg from "../../../images/icons/ai-icon.png";
import {setAppBasic} from "../../../app/appBasicSlice";
import {useAppDispatch} from "../../../store";
import dayjs from "dayjs";
import ReactRating from "react-rating";
import {useSelector} from "react-redux";
import {RootState} from "../../../rootReducer";
import loadingImg from "../../../images/loaders/earth.svg";

type ParamTypes =  {
    tripId: string,
    destinationId:string
}
type Props = {
    tripData:Trip,
    updateTripChecklist:any,
    updateTripData:any
}

const schema = Yup.object().shape({
    title: Yup.string().required('Title required'),
});

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const TripCheckList: FC<Props> = ({tripData,updateTripChecklist, updateTripData}) => {
    const [loading, setLoading] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [note, setNote] = useState("");
    const [selectedChecklist, setSelectedChecklist] = useState<Checklist|undefined>();
    const [checklists, setChecklists] = useState<[Checklist]|undefined>(tripData.checklists);
    const [option, setOption] = useState("");
    const [filterKey, setFilterKey] = useState("all");
    const [searchKey, setSearchKey] = useState("");

    const [showAIModal, setShowAIModal] = useState(false);
    const { user, appBasic, notifications, currencies } = useSelector((state: RootState) => state);

    const [showAIConfirmModal, setShowAIConfirmModal] = useState(false);

    const [isAiSuggestionSubmit, setIsAiSuggestionSubmit] = useState(false);

    const { handleSubmit, register, reset,setFocus,setValue, formState: { errors } } = useForm<Checklist>({
        resolver: yupResolver(schema),
    });

    const [showAddNewModal,setShowAddNewModal] = useState(false)
    const dispatch = useAppDispatch();
    const { width } = useViewport();


    let passengerArray:any = tripData.members.map( (member, index) => {
        if(member.userId){
            return({
                _id:member.userId._id,
                firstName:member.userId.firstName,
                lastName:member.userId.lastName,
                userName:member.userId.userName,
                cover:member.userId.cover,
                parentId:member.parentId,
                status:member.status,
            })
        }

    })

    useEffect(() => {


        if(appBasic?.aiCheckListWaiting?._trip == tripData._id && appBasic?.aiCheckListWaiting?.status == "completed" ){


            setShowAIModal(true)

        }


    },[appBasic?.aiCheckListWaiting])
    const submitForm = (formData: Checklist) => {
        formData.description = note
        setLoading(true)
        if(selectedChecklist){

            editCheckList(formData,"edit",selectedChecklist._id)
        }else{
            http
                .post<Checklist, ChecklistsResponse>(`${trip_url}/${tripData._id}/checklists`, formData)
                .then((res) => {
                    if (res) {

                        const { data, status, message} = res;


                        if(status == 200 ) {
                            setShowAddNewModal(false)
                            setChecklists(data.checklists)

                            updateTripChecklist(data.checklists)

                        }


                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const editCheckList = (formData: Checklist,type:string, _id:string) => {

        formData.type = type

        if(type == "status"){

            let checklistsClone = cloneDeep(tripData.checklists);

            if(checklistsClone){

                //tripData.checklists
                let foundIndex = checklistsClone.findIndex(checklistItem => checklistItem._id == _id);

                checklistsClone[foundIndex].status = checklistsClone[foundIndex].status == "pending"? "completed": "pending"

                setChecklists(checklistsClone)
                updateTripChecklist(checklistsClone)
            }

        }

        http
            .put<Checklist, ChecklistsResponse>(`${trip_url}/${tripData._id}/checklist/${_id}`, formData)
            .then((res) => {
                if (res) {

                    const { data, status, message} = res;


                    if(status == 200 ) {

                        setShowAddNewModal(false)
                        if(type != "status"){

                            setChecklists(data.checklists)
                            updateTripChecklist(data.checklists)
                        }
                    }


                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const getAISuggestions = () => {


        updateTripData({
            ...tripData,
            checkListSuggestionsInProgress:true
        })
/*
        updateTripDestination({
            ...activeDestination,
            suggestionsInProgress:true
        })*/


        dispatch(setAppBasic({aiCheckListWaiting:{
                _trip:tripData._id,
                status:'pending',
            }}
        ));


        http
            .post<null, AiResponse>(get_ai_checklist_suggestions, {
                _trip: tripData._id,
            })
            .then((res) => {
                if (res) {
                    if (res.status == 200){
/*
                        updateTripDestination({
                            ...activeDestination,
                            suggestionsInProgress:false,
                            suggestions:res.data
                        })*/

                        // setShowAIModal(true)

                        //  setSelectedAttractionsData(res.data[0])

                        //  toastMessage(`AI Suggestion generation completed for ${activeDestination?.name} in ${tripData.name} `,'')


                    }else{
                        toastMessage(res.message,'error')

                    }



                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    }


    const moveAiLocationsToChecklist = () => {
        setIsAiSuggestionSubmit(true)
        http
            .post<null, ChecklistsResponse>(trip_url+"/"+tripData._id+"/ai-checklists")
            .then((res) => {
                if (res) {



                    setChecklists(res.data.checklists)
                    updateTripChecklist(res.data.checklists)

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsAiSuggestionSubmit(false)
                setShowAIModal(false)
            });
    }



    return (

        <>
            <div className="col-xl-6 col-md-6">
                <div className="row">

                    <div className="col-xl-12 col-md-12">

                        <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none">
                            <div className="card-header align-items-center border-0">
                                <div className="card-title align-items-start flex-column"><h3
                                    className="fw-bolder mb-1">Checklists</h3>
                                    <div className="fs-7 text-gray-400">Total {checklists && checklists.length}   {` added, `}
                                        {checklists && filter(checklists, (o) => {
                                            return o.status == "completed";
                                        }).length}
                                        {` completed, `}
                                        {checklists && filter(checklists, (o) => {
                                            return o.status == "pending";
                                        }).length}
                                        {` pending, `}
                                    </div>
                                </div>
                                {tripData.createdType !== "ai"?
                                    <div className={`card-toolbar ${width <= 620? 'w-100 d-flex justify-content-center':''}`}>
                                        <button  className={`btn btn-primary btn-icon float-end ms-2 ${width <= 620? 'me-3':'' } ${tripData.status == 'completed'? 'disabled':''}`} onClick={() => {
                                            if(tripData.status != 'completed'){

                                                setSelectedChecklist(undefined)
                                                setNote("")
                                                setValue('title',"");
                                                setShowAddNewModal(true)
                                            }
                                        }}>
                                            <i className="bi bi-plus-circle"></i> Add New
                                        </button>
                                        <button className={`btn  ${tripData.status == 'completed'? 'disabled': ''} ${tripData?.checkListSuggestions?.length? '': tripData?.checkListSuggestionsInProgress? ' ai-loading-btn': ''}` } style={{ backgroundColor: '#50579f', color:'#fff'}} onClick={() => {

                                            if(tripData?.checkListSuggestions?.length){
                                                setShowAIModal(true)
                                            }else{

                                                if(! tripData?.checkListSuggestionsInProgress){

                                                    setShowAIConfirmModal(true)
                                                }
                                            }
                                        }} disabled={tripData?.checkListSuggestions?.length? false: tripData?.checkListSuggestionsInProgress}><img src={AIIconImg} width={20}/>{tripData?.checkListSuggestions?.length? <span>View AI</span>: tripData?.checkListSuggestionsInProgress? <span>Generating AI...</span> : <span>AI Planner</span>}</button>
                                    </div>
                                :""}

                            </div>
                            <div className="row  m-2">
                                <form className=" mt-md-0">
                                    <div className="btn-group w-100" role="group" aria-label="Basic example">
                                        <input type="text"
                                               className="form-control form-control-lg form-control-solid"
                                               name="tripName" placeholder="search by name" onChange={ (e) =>{
                                            setSearchKey(e.target.value)
                                        }} />
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="todo-list">
                            <ul className="list-group ui-sortable">

                                {checklists?  filter(checklists, (o) => {
                                        if(filterKey != "all"){
                                            return o.status == filterKey;
                                        }else{
                                            return true
                                        }
                                    }).length? filter(checklists, (o) => {
                                        if(filterKey != "all"){
                                            return o.status == filterKey;
                                        }else{
                                            return true
                                        }
                                    }).filter((checklists ) => (checklists.title).toLowerCase().includes(searchKey.toLowerCase()) ).map( (checklistItem, key) => {

                                    var startDate = eSMoment(checklistItem.createdAt, );
                                    var endDate = eSMoment();

                                    var dayDiff = endDate.diff(startDate, 'days');
                                    return(
                                        <li className={`list-group-item gap-3 mb-3 checklist-item cursor-pointer ${checklistItem.status}`}>

                                            <div className="d-flex col " >
                                                {  tripData.status != 'completed'?
                                                    <div className="flex-shrink-0 pt-3 pe-3"  onClick={() => {

                                                        setSelectedChecklist(checklistItem)
                                                        editCheckList(checklistItem,"status",checklistItem._id)

                                                    }} >
                                                        <input className="form-check-input" type="checkbox" checked={checklistItem.status == "completed"?true:false} onClick={() => {

                                                            //   setSelectedChecklist(checklistItem)
                                                            // editCheckList(checklistItem,"status",checklistItem._id)
                                                        }} />
                                                    </div>
                                                :

                                                ""}

                                                <div
                                                    className="d-flex align-items-center col" onClick={() => {

                                                        if( tripData.status != 'completed'){

                                                            setSelectedChecklist(checklistItem)
                                                            editCheckList(checklistItem,"status",checklistItem._id)
                                                        }
                                                }}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="ms-3">
                                                            <span className="fs-6 text-gray-900 text-hover-primary mb-2 cursor-pointer" dangerouslySetInnerHTML={{ __html: checklistItem.title as string }}></span>
                                                            <div className="fs-7 text-muted fw-bold mb-2" dangerouslySetInnerHTML={{ __html: checklistItem.description as string }}></div>

                                                        </div>
                                                    </div>


                                                </div>
                                                    <div className="card-toolbar">

                                                        <div className="mt-3 list-options">
                                                            <Dropdown className={` mt-2 `} onSelect={ (eventKey: any, event: Object) =>{

                                                               if(tripData.status != 'completed'){
                                                                   setOption(eventKey)

                                                                   if(eventKey == "edit"){
                                                                       setShowAddNewModal(true)
                                                                       setValue('title', checklistItem.title);
                                                                       setSelectedChecklist(checklistItem)
                                                                       setNote(checklistItem.description)
                                                                   }else if(eventKey == "delete"){
                                                                       editCheckList(checklistItem,"delete",checklistItem._id)
                                                                   }else if(eventKey == "clone"){
                                                                       editCheckList(checklistItem,"clone",checklistItem._id)
                                                                   }
                                                               }

                                                                if(eventKey == "detail"){
                                                                    setSelectedChecklist(checklistItem)
                                                                    setShowDetailModal(true)
                                                                }

                                                            }}>
                                                                <Dropdown.Toggle variant="primary" id="dropdown-basic" className={`p-0`}>
                                                                    <img src={OptionsIconImg} width={28} className={` p-1 hover-opacity-btn`}/>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item eventKey="detail" >View Detail</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="edit" className={`${tripData.status == 'completed'? 'disabled': ''}`} >Edit</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="clone" className={`${tripData.status == 'completed'? 'disabled': ''}`} >Clone</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="delete" className={`${tripData.status == 'completed'? 'disabled': ''}`} >Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>

                                                    </div>
                                            </div>


{/*
                                                <div  className="flex-grow-1 col-10 "  onClick={() => {
                                                    setSelectedChecklist(checklistItem)
                                                    editCheckList(checklistItem,"status",checklistItem._id)
                                                }} >
                                                    <div className="text-truncate fw-bolder">{checklistItem.title}</div>
                                                    <div className="text-truncate fs-8 text-muted"  dangerouslySetInnerHTML={{__html: checklistItem.description}} />

                                                </div>

                                            <div className="">
                                                <div className="ps-3 d-flex gap-3 align-items-center flex-shrink-0">

                                                </div>

                                            </div>*/}
                                        </li>
                                    )
                                }):

                                    <div className={`card py-5`}>
                                        <div className="text-center p-2">

                                            <img src={EmptyChecklistImg} width={100}/>
                                            <p className="fs-5 text-dark fw-bolder">No Checklist</p>
                                            <p className="text-gray-400">Please check after adding planning or activities from <span className="fw-bolder">destination dashboard</span>
                                            </p>
                                        </div>

                                    </div>

                                    :

                                    <div className={`card py-5`}>

                                        <a href="#" className="card card-stretch mb-5 bg-light-info hoverable shadow-none trip-list-select">

                                            <div className="card-body d-flex flex-column pt-5">

                                                <Loader cssClass="normal-loader"/>
                                            </div>
                                        </a>
                                    </div>
                                }



                            </ul>
                        </div>

                    </div>


                </div>




            </div>
            <div className="col-xl-3 col-md-3 mb-9 mb-xl-0">
                <div className="card card-stretch mb-3 mb-xxl-8">
                    <div className="card-body">
                        <div className="card-body p-0 dashboard-menu ">
                            <div className={`d-flex flex-stack py-2 px-2  mb-1 menu-item cursor-pointer ${filterKey == "all"? "active": ""}`} onClick={() => {
                                setFilterKey("all")
                            }}>
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-45px symbol-circle"><span
                                        className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                        <img src={CheckboxAll} width={20}/>
                                    </span></div>
                                    <div className="ms-3"><span className="fs-5 fw-bolder title text-hover-primary mb-2">All</span>
                                        <div className="fw-bold text-gray-400">Get overview and statistics in a glance
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`d-flex flex-stack py-2 px-2  mb-1 menu-item cursor-pointer ${filterKey == "pending"? "active": ""}`} onClick={() => {
                                setFilterKey("pending")
                            }}>
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-45px symbol-circle"><span
                                        className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                        <img src={CheckboxNoCheck} width={20}/>

                                    </span></div>
                                    <div className="ms-3"><span className="fs-5 fw-bolder title text-hover-primary mb-2">Pending</span>
                                        <div className="fw-bold text-gray-400">Upcoming and completed checklists</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`d-flex flex-stack py-2 px-2  mb-1 menu-item cursor-pointer ${filterKey == "completed"? "active": ""}`} onClick={() => {
                                setFilterKey("completed")
                            }}>
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-45px symbol-circle"><span
                                        className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                         <img src={CheckboxCheck} width={20}/>

                                    </span></div>
                                    <div className="ms-3"><span className="fs-5 fw-bolder title text-hover-primary mb-2">Completed</span>
                                        <div className="fw-bold text-gray-400">Upcoming and completed checklists</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showAIConfirmModal}  className="" onHide={function () {

                setShowAIConfirmModal(false)
            }} animation={true} centered>

                {tripData?.checkListSuggestionsInProgress?

                    <>
                        <div className="modal-header border-0">
                            <h5 className="modal-title fs-5 fw-bolder text-dark"> <img src={loadingImg} width={20}/>Generating Your Personalized Checklist</h5>
                        </div>
                        <div className="modal-body border-0 text-center">


                            <p className={`text-dark`}>

                                AI Checklist Generation is currently in progress. You can either stay here until it's finished, or take the opportunity to explore other exciting functionalities on our platform. You'll be notified once your checklist is ready, and by default, it will be organized based on the tasks and due dates. Usually, this procedure takes about 1-2 minutes.
                            </p>
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-secondary" onClick={()=>{setShowAIConfirmModal(false)}}>Close </button>

                        </div>
                    </>
                    :
                    <>
                        <div className="modal-header border-0">
                            <h5 className="modal-title fs-5 fw-bolder text-dark">AI-Generated Checklist</h5>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <p className={`text-dark`}>
                                Generate a personalized checklist for your chosen dates at the designated destination. Please note that checklist creation is contingent upon the availability of tasks. If there are more dates than available tasks, customization options may be constrained.
                            </p>
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-secondary" onClick={()=>{setShowAIConfirmModal(false)}}>Cancel </button>
                            <button type="button" className="btn btn-primary" onClick={()=>{

                                getAISuggestions()
                            }}>Confirm</button>
                        </div>
                    </>
                }


            </Modal>
            <Modal show={showAddNewModal} className="planning-form-modal"  animation={true}>
                <form autoComplete="off"  onSubmit={handleSubmit(submitForm)} >
                <Modal.Body>

                    <div className="row">
                        <div className="p-2 col-4 text-start" >
                            <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn ms-3`} width={20} onClick={()=> setShowAddNewModal(false)}/>
                        </div>
                        <div className="p-2 col-4 fs-4 fw-bolder text-center">
                            {selectedChecklist?"Update Checklist": "Add checklist"}
                        </div>

                        <div className="p-2 col-4 text-end save" >
                            {loading?
                                <button type="button" className="btn btn-none primary-color">{selectedChecklist?"Updating...":"Saving..."}</button>
                                :
                                <button type="submit" className="btn btn-none hover-color ">{selectedChecklist? "Update":"Save" }</button>
                            }
                        </div>
                    </div>


                        <div className="mb-3 row mx-1">
                            <input {...register("title")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.title? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="title"
                                   placeholder="Checklist name" />
                        </div>
                        <div className="mb-3 row mx-1">
                            <QuillEditor users={passengerArray} initialValue={note} onEditorChange={ (value:any) => {
                                setNote(value)
                            }} />
                        </div>


                </Modal.Body>
                </form>
            </Modal>


            <Modal show={showAIModal}  className="" onHide={function () {

                setShowAIModal(false)
            }} size="lg" animation={true} centered>
                <Modal.Body>

                    <div className="row">
                        <div className="p-2 col-4 text-start" >
                            <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn ms-3`} width={20} onClick={()=> setShowAIModal(false)}/>
                        </div>
                        <div className="p-2 col-4 fs-4 fw-bolder text-center">
                            Ai Generated checklist
                        </div>

                        <div className="p-2 col-4 text-end  save" >
                            <button type="button" className={`btn btn-primary me-2 ${isAiSuggestionSubmit? 'disabled': ''} `} disabled={isAiSuggestionSubmit} onClick={()=>{

                                if(!isAiSuggestionSubmit) {

                                    moveAiLocationsToChecklist()

                                }

                            }}>{isAiSuggestionSubmit? 'Moving... Please wait':'Move to trip'}</button>

                        </div>
                    </div>


                    <div className="row">
                        <div className="todo-list">
                            <ul className="list-group ui-sortable">

                                {tripData.checkListSuggestions && tripData.checkListSuggestions.length? tripData.checkListSuggestions.map( (checklistItem, key) => {

                                            var startDate = eSMoment(checklistItem.createdAt, );
                                            var endDate = eSMoment();

                                            var dayDiff = endDate.diff(startDate, 'days');
                                            return(
                                                <li className={`list-group-item gap-3 mb-3 cursor-pointer ${checklistItem.status}`} style={{ backgroundColor: '#f5f8fa'}}>

                                                    <div className="d-flex col " >

                                                        <div
                                                            className="d-flex align-items-center col">
                                                            <div className="d-flex align-items-center">

                                                                <div className="ms-3">
                                                                    <span className="fs-6 text-gray-900 text-hover-primary mb-2 cursor-pointer" dangerouslySetInnerHTML={{ __html: checklistItem.title as string }}></span>
                                                                    <div className="fs-7 text-muted fw-bold mb-2" dangerouslySetInnerHTML={{ __html: checklistItem.description as string }}>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>

                                                </li>
                                            )
                                        }):

                                        <div className={`card py-5`}>
                                            <div className="text-center p-2">

                                                <img src={EmptyChecklistImg} width={100}/>
                                                <p className="fs-5 text-dark fw-bolder">No Checklist</p>
                                                <p className="text-gray-400">Please check after adding planning or activities from <span className="fw-bolder">destination dashboard</span>
                                                </p>
                                            </div>

                                        </div>

                                }



                            </ul>
                        </div>
                    </div>




                </Modal.Body>
            </Modal>
            <Modal show={showDetailModal} className="planning-form-modal"  animation={true}>
                <Modal.Body>
                    <div className="d-flex text-center header">
                        <div className="p-2 flex-fill text-start">
                            <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=> setShowDetailModal(false)}/>

                        </div>
                    </div>
                    <div className="mb-5 mt-2 mx-2">
                        <div className="mb-6">
                            <a href="#" className="fw-bold text-dark mb-4 fs-2 lh-base text-hover-primary">{selectedChecklist?.title}</a>
                            <div className="fw-semibold fs-5 mt-4 text-gray-600 text-dark" dangerouslySetInnerHTML={{__html: selectedChecklist?selectedChecklist.description:""}} />
                        </div>
                        <div className="d-flex flex-stack flex-wrap">

                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45px symbol-circle me-1 ">
                                    {selectedChecklist?.userId?.cover?
                                        <img alt="Pic" src={getImgUrl(selectedChecklist?.userId?.cover.generatedName,selectedChecklist?.userId?._id,'50x')} />
                                        :
                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{selectedChecklist?.userId._id?(selectedChecklist?.userId.firstName).charAt(0): ""}</span>
                                    }

                                </div>
                                <div className="">
                                    <a  className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">{selectedChecklist?.userId.firstName} {selectedChecklist?.userId.lastName}</a>
                                    <div className="fw-bold text-gray-400">{selectedChecklist?.userId.userName}</div>
                                </div>
                            </div>

                            <div className="fs-5 fw-bold text-end">
                                <span className={` ${selectedChecklist?.status == "pending"?'bg-primary': 'bg-success'} badge fw-bold my-2`}>{selectedChecklist?.status}</span>
                                <div className="text-muted fs-7">{eSMoment(selectedChecklist?.createdAt).format('hh.mma, DD MMM YY')}</div>
                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </>






    );
};
export default TripCheckList;
