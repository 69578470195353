export const base_url = process.env.REACT_APP_API_URL;
export const wss_url = process.env.REACT_APP_SOCKET_URL;
export const s3_user_url = process.env.REACT_APP_S3_USER_URL;


export const wp_url = process.env.REACT_APP_WP_URL;


//export const base_url = 'http://localhost:4000';
export const authenticate_url = '/users/authenticate';
export const register_url = '/users';
export const register_business_url = '/users/business';
export const wait_list_url = '/users/wait-list';
export const intro_url = '/intro';
export const airports_url = '/airports';
export const user_url = '/user';
export const social_authenticate_url = '/users/authenticate/social';


export const countries_url = '/countries';
export const user_logs_url = '/logs';
export const user_transactions_url = '/transactions';


export const trips_url = '/trips';
export const trips_ai_url = '/trips/ai';
export const trip_url = '/trip';
export const trips_invite_url = '/trips/invited';
export const user_search_url = '/users/search';

export const notifications_url = '/notifications';
export const upload_url = '/upload';
export const email_check_url = '/trip/email/check';
export const trip_data_validation = '/ai/get-validate-trip-destinations';
export const ai_trip_data_validation = '/ai/get-validate-ai-trip';
export const ai_trip_logged_data_validation = '/ai/get-validate-ai-logged-trip';
export const country_code_url = '/country-by-code';
export const currencies_code_url = '/currencies';
export const itinerary_url = '/itinerary';
export const currencies_live_currency_url = '/currency/live/conversion';
export const checklist_url = '/checklist';
export const airport_url = '/airports';
export const currencies_convert_url = '/currencies/convert';

export const messages_url = '/messages';
export const message_url = '/message';
export const message_to_share_url = '/message-share';
export const get_ai_suggestions = '/ai/get-suggestions';
export const get_ai_checklist_suggestions = '/ai/get-checklist-suggestions';
export const create_payment_intent = '/create-payment-intent';

export const user_packages = '/packages';
export const dashboard_url= '/dashboard';
export const join_trip_url= '/trips/join';
export const trip_date_check_url= '/trips/date-check';
export const trip_calender_url= '/trips/calender';
export const gm_near_by_places= '/google-map/near-by-places';
export const activity_types_url = '/activity-types';

export const google_map_location_url = 'http://maps.google.com/maps?z=12&t=m&q=loc:';
export const google_place_photo_url = 'https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&key=AIzaSyAXnOmNWA3f3dUAQhJKiAcC1cFFCSi-Byo&photo_reference=';
export const get_place_details = '/place';
export const get_google_auto_place = '/google-auto-place';

