import React, {FC, useEffect, useState} from 'react';
import Dropdown from 'react-bootstrap/cjs/Dropdown';
import {Trip, TripDestination, TripDestinationResponse, TripDocumentResponse} from "../../../interfaces/trip.interface";
import {eSMoment, formatBytes, getFileDataURL, showAlert, toastMessage} from "../../../util";

import './documents.scss'
import Modal from "react-bootstrap/cjs/Modal";
import Select from "react-select";
import {getPDFThumbnail} from "../../../utils/pdfthumbnail";
import http from "../../../services/api";
import {UploadResponse} from "../../../interfaces/upload.interface";
import {base_url, trip_url, upload_url} from "../../../services/api.utils";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";






import ImagesImg from '../../../images/illustration-icons/file-types/images.svg';
import FilesImg from '../../../images/illustration-icons/file-types/files.svg';
import DocIconImg from '../../../images/illustration-icons/file-types/doc-icon.svg';
import PdfIconImg from '../../../images/illustration-icons/file-types/pdf-icon.svg';
import DownloadIconImg from "../../../images/icons/download.svg";

import ViewFileIconImg from "../../../images/icons/view-file.svg";
import DeleteIconImg from "../../../images/icons/delete.svg";
import BackButtonImgIcon from "../../../images/icons/back-button.svg";
import DownloadImgIcon from "../../../images/icons/download.svg";
import VerticalMenuImgIcon from "../../../images/icons/vertical-menu.svg";
import EmptyDocumentsImg from "../../../images/illustration-icons/empty-documents.svg";

import Loader from "../../loader/Loader";
import EmptyActivitiesImg from "../../../images/illustration-icons/empty-activities.svg";


const PdfViewer = React.lazy(() => import('../../../utils/pdfViewer'));

type Props = {
    tripData:Trip | undefined,
    updateTripDocuments:any
}

const TripDocuments: FC<Props> = ({tripData,updateTripDocuments}) => {


    const [showUploadModal, setShowUploadModal] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [documents, setDocuments] = useState<any[]>([]);
    const [showFileModel, setFileModel] = useState(false);
    const [selectedFile, setSelectedFile] = useState<{folder: string,
        generatedName:string,
        size:string,
        originalName:string,
        createdAt:string,
        extension?:string
        url: string,
        contentType?: string,
    }>();


    useEffect(()=>{

        const form = new FormData();
        form.append("text", "1111");

        let object:any = {};
        form.forEach((value, key) => { object[key] = value });

        console.log("formData:", object);
    },[])
    const manageSelectedFile = async (fileData:any) => {

        if(6553600 <= fileData.size ){
            toastMessage("Please upload less than 5MB...","error")
        }else{

            let url = ""
            let fileDataType = ""
            let size = fileData.size
            let file = fileData

            if(fileData.type == "image/png" || fileData.type == "image/jpeg" ){
                url = URL.createObjectURL(fileData)

                if(fileData.type == "image/jpg"){
                    fileDataType = "JPG"
                }else if(fileData.type == "image/jpeg"){
                    fileDataType = "JPEG"
                }else{
                    fileDataType = "PNG"
                }
            }else if(fileData.type == "application/pdf"){


                let getPDFThumbnailData = await getPDFThumbnail(
                    fileData,
                    { // thumb image config
                        fileName: 'mythumbimage.png', // thumb file name
                        height: 200, // image height
                        width: 200, // image width
                        pageNo: 1  // pdf page number
                    }
                )

                url = getPDFThumbnailData.imageUrl
                fileDataType = "PDF"

            }else if(fileData.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
                fileDataType = "DOCX"
            }else if( fileData.type == "application/msword"){
                fileDataType = "DOC"
            }


            if(fileDataType){

                setDocuments(documents => [...documents, {
                    data:file,
                    type:fileDataType,
                    url:url,
                    size:size
                }]);
            }
        }


    }

    const uploadCallback = async (fileData: { data: File; size: number }) => {


        console.log("formData:", fileData.data);


        const formDataObj = new FormData();



        formDataObj.append("file", fileData.data);
        formDataObj.append("size", ((fileData.size).toString()));
        formDataObj.append("folder",`trips/${tripData?._id}/documents`);




        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function(progressEvent:any) {

                let loaded = parseFloat(progressEvent.loaded);
                let total = parseFloat(progressEvent.loaded);
                const progress = Math.round(loaded * 100) / total;

            }
        }



        return http.post<null, UploadResponse>(base_url+upload_url, formDataObj,config);

    }


    const handleSave = (data:any) => {

        //type: edit or save
            http
                .post<Trip, TripDocumentResponse>(trip_url+"/"+tripData?._id+"/documents", data)
                .then((res) => {
                    if (res) {

                        updateTripDocuments(res.data.documents)

                        setShowUploadModal(false)
                        setIsUploading(false)

                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {


                });

    }
    const deleteFile = (_id:string) => {

        //type: edit or save
            http
                .delete<null, TripDocumentResponse>(`${trip_url}/${tripData?._id}/document/${_id}`)
                .then((res) => {
                    if (res) {

                        updateTripDocuments(res.data.documents)

                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {


                });

    }
    const getS3FileData = (document:any) => {



        setFileModel(true)

        http
            .post<null, any>(`/signed/file`,{key: document.generatedName})
            .then((res) => {
                if (res) {

                    setSelectedFile({
                        ...document,
                        url: res.data
                    })


                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {


            });
    }


    let totalSize = 0
    let totalFiles = 0
    let totalImages = 0
    let totalImageSize = 0
    let totalDocFiles = 0
    let totalDocFilesSize = 0


    let documentHtml = tripData?.documents?.map( ( document, documentKey) =>{

        if(document.generatedName){

            let extension = document.generatedName.split('.').pop();

            if(extension){
                extension = extension.toLowerCase()
            }
            totalSize += parseFloat(document.size)
            totalFiles++;
            if(extension == "png" || extension == "jpg" || extension == "jpeg"){
                totalImages++
                totalImageSize += parseFloat(document.size)
            }else{
                totalDocFiles++
                totalDocFilesSize += parseFloat(document.size)
            }




            console.log("selectedFile", selectedFile)

            return(
                <div className="col-xl-3 col-6">
                    <div className="card card-file mb-6">
                        <div className="dropdown-file m-2">
                            <Dropdown   onSelect={async (item) => {

                                if(item == "delete" && document._id){
                                    deleteFile(document._id)
                                }
                                if(item == "download"){
                                    window?.open(document.url, '_blank')?.focus();
                                }
                                if(item == "view"){

                                    let contentType =  'image/png';


                                    if(extension == "pdf"){

                                        contentType = 'application/pdf'
                                    }else if(extension == "doc"){
                                        contentType = 'application/msword'

                                    }else if(extension == "docx"){
                                        contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

                                    }else if(extension == "jpeg"){
                                        contentType = 'image/jpeg'

                                    }else if(extension == "jpg"){
                                        contentType = 'image/jpg'

                                    }else if(extension == "png") {
                                        contentType = 'image/png'
                                    }

                                    await getS3FileData({
                                        ...document,
                                        extension:extension,
                                        contentType:contentType,
                                        url:''
                                    })

                                }

                            }}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <img src={VerticalMenuImgIcon} width={15} className={`hover-opacity-btn cursor-pointer`}/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                    <Dropdown.Item eventKey="view" className="text-dark"><img src={ViewFileIconImg} width={15} className={`hover-opacity-btn cursor-pointer`} /> View</Dropdown.Item>
                                    <Dropdown.Item eventKey="download" className="text-dark"><img src={DownloadIconImg} width={15} className={`hover-opacity-btn cursor-pointer`} /> Download</Dropdown.Item>
                                    <Dropdown.Item eventKey="delete"  className="text-dark"><img src={DeleteIconImg} width={15} className={`hover-opacity-btn cursor-pointer`} /> Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>


                        <div  onClick={async ()=>{


                            let contentType =  'image/png';


                            if(extension == "pdf"){

                                contentType = 'application/pdf'
                            }else if(extension == "doc"){
                                contentType = 'application/msword'

                            }else if(extension == "docx"){
                                contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

                            }else if(extension == "jpeg"){
                                contentType = 'image/jpeg'

                            }else if(extension == "jpg"){
                                contentType = 'image/jpg'

                            }else if(extension == "png") {
                                contentType = 'image/png'
                            }

                            await getS3FileData({
                                ...document,
                                extension:extension,
                                contentType:contentType,
                                url:''
                            })
                        }}>
                            <div className="card-file-thumb text-danger" style={extension == "png" || extension == "jpg" || extension == "jpeg"? {backgroundImage: `url(${document.url})`, backgroundSize:`cover` }: {}}>
                                {extension == "png" || extension == "jpg" || extension == "jpeg"?

                                    <></>
                                    :

                                    extension == "pdf"?
                                        <img src={PdfIconImg} width={60}/>
                                        : extension == "doc" || extension == "docx"?
                                            <img src={DocIconImg} width={60}/>
                                            :<></>
                                }
                            </div>
                            <div className="card-body p-2">
                                <h6><a href="" className="text-dark fw-bolder">{document.originalName}.{extension}</a></h6>
                                <span>{formatBytes(document.size,2)}</span>
                            </div>
                            <div className="card-footer pt-0  px-2"><span className="d-none d-sm-inline"><i className='bx bxs-calendar'></i> </span>{eSMoment(document.createdAt).format('DD MMM YY')}
                            </div>
                        </div>


                    </div>
                </div>

            )
        }
    })




    return (

        <>
            <div className="col-xl-6 col-md-6 trip-document-container">
                <div className="row">

                    <div className="col-xl-12">
                        <div className="file-content">
                            <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none">

                                <div className="card-header align-items-center border-0">
                                    <div className="card-title align-items-start flex-column">
                                        <h3 className="fw-bolder mb-1">Documents</h3>

                                        <div className="fs-7 text-gray-400">Total {totalFiles} {totalFiles > 1? "files": "file"}, {formatBytes(totalSize,2)} space usage</div>
                                    </div>
                                    <div className="card-toolbar">
                                        <button type="button" className={`btn btn-primary ${tripData?.status == 'completed'? 'disabled': ''}`} onClick={() => {
                                            if(tripData?.status != 'completed'){
                                                setShowUploadModal(true)
                                            }
                                        }}>Add New</button>
                                    </div>
                                </div>
                            </div>
                            <div className=" row">
                                {documentHtml?.length?
                                documentHtml:
                                    <div className={`card py-5`}>
                                        <div className="text-center p-2">
                                            <img src={EmptyDocumentsImg} width={100}/>
                                            <p className="fs-5 text-dark fw-bolder">No Documents</p>
                                            <p className="text-gray-400">Please check after adding planning or activities from <span className="fw-bolder">destination dashboard</span>
                                            </p>
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>

                    </div>


                    <Modal show={showUploadModal} className="planning-form-modal" onHide={function () {

                        setDocuments([])
                    }}  onExited={function () {

                    }}  animation={true}>
                        <Modal.Body>

                            <div className="d-flex text-center header">
                                <div className="p-2 flex-fill text-start" >
                                    <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=> {
                                        setShowUploadModal(false)
                                        setIsUploading(false)
                                        setDocuments([])
                                    }}/>
                                </div>
                                <div className="p-2 flex-fill fs-4 fw-bolder">
                                    Upload documents
                                </div>
                                <div className="p-2 flex-fill text-end save" >

                                    {isUploading?
                                        <span className=" cursor-pointer text-muted" >Saving...</span>
                                        :
                                        <span className={`cursor-pointer ${documents && documents.length? 'text-primary': 'text-muted'}`} onClick={() => {
                                            if(documents && documents.length){
                                                setIsUploading(true)



                                                let invoicesData = documents.map(async function (invoice,key){
                                                    let res =   await uploadCallback(invoice)

                                                    return res.data

                                                })


                                                Promise.all(invoicesData)
                                                    .then(data => {
                                                        handleSave(data)
                                                    })



                                            }
                                        }}>Save</span>

                                    }
                                </div>
                            </div>

                            <div className="p-6">
                                <label className="d-flex border border-gray-300 border-dashed rounded p-3 mb-3 cursor-pointer file-upload-box">
                                    <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5 justify-content-center">
                                        <div className="symbol symbol-50px me-4">
                                            <i className='bx bxs-cloud-upload fs-1'></i>
                                        </div>
                                        <div className="me-2 text-center">
                                            <span className="text-gray-800 text-hover-primary fs-5 fw-bolder">Click here to upload documents</span>
                                        <input type="file" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" className="d-none"
                                               onChange={ (e)=>{
                                                  if(e && e.target.files && e.target.files.length){
                                                      manageSelectedFile(e.target.files[0])
                                                  }
                                              }}/>
                                            <span
                                            className="text-gray-400  d-block fs-8">.PNG, .JPG, .DOC and .PDF are only supported</span>
                                        </div>
                                    </div>
                                </label>
                            </div>

                            {documents && documents.length?  documents.map(function (document, key) {

                                return(
                                    <div className="document-item"  style={{backgroundImage: `url(${document.url})`}} >
                                                            <span className="image-preview-item-clear">
                                                                  <span className="image-preview-item-clear-icon" onClick={() => {

                                                                      setDocuments(documents => (
                                                                          // Filter out the item with the matching index
                                                                          documents.filter((value, i) => i !== key)
                                                                      ));

                                                                  }} >
                                                                    <i className="bi bi-x"></i>
                                                                  </span>

                                                            </span>
                                        <span className="image-preview-item-type">
                                                                {document.type}
                                                            </span>
                                    </div>
                                )

                            }) : "" }



                        </Modal.Body>
                    </Modal>
                </div>
            </div>
            <div className="col-xl-3  mb-9 mb-xl-0">

                <div className="card highlight-card">
                    <div className="card-header border-0 pt-0"><h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark"> Overview</span><span
                        className="text-gray-400 mt-1  fs-7">{totalImages} Images  and {totalDocFiles} documents added</span>
                    </h3></div>
                    <div className="card-body pt-2 pb-2 d-flex align-items-center">
                        <div className="d-flex flex-column content-justify-center w-100">
                            <div>
                                <div className="passenger-card-item d-flex flex-stack ">
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-50px me-2"><span className="symbol-label">
                                            <img src={ImagesImg} width={20} />
                                        </span></div>
                                        <div className=""><a
                                            className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 text-capitalize ">Images</a>
                                            <div className="fw-bold text-gray-400"> {totalImages} added</div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-end ms-2 member-manage"><span
                                        className="my-3 fs-5 px-2 fw-bolder primary-color"> {formatBytes(totalImageSize,2)}</span></div>
                                </div>
                                <div className="separator separator-dashed my-2"></div>
                            </div>
                            <div>
                                <div className="passenger-card-item d-flex flex-stack ">
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-50px me-2"><span className="symbol-label">

                                             <img src={FilesImg} width={20} />

                                        </span></div>
                                        <div className=""><a
                                            className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 text-capitalize ">Documents</a>
                                            <div className="fw-bold text-gray-400"> {totalDocFiles} added</div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-end ms-2 member-manage"><span
                                        className="my-3 fs-5 px-2 fw-bolder primary-color"> {formatBytes(totalDocFilesSize,2)}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showFileModel}   onHide={() => {
                setFileModel(false)
            }} animation={false} size={`lg`}>
                <Modal.Header className={`border-0 pb-0`}>
                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2 p-0" onClick={() => {
                            setFileModel(false)
                        }}>
                            <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=> setFileModel(false)}/>
                        </div>
                        <Modal.Title className={`fw-bolder`}>Download</Modal.Title>

                        <div className="btn btn-icon btn-sm btn-active-light-primary  p-0" onClick={() => {
                            if(selectedFile){
                                window.open(
                                    selectedFile.url,
                                    '_blank' // <- This is what makes it open in a new window.
                                );
                            }

                        }}>
                            <img src={DownloadImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=> setFileModel(false)}/>
                        </div>


                </Modal.Header>
                <Modal.Body className={`pb-3 text-center file-preview-modal`}>



                    {selectedFile && selectedFile.url ? selectedFile.extension == "pdf" ?

                            <PdfViewer url={selectedFile.url} />

                            :
                            <DocViewer
                                documents={[
                                    { uri: selectedFile.url,
                                        fileType: selectedFile.contentType}, // Remote file
                                ]}
                                prefetchMethod="GET"
                                pluginRenderers={DocViewerRenderers}
                                style={{ height: '80vh' }}
                                config={{ header: { disableHeader: true } }}

                            />

                        :
                        <a  className="card card-stretch mb-5 bg-light-info hoverable shadow-none trip-list-select cursor-pointer">

                            <div className="card-body d-flex flex-column pt-5">

                                <Loader cssClass="normal-loader"/>
                            </div>
                        </a>
                    }

                </Modal.Body>
            </Modal>
        </>

    );
};
export default TripDocuments;
