import React, { FC, lazy, Suspense } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link, useParams, Navigate, Outlet,
} from "react-router-dom";



import './app.css'
import './reset.scss'


//import Trips from '../features/trips/Trips';

/*
import TripItinerary from '../features/trip/itinerary/TripItinerary';
*/





import Login from '../features/authentication/login/Login';
import Register from '../features/authentication/register/Register';
import Dashboard from '../features/dashboard/Dashboard';
import Verify from '../features/authentication/Verify';

import PrivateRoute from './PrivateRoute';

import PageNotFound from '../features/404/PageNotFound';
import JoinTrip from '../features/trip/join/JoinTrip';
import Notifications from '../features/notifications/Notifications';
import AccountContainer from '../features/account/AccountContainer';
import NewTrip from '../features/trips/newtrip/v2/NewTrip';
import Messages from '../features/messages/Messages';
import Trips from '../features/trips/Trips';
import TripContainer from '../features/trip/TripContainer';
import LoginAs from "../features/authentication/login/LoginAs";
import NewAiTrip from "../features/trips/newtrip/v3/NewAiTrip";
import AiLanding from "../features/ai-landing/landing/AiLanding";
import AiItinerary from "../features/ai-landing/itinerary/AiItinerary";


import { HelmetProvider } from 'react-helmet-async';
import RegisterBusiness from "../features/authentication/register/RegisterBusiness";
import {useSelector} from "react-redux";
import {RootState} from "../rootReducer";
import TourPackages from "../business-features/tour-packages/TourPackages";
import NewTourPackageAI from "../business-features/tour-packages/NewTourPackageAI";
import NewTourPackage from "../business-features/tour-packages/NewTourPackage";

type ParamTypes =  {
    tripType: string,
}

function ValidatePackageAccess() {

    const {  user } = useSelector((state: RootState) => state);

    if(user){
        if (user.data.userType == "customer") {

            return <PageNotFound/>
        }
    }
    return <PrivateRoute><TourPackages/></PrivateRoute>;
}
function ValidateTripType() {
    const { tripType } = useParams<ParamTypes>()

    if(tripType){
        if (tripType !== "active" && tripType !== "upcoming" && tripType !== "completed" && tripType !== "invited") {

            return <PageNotFound/>
        }
    }
    return <PrivateRoute><Trips/></PrivateRoute>;
}


function BusinessLayout() {

    const {  user } = useSelector((state: RootState) => state);

    if(user){
        if (user.data.userType === "customer") {

            return <PageNotFound/>
        }
    }
    return  <React.Suspense fallback={<div>Loading...</div>}>
                <Outlet />
            </React.Suspense>;

}

const App: FC = () => {




    return (
            <BrowserRouter>


                {process.env.REACT_APP_IS_AI === "true"?
                    <Routes>


                        <Route path="/" element={
                            <React.Suspense fallback={<>...</>}>
                                <AiLanding/>
                            </React.Suspense>
                        }/>
                        <Route path="/i/:itineraryId/:title" element={
                            <AiItinerary/>
                        }/>

                        <Route path="*" element={
                            <React.Suspense fallback={<>...</>}>
                                <PageNotFound/>
                            </React.Suspense>
                        } />

                    </Routes>
                    :
                    <Routes>

                        <Route path="/" element={<Login/>}/>

                        {process.env.REACT_APP_MODE === "local"&&
                            <Route  path="/login-as" element={<LoginAs/>}/>
                        }

                        <Route  path="/login" element={<Login/>}/>

                        <Route  path="/register" element={<Register/>}/>
                        <Route  path="/register/business" element={<RegisterBusiness/>}/>
                        <Route  path="/verify" element={ <Verify />}/>




                        <Route path="/dashboard" element={
                            <React.Suspense fallback={<>...</>}>
                                <PrivateRoute>
                                    <Navigate to="/trips/upcoming" replace />
                                </PrivateRoute>
                            </React.Suspense>
                        }/>


                        <Route path="/trips/:tripType" element={ <React.Suspense fallback={<>...</>}>

                            <ValidateTripType />
                        </React.Suspense>} />

                        <Route path="/account"  element={
                            <React.Suspense fallback={<>...</>}>
                                <PrivateRoute><AccountContainer/></PrivateRoute>
                            </React.Suspense>
                        }/>
                        <Route path="/join/:joinCode" element={
                            <React.Suspense fallback={<>...</>}>
                                <PrivateRoute><JoinTrip/></PrivateRoute>
                            </React.Suspense>
                        }/>
                        <Route path="/new" element={
                            <React.Suspense fallback={<>...</>}>
                                <PrivateRoute><NewTrip/></PrivateRoute>
                            </React.Suspense>
                        }/>
                        <Route path="/new/ai" element={
                            <React.Suspense fallback={<>...</>}>
                                <PrivateRoute><NewAiTrip/></PrivateRoute>
                            </React.Suspense>
                        }/>
                        <Route path="/trip/:tripId/:destinationId?" element={
                            <React.Suspense fallback={<>...</>}>
                                <PrivateRoute><TripContainer/></PrivateRoute>
                            </React.Suspense>}/>
                        <Route path="/notifications" element={
                            <React.Suspense fallback={<>...</>}>
                                <PrivateRoute><Notifications/></PrivateRoute>
                            </React.Suspense>
                        }/>
                        <Route path="/messages" element={
                            <React.Suspense fallback={<>...</>}>
                                <PrivateRoute><Messages/></PrivateRoute>
                            </React.Suspense>
                        }/>


                        <Route path="*" element={
                            <React.Suspense fallback={<>...</>}>
                                <PageNotFound/>
                            </React.Suspense>
                        } />


                        <Route path="/trips/:tripType" element={ <React.Suspense fallback={<>...</>}>

                            <ValidateTripType />
                        </React.Suspense>} />


                        <Route path="business" element={<BusinessLayout />}>

                            <Route path="packages" element={
                                <React.Suspense fallback={<>...</>}>
                                    <PrivateRoute><TourPackages/></PrivateRoute>
                                </React.Suspense>
                            }/>
                            <Route path="package/create" element={
                                <React.Suspense fallback={<>...</>}>
                                    <PrivateRoute><NewTourPackage/></PrivateRoute>
                                </React.Suspense>
                            }/>

                            <Route path="package/create/ai" element={
                                <React.Suspense fallback={<>...</>}>
                                    <PrivateRoute><NewTourPackageAI/></PrivateRoute>
                                </React.Suspense>
                            }/>

                        </Route>
                    </Routes>
                }

            </BrowserRouter>

    );
};

export default App;
