// new file called DogPicture.jsx
// import React, { useEffect, useState } from 'react';
import ContactOptions from '../options/ContactOptions/ContactOptions'

const Contacts = (props) => {
  return (
    <div>
      {/* <img src={imageUrl} alt='a dog' /> */}
      <ContactOptions actionProvider={props.actionProvider} />
    </div>
  );
};

export default Contacts;