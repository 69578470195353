import React, {FC, useEffect, useImperativeHandle, useRef, useState} from 'react';

import * as Yup from "yup";

import {
    Trip, TripActivity, TripDestination, TripDestinationResponse,
    TripMember, Vehicle
} from "../../../../interfaces/trip.interface";
import {
    Modal,
    Button,
    Input,
    GoogleAddressLookup,
    DateTimePicker,
    Picklist,
    Option,
    Avatar, FileSelector, Textarea, ButtonIcon, CheckboxToggle, ButtonGroup, Card, LoadingShape
} from "react-rainbow-components";
import {FieldArray, FormikProvider, useFormik} from 'formik';
import '../form.scss'
import Select from "react-select";


import CloseIcon from '../../../../images/icons/close.svg';
import {getGooglePhotoUrl, toastMessage} from "../../../../util";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {RootState} from "../../../../rootReducer";
import axios from "axios";
import http from "../../../../services/api";
import {trip_url} from "../../../../services/api.utils";

type Props = {
    tripData:Trip
    category:{
        label: string,
        name: string,
    },
    showModal:boolean,
    setShowModal:any,
    activeDestination:TripDestination,
    activeActivity?:TripActivity,
    updateTripDestination: any;
}

interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}
interface FileWithProgress {
    file: File;
    progress: number;
    completed: boolean;
    started: boolean;
    cancelTokenSource: any;
}
const FormContainer: FC <Props> = ({tripData, category,showModal,setShowModal,activeDestination, activeActivity, updateTripDestination} ) => {



    const {  currencies } = useSelector((state: RootState) => state);

    const [expenseSplitType, setExpenseSplitType] = useState(activeActivity?.paidMembers?.length? 'group':'self');
    const [splitPaidEqually, setSplitPaidEqually] = useState(false);
    const [splitPaidByEqually, setSplitPaidByEqually] = useState(false);
    const [searchKey, setSearchKey] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)

    const fileUploadRef = useRef<FileUploadHandle>(null);
    const [fileCount, setFileCount] = useState(0);

    const [files, setFiles] = useState<FileWithProgress[]>([]);
    const [invoices, setInvoices] = useState<any>([]);
    const [completedFiles, setCompletedFiles] = useState<FileWithProgress[]>([]);
    const [message, setMessage] = useState('');
    const [uploadFailed, setUploadFailed] = useState(false);
    const fileSelectorRef = useRef<HTMLDivElement>(null);


    const validationSchema = Yup.object().shape({
        placeOne: Yup.object().shape({
            name: Yup.string().required('Place One Name is required'),
            rating: Yup.string(),
            photoReference: Yup.string(),
            lat: Yup.string(),
            lng: Yup.string(),
            placeId: Yup.string(),
        }).required('Place One is required'), // Ensure the whole object is required

        placeTwo: Yup.object().when('type', {
            is: (value: string) => ['flight', 'taxi', 'bus', 'train', 'rent-vehicle', 'ferry'].includes(value),
            then: Yup.object().shape({
                name: Yup.string().required('Place Two Name is required'),
                rating: Yup.string(),
                photoReference: Yup.string(),
                lat: Yup.string(),
                lng: Yup.string(),
                placeId: Yup.string(),
            }).required('Place Two is required for flights'),
            otherwise: Yup.object().shape({
                name: Yup.string(),
                formattedAddress: Yup.string(),
                rating: Yup.string(),
                photoReference: Yup.string(),
                lat: Yup.string(),
                lng: Yup.string(),
                placeId: Yup.string(),
            }),
        }),
        type: Yup.string().required('Type is required'),
    });


    console.log("activeActivity",activeActivity)


    const formik = useFormik({
        initialValues: {
            placeOne: {
                _id: "",
                name: activeActivity?.placeOne?.name || "",
                formattedAddress: activeActivity?.placeOne?.formattedAddress || "",
                rating: activeActivity?.placeOne?.rating || "0",
                photoReference: activeActivity?.placeOne?.photoReference || "",
                lat: activeActivity?.placeOne?.lat || "",
                lng: activeActivity?.placeOne?.lng || "",
                placeId: activeActivity?.placeOne?.placeId || "",
            },
            placeTwo: {
                _id: "",
                name: activeActivity?.placeTwo?.name || "",
                formattedAddress: activeActivity?.placeTwo?.formattedAddress || "",
                rating: activeActivity?.placeTwo?.rating || "0",
                photoReference: activeActivity?.placeTwo?.photoReference || "",
                lat: activeActivity?.placeTwo?.lat || "",
                lng: activeActivity?.placeTwo?.lng || "",
                placeId: activeActivity?.placeTwo?.placeId || "",
            },
            startDateTime: activeActivity?.startDateTime ? activeActivity?.startDateTime : activeDestination.startDate?.toString(),
            endDateTime: activeActivity?.endDateTime ? activeActivity?.endDateTime: activeDestination.endDate?.toString(),
            invoices: activeActivity?.invoices || [],
            members: activeActivity?.members || [],
            paidMembers: activeActivity?.paidMembers || [],
            privacy: activeActivity?.privacy || false,
            budget: activeActivity?.budget || "",
            currency: activeActivity?.currency || activeDestination.currencyId,
            notes: activeActivity?.notes || "",
            rooms: activeActivity?.rooms || [],
            vehicles: activeActivity?.vehicles || [],
            type: category?.name || "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values: any) => {


            setFormSubmitted(true)

            let responseData  = await uploadFiles()



            let planningData = values


            planningData.invoices = responseData?.map((item): any => {
                return item.data;
            });

            http
                .post<Trip, TripDestinationResponse>(trip_url+"/"+tripData._id+"/destination/"+activeDestination?._id+"/activity", planningData)
                .then((res) => {
                    if (res) {

                        if(res.status === 200) {


                            updateTripDestination(res.data)

                            toastMessage('Planning successfully added','')

                            // handle submit logic
                            setFormSubmitted(false)
                            setShowModal(false);
                            formik.resetForm();
                        }else{
                            setFormSubmitted(false)
                            toastMessage(`Something went wrong! Please try again`,'error')
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {

                });

        },

    });



    const splitAmountsEqually = () => {

        const members = formik.values.members || []; // Ensure members is always an array
        const paidMembers = formik.values.paidMembers || []; // Ensure paidMembers is always an array

        if (splitPaidByEqually && paidMembers.length > 0) {
            const equalPaidAmount = (parseFloat(formik.values.budget) || 0) / paidMembers.length;
            const updatedPaidMembers = paidMembers.map(member => ({
                ...member,
                paidAmount: equalPaidAmount.toFixed(2) // rounding to 2 decimal places
            }));
            formik.setFieldValue('paidMembers', updatedPaidMembers);
        }

        if (splitPaidEqually && members.length > 0) {
            const equalAmount = (parseFloat(formik.values.budget) || 0) / members.length;
            const updatedMembers = members.map(member => ({
                ...member,
                amount: equalAmount.toFixed(2)
            }));
            formik.setFieldValue('members', updatedMembers);
        }
    };


    useEffect(() => {
        splitAmountsEqually();
    }, [
        splitPaidEqually,
        splitPaidByEqually,
        JSON.stringify(formik.values.paidMembers),
        JSON.stringify(formik.values.members),
        formik.values.budget
    ]);


    const handleFileChange = (fileList: FileList) => {
        const selectedFiles = Array.from(fileList).map(file => ({
            file,
            progress: 0,
            completed: false,
            started: false,
            cancelTokenSource: axios.CancelToken.source()
        }));

        setFiles(selectedFiles);
    };

    useEffect(() => {
        if (files.length === 0) {
            triggerFileSelector();
        }
        setFileCount(files.length)
    }, [files]);

/*    useImperativeHandle(fileUploadRef, () => ({
        uploadFiles
    }));*/

    const uploadFiles = async () => {

        setUploadFailed(false)
        const uploadPromises = files.map(fileWithProgress => {
            const { file, cancelTokenSource } = fileWithProgress;

            const formData = new FormData();
            formData.append('files', file);
            formData.append('folder', `${tripData._id}/${category.name}`);

            // Mark the file as started
            setFiles(prevFiles =>
                prevFiles.map(f =>
                    f.file.name === file.name ? { ...f, started: true } : f
                )
            );

            return http.post( '/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                cancelToken: cancelTokenSource.token,
   /*             onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1));
                        const cappedProgress = Math.min(progress, 80);
                        setFiles(prevFiles =>
                            prevFiles.map(f =>
                                f.file.name === file.name ? { ...f, progress: cappedProgress } : f
                            )
                        );
                    }
                }*/
            })
        });

        try {
           return  await Promise.all(uploadPromises);
        } catch (error) {
            console.error('Some uploads failed', error);
        }
    };

    const cancelUpload = (fileName: string) => {
        setFiles(prevFiles => {
            const fileToCancel = prevFiles.find(f => f.file.name === fileName);
            if (fileToCancel && fileToCancel.cancelTokenSource) {
                fileToCancel.cancelTokenSource.cancel();
            }
            return prevFiles.filter(f => f.file.name !== fileName); // Remove the canceled file from the state
        });
    };

    const triggerFileSelector = () => {
        if (fileSelectorRef.current) {
            const button = fileSelectorRef.current.querySelector('[data-id="button-icon-element"]');
            if (button) {
                (button as HTMLElement).click();
            }
        }
    };


    return (
        <FormikProvider value={formik}>
            <Modal
                id="modal-add-planning"
                title={formSubmitted? 'Loading, please wait...': activeActivity? `Update ${category.label}`: `Add ${category.label}`}
                size={`large`}
                isOpen={showModal}
                onRequestClose={() => {
                    setFormSubmitted(false)
                    setShowModal(false)
                    setFiles([])
                    formik.resetForm();
                }}
                footer={
                    <div className="d-flex justify-content-end">
                        <Button
                            label={activeActivity? 'Update': 'Create'}
                            variant="brand"
                            className="rainbow-m-around_medium"
                            type={`button`}
                            isLoading={formSubmitted}
                            onClick={() => {

                                if(!formSubmitted) {
                                    formik.handleSubmit()
                                }
                            }}  // Correctly wrap handleSubmit
                        />
                    </div>
                }
            >


                {formSubmitted ?

                    <>



                        <div className="d-flex p-3 justify-content-center">
                            <div className="me-3" style={{ width: '100px' }}>
                                <LoadingShape variant="image" shape="square" />
                            </div>
                            <div>
                                <div className="row">
                                    <LoadingShape className="pe-2 mt-1" />
                                    <LoadingShape className="pe-2 mt-1" />
                                </div>
                                <div>
                                    <LoadingShape className="mb-2 mt-1" />
                                    <LoadingShape className="mb-2 mt-1" />
                                    <div className="d-flex justify-content-between">
                                        <LoadingShape className="pe-2 mt-1" />
                                        <LoadingShape className="pe-2 mt-1" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>

                    :
                    <form>
                        <div className="container planning-form-container p-3"
                             style={{backgroundColor: '#9b9b9b45', borderRadius: '10px'}}>
                            <div className={`card p-3`}>

                                <div className="row">
                                    <div className="col-6">

                                        {['flight', 'taxi', 'bus', 'train', 'rent-vehicle', 'ferry'].includes(category.name) && (
                                            <div className={`row`}>
                                                <div className={`col-6`}>

                                                    {formik.values.placeOne.name ?

                                                        <>
                                                            <label>From</label>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    flexGrow: 1,
                                                                    position: 'relative',
                                                                    padding: '0px'
                                                                }}
                                                                className="place-view"
                                                                onClick={() => {
                                                                    formik.setFieldValue('placeOne', {
                                                                        _id: "",
                                                                        name: "",
                                                                        formattedAddress: "",
                                                                        rating: "0",
                                                                        photoReference: "",
                                                                        lat: "",
                                                                        lng: "",
                                                                        placeId: "",
                                                                    });
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        overflow: 'hidden', // Ensures no vertical scrolling
                                                                        whiteSpace: 'nowrap', // Keeps text on a single line
                                                                        textOverflow: 'ellipsis' // Truncates text with ellipsis if it overflows
                                                                    }}
                                                                    className="px-3 py-2"
                                                                >
                                                                    <a
                                                                        href="#"
                                                                        className="text-gray-800 text-hover-primary fs-7 fw-bolder"
                                                                        style={{
                                                                            whiteSpace: 'nowrap', // Keeps text on a single line
                                                                            overflow: 'hidden', // Prevents overflow
                                                                            textOverflow: 'ellipsis' // Truncates text with ellipsis if it overflows
                                                                        }}
                                                                    >
                                                                        {formik.values.placeOne.name}
                                                                    </a>
                                                                    <span
                                                                        className="text-muted fw-bold fs-9"
                                                                        style={{
                                                                            whiteSpace: 'nowrap', // Keeps text on a single line
                                                                            overflow: 'hidden', // Prevents overflow
                                                                            textOverflow: 'ellipsis' // Truncates text with ellipsis if it overflows
                                                                        }}
                                                                    >
                <i className="bi bi-geo-alt-fill primary-color"></i> {formik.values.placeOne.formattedAddress}
            </span>
                                                                </div>
                                                                <img
                                                                    src={CloseIcon}
                                                                    className="hover-opacity-btn"
                                                                    alt="Close"
                                                                    width="10"
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: '-5px',
                                                                        width: '20px',
                                                                        top: '-5px'
                                                                    }}
                                                                    onClick={() => formik.setFieldValue('placeOne.name', '')}
                                                                />
                                                            </div>
                                                        </>


                                                        :

                                                        <>
                                                            <GoogleAddressLookup
                                                                label={'From'}
                                                                labelAlignment="left"
                                                                onChange={(value: any) => {
                                                                    if (value && value.name) {
                                                                        formik.setFieldValue('placeOne', {
                                                                            _id: "", // If you need to generate or assign an ID
                                                                            name: value.name || "",
                                                                            formattedAddress: value.formatted_address || "",
                                                                            rating: value.rating || "0", // Assuming the API provides a rating
                                                                            photoReference: value.photos ? value.photos[0].photo_reference : "",
                                                                            lat: value.geometry.location.lat(),
                                                                            lng: value.geometry.location.lng(),
                                                                            placeId: value.place_id || "",
                                                                        });
                                                                        formik.setFieldTouched('placeOne.name', true, false);

                                                                    }
                                                                }}
                                                                placeholder="Enter location"
                                                                apiKey="AIzaSyAXnOmNWA3f3dUAQhJKiAcC1cFFCSi-Byo"
                                                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                borderRadius="semi-rounded"
                                                                searchOptions={{
                                                                    location: {
                                                                        latitude: parseFloat(activeDestination.lat),
                                                                        longitude: parseFloat(activeDestination?.lng),
                                                                    },
                                                                    radius: 150000,
                                                                    types: category.name === 'flight'?['airport']: ['address'],
                                                                }}
                                                            />
                                                            {formik.errors.placeOne?.name && (
                                                                <div
                                                                    className="fs-7 text-danger mt-1">{formik.errors.placeOne.name}</div>
                                                            )}
                                                        </>}

                                                </div>
                                                <div className={`col-6`}>

                                                    {formik.values.placeTwo.name ?

                                                        <>
                                                            <label>To</label>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    flexGrow: 1,
                                                                    position: 'relative',
                                                                    padding: '0px'
                                                                }}
                                                                className="place-view"
                                                                onClick={() => {
                                                                    formik.setFieldValue('placeTwo', {
                                                                        _id: "",
                                                                        name: "",
                                                                        formattedAddress: "",
                                                                        rating: "0",
                                                                        photoReference: "",
                                                                        lat: "",
                                                                        lng: "",
                                                                        placeId: "",
                                                                    });
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        overflow: 'hidden', // Changed to 'hidden' to avoid vertical scrolling
                                                                        whiteSpace: 'nowrap', // Ensures single line text
                                                                        textOverflow: 'ellipsis' // Adds ellipsis if text overflows
                                                                    }}
                                                                    className="px-3 py-2"
                                                                >
                                                                    <a
                                                                        href="#"
                                                                        className="text-gray-800 text-hover-primary fs-7 fw-bolder"
                                                                        style={{
                                                                            whiteSpace: 'nowrap', // Ensures single line text
                                                                            overflow: 'hidden', // Prevents overflow
                                                                            textOverflow: 'ellipsis' // Adds ellipsis if text overflows
                                                                        }}
                                                                    >
                                                                        {formik.values.placeTwo.name}
                                                                    </a>
                                                                    <span
                                                                        className="text-muted fw-bold fs-9"
                                                                        style={{
                                                                            whiteSpace: 'nowrap', // Ensures single line text
                                                                            overflow: 'hidden', // Prevents overflow
                                                                            textOverflow: 'ellipsis' // Adds ellipsis if text overflows
                                                                        }}
                                                                    >
                                                                <i className="bi bi-geo-alt-fill primary-color"></i> {formik.values.placeTwo.formattedAddress}
                                                            </span>
                                                                </div>
                                                                <img
                                                                    src={CloseIcon}
                                                                    className="hover-opacity-btn"
                                                                    alt="Close"
                                                                    width="10"
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: '-5px',
                                                                        width: '20px',
                                                                        top: '-5px'
                                                                    }}
                                                                    onClick={() => formik.setFieldValue('placeTwo.name', '')}
                                                                />
                                                            </div>

                                                        </>

                                                        :

                                                        <>
                                                            <GoogleAddressLookup
                                                                label={'To'}
                                                                labelAlignment="left"
                                                                onChange={(value: any) => {
                                                                    if (value && value.name) {
                                                                        formik.setFieldValue('placeTwo', {
                                                                            _id: "", // If you need to generate or assign an ID
                                                                            name: value.name || "",
                                                                            formattedAddress: value.formatted_address || "",
                                                                            rating: value.rating || "0", // Assuming the API provides a rating
                                                                            photoReference: value.photos ? value.photos[0].photo_reference : "",
                                                                            lat: value.geometry.location.lat(),
                                                                            lng: value.geometry.location.lng(),
                                                                            placeId: value.place_id || "",
                                                                        });
                                                                        formik.setFieldTouched('placeOne.two', true, false);

                                                                    }
                                                                }}
                                                                placeholder="Enter location"
                                                                apiKey="AIzaSyAXnOmNWA3f3dUAQhJKiAcC1cFFCSi-Byo"
                                                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto "
                                                                borderRadius="semi-rounded"
                                                                searchOptions={{
                                                                    location: {
                                                                        latitude: parseFloat(activeDestination.lat),
                                                                        longitude: parseFloat(activeDestination?.lng),
                                                                    },
                                                                    radius: 150000,
                                                                    types: category.name === 'flight'?['airport']: ['address'],
                                                                }}
                                                            />
                                                            {formik.errors.placeTwo?.name && (
                                                                <div
                                                                    className="fs-7 text-danger mt-1">{formik.errors.placeTwo.name}</div>
                                                            )}
                                                        </>

                                                    }

                                                </div>

                                            </div>

                                        )}

                                        {['hotel', 'pre-booked', 'attraction', 'restaurant'].includes(category.name) && (
                                            <div className={`row`}>

                                                {formik.values.placeOne.name ?

                                                    <>
                                                        <label>Search</label>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            flexGrow: 1,
                                                            position: 'relative',
                                                            padding: '0px'
                                                        }}
                                                             className={`place-view`}

                                                             onClick={() => {
                                                                 formik.setFieldValue('placeOne', {
                                                                     _id: "",
                                                                     name: "",
                                                                     formattedAddress: "",
                                                                     rating: "0",
                                                                     photoReference: "",
                                                                     lat: "",
                                                                     lng: "",
                                                                     placeId: "",
                                                                 });
                                                             }}
                                                        >
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                overflow: 'auto'
                                                            }} className={`px-3 py-2`}>
                                                                <a href="#"
                                                                   className="text-gray-800 text-hover-primary fs-7 fw-bolder">
                                                                    {formik.values.placeOne.name}
                                                                </a>
                                                                <span className="text-muted fw-bold fs-9 text-truncate">
                                                            <i className="bi bi-geo-alt-fill primary-color"></i> {formik.values.placeOne.formattedAddress}
                                                        </span>
                                                            </div>
                                                            <img
                                                                src={CloseIcon}
                                                                className="hover-opacity-btn"
                                                                alt="Close"
                                                                width="10"
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '-5px',
                                                                    width: '20px',
                                                                    top: '-5px'
                                                                }}
                                                                onClick={() => formik.setFieldValue('placeOne.name', '')}
                                                            />
                                                        </div>
                                                    </>


                                                    :
                                                    <>
                                                        <GoogleAddressLookup
                                                            label={'Search'}
                                                            labelAlignment="left"
                                                            onChange={(value: any) => {
                                                                if (value && value.name) {
                                                                    formik.setFieldValue('placeOne', {
                                                                        _id: "", // If you need to generate or assign an ID
                                                                        name: value.name || "",
                                                                        formattedAddress: value.formatted_address || "",
                                                                        rating: value.rating || "0", // Assuming the API provides a rating
                                                                        photoReference: value.photos ? value.photos[0].photo_reference : "",
                                                                        lat: value.geometry.location.lat(),
                                                                        lng: value.geometry.location.lng(),
                                                                        placeId: value.place_id || "",
                                                                    });

                                                                }
                                                            }}
                                                            placeholder="Enter location"
                                                            apiKey="AIzaSyAXnOmNWA3f3dUAQhJKiAcC1cFFCSi-Byo"
                                                            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto "
                                                            borderRadius="semi-rounded"
                                                            searchOptions={{
                                                                location: {
                                                                    latitude: parseFloat(activeDestination.lat),
                                                                    longitude: parseFloat(activeDestination?.lng),
                                                                },
                                                                radius: 150000,
                                                                types: ['address'],
                                                            }}
                                                        />
                                                        {formik.errors.placeOne?.name && (
                                                            <div
                                                                className="fs-7 text-danger mt-1">{formik.errors.placeOne.name}</div>
                                                        )}

                                                    </>


                                                }

                                            </div>

                                        )}


                                    </div>

                                    <div className="col-6">
                                        <div className={`row`}>
                                            <div className="col-6">
                                                <DateTimePicker
                                                    label={`Start Date`}
                                                    labelAlignment={`left`}
                                                    formatStyle="medium"
                                                    borderRadius="semi-rounded"
                                                    className={`form-calender`}
                                                    onChange={(value) => {

                                                        const formattedDate = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
                                                        formik.setFieldValue('startDateTime', formattedDate);
                                                    }}
                                                    value={new Date(formik.values.startDateTime)}
                                                    minDate={new Date(activeDestination.startDate)}
                                                    maxDate={new Date(activeDestination.endDate)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <DateTimePicker
                                                    label={`End Date`}
                                                    labelAlignment={`left`}
                                                    formatStyle="medium"
                                                    borderRadius="semi-rounded"
                                                    className={`form-calender`}
                                                    onChange={(value) => {
                                                        const formattedDate = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
                                                        formik.setFieldValue('endDateTime', formattedDate);
                                                    }}
                                                    value={new Date(formik.values.endDateTime)}
                                                    minDate={new Date(activeDestination.startDate)}
                                                    maxDate={new Date(activeDestination.endDate)}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            {category.name === 'hotel' && (
                                <FieldArray name="rooms">
                                    {({remove, push}) => {

                                        // Collect all user IDs that are already selected in any room
                                        const selectedUserIds = formik.values.rooms
                                            .flatMap(r => r._user || []) // Flatten the user arrays
                                            .map(user => user._id); // Get the IDs

                                        return(
                                            <div>
                                                {formik.values.rooms.map((room, index) => {


                                                    return (
                                                        <div className="card mt-3 p-3" key={index}>
                                                            <div className="mb-3">
                                                                <div className="d-flex justify-content-between">
                                                                    <h2 className="fs-4">Room {index + 1}</h2>
                                                                    <Button
                                                                        label="Remove room"
                                                                        variant="destructive"
                                                                        borderRadius="semi-rounded"
                                                                        onClick={() => remove(index)}
                                                                        className="rainbow-m-around_medium"
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <Input
                                                                            label="Room Number"
                                                                            name={`rooms.${index}.roomNumber`}
                                                                            value={room.roomNumber || ''}
                                                                            onChange={formik.handleChange}
                                                                            labelAlignment="left"
                                                                            placeholder="Enter room number"
                                                                            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                            borderRadius="semi-rounded"
                                                                        />
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label>Member</label>

                                                                        <Select
                                                                            isMulti
                                                                            options={tripData.members
                                                                                .filter(member => !selectedUserIds.includes(member.userId?._id)) // Exclude already selected members
                                                                                .map(member => ({
                                                                                    value: member.userId?._id,
                                                                                    label: `${member.userId?.firstName} ${member.userId?.lastName}`
                                                                                }))
                                                                            }
                                                                            onChange={(selectedOptions) => {
                                                                                const updatedMembers = selectedOptions.map(option => {
                                                                                    const selectedMember = tripData.members.find(member => member.userId?._id === option.value);
                                                                                    return {
                                                                                        ...selectedMember?.userId, // Spread MinimalUser properties
                                                                                    };
                                                                                });
                                                                                formik.setFieldValue(`rooms.${index}._user`, updatedMembers);
                                                                            }}
                                                                            value={room._user?.map(user =>
                                                                                tripData.members.find(tripMember => tripMember.userId?._id === user._id)
                                                                            ).map(member => ({
                                                                                value: member?.userId?._id,
                                                                                label: `${member?.userId?.firstName} ${member?.userId?.lastName}`
                                                                            }))}
                                                                            placeholder="Select Members"
                                                                            isSearchable
                                                                            className="react-select-container"
                                                                            classNamePrefix="react-select"
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className="row mt-3 mx-0">
                                                        <Button
                                                            label="Add Room"
                                                            variant="neutral"
                                                            borderRadius="semi-rounded"
                                                            onClick={() => push({roomNumber: "", _user: undefined})}
                                                            className="rainbow-m-around_medium"
                                                            disabled={
                                                                selectedUserIds.length >= tripData.members.length || // Disable if all members are selected
                                                                (formik.values.rooms.length ? !formik.values.rooms[formik.values.rooms.length - 1]?.roomNumber : false) // Disable if last room number is not filled
                                                            }
                                                        />

                                                </div>
                                            </div>
                                        )
                                    }}
                                </FieldArray>
                            )}



                            {['flight', 'taxi', 'bus', 'train', 'rent-vehicle', 'ferry'].includes(category.name) && (
                                <FieldArray name="vehicles">
                                    {({remove, push}) => {


                                        const selectedUserIds = formik.values.vehicles
                                            ?.flatMap(vehicle => vehicle.seats?.map(seat => seat._user))
                                            .filter(userId => userId !== undefined); // Filter out undefined values if any


                                        return(
                                            <div>
                                                {formik.values.vehicles.map((vehicle, index) => (
                                                    <div className="card mt-3 p-3">
                                                        <div key={index} className="mb-3">
                                                            <div className={` d-flex justify-content-between `}>

                                                                <h2 className={`fs-4`}>{category.name === 'rent-vehicle' ? 'Vehicle' : `${category.name}`} {index + 1}</h2>

                                                                <Button
                                                                    label={category.name === 'rent-vehicle' ? 'RemoveVehicle' : `Remove ${category.name}`}
                                                                    variant="destructive" borderRadius="semi-rounded"
                                                                    onClick={() => remove(index)}
                                                                    className="rainbow-m-around_medium"/>

                                                            </div>

                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <Input
                                                                        label={category.name === 'rent-vehicle' ? 'Vehicle' : `${category.name}`}
                                                                        name={`vehicles.${index}.vehicleNumber`}
                                                                        value={vehicle.vehicleNumber}
                                                                        onChange={formik.handleChange}
                                                                        labelAlignment="left"
                                                                        placeholder={category.name === 'rent-vehicle' ? 'Enter Vehicle Number' : `Enter ${category.name} Number`}
                                                                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                        borderRadius="semi-rounded"
                                                                    />
                                                                </div>

                                                                {category.name === 'rent-vehicle' && (

                                                                    <>
                                                                        <div className="col-12">
                                                                            <Input
                                                                                label={`Daily Usage`}
                                                                                name={`vehicles.${index}.dailyUsage`}
                                                                                value={vehicle.dailyUsage}
                                                                                onChange={formik.handleChange}
                                                                                labelAlignment="left"
                                                                                placeholder="Enter daily usage"
                                                                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                                borderRadius="semi-rounded"
                                                                            />
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <Input
                                                                                label={`Starting Km`}
                                                                                name={`vehicles.${index}.startingKm`}
                                                                                value={vehicle.startingKm}
                                                                                onChange={formik.handleChange}
                                                                                labelAlignment="left"
                                                                                placeholder="Enter starting km"
                                                                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                                borderRadius="semi-rounded"
                                                                            />
                                                                        </div>

                                                                    </>

                                                                )}

                                                            </div>

                                                            {/* PNR Numbers */}
                                                            <FieldArray name={`vehicles.${index}.pnr_numbers`}>
                                                                {({remove, push}) => (
                                                                    <div className="row mt-3">
                                                                        <label>PNR Numbers</label>
                                                                        {(vehicle.pnrNumbers || []).map((pnr, pnrIndex) => {
                                                                            const isDisabled = pnrIndex > 0 && formik.values.vehicles?.[index]?.pnrNumbers?.[pnrIndex - 1] === '';

                                                                            return (
                                                                                <div key={pnrIndex}
                                                                                     className="row align-items-center mb-3 mt-1">
                                                                                    <div className="col-10">
                                                                                        <Input
                                                                                            name={`vehicles.${index}.pnr_numbers.${pnrIndex}`}
                                                                                            value={pnr}
                                                                                            onChange={formik.handleChange}
                                                                                            labelAlignment="left"
                                                                                            placeholder="Enter PNR number"
                                                                                            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                                            borderRadius="semi-rounded"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <Button
                                                                                            label="Remove"
                                                                                            variant="destructive"
                                                                                            borderRadius="semi-rounded"
                                                                                            onClick={() => remove(pnrIndex)}
                                                                                            disabled={isDisabled} // Disable remove button if previous PNR number is not filled
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}

                                                                        <div className={`container`}>
                                                                            <Button
                                                                                label="Add PNR Number"
                                                                                variant="neutral"
                                                                                onClick={() => push('')}
                                                                                className="rainbow-m-around_medium mt-1"
                                                                                borderRadius="semi-rounded"
                                                                                disabled={
                                                                                    (() => {
                                                                                        const pnrNumbers = formik.values.vehicles?.[index]?.pnrNumbers;
                                                                                        const lastPnrNumber = pnrNumbers ? pnrNumbers[pnrNumbers.length - 1] : undefined;

                                                                                        return formik.values.vehicles?.[index]?.pnrNumbers?.length ? !pnrNumbers?.length || !lastPnrNumber : false;
                                                                                    })()
                                                                                }
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </FieldArray>


                                                            {/* Contacts */}
                                                            <FieldArray name={`vehicles.${index}.contacts`}>
                                                                {({remove, push}) => (
                                                                    <div className="row mt-3">
                                                                        <label>Contacts</label>
                                                                        {(vehicle.contacts || []).map((contact, contactIndex) => {
                                                                            const isDisabled = contactIndex > 0 && !formik.values.vehicles?.[index]?.contacts?.[contactIndex - 1];

                                                                            return (
                                                                                <div key={contactIndex}
                                                                                     className="row align-items-center mb-3 mt-1">
                                                                                    <div className="col-10">
                                                                                        <Input
                                                                                            name={`vehicles.${index}.contacts.${contactIndex}`}
                                                                                            value={contact}
                                                                                            onChange={formik.handleChange}
                                                                                            labelAlignment="left"
                                                                                            placeholder="Enter contact"
                                                                                            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                                            borderRadius="semi-rounded"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <Button
                                                                                            label="Remove"
                                                                                            variant="destructive"
                                                                                            borderRadius="semi-rounded"
                                                                                            onClick={() => remove(contactIndex)}
                                                                                            disabled={isDisabled} // Disable remove button if previous contact is not filled
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}

                                                                        <div className={`container`}>
                                                                            <Button
                                                                                label="Add Contact"
                                                                                variant="neutral"
                                                                                onClick={() => push('')}
                                                                                className="rainbow-m-around_medium mt-1"
                                                                                borderRadius="semi-rounded"
                                                                                disabled={
                                                                                    (() => {
                                                                                        const contacts = formik.values.vehicles?.[index]?.contacts;
                                                                                        const lastContact = contacts ? contacts[contacts.length - 1] : undefined;

                                                                                        return formik.values.vehicles?.[index]?.contacts?.length ? !contacts?.length || !lastContact : false;
                                                                                    })()
                                                                                } // Disable "Add Contact" button if the last contact is not filled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </FieldArray>


                                                            {/* Seats */}
                                                            <FieldArray name={`vehicles.${index}.seats`}>
                                                                {({remove, push}) => {


                                                                    return (
                                                                        <div className="row mt-3">
                                                                            <label>Seats</label>
                                                                            {(vehicle.seats || []).map((seat, seatIndex) => {
                                                                                const isDisabled = seatIndex > 0 && !formik.values.vehicles?.[index]?.seats?.[seatIndex - 1]?.seatNumber;

                                                                                // Find the member associated with the seat's user ID
                                                                                const member = tripData.members.find(
                                                                                    memberData => memberData.userId?._id === seat._user
                                                                                );

                                                                                return (
                                                                                    <div key={seatIndex}
                                                                                         className="row align-items-center mb-3 mt-1">
                                                                                        <div className="col-6">
                                                                                            <Input
                                                                                                name={`vehicles.${index}.seats.${seatIndex}.seat_number`}
                                                                                                value={seat.seatNumber || ''}
                                                                                                onChange={formik.handleChange}
                                                                                                labelAlignment="left"
                                                                                                placeholder="Enter seat number"
                                                                                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                                                borderRadius="semi-rounded"
                                                                                                disabled={isDisabled} // Disable input if previous seat is not filled
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-4">
                                                                                            <Picklist
                                                                                                id="picklist-user"
                                                                                                placeholder="Select User"
                                                                                                label="User"
                                                                                                hideLabel
                                                                                                enableSearch
                                                                                                onSearch={query => {
                                                                                                    setSearchKey(query);
                                                                                                }}
                                                                                                onChange={value => {
                                                                                                    setSearchKey('');
                                                                                                    formik.setFieldValue(`vehicles.${index}.seats.${seatIndex}._user`, value.value);
                                                                                                }}
                                                                                                value={member ? {
                                                                                                    label: `${member?.userId?.firstName} ${member?.userId?.lastName}`,
                                                                                                    value: member?.userId?._id,
                                                                                                    name: member?.userId?._id
                                                                                                } : undefined}
                                                                                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                                                borderRadius="semi-rounded"
                                                                                                disabled={isDisabled} // Disable Picklist if previous seat is not filled
                                                                                            >
                                                                                                {tripData.members.filter(member => {

                                                                                                    console.log("selectedUserIds",selectedUserIds)
                                                                                                    console.log("selectedUserIds member",member)
                                                                                                    const fullName = `${member.userId?.firstName} ${member.userId?.lastName}`.toLowerCase();
                                                                                                    const memberId:any= member?.userId?._id
                                                                                                    const isAlreadySelected = selectedUserIds?.includes(memberId);
                                                                                                    return fullName.includes(searchKey.toLowerCase()) && !isAlreadySelected;
                                                                                                }).map(member => (
                                                                                                    <Option
                                                                                                        key={member.userId?._id}
                                                                                                        name={member.userId?._id}
                                                                                                        value={member.userId?._id}
                                                                                                        label={`${member.userId?.firstName} ${member.userId?.lastName}`}
                                                                                                    />
                                                                                                ))}
                                                                                            </Picklist>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            <Button
                                                                                                label="Remove"
                                                                                                variant="destructive"
                                                                                                borderRadius="semi-rounded"
                                                                                                onClick={() => remove(seatIndex)}
                                                                                                disabled={isDisabled} // Disable remove button if previous seat is not filled
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}

                                                                            <div className="container">
                                                                                <Button
                                                                                    label="Add Seat"
                                                                                    variant="neutral"
                                                                                    onClick={() => push({
                                                                                        seat_number: '',
                                                                                        _user: undefined
                                                                                    })}
                                                                                    className="rainbow-m-around_medium mt-1"
                                                                                    borderRadius="semi-rounded"
                                                                                    disabled={
                                                                                        (() => {
                                                                                            const seats = formik.values.vehicles?.[index]?.seats;
                                                                                            const lastSeat = seats ? seats[seats.length - 1]?.seatNumber : undefined;

                                                                                            return formik.values.vehicles?.[index]?.seats?.length ? !seats?.length || !lastSeat : false;
                                                                                        })()
                                                                                    } // Disable "Add Seat" button if the last seat is not filled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }}
                                                            </FieldArray>


                                                        </div>
                                                    </div>

                                                ))}
                                                <div className={`row mt-3 mx-0`}>
                                                    <Button
                                                        label={category.name === 'rent-vehicle' ? 'Add Vehicle' : `Add ${category.name}`}
                                                        variant="neutral"
                                                        borderRadius="semi-rounded"
                                                        onClick={() => push({
                                                            vehicleNumber: "",
                                                            pnr_numbers: [],
                                                            contacts: [],
                                                            dailyUsage: "",
                                                            startingKm: "",
                                                            seats: [],
                                                        })}
                                                        className="rainbow-m-around_medium"
                                                        disabled={formik.values.vehicles.length ? !formik.values.vehicles[formik.values.vehicles.length - 1]?.vehicleNumber : false} // Disable "Add Room" button if last room number is not filled

                                                    />
                                                </div>

                                            </div>
                                        )
                                    }}
                                </FieldArray>
                            )}


                            <div className={`card mt-3 p-3`}>

                                <div className="row">
                                    <div className="col-6">
                                        <Input
                                            label="Expense"
                                            labelAlignment="left"
                                            placeholder="Enter expense"
                                            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                            borderRadius="semi-rounded"
                                            name="budget"
                                            value={formik.values.budget || ''}
                                            onChange={formik.handleChange}
                                        />
                                    </div>

                                    <div className="col-6">
                                        <div className={`row`}>
                                            <div className="col-6">
                                                <Picklist
                                                    id="picklist-user"
                                                    placeholder="Select Currency"
                                                    label="Currency"
                                                    labelAlignment={`left`}
                                                    enableSearch
                                                    onSearch={(query) => {

                                                        setSearchKey(query)
                                                    }}
                                                    onChange={(value) => {
                                                        setSearchKey('')
                                                        formik.setFieldValue(`currency`, value.value);
                                                    }}

                                                    value={formik.values.currency ? (() => {
                                                        const selectedCurrency = currencies?.data.currencies?.find(currencyData =>
                                                            currencyData._id === formik.values.currency
                                                        );
                                                        return selectedCurrency ? {
                                                            label: selectedCurrency.name,
                                                            value: selectedCurrency._id,
                                                            name: selectedCurrency._id,
                                                        } : undefined;
                                                    })() : undefined}

                                                    className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                    borderRadius="semi-rounded"
                                                >
                                                    {currencies?.data.currencies.filter((currency) => {
                                                        const name = `${currency.name} ${currency.code}`.toLowerCase();
                                                        return name.includes(searchKey.toLowerCase());
                                                    }).map((currency) =>
                                                        <Option name={currency._id} value={currency._id}
                                                                label={`${currency.name} - ${currency.code}`}/>
                                                    )}
                                                </Picklist>
                                            </div>
                                            <div className="col-6">
                                                <div className={`d-flex flex-column`}>

                                                    <label>Expense Split</label>

                                                    <ButtonGroup className="rainbow-m-bottom_medium"
                                                                 borderRadius="semi-rounded">
                                                        <Button label="Self" disabled={tripData.tripType !== 'group'}
                                                                variant={expenseSplitType === 'self' ? 'brand' : 'neutral'}
                                                                size="large" onClick={() => {
                                                            if (tripData.tripType === 'group') {
                                                                setExpenseSplitType('self')
                                                            }
                                                        }}/>
                                                        <Button label="Group" disabled={tripData.tripType !== 'group'}
                                                                variant={expenseSplitType === 'group' ? 'brand' : 'neutral'}
                                                                size="large" onClick={() => {
                                                            if (tripData.tripType === 'group') {
                                                                setExpenseSplitType('group')
                                                            }
                                                        }}/>
                                                    </ButtonGroup>

                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            {expenseSplitType === 'group' && (
                                <div className={`row`}>
                                    <div className={`col-6`}>
                                        <div className={`card mt-3 p-3`}>

                                            <div className="row">

                                                <div className={`d-flex justify-content-between`}>
                                                    <label htmlFor="react-select-container">Paid By</label>
                                                    <CheckboxToggle
                                                        label="Split Equally"
                                                        labelAlignment={`left`}
                                                        value={splitPaidByEqually}
                                                        onChange={(event) => setSplitPaidByEqually(event.target.checked)}
                                                    />
                                                </div>
                                                <Select
                                                    isMulti
                                                    options={tripData.members.map(member => ({
                                                        value: member.userId?._id,
                                                        label: `${member.userId?.firstName} ${member.userId?.lastName}`
                                                    }))}
                                                    onChange={(selectedOptions) => {

                                                        const updatedPaidMembers = selectedOptions.map(option => {
                                                            const existingMember = formik.values.paidMembers?.find(member => member._user === option.value);
                                                            return existingMember ? existingMember : {
                                                                _user: option.value,
                                                                paidAmount: ''
                                                            };
                                                        });
                                                        formik.setFieldValue('paidMembers', updatedPaidMembers);
                                                    }}
                                                    value={formik.values.paidMembers?.map(memberId =>
                                                        tripData.members.find(member => member.userId?._id === memberId._user)
                                                    ).map(member => ({
                                                        value: member?.userId?._id,
                                                        label: `${member?.userId?.firstName} ${member?.userId?.lastName}`
                                                    }))}
                                                    placeholder="Select Members"
                                                    isSearchable
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                />

                                            </div>
                                            <div className={`container mt-3`}>

                                                <div className={`row`}>
                                                    {formik.values.paidMembers?.map((memberFormik, paidIndex) => {

                                                        const member = tripData.members.find(
                                                            memberData => memberData.userId?._id === memberFormik._user
                                                        );

                                                        return (
                                                            <div
                                                                key={paidIndex}
                                                                className="paid-passenger-card-item d-flex flex-stack py-3 px-0 cursor-pointer">
                                                                <div className="d-flex align-items-center col-6">
                                                                    <div className="symbol symbol-45px symbol-circle ">
                                                                    <span
                                                                        className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                                        {member?.userId?.firstName?.charAt(0)}
                                                                    </span>
                                                                    </div>
                                                                    <div className="ms-3">
                                                                        <a className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">
                                                                            {member?.userId?.firstName} {member?.userId?.lastName}
                                                                        </a>
                                                                        <div
                                                                            className="fw-bold text-gray-400">{member?.userId?.userName}</div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex flex-column align-items-end me-2 member-budget-manage  col-6">
                                                                    <div className="form-check d-flex">
                                                                        <div className="position-relative">
                                                                            <Input
                                                                                name={`paidMembers.${paidIndex}.paidAmount`}
                                                                                value={memberFormik.paidAmount || ''}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e); // Handle the form change
                                                                                    setSplitPaidByEqually(false); // Set splitPaidEqually to false when the amount is directly changed
                                                                                }}
                                                                                labelAlignment="left"
                                                                                placeholder="Enter amount"
                                                                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                                borderRadius="semi-rounded"
                                                                            />
                                                                        </div>
                                                                        <span
                                                                            className="my-3 fs-5 px-2 fw-bolder primary-color">
                                                                        {currencies?.data.currencies.find(currencyData =>
                                                                            currencyData._id === formik.values.currency
                                                                        )?.code || "Currency not found"}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className={`col-6`}>
                                        <div className={`card mt-3 p-3`}>

                                            <div className="row">

                                                <div className={`d-flex justify-content-between`}>
                                                    <label htmlFor="react-select-container">Paid For</label>
                                                    <CheckboxToggle
                                                        label="Split Equally"
                                                        labelAlignment={`left`}
                                                        value={splitPaidEqually}
                                                        onChange={(event) => setSplitPaidEqually(event.target.checked)}
                                                    />
                                                </div>
                                                <Select
                                                    isMulti
                                                    options={tripData.members.map(member => ({
                                                        value: member.userId?._id,
                                                        label: `${member.userId?.firstName} ${member.userId?.lastName}`
                                                    }))}
                                                    onChange={(selectedOptions) => {

                                                        const updatedMembers = selectedOptions.map(option => {
                                                            const existingMember = formik.values.members?.find(member => member._user === option.value);
                                                            return existingMember ? existingMember : {
                                                                _user: option.value,
                                                                amount: ''
                                                            };
                                                        });
                                                        formik.setFieldValue('members', updatedMembers);
                                                    }}
                                                    value={formik.values.members?.map(memberId =>
                                                        tripData.members.find(member => member.userId?._id === memberId._user)
                                                    ).map(member => ({
                                                        value: member?.userId?._id,
                                                        label: `${member?.userId?.firstName} ${member?.userId?.lastName}`
                                                    }))}
                                                    placeholder="Select Members"
                                                    isSearchable
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                />

                                            </div>
                                            <div className={`container mt-3`}>

                                                <div className={`row`}>
                                                    {formik.values.members?.map((memberFormik, memberIndex) => {

                                                        const member = tripData.members.find(
                                                            memberData => memberData.userId?._id === memberFormik._user
                                                        );

                                                        return (
                                                            <div
                                                                key={memberIndex}
                                                                className="paid-passenger-card-item d-flex flex-stack py-3 px-0 cursor-pointer">
                                                                <div className="d-flex align-items-center col-6">
                                                                    <div className="symbol symbol-45px symbol-circle ">
                                                                    <span
                                                                        className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                                        {member?.userId?.firstName?.charAt(0)}
                                                                    </span>
                                                                    </div>
                                                                    <div className="ms-3">
                                                                        <a className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 ">
                                                                            {member?.userId?.firstName} {member?.userId?.lastName}
                                                                        </a>
                                                                        <div
                                                                            className="fw-bold text-gray-400">{member?.userId?.userName}</div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex flex-column align-items-end me-2 member-budget-manage  col-6">
                                                                    <div className="form-check d-flex">
                                                                        <div className="position-relative">
                                                                            <Input
                                                                                name={`members.${memberIndex}.amount`}
                                                                                value={memberFormik.amount || ''}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e); // Handle the form change
                                                                                    setSplitPaidEqually(false); // Set splitPaidEqually to false when the amount is directly changed
                                                                                }}
                                                                                labelAlignment="left"
                                                                                placeholder="Enter amount"
                                                                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                                borderRadius="semi-rounded"
                                                                            />
                                                                        </div>
                                                                        <span
                                                                            className="my-3 fs-5 px-2 fw-bolder primary-color">
                                                                        {currencies?.data.currencies.find(currencyData =>
                                                                            currencyData._id === formik.values.currency
                                                                        )?.code || "Currency not found"}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            )}

                            <div className={`card mt-3 p-3`}>

                                <div className="rainbow-p-vertical_large rainbow-p-horizontal_medium rainbow-m_auto">
                                    <div ref={fileSelectorRef}>
                                        <FileSelector
                                            label="File selector multiple"
                                            placeholder="Drag & Drop or Click to Browse"
                                            bottomHelpText="Can select multiple files"
                                            variant="multiline"
                                            multiple
                                            value={files}
                                            accept=".pdf"
                                            onChange={(fileList: FileList) => handleFileChange(fileList)}
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className={`card mt-3 p-3`}>
                                <Textarea
                                    id="example-textarea-1"
                                    label="Note"
                                    labelAlignment={`left`}
                                    rows={4}
                                    placeholder="Placeholder Text"
                                    variant="shaded"
                                    className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                />
                            </div>

                        </div>

                    </form>
                }


            </Modal>
        </FormikProvider>
    );
};
export default FormContainer;
