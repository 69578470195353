import React, {FC, useEffect, useState} from 'react';
import {Trip, TripDestination, TripMemberPopulated, TripResponse} from "../../../interfaces/trip.interface";

import './note.scss'
import QuillEditor from "../../editor/QuillEditor";
import find from "lodash/find";
import http from "../../../services/api";
import {trip_url} from "../../../services/api.utils";
import Board from "../stickyNote/Board";
type ParamTypes =  {
    tripId: string,
    destinationId:string
}
type Props = {
    tripData:Trip
}

const TripNote: FC<Props> = ({tripData}) => {
    const [noteChanged, setNoteChanged] = useState(false);
    const [note, setNote] = useState(tripData.note);
    const [loading, setLoading] = useState(false);


    let passengerArray:any = tripData.members.map(function (member, index) {
        if(member.userId){
            return({
                _id:member.userId._id,
                firstName:member.userId.firstName,
                lastName:member.userId.lastName,
                userName:member.userId.userName,
                cover:member.userId.cover,
                parentId:member.parentId,
                status:member.status,
            })
        }

    })


    const saveNoteChanges = () => {
        setLoading(true);

        let data = {note: note,updateType:"note"}

        http
            .put<any, TripResponse>(trip_url+"/"+tripData._id, data)
            .then((res) => {

                if(res.status == 200){

                    setNoteChanged(false)

                }


            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {
                setLoading(false);
            });

    }





    return (

        <div className="col-xl-9 col-md-9 trip-note">
             <div className="row">

                    <div className="col-xl-12 col-md-12  mb-7 mb-xl-0">
                        <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none ">

                            <div className="card-header align-items-center border-0">

                                <div className="card-title align-items-start flex-column"><h3
                                    className="fw-bolder mb-1">Note</h3>
                                    <div className="fs-7 text-gray-400">A short board for trip planning...</div>
                                </div>
                                <div className="card-toolbar">

                                    <button className={`btn btn-primary shadow-none ${noteChanged? "" :"disabled"} ${tripData.status == 'completed'? "disabled": ''} `} onClick={function () {

                                        if(noteChanged){
                                            saveNoteChanges();
                                        }

                                    }} type="button">{loading? "Saving...": "Save changes"}</button>

                                </div>
                            </div>

                            <div className="card-body">

{/*
                                <Board count={10} />
*/}
                                {tripData.status == 'completed'?
                                    <div className="quill" style={{ backgroundColor:'#f5f8fa', borderRadius: '15px'}}>
                                        <div className="ql-snow">
                                            <div className="ql-editor">
                                                <div dangerouslySetInnerHTML={{__html: note}} />

                                            </div>
                                        </div>
                                    </div>
                                :
                                    <QuillEditor users={passengerArray} initialValue={note} onEditorChange={function (value:any) {
                                        setNoteChanged(true)
                                        setNote(value)
                                    }} />
                                }

                            </div>
                        </div>
                    </div>


                </div>

        </div>


    );
};
export default TripNote;
