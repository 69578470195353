import React, {FC, useEffect, useState} from 'react';

import './header.scss'


type HeaderProps = {
    title: string;
    dateRange?: string;
}

const Header: FC <HeaderProps> = ({ title,dateRange }) => {

    return (


            <header className="header">

                <div className="d-flex flex-column flex-md-row align-items-center pb-3 pt-3 mb-4 mt-3 ps-3 header-container">

                    <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
                        <h3 className="text-dark fw-bolder fs-4">{title}</h3>
                    </div>
                </div>

            </header>

    );
};

export default Header;
