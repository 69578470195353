import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {AppBasic} from '../interfaces/app.interface';

const appBasic = createSlice({
    name: 'appBasic',
    initialState: null as AppBasic | null,
    reducers: {
        setAppBasic(state, { payload }: PayloadAction<AppBasic>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setAppBasic } = appBasic.actions;
export default appBasic.reducer;
