import React from "react";
import Options from "../options/options";

const ContactOptions = (props) => {
  const options = [
    {
      name: "Mobile",
      handler: props.actionProvider.handleMobile,
      id: 1,
    },
    {
      name: "Email",
      handler: props.actionProvider.handleEmail,
      id: 2,
    },
    {
      name: "LinkedIn",
      handler: props.actionProvider.handleResume,
      id: 3,
    },
  ];

  return <Options options={options} />;
};

export default ContactOptions;