import React, {FC, forwardRef, useCallback, useEffect, useRef, useState, CSSProperties} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import {getImgUrl, showAlert, toastMessage} from "../../../../util";
import * as Yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";

import remove from 'lodash/remove'

import './newtrip.scss'

import "@pathofdev/react-tag-input/build/index.css";
import {
    GooglePlaceResponse,
    NewTripData,
    Trip, TripDataValidationResponse, TripDestination, TripInviteEmailResponse,
    TripMember,
    TripResponse
} from "../../../../interfaces/trip.interface";
import {useSelector} from "react-redux";
import {RootState} from "../../../../rootReducer";
import dayjs from "dayjs";
import {eSMoment} from "../../../../util";
import http from "../../../../services/api";
import {
    MembersResponse,
    User,
    UserResponse,
    UserSearch,
    UsersResponse,
    UsersSearchResponse
} from "../../../../interfaces/user.interface";
import {
    upload_url,
    trips_url,
    user_search_url,
    base_url,
    currencies_code_url,
    user_url, google_place_photo_url, email_check_url, trip_data_validation, trip_date_check_url
} from "../../../../services/api.utils";
import {UploadResponse} from "../../../../interfaces/upload.interface";


import Sidebar from "../../../sidebar/Sidebar";
import {CurrenciesResponse, Currency} from "../../../../interfaces/country.interface";
import GooglePlaces from "../../../googleplaces/GooglePlaces";
import DatePicker from "react-datepicker";
import Select, { components, DropdownIndicatorProps } from 'react-select';

import {ButtonProps} from "react-bootstrap";
import Modal from "react-bootstrap/cjs/Modal";
import ReactTagInput from "@pathofdev/react-tag-input";
import FamilyMemberItem from "../../cards/FamilyMemberItem";
import find from "lodash/find";
import SearchMemberItem from "../../cards/SearchMemberItem";
import {useAppDispatch} from "../../../../store";
import BackButtonImgIcon from "../../../../images/icons/back-button.svg";
import CloseImgIcon from "../../../../images/icons/close.svg";
import CloseRedImgIcon from "../../../../images/icons/close-red.svg";
import {setCurrencies} from "../../../currency/currenciesSlice";
import Loader from "../../../loader/Loader";


import SoloTravelIcon from "../../../../images/illustration-icons/solo-travel.svg";
import FamilyTravelIcon from "../../../../images/illustration-icons/family-travel.svg";
import GroupTravelIcon from "../../../../images/illustration-icons/group-travel.svg";
import DeleteIconImg from "../../../../images/icons/delete.svg";
import EmptyFamilyImg from "../../../../images/illustration-icons/empty-family.svg";
import EmptyGroupImg from "../../../../images/illustration-icons/empty-group.svg";
import loadingImg from "../../../../images/loaders/earth.svg";
import ErrorInfoImg from "../../../../images/icons/error.svg";
import DotRedImg from "../../../../images/icons/dot-red.svg";
import CalenderImg from "../../../../images/icons/calender.svg";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import ErrorIconImg from "../../../../images/icons/error.svg";
import filter from "lodash/filter";
import {inRange} from "lodash";
import {Helmet} from "react-helmet";
import {setUser} from "../../../account/userSlice";


type OptionSchema ={
    name?: string
    value: string
    label?: string
}
const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}

const NewTrip: FC = () => {

    const { width } = useViewport();

    const { handleSubmit:handleSubmitWizardOne, register:registerWizardOne, formState: { errors:errorsWizardOne } } = useForm<NewTripData>();
    const { handleSubmit:handleSubmitWizardTwo, register:registerWizardTwo, reset: resetWizardTwo , formState: { errors:errorsWizardTwo } } = useForm<TripDestination>();
    const { user, searchUsers, currencies } = useSelector((state: RootState) => state);

    const [searchKey, setSearchKey] = useState("");
    const [calenderIsOpen, setCalenderIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("intro");
    const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
    const [showAiSuggestionModal, setShowAiSuggestionModal] = useState(false);
    const [tripValidationData, setTripValidationData] = useState<TripDataValidationResponse>();
    const [tripValidationLoading, setTripValidationLoading] = useState(false);
    const [tripType, setTripType] = useState("");
    const [completedTab, setCompletedTab] = useState(0);
    const [editDestinationKey, setEditDestinationKey] = useState<number | null>(null);
    const [minDate, setMinDate] = useState<Date | null>();
    const [disableSaveTripButton, setDisableSaveTripButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tripBasicData, setTripBasicData] = useState<NewTripData>();
    const [members, setMembers] = useState(searchUsers as UsersSearchResponse| undefined);
    const [addedMembers, setAddedMembers] = useState<UsersSearchResponse>();
    const [unavailableDates, setUnavailableDates] = useState();
    const [showTripTypeChangeModal, setShowTripTypeChangeModal] = useState(false);

    const [cover, setCover] = useState({} as UploadResponse);
    const [loadedCover, setLoadedCover] = useState({
        meta: {
            previewUrl: ""
        }
    });


    const authUserId = user?.data.userId;

    const [activeFormWizard, setActiveFormWizard] = useState("basic");
    const [showTripTypeModal, setShowTripTypeModal] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);

    const [previewDestinations, setPreviewDestinations] = useState(false);
    const [previewMembers, setPreviewMembers] = useState(false);
    const [currenciesData, setCurrenciesData] = useState<[Currency]|null>();
    const [currenciesOptions, setCurrenciesOptions] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState<OptionSchema|null>(null);
    const [familyMembers, setFamilyMembers] = useState<MembersResponse>();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {

        if (familyMembers == undefined) {
            getFamilyMembers()
        }

    },[])
    useEffect(() => {

        http
            .post<null, any>(trip_date_check_url)
            .then((res) => {
                if (res) {

                    if(res.status == 200){

                        let dateArray:any = []

                        res.data.map((date:any)=>{

                            dateArray.push( new Date(date))
                        })


                        setUnavailableDates(dateArray)


                    }
                }

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {


            });

    },[])

    useEffect(() => {

        if(currencies){
            let currenciesData:any = []

            currencies.data.currencies.map(function (currency) {
                currenciesData.push( { value: currency._id, label: currency.name+" - "+currency.code })
            })

            setCurrenciesOptions(currenciesData)

        }
    },[currencies])



    useEffect(() => {

        if (currencies == null) {

            getCurrencies()
        }


    }, []);


    const getCurrencies = () => {

        http
            .get<null, CurrenciesResponse>(currencies_code_url)
            .then((res) => {
                if (res) {

                    if(res.status == 200){
                        dispatch(setCurrencies(res))
                    }
                }

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {


            });


    };


    const getFamilyMembers = () => {

            http
                .get<null, MembersResponse>(`${user_url}/${authUserId}/family`)
                .then((res) => {

                    setFamilyMembers(res)


                }).catch((error) => {
                console.log(error);
            })
                .finally(() => {
                    setLoading(false);
                });
    }

    const submitFormWizardOne = (data: NewTripData) => {

        setTripBasicData(data)
        setActiveFormWizard("destinations")
        setCompletedTab(1)
        setActiveTab("destination")
        setShowTripTypeChangeModal(false)
    };
    const submitFormWizardTwo = (data: TripDestination) => {

        if(editDestinationKey != null){

            if(tripBasicData){


                tripBasicData.destinations.map(function (destination, key) {

                    if(key == editDestinationKey){

                        if(startDate){
                            destination.startDate = startDate.toString();
                        }
                        if(endDate){
                            destination.endDate = endDate.toString();

                        }
                    }else if(key > editDestinationKey){


                        let currentDestinationDateDifference = eSMoment(destination.endDate).diff(destination.startDate, 'days')


                        let nextDate = eSMoment(new Date(tripBasicData.destinations[key-1].endDate)).add(currentDestinationDateDifference, 'days').format('YYYY/MM/DD HH:mm:ss')
                        destination.startDate = tripBasicData?.destinations[key-1].endDate;
                        destination.endDate = nextDate;

                    }
                    return destination

                })
                let lastTripEndDate = new Date()
                let tripDestinationLength =   tripBasicData.destinations.length
                if(tripDestinationLength){
                    lastTripEndDate =  new Date( tripBasicData.destinations[tripDestinationLength-1].endDate);
                    nextDate = eSMoment(new Date(lastTripEndDate)).add(2, 'days').format('YYYY/MM/DD HH:mm:ss')
                }


                setStartDate(lastTripEndDate);
              //  setEndDate(new Date(nextDate));

                setStartDate(null);
                //setEndDate(null);

                setAddress("")
                setEditDestinationKey(null)

                setTripBasicData({...tripBasicData})


            }


        }else{

            if(place){

                if(filter(tripBasicData?.destinations, (destination)=> destination.placeId == place.placeId).length == 0){

                    data.name = place.name;
                    data.formattedAddress = place.formattedAddress;
                    data.lat = place.lat;
                    data.lng = place.lng;
                    data.placeId = place.placeId;
                    data._country = place._country;
                    data.currencyId = selectedCurrency?.value?selectedCurrency?.value:"";
                    data.photoReference =place.photoReference;


                    if(startDate){
                        data.startDate = startDate.toString();
                    }


                    if(endDate){
                        data.endDate = endDate.toString();

                        setMinDate(endDate)
                    }

                    if(tripBasicData){

                        if(tripBasicData.destinations){
                            tripBasicData.destinations.push(data)
                        }else{

                            tripBasicData.destinations = [data];
                        }
                    }

                    let nextDate = eSMoment(endDate?new Date(endDate):new Date()).add(2, 'days').format('YYYY/MM/DD HH:mm:ss')

                    setStartDate(endDate);
                    setEndDate(null);

                    setTripBasicData(tripBasicData)

                    setAddress("")
                    setPlace(undefined)
                    resetWizardTwo()
                    setCompletedTab(2)

                }else {

                    setAddress("")
                    toastMessage("The selected destination has already been added. To continue, please choose another destination. ", 'error')

                }



            }
        }

        setSelectedCurrency(null)


    };


    let nextDate = eSMoment(new Date()).add(2, 'days').format('YYYY/MM/DD HH:mm:ss')

    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();


    const deleteDestination = (key:any) => {

        let tripBasicDataClone = tripBasicData


        let lastTripEndDate = new Date()
        let nextDate = eSMoment(new Date()).add(2, 'days').format('YYYY/MM/DD HH:mm:ss')

        if(tripBasicDataClone){
            if(tripBasicDataClone.destinations.length > 1){
                tripBasicDataClone.destinations.splice(key, 1);

                lastTripEndDate =  new Date(tripBasicDataClone.destinations[tripBasicDataClone.destinations.length-1].endDate);
                nextDate = eSMoment(new Date(lastTripEndDate)).add(2, 'days').format('YYYY/MM/DD HH:mm:ss')

            }else{
                tripBasicDataClone.destinations.pop();

                setCompletedTab(1)

                setPreviewDestinations(false)


            }


            setStartDate(lastTripEndDate);
            setEndDate(null);
            setMinDate(lastTripEndDate)

            setTripBasicData({...tripBasicDataClone})


            setEditDestinationKey(null)




                setAddress("")


        }

    };
    const editDestination = (key:any) => {


        if(tripBasicData){
            setStartDate(new Date(tripBasicData.destinations[key].startDate));
            setEndDate(new Date(tripBasicData.destinations[key].endDate))
            setAddress(tripBasicData.destinations[key].name)
            setEditDestinationKey(key)


            let minimumDate = null
            if(key > 0){
                minimumDate = new Date(eSMoment(new Date(tripBasicData.destinations[key-1].endDate)).format('YYYY/MM/DD HH:mm:ss'))
            }
            setMinDate(minimumDate)

        }

    };
    const searchMembers = (keyword?:string) => {


        if(searchKey || keyword){
            setLoading(true)
            http
                .post<null, UsersSearchResponse>(user_search_url,{key:keyword})
                .then((res) => {
                    if (res) {
                        setMembers(res)
                    }

                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }else{
            setMembers(undefined)
        }



    };

    const addMember = (user: UserSearch) => {

        if(tripBasicData){

            let tripMember:TripMember = {
                notifications: {
                    planning: true,
                    checklist: true,
                    document: true,
                    activity: true,
                    photo: true
                },
                status: "pending",
                joinType: "web",
                canAddData: false,
                role: "member",
                budget: tripBasicData.budget,
                userId: user._id,
                parentId: user.parentId?user.parentId:"",
            }

            if(tripBasicData.members){
                tripBasicData.members.push(tripMember)
            }else{

                tripBasicData.members = [tripMember];
            }


            if(! user.totalTrips){
                user.totalTrips = 0;
            }
            if(! user.role){
                user.role = "member";
            }

            let addedMembersClone = addedMembers
            if(addedMembersClone && addedMembersClone.data){
                addedMembersClone.data.push(user)
            }else{

                addedMembersClone = {
                    data: [user]
                };
            }
            setAddedMembers(addedMembersClone)
            setTripBasicData({...tripBasicData})

            //setMembers(undefined)


        }

    };



    const removeMember = (user: UserSearch) => {

        if(tripBasicData){

            remove(tripBasicData.members, function(member) {
                return member.userId == user._id
            });
            if(addedMembers){

                remove(addedMembers.data, function(member) {
                    return member._id == user._id
                });
                setAddedMembers({...addedMembers})

            }
            setTripBasicData({...tripBasicData})

            if(addedMembers && addedMembers.data.length < 2){
                setPreviewMembers(false)
            }
        }

    };

    const saveTrip = () => {



        setDisableSaveTripButton(true)


        if(tripBasicData && tripBasicData.destinations){


            tripBasicData.tripType = tripType

            tripBasicData.cover=cover.data
            http
                .post<Trip, TripResponse>(trips_url, tripBasicData)
                .then((res) => {
                    if (res) {

                        const { data, status} = res;

                        navigate(`/trip/${data._id}`);

                        if(status == 200){



                            http
                                .get<null, UserResponse>(user_url)
                                .then((res) => {


                                    let resData = res
                                    resData.data.accessToken = user?.data.accessToken
                                    localStorage.setItem('user', JSON.stringify(resData));

                                    dispatch(setUser(res));

                                    toastMessage("Trip created successfully", 'success')

                                }).catch((error) => {
                                console.log(error);
                            })
                                .finally(() => {
                                    setLoading(false);
                                });




                        }else{
                            toastMessage("Trip creation failed. Please try later", 'error')
                        }


                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });

        }

    }


    const uploadCallback = (data:any) => {


        setLoadedCover(data)

        var formData = new FormData();
        formData.append("file", data.file);
        formData.append("folder", "cover");

        http
            .post<null, UploadResponse>(base_url+upload_url, formData)
            .then((res) => {
                if (res) {

                    const { data} = res;

                    setCover(res)

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });

    }

    const checkEmailByUser = (tagItem:any) => {

        http
            .post<any, TripInviteEmailResponse>(base_url+email_check_url, {tags:tagItem})
            .then((res) => {
                if (res) {


                    if(res.status == 200){


                        if(res.data && res.data.tags){
                            setTags(res.data.tags)

                      /*      setTripBasicData({
                                ...tripBasicData,
                                invites: res.data.tags
                            })*/
                        }



                    }else{
                        toastMessage("Trip creation failed. Please try later", 'error')

                    }

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });

    }

    const [address, setAddress] = useState("");
    const [place, setPlace] = useState<GooglePlaceResponse>();

    const handlePlaceChange = (address:any) => {



        setAddress(address)
    };


    const handlePlaceSelect = (place:GooglePlaceResponse) => {

        setAddress(place.name)
        setPlace(place)
    };

    const areDatesInRange = (startDate: Date, endDate: Date, dateArray: string[]): boolean => {
        for (const dateString of dateArray) {
            const currentDate = new Date(dateString);
            if (currentDate >= startDate && currentDate <= endDate) {
                return true;
            }
        }
        return false;
    };


    const onChange = (end:any) => {
      //  const [start, end] = dates;


        if(unavailableDates && startDate){

            if(areDatesInRange(startDate, end, unavailableDates)){



                toastMessage("The dates you've selected for your upcoming trip overlap with another trip you are already a part of it.", 'error')

            }else{

                setEndDate(end);
                setCalenderIsOpen(false)
            }

        }

    };

    const updateTab = (tab: any) => {

        setActiveFormWizard(tab)

    }
    const selectCurrency = (selectedCurrency:any) => {

        if(selectedCurrency){

            setSelectedCurrency(selectedCurrency)

        }

    }
    const validateTripData = () => {

        setTripValidationLoading(true)
        setShowAiSuggestionModal(true)

        http
            .post<any, TripDataValidationResponse>(base_url+trip_data_validation, {destinations:tripBasicData?.destinations})
            .then((res) => {
                if (res) {

                    if(res.status == 200){

                        setTripValidationLoading(false)


                        setTripValidationData(res)

                    }else {
                        setTripValidationLoading(false)

                        setShowAiSuggestionModal(false)

                        toastMessage(res.message, 'error')

                    }

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });



        //
        //
        //



    }

    const [tags, setTags] = useState<string[]>([])

    const DropdownIndicator = (
        props: DropdownIndicatorProps<OptionSchema, true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="bx bxs-dollar-circle" ></i>
            </components.DropdownIndicator>
        );
    };

    return (
        <main className="col-md-11 ms-sm-auto col-lg-11 px-md-4 container-main trip-dashboard-container" >
            <Helmet>

            </Helmet>
            <div className="container  mt-20">
                {activeTab == "destination"?
                    <div className="col-md-8 mx-auto text-center mb-10">
                        <h1 className="mb-3 fw-semibold fs-1 text-dark">Choose the dates for your upcoming journey</h1>
                        <p className=" mb-4 text-dark">
                            Take a moment to specify the dates that best suit your plans for an upcoming adventure, ensuring a well-prepared and enjoyable journey tailored to your schedule and preferences.                        </p>
                    </div>
                    :
                    <div className="col-md-8 mx-auto text-center mb-10">
                        <h1 className="mb-3 fw-semibold fs-1 text-dark">What Type of Trip do you need?</h1>
                        <p className=" mb-4 fs-7">
                            Choose your ideal journey: Solo trips offer personal freedom, group adventures foster camaraderie, and family vacations create cherished moments for all. What type of trip suits you?
                        </p>
                    </div>
                }

                <div className="col-md-10 mx-auto form-wizard">
                    {activeTab == "intro"?
                        <form onSubmit={handleSubmitWizardOne(submitFormWizardOne)} className="trip-form">
                            <div className="row  text-center ">
                                <div className="col-lg-4  mb-3">
                                    <div className={`item ${tripType == 'solo'? 'active': ''} py-5 px-4  border rounded`} onClick={() => {
                                        setTripType("solo")
                                        //   setActiveTab("destination")
                                    }}>
                                        <input id="answer_1" type="radio" name="tripType"
                                               value="solo" className="required" checked={tripType === 'solo'}  />
                                        <label htmlFor="answer_1">
                                            <div className="card align-items-center">
                                                <img src={SoloTravelIcon} width={150}  alt="" />
                                                <div className="card-body">
                                                    <h5 className="card-title fs-2 text-dark">Solo</h5>
                                                    <p className="card-text fs-7">A solo trip is your personal adventure, offering freedom and self-discovery as you create unique experiences on your own terms.</p>
                                                    <p className="card-text primary-color mt-3 fw-bolder fs-7" onClick={()=>setShowTripTypeModal(true)}>Read more</p>

                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-4  mb-3">
                                    <div className={`item ${tripType == 'family'? 'active': ''} px-4 py-5 border rounded`} onClick={() => {
                                        setTripType("family")
                                        // setActiveTab("destination")
                                    }}>
                                        <input id="answer_2" name="tripType" type="radio"
                                               value="family" className="required" checked={tripType === 'family'}  />
                                        <label htmlFor="answer_2">
                                            <div className="card align-items-center">
                                                <img src={FamilyTravelIcon} width={150} alt="" />
                                                <div className="card-body">
                                                    <h5 className="card-title fs-2  text-dark">Family</h5>
                                                    <p className="card-text fs-7">Family trips mix bonding, diverse activities for all, creating cherished memories, and strengthening connections in beautiful destinations.</p>
                                                    <p className="card-text primary-color mt-3 fw-bolder fs-7" onClick={()=>setShowTripTypeModal(true)}>Read more</p>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-4  mb-3">
                                    <div className={`item ${tripType == 'group'? 'active': ''} p-5 border rounded`}  onClick={() => {
                                        setTripType("group")
                                    }}>
                                        <input id="answer_3" name="tripType" type="radio" value="group"
                                               className="required" checked={tripType === 'group'} />
                                        <label htmlFor="answer_3">

                                            <div className="card align-items-center">
                                                <img src={GroupTravelIcon} width={175} alt="" />
                                                <div className="card-body">
                                                    <h5 className="card-title fs-2  text-dark">Group</h5>
                                                    <p className="card-text fs-7">Group trips unite friends, create memories, foster connections in exciting destinations with shared adventures.</p>
                                                    <p className="card-text primary-color mt-3 fw-bolder fs-7" onClick={()=>setShowTripTypeModal(true)}>Read more</p>

                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex mt-0 mt-xl-5 mb-10 mb-xl-0 justify-content-end">
                                    {tripType?
                                        <button type="submit" name="backward" className={`btn btn-lg btn-primary shadow-none ${width<=620? 'w-100':''}`}>Explore places!
                                        </button>
                                        :

                                        <button type="button" name="backward" className={`btn btn-lg btn-primary shadow-none disabled ${width<=620? 'w-100':''}`} disabled={true}>Explore places!
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                        : activeTab == "destination"?

                            <>
                                <div>
                                    <span className={`text-dark`}>You've opted for a <span className={`fw-bolder cursor-pointer text-dark text-capitalize`}>{tripType} trip</span> and wish to make a change, <span className={`fw-bolder cursor-pointer text-primary`} onClick={()=>setShowTripTypeChangeModal(true)}>click here</span> to explore alternative options.</span>
                                </div>
                                <div className="destination-search mb-10 rounded" style={width<=620?{backgroundColor: 'transparent'}:{}}>

                                    <div className="row ">
                                        <form onSubmit={handleSubmitWizardTwo(submitFormWizardTwo)} className="trip-form d-flex flex-column flex-lg-row   p-3 p-sm-0 p-md-0 p-xl-0 p-lg-0 " style={{ backgroundColor:'#FFF'}}>
                                            <div className={`col-xl-5 p-3 position-relative ${width<= 620? 'bg-white rounded-4':''}`} >
                                                <div className={`position-absolute ` } style={{ backgroundColor:'#f5f8fa',borderRadius: '10px', width:'96%'}}>
                                                    <GooglePlaces placeholder='Where do you want to go?' searchType={'all-location'} address={address}  lat={2} lng={1}  handlePlaceSelect={handlePlaceSelect} handlePlaceChange={handlePlaceChange} />


                                                </div>
                                            </div>
                                            <div className={`col-xl-3 p-3 d-flex ${width<= 620? 'bg-white rounded-4 mt-3 pt-2 pb-3  justify-content-start':' justify-content-center'}`} >


                                                <div className={`px-3 me-3 cursor-pointer`} style={{ backgroundColor:'#f5f8fa',borderRadius: '10px'}}>
                                                    <DatePicker
                                                        minDate={minDate}
                                                        selected={startDate}
                                                        onChange={(dateItem)=> {
                                                            setCalenderIsOpen(true)
                                                            setStartDate(dateItem)
                                                            setEndDate(null)
                                                        }}
                                                        onInputClick={()=>setCalenderIsOpen(false)}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        excludeDates={unavailableDates}
                                                        selectsStart
                                                        dateFormat="MMM d yyyy"
                                                        wrapperClassName={width<=620? 'new-trip-date-input-sm':'new-trip-date-input'}
                                                        customInput={React.createElement(function ({ value, onClick }: {value: string; onClick: any}){

                                                            return(
                                                                <div onClick={onClick} className={``}>
                                                                    {startDate?
                                                                        <div className={`d-flex mt-2`}>
                                                                            <img src={CalenderImg} className={`me-2`} style={{ opacity: '0.5'}}  width={30}/>
                                                                            <div className={`d-flex flex-column`}>

                                                                                <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>{eSMoment(value).format('MMM D')}</span>
                                                                                <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>{eSMoment(value,'MMM D YYYY').format('YYYY')}</span>

                                                                            </div>
                                                                        </div>
                                                                        :
                                                                    <div className={`d-flex mt-2`}>
                                                                        <img src={CalenderImg} className={`me-2`}  style={{ opacity: '0.5'}}  width={30}/>
                                                                        <div className={`d-flex flex-column`}>

                                                                            <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>Start</span>
                                                                            <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>Date</span>

                                                                        </div>
                                                                    </div>
                                                                    }

                                                                </div>

                                                            )

                                                        })}
                                                    />
                                                </div>


                                                <div className={`px-3 me-3 cursor-pointer`} style={{ backgroundColor:'#f5f8fa', borderRadius: '10px'}}>
                                                    <DatePicker
                                                        minDate={startDate}
                                                        selected={endDate}
                                                        onChange={onChange}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        excludeDates={unavailableDates}
                                                        open={calenderIsOpen}
                                                        selectsEnd
                                                        openToDate={startDate || new Date()} // Set the openToDate prop here
                                                        dateFormat="MMM d yyyy"
                                                        wrapperClassName={width<=620? 'new-trip-date-input-sm':'new-trip-date-input'}
                                                        customInput={React.createElement(function ({ value, onClick }: {value: string; onClick: any}){

                                                            console.log("endDateendDate",endDate)


                                                            return(
                                                                <div onClick={()=>{
                                                                    setCalenderIsOpen(!calenderIsOpen)
                                                                    onClick()
                                                                }}>
                                                                    {endDate?
                                                                        <div className={`d-flex mt-2`}>
                                                                            <img src={CalenderImg} className={`me-2`} style={{ opacity: '0.5'}}  width={30}/>
                                                                            <div className={`d-flex flex-column`}>
                                                                                <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>{eSMoment(value).format('MMM D')}</span>
                                                                                <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>{eSMoment(value).format('YYYY')}</span>

                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={`d-flex mt-2`}>
                                                                            <img src={CalenderImg} className={`me-2`} style={{ opacity: '0.5'}} width={30}/>
                                                                            <div className={`d-flex flex-column`}>

                                                                                <span className={`cursor-pointer text-dark `} style={{fontSize:'13px', lineHeight:'13px'}}>End</span>
                                                                                <span className={`cursor-pointer text-dark `} style={{fontSize:'19px'}}>Date</span>

                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>

                                                            )

                                                        })}
                                                    />
                                                </div>








                                            </div>
                                            <div className={`col-xl-3 col d-flex p-3 ps-0 ${width<= 620? 'bg-white rounded-4 mt-3 py-2 mb-5':''}`}>
                                                <div className="label currency-container" >


                                                    <Select
                                                        value={selectedCurrency}
                                                        onChange={selectCurrency}
                                                        options={currenciesOptions}
                                                        components={{ DropdownIndicator }}
                                                        placeholder={`Choose Currency`}
                                                        styles={{
                                                            control: (base):any => ({
                                                                ...base,
                                                                border: 'none',
                                                                boxShadow:'none',
                                                                color:'#5e6278',
                                                                cursor:'pointer',
                                                                backgroundColor:'transparent'
                                                            }),
                                                            placeholder: (base):any => ({
                                                                ...base,
                                                                color:'#5e6278',

                                                            }),
                                                            indicatorSeparator: (base):any => ({
                                                                ...base,
                                                                width: '0px',
                                                            }),

                                                            input: (base):any => ({
                                                                ...base,
                                                                color:'#5e6278',
                                                            }),

                                                            menu: (base):any => ({
                                                                ...base,
                                                                boxShadow:'none',
                                                                padding:'5px'
                                                            }),

                                                            option: (base, {isFocused, isSelected}):any => ({
                                                                ...base,
                                                                border: `none`,
                                                                height: '100%',
                                                                color:'#5e6278',
                                                                fontSize: '16px',
                                                                cursor:'pointer',
                                                                backgroundColor: isFocused
                                                                    ? '#f5f8fa'
                                                                    : isSelected
                                                                        ? '#f5f8fa'
                                                                        : undefined,
                                                                "&:active": {
                                                                    backgroundColor: "#f5f8fa"
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`col-xl-1 pe-3  d-flex   justify-content-center ${width<= 620? 'col-12':''} `}>
                                                {place && selectedCurrency && startDate && endDate ?

                                                    <button type="submit"
                                                            className={`btn btn-lg btn-primary shadow-none mt-3 w-100`}>
                                                        Add
                                                    </button> :

                                                    <button type="button"
                                                            className={`btn btn-lg btn-primary shadow-none mt-3 w-100`} disabled={true}>
                                                        Add
                                                    </button>
                                                }
                                            </div>
                                        </form>

                                    </div>
                                </div>
                                <div className="row g-3 mb-3 preview-destination">

                                    {tripBasicData && tripBasicData.destinations && tripBasicData.destinations.length ?  tripBasicData?.destinations.map(function (destination,key) {

                                        return (
                                            <div className="col-xl-6" key={key }>
                                                <div className="d-flex destination ">
                                                    <div className="">
                                                        <img className="place-image" src={`${google_place_photo_url}${destination.photoReference}`}
                                                             style={ width>=620? {width: "140px", height:"140px"}:{width: "100px", height:"140px"}} />
                                                    </div>
                                                    <div className="card cursor-pointer w-100">
                                                        <div className="card-header pt-3 border-0"><h3
                                                            className="card-title">{destination.name}</h3>
                                                            <div className="card-toolbar">

                                                                <span className=" hover-opacity-btn cursor-pointer"  onClick={() => {
                                                                deleteDestination(key)
                                                            }}>
                                                               <img src={DeleteIconImg} width={15}/>
                                                            </span>

                                                                {eSMoment(destination.endDate).diff(destination.startDate, 'days')> 7 &&(
                                                                    <OverlayTrigger  placement="top" overlay={
                                                                        <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                                                               <span className={`text-dark`} >

If your visit to a single destination extends beyond one week, we may not be able to provide attraction suggestions. Consider splitting your itinerary into sub-destinations for more personalized recommendations.
                                                                               </span>
                                                                        </Tooltip>
                                                                    }>
                                                                           <span>
                                                                                <span  className={`text-white fw-bolder  fs-8`} > <img src={ErrorIconImg} width={17} className={`option`}/></span>
                                                                           </span>

                                                                    </OverlayTrigger>
                                                                )}

                                                            </div>
                                                        </div>
                                                        <div className="card-body d-flex justify-content-between">
                                                            <div className="col"><span className="date-title">Starting On</span>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="symbol symbol-45px me-2"><span
                                                                        className="align-items-end date">  {eSMoment(destination.startDate).format('D')}</span></div>
                                                                    <div className="d-flex flex-column flex-grow-1">
                                                                        <span
                                                                            className="text-gray-800 text-hover-primary mb-1 fs-8 fw-bolder"> {eSMoment(destination.startDate).format('MMM YYYY')}</span><span
                                                                        className="text-muted fs-9">{eSMoment().diff(destination.startDate, 'days') == 0? "Today": eSMoment(destination.startDate).format('dddd')}</span></div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="pt-4"><span
                                                                    className="text-muted fs-4 ">{eSMoment(destination.endDate).diff(destination.startDate, 'days')}D/{(eSMoment(destination.endDate).diff(destination.startDate, 'days'))-1}N</span></div>
                                                            </div>
                                                            <div className="col"><span
                                                                className="date-title">Ending On</span>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="symbol symbol-45px me-2"><span
                                                                        className="align-items-end date">{eSMoment(destination.endDate).format('D')}</span></div>
                                                                    <div className="d-flex flex-column flex-grow-1">
                                                                        <span
                                                                            className="text-gray-800 text-hover-primary mb-1 fs-8 fw-bolder">{eSMoment(destination.endDate).format('MMM YYYY')}</span><span
                                                                        className="text-muted fs-9">{eSMoment().diff(destination.endDate, 'days') == 0? "Today": eSMoment(destination.startDate).format('dddd')}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    }) : ''}


                                </div>

                                <div className="d-flex mt-3 mb-10 mb-xl-0 justify-content-end">
                                    {tripBasicData && tripBasicData.destinations && tripBasicData.destinations.length ?


                                        tripType == "solo" ?

                                            <button type="button" name="backward"
                                                    disabled={disableSaveTripButton}
                                                    className=" btn btn-lg btn-primary shadow-none"
                                                    onClick={ validateTripData}>Let's start!
                                            </button>
                                            :

                                            <button type="button" name="backward"
                                                    className=" btn btn-lg mb-10 mb-xl-0 btn-primary shadow-none"
                                                    onClick={function () {

                                                        validateTripData()

                                                    }}>Validate Trip
                                            </button>

                                        : ""
                                    }
                                </div>
                            </>

                            : ""
                    }
                </div>

            </div>
            <Modal show={showInviteMemberModal}  onHide={function () {

                setShowInviteMemberModal(false)
            }} animation={false}  backdrop="static">

                <div className="modal-header pb-0 border-0">

                    <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {

                        setDisableSaveTripButton(false)

                        setShowInviteMemberModal(false)
                    }}/>

                </div>
                <div className="modal-body">


                    <div className="text-center mt-0 mb-10">
                        <h1 className="mb-3 fs-1 text-dark">Invite members to this trip</h1>
                        <div className="text-muted fw-semibold fs-5 text-dark">Invite existing members or add new ones.</div>
                    </div>

                    <div className="">

                        { tripType == "group" ?
                            <>
                                <h3 className="text-muted fw-semibold mt-0 mb-3 fs-5">ExploreSoul members</h3>

                                <div  className="w-100 position-relative mb-5 d-flex justify-content-end"
                                 >



                                    <input type="text" className="form-control form-control-lg form-control-solid pe-5"
                                           name="search" value={searchKey}
                                           placeholder="Search by username, full name or email..."  onChange={(e)=>{
                                               setSearchKey(e.target.value)
                                                searchMembers(e.target.value)
                                    }} onKeyDown={(e:any)=>{
                                        if(e.key == "Enter") {

                                            searchMembers()
                                        }

                                    }}  />

                                    {members?.data?
                                        <span
                                            className="svg-icon svg-icon-2 svg-icon-lg-1 cursor-pointer svg-icon-gray-500 position-absolute top-50 me-2 translate-middle-y"
                                            onClick={()=>{

                                                setSearchKey("")
                                                setMembers(undefined)
                                            }}>


                                            <img src={CloseImgIcon} className={`hover-opacity-btn`} width={20}/>
                                    </span>
                                        :
                                        searchKey?
                                            <span
                                                className="svg-icon svg-icon-2 svg-icon-lg-1 cursor-pointer svg-icon-gray-500 position-absolute top-50 me-2 translate-middle-y"
                                                onClick={()=>{
                                                    searchMembers()
                                                }}>

                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                                      transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                <path
                                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        </span>
                                            :''

                                    }

                                </div>


                                {loading?
                                    <Loader cssClass="normal-loader"/>

                                    : members?.data && members.data.length ?
                                    <div className="pt-0">

                                        { members?.data.map(function (user, key) {

                                                let isAdded = false
                                                let isAdminUser = false
                                                if (tripBasicData?.members && tripBasicData?.members.length) {

                                                    if (find(tripBasicData.members, {userId: user._id})) {

                                                        isAdded = true
                                                        //   return false
                                                    }
                                                }

                                                if (user._id == authUserId) {
                                                    isAdminUser = true
                                                }
                                                return (
                                                    <SearchMemberItem canManage={true} user={user} isAdminUser={isAdminUser}
                                                                      key={key} isAdded={isAdded} keyValue={key}
                                                                      addMember={addMember} removeMember={removeMember}/>
                                                )

                                            }) }
                                    </div>
                                            :

                                    searchKey?

                                        <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-0" >

                                            <div className="text-center p-2 mt-1 mb-0">
                                                <img src={EmptyGroupImg} width={100}/>
                                                <p className="fs-5 text-dark fw-bolder">No user found</p>
                                                <p className="text-gray-400">Please try with different keyword
                                                    or <span className="fw-bolder cursor-pointer" > invite via email</span>
                                                </p>
                                            </div>
                                        </div>
                                        :""
                                        }
                            </>
                            : ""}



                        {tripType == "family" || tripType == "group" ?
                            <div className="pt-0">

                                <div className="">

                                    <h3 className="text-muted fw-semibold mt-0 mb-3 fs-5">Family members</h3>

                                    {familyMembers?.data.length?

                                        <div className="mh-375px scroll-y me-n7 pe-7">
                                            {familyMembers?.data.map(function (user, key) {
                                                let isAdded = false
                                                if (tripBasicData?.members && tripBasicData?.members.length) {

                                                    if (find(tripBasicData.members, {userId: user._id})) {

                                                        isAdded = true
                                                        //   return false
                                                    }
                                                }

                                                return(
                                                    <FamilyMemberItem isAdded={isAdded} user={user} key={key} keyValue={key} addMember={addMember} removeMember={removeMember} />
                                                )
                                            })}
                                        </div>
                                        :

                                        <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-40px'}}>

                                            <div className="text-center p-2 mt-1 mb-0">
                                                <img src={EmptyFamilyImg} width={100}/>
                                                <p className="fs-5 text-dark fw-bolder">No data available</p>
                                                <p className="text-gray-400">Please add family member
                                                    from <span className="fw-bolder cursor-pointer" onClick={()=>{navigate('/account')}}>profile</span>
                                                </p>
                                            </div>
                                        </div>
                                    }

                                </div>


                            </div>
                            : ""
                        }


                    </div>
                    <div>
                        <h3 className="text-muted fw-semibold mt-0 mb-3 fs-5">Selected members</h3>

                        {addedMembers && addedMembers.data.length? addedMembers && addedMembers.data.map(function (user,key) {
                            let isAdded = false
                            let isAdminUser = false


                            if(user.userId && user.userId._id == authUserId){
                                isAdminUser = true
                            }
                            return(
                                <div className="chip m-1">
                                    <div className="chip-head">
                                        {user.cover?.generatedName?
                                            <img alt="Pic" src={getImgUrl(user.cover?.generatedName,user._id,'50x')} />
                                            :
                                            <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{(user.firstName).charAt(0)}</span>
                                        }
                                    </div>
                                    <div className="chip-content fw-bolder ps-3 pe-1">{user.parentId? user.firstName: user.userName}</div>
                                    <div className="chip-close" onClick={() => {
                                        if(! isAdminUser){
                                            removeMember(user)
                                        }
                                    }}>
                                        {! isAdminUser ?
                                            <img src={CloseRedImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20}/>
                                            :
                                            ""}


                                    </div>
                                </div>
                            )
                        }) :
                            <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-40px'}}>

                                <div className="text-center p-2 mt-1 mb-0">
                                    <p className="fs-5 text-dark fw-bolder">No members selected</p>
                                    <p className="text-gray-400">
                                        Utilize the search option to choose members.
                                    </p>
                                </div>
                            </div>}
                    </div>
                    {tripType == "group" ?

                        <div>
                            <div className="text-muted fw-semibold mt-0 mb-3 fs-5">Not a exploresoul member? Type their email below:</div>
                            <ReactTagInput
                                tags={tags}

                                removeOnBackspace={true}
                                onChange={ (newTags) => {

                                    if(tripBasicData){


                                        setTags(newTags)

                                          setTripBasicData({
                                              ...tripBasicData,
                                              invites: newTags
                                          })



                                        //checkEmailByUser(newTags)

                                    }
                                }}
                                validator={(value) => {

                                    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

                                    const isEmail = regexExp.test(value)
                                    if (!isEmail) {
                                        // alert("Please enter an e-mail address");
                                    }
                                    const isDuplicate  = tags.includes(value);

                                    // Return boolean to indicate validity
                                    return isEmail && !isDuplicate;
                                }}
                            />

                        </div>
                        :""
                    }


                    <div className="d-flex mt-3 justify-content-end">
                        <button type="button" name="backward"  disabled={disableSaveTripButton}
                                className=" btn btn-lg btn-primary shadow-none" onClick={saveTrip}>Let's start!
                        </button>

                    </div>
                </div>
            </Modal>
            <Modal show={showAiSuggestionModal}  onHide={function () {

                setShowAiSuggestionModal(false)
            }} animation={false} size={'lg'}   className={`suggestion-modal`}  backdrop="static">

                <div className="modal-header pb-0 border-0">

                    <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {

                        setShowAiSuggestionModal(false)
                    }}/>

                </div>
                <div className="modal-body">

                    {tripValidationLoading ?

                        <div  className={`text-center mb-5 pb-5`}>

                            <img src={loadingImg} width={100}/>
                            <p className={`text-dark fs-4 mx-1 mx-lg-5 px-0 px-lg-5`}>We are currently in the process of verifying the information to ensure a smooth and accurate travel experience for you; this typically takes 15-60 seconds.</p>
                        </div>

                        :

                        <>
                            <div className="text-center mt-0 mb-10">
                                <h1 className="mb-3 fs-3 text-dark fw-bolder">{tripValidationData?.data.itinerary_status_reason}</h1>
                                <h1 className="mb-3 fs-5 text-dark">{tripValidationData?.data.itinerary_status_description}</h1>
                            </div>

                            <div className="container">



                                <div className="row g-3 mb-3 preview-destination">

                                    {tripValidationData?.data?.suggestion && tripValidationData?.data?.suggestion.map((destination, key)=>{



                                        let startDate = eSMoment(destination.start_date,'DD/MM/YYYY');
                                        let endDate = eSMoment(destination.end_date,'DD/MM/YYYY');
                                        return (

                                                <div className="col-xl-6" key={key }>
                                                    <div className="d-flex destination ">
                                                        <div className="">
                                                            <img className="place-image" src={`${google_place_photo_url}${destination.photoReference}`}
                                                                 style={ width>=620? {width: "140px", height:"140px"}:{width: "100px", height:"140px"}} />
                                                        </div>
                                                        <div className="card cursor-pointer w-100">
                                                            <div className="card-header pt-3 border-0"><h3
                                                                className="card-title">{destination.destination}</h3>
                                                                <div className="card-toolbar">

                                                                    <OverlayTrigger  placement="right" overlay={
                                                                        <Tooltip id={`tooltip-${key}`}  className={``} >
                                                                            <div className="d-flex align-items-center">


                                                                                <div className="ms-0 text-start">
                                                                                    <span className="fs-6 text-gray-900 text-hover-primary mb-2 cursor-pointer">

                                                                                        {destination.recommendation}
                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                        </Tooltip>
                                                                    }>
                                                                        <img src={ErrorInfoImg} width={15}/>
                                                                    </OverlayTrigger>


                                                                </div>
                                                            </div>
                                                            <div className="card-body d-flex justify-content-between">
                                                                <div className="col"><span className="date-title">Starting On</span>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="symbol symbol-45px me-2"><span
                                                                            className="align-items-end date">  {startDate.format('D')}</span></div>
                                                                        <div className="d-flex flex-column flex-grow-1">
                                                                        <span
                                                                            className="text-gray-800 text-hover-primary mb-1 fs-8 fw-bolder"> {startDate.format('MMM YYYY')}</span><span
                                                                            className="text-muted fs-9">{eSMoment().diff(startDate, 'days') == 0? "Today":startDate.format('dddd')}</span></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="pt-4"><span
                                                                        className="text-muted fs-4 ">{endDate.diff(startDate, 'days')}D/{(endDate.diff(startDate, 'days'))-1}N</span></div>
                                                                </div>
                                                                <div className="col"><span
                                                                    className="date-title">Ending On</span>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="symbol symbol-45px me-2"><span
                                                                            className="align-items-end date">{endDate.format('D')}</span></div>
                                                                        <div className="d-flex flex-column flex-grow-1">
                                                                        <span
                                                                            className="text-gray-800 text-hover-primary mb-1 fs-8 fw-bolder">{endDate.format('MMM YYYY')}</span><span
                                                                            className="text-muted fs-9">{eSMoment().diff(endDate, 'days') == 0? "Today": startDate.format('dddd')}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                        )
                                    }) }


                                </div>



                            </div>
                            <div className="d-flex mt-3 justify-content-end">

                                {tripValidationData?.data.itinerary_status?

                                    <button type="button" name="backward"  disabled={disableSaveTripButton}
                                            className=" btn btn-lg btn-primary shadow-none me-3" onClick={()=>{
                                        setShowAiSuggestionModal(false)

                                        if(tripType == "solo"){

                                            saveTrip()
                                        }else{
                                            setShowInviteMemberModal(true)
                                        }
                                    }}>Continue
                                    </button>

                                    :

                                    <>
                                        <button type="button" name="backward"  disabled={disableSaveTripButton}
                                                className=" btn btn-lg btn-secondary shadow-none me-3" onClick={()=>{
                                            setShowAiSuggestionModal(false)

                                            if(tripType == "solo"){

                                                saveTrip()
                                            }else{
                                                setShowInviteMemberModal(true)
                                            }
                                        }}>Ignore
                                        </button>
                                        <button type="button" name="backward"  disabled={disableSaveTripButton}
                                                className=" btn btn-lg btn-primary shadow-none" onClick={()=>{


                                            if(tripBasicData) {

                                                let updatedDestinations = tripBasicData.destinations
                                                tripValidationData?.data?.suggestion.map((suggestion)=>{


                                                    let index = updatedDestinations.findIndex(destination => destination.placeId === suggestion.placeId);

                                                    if(index >= 0){



                                                        updatedDestinations[index].startDate =  eSMoment(suggestion.start_date,'DD/MM/YYYY').toString();
                                                        updatedDestinations[index].endDate =  eSMoment(suggestion.end_date,'DD/MM/YYYY').toString();
                                                    }
                                                })
                                                setTripBasicData({
                                                    ...tripBasicData,
                                                    destinations:updatedDestinations
                                                })
                                            }

                                            if(tripType == "solo"){

                                                saveTrip()
                                            }else{
                                                setShowAiSuggestionModal(false)
                                                setShowInviteMemberModal(true)
                                            }

                                        }}>Update trip
                                        </button>
                                    </>
                                }


                            </div>
                        </>
                    }





{/*
                    <div className="d-flex mt-3 justify-content-end">
                        <button type="button" name="backward"  disabled={disableSaveTripButton}
                                className=" btn btn-lg btn-primary shadow-none" onClick={saveTrip}>Let's start!
                        </button>

                    </div>*/}
                </div>
            </Modal>
            <Modal show={showTripTypeModal}  onHide={function () {

                setShowTripTypeModal(false)
            }} animation={false} size={'lg'}   className={`suggestion-modal`} >

                <div className="modal-header pb-0 border-0">

                    <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {

                        setShowTripTypeModal(false)
                    }}/>

                    <span className={`fw-bolder fs-4 text-dark text-capitalize`}>{tripType} trip information</span>
                    <span></span>

                </div>
                <div className="modal-body">

                    <div className={`card`}>

                        <div className={`p-5 m-3 text-dark`}>
                            <>

                                {tripType == 'solo'?
                                    <div className={`d-flex`}>
                                        <img src={SoloTravelIcon} width={250}  alt="" />
                                            <ul className={`list-group mt-4`}>
                                                <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>Only You (trip owner) can add, plan, and view the trip details.</li>
                                                <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>Once it's a solo trip, no other users can join the trip.</li>
                                            </ul>
                                    </div>
                                :tripType == 'family'?
                                    <div className={`d-flex`}>
                                        <img src={FamilyTravelIcon} width={250}  alt="" />
                                        <ul className={`list-group mt-4`}>
                                            <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>Only You (trip owner) can add, plan, and view the trip details.</li>
                                            <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>Multiple family members can be added from the profile page.</li>
                                            <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>Family members added won't have login permissions.</li>
                                            <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>Budget and other planning can be done in family members' names.</li>
                                            <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>Once it's a family trip, no other users can join the trip.</li>

                                         </ul>

                                    </div>
                                :
                                    <div  className={`d-flex`}>
                                        <img src={GroupTravelIcon} width={250}  alt="" />
                                        <ul className={`list-group mt-4`}>
                                            <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>You can invite multiple members from the app or via email or QR code to join the trip.</li>
                                            <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>Family members can be added to the group trip without login access.</li>
                                            <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>Each member will have the same dashboard to see all the planning and budgets.</li>
                                            <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>Private activities or planning won't be visible to other group members.</li>
                                            <li className={`list-group-item border-0`}><img src={DotRedImg} width={10} style={{paddingBottom: '1px',marginRight: '3px'}}/>You have control over who can join the group trip.</li>
                                         </ul>
                                    </div>
                                }



                            </>
                        </div>


                    </div>


                </div>
            </Modal>
            <Modal show={showTripTypeChangeModal}  onHide={function () {

                setShowTripTypeChangeModal(false)
            }} animation={false} size={'lg'}   className={`suggestion-modal`} >

                <div className="modal-body form-wizard-modal ">
                    <form onSubmit={handleSubmitWizardOne(submitFormWizardOne)} className="trip-form">
                        <div className="modal-header pb-0 border-0">

                            <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={function () {

                                setShowTripTypeChangeModal(false)
                            }}/>

                            <span className={`fw-bolder fs-4 text-dark text-capitalize`}>Modify Travel Category</span>
                            <span>
                                   <button type="button" name="backward" className={`btn btn-secondary shadow-none me-3 ${width<=620? 'w-100':''}`}>Keep Current</button>
                               {tripType?
                                   <button type="submit" name="backward" className={`btn  btn-primary shadow-none ${width<=620? 'w-100':''}`}>Apply Changes</button>
                                   :

                                   <button type="button" name="backward" className={`btn btn-primary shadow-none disabled ${width<=620? 'w-100':''}`} disabled={true}>Change
                                   </button>
                               }
                            </span>

                        </div>
                        <div className="row  text-center mt-3 ">
                            <div className="col-lg-4  mb-3">
                                <div className={`item ${tripType == 'solo'? 'active': ''} p-2 border rounded`} onClick={() => {
                                    setTripType("solo")
                                    //   setActiveTab("destination")
                                }}>
                                    <input id="answer_1" type="radio" name="tripType"
                                           value="solo" className="required" checked={tripType === 'solo'}  />
                                    <label htmlFor="answer_1">
                                        <div className="card align-items-center">
                                            <img src={SoloTravelIcon} width={150}  alt="" />
                                            <div className="card-body">
                                                <h5 className="card-title fs-2 text-dark">Solo</h5>
                                                <p className="card-text fs-7">A solo trip is your personal adventure, offering freedom and self-discovery as you create unique experiences on your own terms.</p>
                                                <p className="card-text primary-color mt-3 fw-bolder fs-7" onClick={()=>setShowTripTypeModal(true)}>Read more</p>

                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4  mb-3">
                                <div className={`item ${tripType == 'family'? 'active': ''} p-2 border rounded`} onClick={() => {
                                    setTripType("family")
                                    // setActiveTab("destination")
                                }}>
                                    <input id="answer_2" name="tripType" type="radio"
                                           value="family" className="required" checked={tripType === 'family'}  />
                                    <label htmlFor="answer_2">
                                        <div className="card align-items-center">
                                            <img src={FamilyTravelIcon} width={150} alt="" />
                                            <div className="card-body">
                                                <h5 className="card-title fs-2  text-dark">Family</h5>
                                                <p className="card-text fs-7">Family trips mix bonding, diverse activities for all, creating cherished memories, and strengthening connections in beautiful destinations.</p>
                                                <p className="card-text primary-color mt-3 fw-bolder fs-7" onClick={()=>setShowTripTypeModal(true)}>Read more</p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4  mb-3">
                                <div className={`item ${tripType == 'group'? 'active': ''} p-2 border rounded`}  onClick={() => {
                                    setTripType("group")
                                }}>
                                    <input id="answer_3" name="tripType" type="radio" value="group"
                                           className="required" checked={tripType === 'group'} />
                                    <label htmlFor="answer_3">

                                        <div className="card align-items-center">
                                            <img src={GroupTravelIcon} width={175} alt="" />
                                            <div className="card-body">
                                                <h5 className="card-title fs-2  text-dark">Group</h5>
                                                <p className="card-text fs-7">Group trips unite friends, create memories, foster connections in exciting destinations with shared adventures.</p>
                                                <p className="card-text primary-color mt-3 fw-bolder fs-7" onClick={()=>setShowTripTypeModal(true)}>Read more</p>

                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </main>




    );
};
export default NewTrip;
