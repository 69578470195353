import React, {FC, useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {Link, useNavigate, useLocation} from 'react-router-dom';

import {OtpVerification, User, UserResponse} from '../../../interfaces/user.interface';
import * as Yup from 'yup';
import http from '../../../services/api';


import '../authentication.scss'
import {register_url, wp_url} from "../../../services/api.utils";
import Loader from "../../loader/Loader";
import logo from "../../../images/logo.png";
import appleLogo from "../../../images/login/apple-logo.svg";
import googleLogo from "../../../images/login/google-logo.svg";
import {toastMessage} from "../../../util";
import AuthHeader from "../AuthHeader";
import {setUser} from "../../account/userSlice";
import {useAppDispatch} from "../../../store";
import Modal from "react-bootstrap/cjs/Modal";
import UserPackageContainer from "../../userPackages/UserPackageContainer";
import BackButtonImgIcon from "../../../images/icons/back-button.svg";
import DownloadIconImg from "../../../images/icons/download.svg";
import CloseIconImg from "../../../images/icons/close-button.svg";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import EmailSentImgIcon from "../../../images/icons/email-sent.svg";
import EyeCloseImgIcon from "../../../images/icons/eye-close.svg";
import EyeOpenImgIcon from "../../../images/icons/eye-open.svg";



const schema = Yup.object().shape({
    firstName: Yup.string()
        .required('First Name required')
        .matches(/^[a-zA-Z]+$/, 'First Name should contain only letters'),
    lastName: Yup.string()
        .required('Last Name required')
        .matches(/^[a-zA-Z]+$/, 'Last Name should contain only letters'),
    userName: Yup.string()
        .required('User Name required')
        .matches(/^[_a-zA-Z0-9]+$/, 'User Name should only contain letters, numbers, and underscores'),
    email: Yup.string().required('Email required').email('Please provide a valid email address (abc@xy.z)'),
    password: Yup.string().required('Password required'),
    terms: Yup.boolean().oneOf([true],'You must accept Terms And Conditions And Privacy Policy')
});
const verificationSchema = Yup.object().shape({
    inputOne: Yup.string().required('Required'),
    inputTwo: Yup.string().required('Required'),
    inputThree: Yup.string().required('Required'),
    inputFour: Yup.string().required('Required'),
});

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}

const Register: FC = () => {

    const { width } = useViewport();
    const breakpoint = 620;


    const query = new URLSearchParams(useLocation().search);
    const code = query.get('code');
    const [authError, setAuthError] = useState("");



    const { handleSubmit, register, reset,setFocus, formState: { errors } } = useForm<User>({
        resolver: yupResolver(schema),
    });

    const { handleSubmit:handleSubmitVerification, register:registerVerification, reset:resetVerification,setFocus:setFocusVerification, formState: { errors :errorsVerification} } = useForm<OtpVerification>({
        resolver: yupResolver(verificationSchema),
    });


    const [otpResendLoading, setOtpResendLoading] = useState(false);

    const [userData, setUserData] = useState<User>();
    const [showPackageModel, setShowPackageModel] = useState(false);

    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [otpInput, setOtpInput] = useState({
        inputOne: "",
        inputTwo: "",
        inputThree: "",
        inputFour: ""
    });

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {

        if(!showVerifyModal){
            setFocus("firstName");

        }

        const user = localStorage.getItem("user");

        if(user){
            let redirect = localStorage.getItem("redirect");

            console.log("redirect",redirect)
            if(redirect){
                localStorage.removeItem("redirect");

                navigate(redirect);
            }else{
                navigate("/dashboard");
            }
        }



    },[])

    const submitForm = (data: User) => {
        setLoading(true);

        data.code = code
        http
            .post<User, UserResponse>(register_url, data)
            .then((res) => {
                if (res) {
                    const { data,status,message} = res;


                    if(status == 200 && data.userId){
                        reset();

                        setUserData(data)

                        setShowVerifyModal(true)




                    }else{

                        setAuthError(message)

                    }


                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submitFormVerification = (otp:OtpVerification) => {

        let data = {
            email:  userData?.email,
            otp: otp.inputOne+otp.inputTwo+otp.inputThree+otp.inputFour
        }
        callVerificationAPI(data,"submit")

    };

    const callVerificationAPI = (data:any,type:string) => {
        if(type == "submit"){
            setOtpLoading(true);
        }else{
            setOtpResendLoading(true);
        }
        http
            .post<User, UserResponse>(`${register_url}/verify-token`, data)
            .then((res) => {
                if (res) {
                    const { data,status,message} = res;


                    if(type == "submit"){
                        if(status == 200 && data.userId){

                            if(data.accessToken){

                                http.defaults.headers.common.Authorization = 'Bearer '+data.accessToken;

                            }
                            setUserData(data)
                            //if(data.userPackage?.packageId){
                                reset();
                                localStorage.setItem('user', JSON.stringify(res));
                                let redirect = localStorage.getItem("redirect");

                                if(redirect){
                                    localStorage.removeItem("redirect");

                                    navigate(redirect);
                                }else{
                                    navigate("/dashboard");
                                }
                            /*}else{

                                setShowVerifyModal(false)
                                setShowPackageModel(true)
                            }*/
                        }else{
                            toastMessage('Invalid OTP', 'error')
                        }
                    }else{

                        toastMessage('URL resent', '')
                    }



                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setOtpLoading(false);
                setOtpResendLoading(false);
            });
    }

    const updateUserData = (user: User) => {

        setUserData(user)

    }
    return (

        <div className="wrapper">

            <div className="login-section">
                {width > breakpoint ?
                    <AuthHeader/>
                    : ""}
                <div className="container login-container">
                    <div className="col-lg-6 col-md-6 login-left-section">
                        <div className="text-center text-center mt-5 pt-5">

                            <h2 className="title">Welcome to ExploreSoul...</h2>
                            <p className="subtitle">Discover new place you will love</p></div>
                    </div>
                    <div className="col-lg-6 col-md-12 login-content-section">
                        <div className="login-content-body">


                            {width < breakpoint ?

                                <div className="text-center login-header-responsive">
                                    <img src={logo} width="50px" />
                                    <h2 className="title">Discover new place
                                        you will love</h2>
                                </div>
                                :

                                <div className="text-center login-header mb-3">
                                    <h2 className="text-center fw-bolder fs-1">Sign up

                                    </h2>
                                    <p className="text-center fs-4 text-muted">Create an account to start using ExploreSoul</p>
                                </div>
                            }
                            <form onSubmit={handleSubmit(submitForm)} className="login-form">
                                <div className="row g-3">

                                    <div className="col-6">
                                        <label htmlFor="firstName" className="text-dark fs-6">First Name </label>
                                        <input {...register("firstName")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.firstName? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="firstName"
                                               placeholder="John" />
                                        {errors && errors.firstName && (
                                            <div className="invalid-feedback show"> {errors.firstName.message} </div>
                                        )}
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="lastName" className="text-dark fs-6">Last Name </label>
                                        <input {...register("lastName")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.lastName? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="lastName"
                                               placeholder="Doe" />
                                        {errors && errors.lastName && (
                                            <div className="invalid-feedback show"> {errors.lastName.message} </div>
                                        )}
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="userName" className="text-dark fs-6">Username </label>
                                        <input {...register("userName")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.userName? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="userName"
                                               placeholder="username" onChange={()=> setAuthError("")} />
                                        {errors && errors.userName && (
                                            <div className="invalid-feedback show"> {errors.userName.message} </div>
                                        )}
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="email" className="text-dark fs-6">Email </label>
                                        <input {...register("email")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.email? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="email"
                                               placeholder="you@example.com"  onChange={()=> setAuthError("")} />
                                        {errors && errors.email && (
                                            <div className="invalid-feedback show"> {errors.email.message} </div>
                                        )}
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="password" className="text-dark fs-6">Password </label>
                                        <div className="password-input-container">
                                            <input
                                                {...register("password")}
                                                type={showPassword ? "text" : "password"}
                                                className={`form-control form-control-lg form-control-solid ${Object.keys(errors).length === 0 ? "" : errors.password ? "is-invalid" : "is-valid"}`}
                                                name="password"
                                                placeholder="*****"
                                                onChange={() => setAuthError("")}
                                            />
                                            <button
                                                type="button"
                                                className="toggle-password-visibility"
                                                onClick={togglePasswordVisibility}
                                            >
                                                <img className={`opacity-50`} src={showPassword?EyeCloseImgIcon: EyeOpenImgIcon} width={15}/>
                                            </button>
                                        </div>
                                        {errors && errors.password && (
                                            <div className="invalid-feedback show"> {errors.password.message} </div>
                                        )}
                                        {authError &&(
                                            <div className="primary-color"> {authError} </div>
                                        ) }
                                    </div>

                                    <div className="form-check ms-2 mt-4">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckIndeterminate" {...register("terms")} onChange={()=>{}}  />
                                            <label className="form-check-label" htmlFor="flexCheckIndeterminate">
                                                I accept the ExploreSoul <a href={`${wp_url}/user-agreement`} target={`_blank`}>User Agreement</a> and have read the <a href={`${wp_url}/privacy-policy`} target={`_blank`}>Privacy Statement</a>
                                            </label>
                                        {errors && errors.terms && (
                                            <div className="invalid-feedback show" style={{ display: 'block'}}> {errors.terms.message} </div>
                                        )}
                                    </div>
                                </div>
                                <button className="w-100 btn btn-primary btn-lg shadow-none mt-5" disabled={loading?true : false} type="submit">{loading?<Loader cssClass="button-loader"/> :"Register" }</button>


                            </form>
                            <hr className="hr-text" data-content="OR" />

                            <div className="login-bottom-container">
                                <div className="text-center">
                                    <p className="text-dark">ALREADY REGISTERED?
                                        <Link className=" px-2 link-secondary  fw-bolder cursor-pointer hover-color" to="/login" >
                                            SIGN IN
                                        </Link>
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Modal onHide={()=>{
                setShowVerifyModal(false)
            }} keyboard={true} centered show={showVerifyModal}  animation={false}>

                <div className="d-flex text-center header">
                    <div className="p-2 col text-start" >

                        <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>{
                            setShowVerifyModal(false)
                        }}/>
                    </div>
                    <div className="p-2 col fs-4 fw-bolder">

                    </div>
                    <div className="p-2 col text-end save"
                    >
                        <span></span>
                    </div>
                </div>


                <Modal.Body className="pt-0 pb-5 verification-modal">

                    <div className="text-center pt-0 p-2  mt-0 mb-0">
                        <img src={EmailSentImgIcon} className={``} width={70}/>

                        <p className="fs-5 text-dark fw-bolder">Email sent successfully</p>
                        <p className="text-gray-400">Please check your <span className="fw-bolder">inbox</span> to verify to your account

                        </p>
                    </div>                    {/*<form onSubmit={handleSubmitVerification(submitFormVerification)} className="verification-form">

                        <div className="text-muted fw-semibold mb-10">Enter your mobile phone number with country code and
                            we will send you a verification code upon request.
                        </div>

                        <div className="d-flex flex-row mt-5">
                            <input type="number" {...registerVerification("inputOne")} value={otpInput.inputOne} className="form-control fs-1 me-3" onChange={function (e){

                                if(otpInput.inputOne == "" || e.target.value == ""){

                                    setOtpInput({
                                        ...otpInput,
                                        inputOne: e.target.value
                                    })
                                    if(e.target.value){
                                        setFocusVerification("inputTwo");
                                    }

                                }



                            }}  />
                            <input type="number" {...registerVerification("inputTwo")}  value={otpInput.inputTwo}  className="form-control fs-1 me-3"
                                   onKeyUp={function (e){
                                       if( e.key == "Backspace" ){
                                           setFocusVerification("inputOne");
                                       }
                                   }}
                                   onChange={function (e){

                                    if(otpInput.inputTwo == "" || e.target.value == ""){
                                        setOtpInput({
                                            ...otpInput,
                                            inputTwo: e.target.value
                                        })
                                        if(e.target.value){
                                            setFocusVerification("inputThree");
                                        }

                                    }
                                }}
                            />
                            <input type="number"{...registerVerification("inputThree")}  value={otpInput.inputThree} className="form-control fs-1 me-3"
                                   onKeyUp={function (e){
                                       if( e.key == "Backspace" ){
                                           setFocusVerification("inputTwo");
                                       }
                                   }}
                                   onChange={function (e){
                                        if(otpInput.inputThree == "" || e.target.value == ""){

                                            setOtpInput({
                                                ...otpInput,
                                                inputThree: e.target.value
                                            })
                                            if(e.target.value){
                                                setFocusVerification("inputFour");
                                            }

                                        }
                                    }}
                            />
                            <input type="number" {...registerVerification("inputFour")}  value={otpInput.inputFour}  className="form-control fs-1 me-3"
                            onKeyUp={function (e){
                                if( e.key == "Backspace" ){
                                    setFocusVerification("inputThree");
                                }
                            }}  onChange={function (e){

                                if(otpInput.inputFour == "" || e.target.value == ""){
                                    setOtpInput({
                                        ...otpInput,
                                        inputFour: e.target.value
                                    })
                                }


                            }} />
                        </div>
                        <div className="text-center my-5"><span
                            className="d-block mobile-text text-dark">Don't receive the code?</span><span
                            className="fw-bolder  hover-color cursor-pointer" onClick={() => {

                            if(! otpResendLoading){

                                let data = {
                                    email:  userData?.email,
                                    type:  "resend",
                                    userId: userData?._id,
                                    name: userData?.firstName+" "+userData?.firstName,
                                }
                                callVerificationAPI(data,"resend")
                            }
                        }}>{otpResendLoading? "Resending...":"Resend" }</span></div>

                    </form>*/}

                </Modal.Body>
            </Modal>

            <Modal size="lg"  backdrop="static" keyboard={false} centered show={showPackageModel}  animation={false}>
                <Modal.Body>

                    {userData &&
                        <UserPackageContainer updateUserData={updateUserData}  fromSettings={false} userData={userData} />

                    }

                </Modal.Body>
            </Modal>
        </div>

    );
};
export default Register;
