import React, { FC,useEffect,useState } from 'react';

import FlightLandImg from '../../../../images/trip/flight_land.svg'
import FlightStartImg from '../../../../images/trip/flight_start.svg'
import {TripActivity} from "../../../../interfaces/trip.interface";

import '../cards/cards.scss'
import {eSMoment, getImgUrl} from "../../../../util";
import Dropdown from "react-bootstrap/cjs/Dropdown";
import {Currency} from "../../../../interfaces/country.interface";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import filter from "lodash/filter";
import LoaderLayout from "../../../loader/LoaderLayout";
import BudgetIconImg from "../../../../images/icons/budget.svg";
import DownloadIconImg from "../../../../images/icons/download.svg";
import SecureIconImg from "../../../../images/icons/secure.svg";
import CopyIconImg from "../../../../images/icons/copy-icon.svg";
import NotesIconImg from "../../../../images/icons/notes.svg";
import EditDropDownCard from "./EditDropDownCard";

type Props = {
    activity:TripActivity,
    handleActivityUpdate?:any,
    tripOwner?:any,
    tripStatus?:string,
    activeCurrency?:Currency,
    tripType?:string,
    width:number,

}

const FlightCard: FC <Props> = ({activity,handleActivityUpdate, tripOwner, activeCurrency, tripType,width,tripStatus}) => {

    const [updateType, setUpdateType] = useState("");
    const [clipboardText, setClipboardText] = useState("Copy PNR to Clipboard");




    return (

        <div className="card flight"  >

            <div className="card-body">
                {handleActivityUpdate?
                    <div className="d-flex position-absolute toolbar">

                        <div className="me-n3 ">

                            {activity.privacy?

                                width >= 620 ?

                                    <OverlayTrigger  placement="top" overlay={
                                        <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                            <span  className={`text-dark`}>You can only view this content</span>
                                        </Tooltip>
                                    }>
                                        <img src={SecureIconImg} width={26} style={{ padding: '5px' }} className={`option`}/>
                                    </OverlayTrigger>

                                    :
                                    <img src={SecureIconImg} width={26} style={{ padding: '5px' }} className={`option`}/>

                                :""}
                            <span className={`card-options`}>
                                         {activity.notes ?
                                             width >= 620 ?


                                                 <OverlayTrigger  placement="top" overlay={
                                                     <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                                         <span  className={`text-dark`}>View Notes</span>
                                                     </Tooltip>
                                                 }>
                                                     <img src={NotesIconImg} width={28} className={`option`} onClick={(e) => {
                                                         if(handleActivityUpdate){

                                                             handleActivityUpdate(activity,"notes")
                                                         }
                                                     }}/>
                                                 </OverlayTrigger>

                                                 :
                                                 <img src={NotesIconImg} width={28} className={`option`} onClick={(e) => {
                                                     if(handleActivityUpdate){

                                                         handleActivityUpdate(activity,"notes")
                                                     }
                                                 }}/>
                                             :""}
                                {activity.budget ?

                                    width >= 620 ?

                                        <OverlayTrigger  placement="top" overlay={
                                            <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                                <span  className={`text-dark`}>View budget</span>
                                            </Tooltip>
                                        }>
                                            <img src={BudgetIconImg} width={28} className={`option`} onClick={(e) => {
                                                if(handleActivityUpdate){

                                                    handleActivityUpdate(activity,"budget")
                                                }
                                            }}/>
                                        </OverlayTrigger>
                                        :
                                        <img src={BudgetIconImg} width={28} className={`option`} onClick={(e) => {
                                            if(handleActivityUpdate){

                                                handleActivityUpdate(activity,"budget")
                                            }
                                        }}/>


                                    :""}
                                {activity.invoices?.length ?

                                    width >= 620 ?
                                        <OverlayTrigger  placement="top" overlay={
                                            <Tooltip id={`tooltip-1`}  className={`card-options-tooltip`}>
                                                <span className={`text-dark`}>Download Files</span>
                                            </Tooltip>
                                        }>
                                            <img src={DownloadIconImg} width={28} className={`option`}  onClick={(e) => {
                                                if(handleActivityUpdate){

                                                    handleActivityUpdate(activity,"download")
                                                }
                                            }}/>
                                        </OverlayTrigger>
                                        :
                                        <img src={DownloadIconImg} width={28} className={`option`}  onClick={(e) => {
                                            if(handleActivityUpdate){

                                                handleActivityUpdate(activity,"download")
                                            }
                                        }}/>
                                    :""}


                                <EditDropDownCard width={width}  tripType={tripType} activity={activity} tripStatus={tripStatus}  handleActivityUpdate={handleActivityUpdate} tripOwner={tripOwner} />


                                    </span>

                        </div>

                    </div>
                    :""}

                <div className="d-flex text-center  col-md-12 mt-4 mt-xl-0">
                    <div className="p-2 flex-fill col-md-5">
                        <img src={FlightStartImg}/>
                        <span className="city-code fs-4 primary-color">{activity.placeOne?.code}</span>
                        <span className="mb-0 mr-2 fs-6 fw-bolder">{activity.placeOne?.name}</span>
                        <span className="country fs-7"><i className="bx bx-time-five"></i>{eSMoment(activity.startDateTime).format('hh.mma, DD MMM YY')}</span>

                    </div>
                    <div className="p-2 flex-fill direction  col-md-2"><i className="bi bi-arrow-right"></i></div>
                    <div className="p-2 flex-fill  col-md-5">
                        <img src={FlightLandImg}/>
                        <span className="city-code fs-4 primary-color">{activity.placeTwo?.code}</span>
                        <span className="mb-0 mr-2 fs-6 fw-bolder">{activity.placeTwo?.name}</span>
                        <span className="country fs-7"><i className="bx bx-time"></i>{eSMoment(activity.endDateTime).format('hh.mma, DD MMM YY')}</span>
                    </div>
                </div>
                <div className="d-flex justify-content-between  mx-5 mt-3">

                    {activity.vehicles?.map((vehicle) =>
                        <div className={`col-3`}>
                            <span
                                className="fs-8 fw-bolder text-muted d-flex ">Flights</span>

                            <span
                                className="fs-6 text-gray-800 fw-bolder d-flex  text-hover-primary">{vehicle.vehicleNumber}
                                <span className="copy-text-tooltip ms-1">
                                        <span onClick={() => {

                                            if (vehicle.vehicleNumber) {
                                                setClipboardText("Copied!")
                                                navigator.clipboard.writeText(vehicle.vehicleNumber);
                                            }
                                        }} onMouseLeave={() => {
                                            setClipboardText("Copy Flight Number to Clipboard")
                                        }}>
                                            <span className="tooltiptext" id="myTooltip">{clipboardText}</span>
                                           <img src={CopyIconImg} width={15} className={`hover-opacity-btn`}/>
                                        </span>
                                    </span>
                                </span>


                        </div>
                    )}

                </div>

                <div className="row px-4 mt-3 pt-3" style={{borderTop: '1px dotted rgb(210 214 216)'}} onClick={() => {
                    if (handleActivityUpdate) {
                        // handleActivityUpdate(activity,"detail")
                    }
                }}>
                    <div className={`col-9`}>
                        {tripType != 'solo' ?
                            <div className="d-flex ms-2">
                                <div className={`symbol-group symbol-hover`}>
                                    {activity && activity.members ? filter(activity.members, (member) => {
                                        return member.amount != "" ? true : false
                                    }).slice(0, 3).map(function (member, key) {

                                        return (
                                            <OverlayTrigger placement="top" overlay={
                                                <Tooltip id={`tooltip-${key}`} className={`card-members-tooltip`}>
                                                    <div className="text-start">
                                                        <div
                                                            className="fs-7 fw-bolder">{member.user?.firstName} {member.user?.lastName}</div>
                                                        <div className=" me-2 fs-7"><i
                                                            className='bx bx-money'></i> {member.amount} {activeCurrency?.code}
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            }>
                                                <div className="symbol symbol-25px symbol-circle cursor-pointer" key={key}>
                                                    {member.user?.cover?
                                                        <img alt="Pic" className={`avatar`} src={getImgUrl(member.user?.cover?.generatedName,member.user._id,'50x')}  />
                                                        :
                                                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder avatar">{member.user?._id?(member.user?.firstName).charAt(0): ""}</span>
                                                    }

                                                </div>
                                            </OverlayTrigger>
                                        )

                                    }): ""}
                                </div>

                            </div>
                            :""
                        }

                    </div>
                    <div className={`col-3`}>
                        <h3 className="fs-4 fw-bolder primary-color text-end">{activity.budget} {activeCurrency?.code}</h3>

                    </div>


                </div>



            </div>

        </div>


    );
};
export default FlightCard;
