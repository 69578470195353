import React, {FC, useEffect, useState} from 'react';
import './trip.scss'
import DashboardMenuItem from "./cards/DashboardMenuItem";


import PlanningImg from '../../images/illustration-icons/planning.svg';
import ActivitiesImg from '../../images/illustration-icons/activities.svg';
import BudgetImg from '../../images/illustration-icons/budget.svg';
import OverviewImg from '../../images/illustration-icons/overview.svg';
import NoteImg from '../../images/illustration-icons/note.svg';
import ChecklistImg from '../../images/illustration-icons/checklist.svg';
import DocumentsImg from '../../images/illustration-icons/documents.svg';
import {NavLink} from "react-router-dom";
import HomeIconImg from "../../images/icons/sidebar/home.svg";
import TripsIconImg from "../../images/icons/sidebar/trip.svg";
import AddTripIconImg from "../../images/icons/sidebar/add.svg";
import MessageIconImg from "../../images/icons/sidebar/message.svg";
import ProfileIconImg from "../../images/icons/sidebar/account.svg";



const dashboardMenuItems = [
    {
        title:"Overview",
        subTitle:"Summary of the trip's essential details.",
        icon:OverviewImg,
        id:1
    },{
        title:"Checklist",
        subTitle:"Key items ensure stress-free travel.",
        icon:ChecklistImg,
        id:4
    },{
        title:"Note",
        subTitle:"Essential tips for your journey.",
        icon:NoteImg,
        id:4
    },{
        title:"Documents",
        subTitle:"Essential travel documents and IDs.",
        icon:DocumentsImg,
        id:5
    }
]
const destinationMenuItems = [
    {
        title:"Planning",
        subTitle:"Plan Flights, Hotels, Taxi and more",
        icon: PlanningImg,
        id:2
    },{
        title:"Activities",
        subTitle:"Shopping, Trekking and other fun activities ",
        icon: ActivitiesImg,
        id:3
    },{
        title:"Expenses",
        subTitle:"Invoices, documents and other files",
        icon:BudgetImg,
        id:6
    }
]

type Props = {
    activeTab:string,
    updateTab:any,
    activeDashboard:string
}

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const TripMenu: FC <Props> = ({updateTab,activeTab,activeDashboard} ) => {

    const { width } = useViewport();
    const breakpoint = 620;

    const updateActiveTab = (tab: any) => {

        updateTab(tab)

    }

    let menuItems = activeDashboard == "trip"? dashboardMenuItems : destinationMenuItems;



    if(width < breakpoint ){
        return (
            <nav className="navbar fixed-bottom d-flex justify-content-center bottombar bottombar-responsive" >
                <ul className="d-flex" style={{ listStyle: 'none', padding: '0px'}}>
                    {
                        menuItems.map(function (item, key) {

                            return(

                                <li className={`nav-item ${activeTab == item.title? 'active': "" } `} onClick={function(){
                                    updateActiveTab(item.title)
                                }}>
                                    <span className="nav-link">
                                        <img src={item.icon} width={30} className={`hover-opacity-btn`}/>
                                        <span>{item.title}</span>
                                    </span>
                                </li>
                            )

                        })
                    }

                </ul>
            </nav>
        );

    }else{
        return (<div className="card card-stretch mb-3 mb-xxl-8">

                <div className="card-body">
                    <div className="card-body p-0 dashboard-menu ">

                        {
                            menuItems.map(function (item,key) {

                                return(<DashboardMenuItem activeTab={activeTab} title={item.title}  subTitle={item.subTitle} icon={item.icon} key={key} updateActiveTab={updateActiveTab} />)
                            })
                        }

                    </div>
                </div>
            </div>
        );
    }

};
export default TripMenu;
