import React, {FC, ReactElement} from "react";
import { Navigate, Outlet, Route } from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "../rootReducer";
import {setUser} from "../features/account/userSlice";
import {useAppDispatch} from "../store";
import {User, UserResponse} from "../interfaces/user.interface";
import FeatureContainer from "../features/FeatureContainer";
import BusinessFeatureContainer from "../business-features/BusinessFeatureContainer";


function PrivateRoute({ children }:any) {
    let userData = localStorage.getItem("user");

    const { user } = useSelector((state: RootState) => state);
    const dispatch = useAppDispatch();


    if(user == null && userData != null){


        dispatch(setUser(JSON.parse(userData)));
    }


    console.log("user?.data.userType",user?.data.userType)
    let pathname = "/"
    if(userData == null){
       return  <Navigate to={{pathname: '/'}}/>
    }else{


        return <FeatureContainer userData={JSON.parse(userData).data}>{children}</FeatureContainer>
 /*       if(user?.data.userType === "customer"){
            return <FeatureContainer userData={JSON.parse(userData).data}>{children}</FeatureContainer>

        }else{
            return <BusinessFeatureContainer userData={JSON.parse(userData).data}>{children}</BusinessFeatureContainer>

        }*/
    }



}

export default PrivateRoute;


