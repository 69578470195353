import React, {FC, useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {Link, NavLink, useNavigate} from 'react-router-dom';

import {ForgotPassword, OtpVerification, User, UserResponse} from '../../../interfaces/user.interface';
import * as Yup from 'yup';
import http from '../../../services/api';


import '../authentication.scss'
import {authenticate_url, register_url, social_authenticate_url} from "../../../services/api.utils";
import Loader from "../../loader/Loader";
import logo from "../../../images/logo.png";
import appleLogo from "../../../images/login/apple-logo.svg";
import googleLogo from "../../../images/login/google-logo.svg";
import AuthHeader from "../AuthHeader";
import Modal from "react-bootstrap/cjs/Modal";
import MemberItem from "../../trips/cards/MemberItem";
import UserPackageContainer from "../../userPackages/UserPackageContainer";
import {setUser} from "../../account/userSlice";
import {useAppDispatch} from "../../../store";
import BackButtonImgIcon from "../../../images/icons/back-button.svg";
import EmailSentImgIcon from "../../../images/icons/email-sent.svg";
import EyeOpenImgIcon from "../../../images/icons/eye-open.svg";
import EyeCloseImgIcon from "../../../images/icons/eye-close.svg";
import {eSMoment, toastMessage} from "../../../util";



const schema = Yup.object().shape({
    email: Yup.string().required('Email required'),
    password: Yup.string().required('Password required'),
});
const verificationSchema = Yup.object().shape({
    inputOne: Yup.string().required('Required'),
    inputTwo: Yup.string().required('Required'),
    inputThree: Yup.string().required('Required'),
    inputFour: Yup.string().required('Required'),
});
const forgotSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
});
const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const Login: FC = () => {

    const { width } = useViewport();
    const breakpoint = 620;



    const { handleSubmit, register, reset,setFocus, formState: { errors } } = useForm<User>({
        resolver: yupResolver(schema),
    });

    const { handleSubmit:handleSubmitVerification, register:registerVerification, reset:resetVerification,setFocus:setFocusVerification, formState: { errors :errorsVerification} } = useForm<OtpVerification>({
        resolver: yupResolver(verificationSchema),
    });
    const { handleSubmit:handleSubmitForgotPassword, register:registerForgotPassword, reset:resetForgotPassword,setFocus:setFocusForgotPassword, formState: { errors :errorsForgotPassword} } = useForm<ForgotPassword>({
        resolver: yupResolver(forgotSchema),
    });


    const [loading, setLoading] = useState(false);
    const [otpResendLoading, setOtpResendLoading] = useState(false);

    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [otpInput, setOtpInput] = useState({
        inputOne: "",
        inputTwo: "",
        inputThree: "",
        inputFour: ""
    });

    const [userData, setUserData] = useState<User>();
    const [showPackageModel, setShowPackageModel] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [otpLoading, setOtpLoading] = useState(false);
    const [forgotLoading, setForgotLoading] = useState(false);
    const [showForgotModal, setShowForgotModal] = useState(false);
    const [isOtpLogin, setIsOtpLogin] = useState(false);
    const [authError, setAuthError] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        if(!showVerifyModal){

            setFocus("email");
        }

        const user = localStorage.getItem("user");

        if(user){

            let redirect = localStorage.getItem("redirect");


            if(redirect){
                localStorage.removeItem("redirect");

                navigate(redirect);
            }else{
                navigate("/dashboard");
            }
        }

    },[])

    const submitForm = (formData: User) => {


        setLoading(true);
        http
            .post<User, UserResponse>(authenticate_url, formData)
            .then((res) => {
                if (res) {

                    const { data, status, message} = res;

                    if(status == 200 && data.userId){

                        if(data.isEmailVerified){

                            if(data.accessToken){

                                http.defaults.headers.common.Authorization = 'Bearer '+data.accessToken;

                            }
                            setUserData(data)
                            //if(data.userPackage?.packageId){
                                reset();
                                localStorage.setItem('user', JSON.stringify(res));
                                let redirect = localStorage.getItem("redirect");

                                if(redirect){
                                    localStorage.removeItem("redirect");

                                    navigate(redirect);
                                }else{
                                    navigate("/dashboard");
                                }
                          /*  }else{
                                setShowPackageModel(true)
                            }*/

                        }else{
                            setUserData(data)
                            setShowVerifyModal(true)
                        }


                    }else{

                        reset({
                            email:formData.email,
                            password:""
                        });
                        setFocus("password");

                        setAuthError(message)

                    }



                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const googleSignIn = (googleData:any) => {
        setLoading(true);

        let formData = {
            type:"google",
            firstName:googleData.profileObj.givenName,
            lastName:googleData.profileObj.familyName,
            userName:googleData.profileObj.googleId,
            email:googleData.profileObj.email,
            googleId:googleData.googleId,
            googleToken:googleData.accessToken,

        }

        http
            .post<User, UserResponse>(social_authenticate_url, formData)
            .then((res) => {
                if (res) {

                    const { data, status, message} = res;


                    if(status == 200 && data.accessToken && data.userId){
                        localStorage.setItem('user', JSON.stringify(res));

                        http.defaults.headers.common.Authorization = 'Bearer '+data.accessToken;
                        navigate("/dashboard");
                    }else{

                        setAuthError(message)

                    }

                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const submitFormVerification = (otp:OtpVerification) => {

        let data = {
            email:  userData?.email,
            token: otp.inputOne+otp.inputTwo+otp.inputThree+otp.inputFour
        }
        callVerificationAPI(data,"submit")

    };

    const callVerificationAPI = (data:any,type:string) => {
        if(type == "submit"){
            setOtpLoading(true);
        }else{
            setOtpResendLoading(true);
        }
        http
            .post<User, UserResponse>(`${register_url}/verify-token`, data)
            .then((res) => {
                if (res) {
                    const { data,status,message} = res;


                    if(type == "submit"){
                        if(status == 200 && data.userId){

                            if(data.accessToken){

                                http.defaults.headers.common.Authorization = 'Bearer '+data.accessToken;

                            }
                            setUserData(data)
                            //if(data.userPackage?.packageId){
                                reset();
                                localStorage.setItem('user', JSON.stringify(res));
                                let redirect = localStorage.getItem("redirect");

                                if(redirect){
                                    localStorage.removeItem("redirect");

                                    navigate(redirect);
                                }else{
                                    navigate("/dashboard");
                                }
                            /*}else{

                                setShowVerifyModal(false)
                                setShowPackageModel(true)
                            }*/
                        }else{
                            toastMessage('Invalid URL', 'error')
                        }
                    }else{

                        toastMessage('Login URL sent', '')
                    }



                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setOtpLoading(false);
                setOtpResendLoading(false);
            });
    }

    const resendToken = () => {



        let data = {
            _id:  userData?._id,
            firstName:  userData?.firstName,
            lastName:  userData?.lastName,
            email:  userData?.email,
        }


        setOtpResendLoading(true);
        http
            .post<User, UserResponse>(`${register_url}/resend-token`, data)
            .then((res) => {
                if (res) {
                    const { data,status,message} = res;

                        if(status == 200){

                            toastMessage('Resend successful. Please check your inbox', '')
                            setOtpResendLoading(false);
                        }else{
                            toastMessage('Resend failed. Please try again', 'error')
                        }



                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setOtpLoading(false);
                setOtpResendLoading(false);
            });
    }
    const updateUserData = (user: User) => {

        setUserData(user)

    }
    const submitForgotPassword = (data:any) => {


        setIsOtpLogin(true)
        setForgotLoading(true)
        http
            .post<User, UserResponse>(`${register_url}/forgot-password`, data)
            .then((res) => {
                if (res) {
                    const { data,status,message} = res;

                    if(status == 200){


                        setUserData(data)
                        setShowForgotModal(false)
                        setShowVerifyModal(true)

                    }else{


                        toastMessage('Invalid Email', 'error')
                    }



                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setForgotLoading(false);
            });
    }

        return (

        <div className="wrapper">

            <div className="login-section">

                {width > breakpoint ?
                <AuthHeader/>
                    : ""}
                <div className="container login-container">
                    <div className="col-lg-6 col-md-6 login-left-section">
                        <div className="text-center text-center mt-5 pt-5">

                            <h2 className="title">Welcome to ExploreSoul...</h2>
                            <p className="subtitle">Discover new place you will love</p></div>
                    </div>
                    <div className="col-lg-6 col-md-12 login-content-section">
                        <div className="login-content-body">


                            {width < breakpoint ?

                                <div className="text-center login-header-responsive">
                                    <img src={logo} width="50px" />
                                    <h2 className="title">Discover new place
                                        you will love</h2>
                                </div>
                                :

                                <div className="text-center login-header mb-3">
                                    <h2 className="text-center fw-bolder fs-1">Sign in

                                    </h2>
                                        <p className="text-center fs-4 text-muted">Sign in to your account to start using ExploreSoul

                                    </p>
                                </div>
                            }
                            <form onSubmit={handleSubmit(submitForm)} className="login-form">
                                <div className="row g-3">

                                    <div className="col-12">
                                        <label htmlFor="email" className="text-dark fs-6">Email/Username </label>
                                        <input {...register("email")} type="text" className={Object.keys(errors).length === 0? "form-control form-control-lg form-control-solid": errors.email? "form-control form-control-lg form-control-solid is-invalid": "form-control form-control-lg form-control-solid is-valid"}  name="email"
                                               placeholder="you@example.co/username"   />
                                        {errors && errors.email && (
                                            <div className="invalid-feedback show"> {errors.email.message} </div>
                                        )}
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="password" className="text-dark  fs-6">Password </label>
                                        <div className="password-input-container">
                                            <input
                                                {...register("password")}
                                                type={showPassword ? "text" : "password"}
                                                className={`form-control form-control-lg form-control-solid ${Object.keys(errors).length === 0 ? "" : errors.password ? "is-invalid" : "is-valid"}`}
                                                name="password"
                                                placeholder="*****"
                                            />
                                            <button
                                                type="button"
                                                className="toggle-password-visibility"
                                                onClick={togglePasswordVisibility}
                                            >
                                                <img className={`opacity-50`} src={showPassword?EyeCloseImgIcon: EyeOpenImgIcon} width={15}/>
                                            </button>
                                        </div>
                                        {errors && errors.password && (
                                            <div className="invalid-feedback show"> {errors.password.message} </div>
                                        )}
                                        {authError &&(
                                            <div className="primary-color"> {authError} </div>
                                        ) }
                                    </div>
                                    <div className="col-12">
                                        <p className="text-end mb-2 text-dark"> <span className="cursor-pointer hover-color" onClick={() => {
                                            setShowForgotModal(true)
                                        }}>Forgot Password ? </span></p>
                                    </div>

                                </div>
                                <button className="w-100 btn btn-primary btn-lg  shadow-none" disabled={loading?true : false} type="submit">{loading?<Loader cssClass="button-loader"/> :"Login" }</button>

                            </form>
                            <hr className="hr-text" data-content="OR" />

                            <div className="login-bottom-container mt-3">
                              {/*  <div className="social-btn-container d-flex justify-content-center">
                                    <GoogleLogin
                                        clientId='946992813238-2ntnnlo3hmamin3d6bfqmsrc3ml6hk9u.apps.googleusercontent.com'
                                        render={renderProps => (
                                            <button type="button" onClick={renderProps.onClick} disabled={renderProps.disabled}  className="btn btn-primary social-btn google-btn" role="button" >
                                                <img width="20px"
                                                     alt="Google sign-in"
                                                     src={googleLogo}/>
                                                <span>Google</span>
                                            </button>
                                        )}
                                        buttonText="Google Sign in"
                                        onSuccess={socialResponse => {

                                            googleSignIn(socialResponse)
                                        }}
                                        onFailure={failResponse => {

                                        }}
                                        cookiePolicy={'single_host_origin'}
                                    />


                                    <button type="button" className="btn  btn-dark social-btn apple-btn" role="button" >
                                        <img width="20px"
                                             alt="Google sign-in"
                                             src={appleLogo}/>
                                        <span>Apple</span>
                                    </button>

                                </div>*/}


                                <div className="text-center text-capitalize">
                                    <p className="text-dark ">Don't have any account??

                                        <Link className=" px-2 link-secondary fw-bolder cursor-pointer hover-color" to="/register" >
                                            SIGN UP
                                        </Link>
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="text-muted text-center mt-5 pt-5">Copyright {eSMoment().year( )}, ExploreSoul All Rights Reserved.</div>

                    </div>
                </div>
            </div>
            <Modal onHide={()=>{
                setShowVerifyModal(false)
                resetForgotPassword()
            }} keyboard={true} centered show={showVerifyModal}  animation={false}>

                <div className="d-flex text-center header">
                    <div className="p-2 col text-start" >

                        <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>{
                            setShowVerifyModal(false)
                        }}/>
                    </div>
                    <div className="p-2 col fs-4 fw-bolder">
                    </div>
                    <div className="p-2 col text-end save"
                    >
                        <span></span>
                    </div>
                </div>
                <Modal.Body className="pt-0 pb-5 verification-modal">

                    {userData?

                        <>
                            <div className="text-center pt-0 p-2  mt-0 mb-0">
                                <img src={EmailSentImgIcon} className={``} width={70}/>

                                <p className="fs-5 text-dark fw-bolder">Email verification required</p>
                                <p className="text-gray-400">Please check your <span className="fw-bolder">inbox</span> to verify your account</p>

                            </div>
                            <div className="text-center mt-4">
                                <span className="d-block mobile-text text-dark">Don't receive the code?</span><span
                                className="fw-bolder  hover-color cursor-pointer" onClick={() => {

                                if(! otpResendLoading){

                                    resendToken()
                                }
                            }}>{otpResendLoading? "Resending...":"Resend" }</span></div>

                        </>

                    :
                        <div className="text-center pt-0 p-2  mt-0 mb-0">
                            <img src={EmailSentImgIcon} className={``} width={70}/>

                            <p className="fs-5 text-dark fw-bolder">Email sent successfully</p>
                            <p className="text-gray-400">Please check your <span className="fw-bolder">inbox</span> to sign in to your account

                            </p>
                        </div>
                    }



                </Modal.Body>
            </Modal>
            <Modal  backdrop="static" keyboard={false} centered show={showForgotModal}  animation={false}>
                    <form onSubmit={handleSubmitForgotPassword(submitForgotPassword)} className="verification-form">

                        <Modal.Header className={`border-0 pb-0`}>
                            <Modal.Title className={`col`}>
                                <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=> setShowForgotModal(false)}/>
                            </Modal.Title>
                            <h3 className={`text-dark fs-4 fw-bolder col text-center`}>Login with link</h3>
                            <button type="submit" disabled={otpLoading} className="btn btn-transparent hover-color text-dark fw-bolder col  text-end">
                                {forgotLoading? <span className="indicator-label">Loading..</span>: <span className="indicator-label">Send</span>}
                            </button>
                        </Modal.Header>
                        <Modal.Body className="p-10 verification-modal">
                            <div className="text-muted fw-semibold mb-10">Enter your mobile phone number with country code and
                                we will send you a verification code upon request.
                            </div>

                            <div className="my-5">

                                <input type="text"
                                       className="form-control form-control-lg form-control-solid" {...registerForgotPassword("email")}  placeholder="you@example.com" />
                            </div>

                        </Modal.Body>

                    </form>

            </Modal>
            <Modal size="lg"  backdrop="static" keyboard={false} centered show={showPackageModel}  animation={false}>
                <Modal.Body>

                    {userData &&
                        <UserPackageContainer updateUserData={updateUserData}   fromSettings={false} userData={userData} />

                    }

                </Modal.Body>
            </Modal>

        </div>

    );
};
export default Login;
