import React, { FC,useEffect,useState } from 'react';

import '../trips.scss'
import {FamilyMember, UserSearch} from "../../../interfaces/user.interface";

type ItemProps = {
    user: FamilyMember,
    keyValue: any,
    addMember:any,
    removeMember:any,
    isAdded:boolean

}

const FamilyMemberItem: FC <ItemProps> = ({ user,keyValue ,addMember,removeMember, isAdded}) => {


    console.log("user",user)
    return (


        <div className={`d-flex flex-stack py-1 px-2 cursor-pointer search-member-item` } key={keyValue} >
            <div className={`d-flex align-items-center`}>

                <div className="ms-3">

                    <div className="form-check form-check-custom form-check-solid my-3">
                        <input className="form-check-input" type="checkbox" checked={isAdded}  id={` flexCheckDefault${keyValue}`} onChange={function (e){
                            if(!isAdded){
                                addMember(user)
                            }else{
                                removeMember(user)
                            }
                        }} />
                        <label className="form-check-label" htmlFor={` flexCheckDefault${keyValue}`} >
                            <span
                               className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">{user.firstName} {user.lastName}</span>
                        </label>
                    </div>

                </div>
            </div>

        </div>


    );
};
export default FamilyMemberItem;
