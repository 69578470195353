import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersSearchResponse} from "../../interfaces/user.interface";

const searchUsers = createSlice({
    name: 'users',
    initialState: null as UsersSearchResponse | null,
    reducers: {
        setSearchUsers(state, { payload }: PayloadAction<UsersSearchResponse | null>) {
            return state = (payload != null) ? payload : null;
        },
    },
});
export const { setSearchUsers } = searchUsers.actions;
export default searchUsers.reducer;
