import React from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import {Button, Col, Form, Row} from 'react-bootstrap';
import { TourPackage } from '../../../interfaces/tourPackage.interface';

const Itinerary: React.FC = () => {
    const { control, formState: { errors } } = useFormContext<TourPackage>();  // Using TourPackage directly

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'itinerary'  // No need for casting if your useFormContext is typed correctly
    });

    return (
        <div>
            <h2>Itinerary</h2>
            {fields.map((field, index) => (
                <Form.Group key={field.id}>
                    <Row>

                        <Col>
                            <Form.Label>Day {index + 1}</Form.Label>
                            <Controller
                                name={`itinerary.${index}.activities`}
                                control={control}
                                render={({ field }) => <Form.Control as="textarea" {...field} isInvalid={!!errors.itinerary?.[index]?.activities} />}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.itinerary?.[index]?.activities?.message}
                            </Form.Control.Feedback>
                            {field.accommodation !== undefined && (
                                <>
                                    <Form.Label>Accommodation</Form.Label>
                                    <Controller
                                        name={`itinerary.${index}.accommodation`}
                                        control={control}
                                        render={({ field }) => <Form.Control {...field} isInvalid={!!errors.itinerary?.[index]?.accommodation} />}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.itinerary?.[index]?.accommodation?.message}
                                    </Form.Control.Feedback>
                                </>
                            )}
                        </Col>
                        <Col>
                            <Button variant="danger" onClick={() => remove(index)}>Remove Day</Button>

                        </Col>
                    </Row>

                </Form.Group>
            ))}
            <Button variant="primary" onClick={() => append({ day: fields.length + 1, activities: '', accommodation: '' })}>
                Add Day
            </Button>
        </div>
    );
};

export default Itinerary;
