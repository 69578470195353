import React, {FC, useEffect, useState} from 'react';
import './notifications.scss'
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import Loader from "../loader/Loader";
import http from "../../services/api";
import {NotificationsResponse} from "../../interfaces/notification.interface";
import {notifications_url} from "../../services/api.utils";
import {setNotifications} from "./notificationsSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../rootReducer";
import dayjs from "dayjs";
import {useAppDispatch} from "../../store";
import Empty from "../empty/Empty";
import {useNavigate} from "react-router-dom";
import {setAppBasic} from "../../app/appBasicSlice";
import {eSMoment, getImgUrl} from "../../util";


const Notifications: FC = () => {



    const [loading, setLoading] = useState(false);
    const { notifications } = useSelector((state: RootState) => state);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();



    useEffect(() => {



        if(notifications == null){
            getNotificationData()
        }


    }, []);



    const getNotificationData = () => {

        http
            .get<null, NotificationsResponse>(notifications_url)
            .then((res) => {

                    dispatch(setNotifications(res));

                    dispatch(setAppBasic({trip:{
                            hasNotification:res.data.pendingTrips?true:false
                        }}
                    ));

                putNotificationStatus()

            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {

            });
    }

    const putNotificationStatus = () => {

        http
            .put<null, NotificationsResponse>(notifications_url)
            .then((res) => {



            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {

            });
    }


    return (
        <main className="col-md-11 ms-sm-auto col-lg-11 px-md-4 container-main">
            <Header title="Notifications" />
            <div className="chartjs-size-monitor">
                <div className="chartjs-size-monitor-expand">
                    <div className=""></div>
                </div>
                <div className="chartjs-size-monitor-shrink">
                    <div className=""></div>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-xl-10">
                    <div className="card card-stretch mb-5 mb-xxl-8">
                        <div className="card-body pt-0">


                            {loading?
                                <a href="#" className="card card-stretch mb-5 bg-light-info hoverable shadow-none trip-list-select">

                                    <div className="card-body d-flex flex-column pt-5">

                                        <Loader cssClass="normal-loader"/>
                                    </div>
                                </a>
                                :
                                notifications? notifications?.data?.notifications.length? notifications?.data?.notifications?.map(function (notification,key) {

                                    if(notification.tripId){

                                        var startDate = eSMoment(notification.createdAt, );
                                        var endDate = eSMoment();

                                        var dayDiff = endDate.diff(startDate, 'days');

                                        return(
                                            <div className="d-flex flex-stack py-4 notification-item">
                                                <div className="d-flex align-items-center">
                                                    <div className="symbol symbol-45px symbol-circle">
                                                        {notification.fromUserId.cover?
                                                            <img alt="Pic" src={getImgUrl(notification.fromUserId.cover.generatedName,notification.fromUserId?._id,'50x')} />
                                                            :
                                                            <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{(notification.fromUserId.firstName).charAt(0)}</span>
                                                        }
                                                    </div>
                                                    <div className="ms-3">
                                                        {notification.type == "trip-invite"?
                                                            <div className="text-dark description">You have been invited by <span className="fw-bolder text-dark">{notification.fromUserId.firstName} {notification.fromUserId.lastName}</span> to join <span className="fw-bolder cursor-pointer" onClick={() => navigate(`/trips/invited`)}>{notification.tripId.name}</span></div>:
                                                            notification.type == "new-activity"?
                                                                <div className="fw-bold text-dark description">An activity has been added to the trip <span className="fw-bolder cursor-pointer" onClick={() => navigate(`/trip/${notification.tripId._id}`)}>{notification.tripId.name}</span> by <span className="fw-bolder">{notification.fromUserId.firstName} {notification.fromUserId.lastName}</span> </div>
                                                              :  notification.type == "new-planning"?
                                                                <div className="fw-bold text-dark description">An Planning has been added to the trip <span className="fw-bolder cursor-pointer" onClick={() => navigate(`/trip/${notification.tripId._id}`)}>{notification.tripId.name}</span> by <span className="fw-bolder">{notification.fromUserId.firstName} {notification.fromUserId.lastName}</span> </div>
                                                                : notification.type == "ai-suggestions"?
                                                                <div className="fw-bold text-dark description">AI Suggestion generation completed for <span className="fw-bolder cursor-pointer" onClick={() => navigate(`/trip/${notification.tripId._id}`)}>{notification.tripId.name}</span></div>
                                                                :
                                                                <div className="fw-bold text-dark">{notification.description}</div>
                                                        }

                                                        <div className="fw-bold text-gray-400">{dayDiff >= 1? eSMoment(notification.createdAt).format("DD MMMM [at]  LT"): eSMoment(notification.createdAt).fromNow()}</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column align-items-end ms-2">

                                                    {notification.unread?
                                                        <i className="bi bi-circle-fill primary-color fs-10"></i>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }

                                    })
                                    :
                                        <div className="card card-stretch mb-5 bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '80vh'}}>
                                            <div className="card-body d-flex flex-column align-items-center justify-content-center  pt-5" style={{ position: 'relative', top: '-40px'}}>

                                                <div className="text-center p-2 mt-1 mb-0">
                                                    <p className="fs-5 text-dark fw-bolder">No data available</p>
                                                    <p className="text-gray-400">Please check after adding planning or activities
                                                        from <span className="fw-bolder">destination dashboard</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                            :

                                    <div  className="card card-stretch bg-light-info hoverable shadow-none cursor-pointer" style={{ height: '83vh'}}>
                                        <div className="card-body d-flex flex-column pb-5 pt-3 mb-5 justify-content-center">
                                            <Loader cssClass="normal-loader"/>
                                        </div>
                                    </div>

                            }

                        </div>
                    </div>
                </div>

            </div>

        </main>



    );
};
export default Notifications;
