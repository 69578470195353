import React, { FC,useEffect,useState } from 'react';

import '../trips.scss'
import {UserSearch} from "../../../interfaces/user.interface";


import AddUserIconImg from "../../../images/icons/add_user.svg"
import RemoveUserIconImg from "../../../images/icons/remove-user.svg"
import {getImgUrl} from "../../../util";

type ItemProps = {
    user: UserSearch,
    keyValue: any,
    addMember:any,
    removeMember:any,
    isAdded:boolean
    isAdminUser:boolean,
    canManage:boolean

}

const SearchMemberItem: FC <ItemProps> = ({ canManage,user,keyValue ,addMember,removeMember,isAdded,isAdminUser}) => {


    return (


        <div className={`d-flex flex-stack py-1 px-2 cursor-pointer search-member-item` } key={keyValue}>
            <div className="d-flex align-items-center">
                <div className="symbol symbol-45px symbol-circle">
                    {user.cover?.generatedName?
                        <img alt="Pic" src={getImgUrl(user.cover?.generatedName,user._id,'50x')} />
                        :
                        <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{(user.firstName).charAt(0)}</span>
                    }

                </div>
                <div className="ms-3">
                    <a href="#"
                       className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">{user.firstName} {user.lastName}</a>
                    <div className="fw-bold text-gray-400">{user.parentId ? "Family": user.userName}</div>
                </div>
            </div>

            <div className="d-flex flex-column align-items-end ms-2 member-manage">

                {isAdded?
                    <span className="text-muted fs-2 mb-1 cursor-pointer active" onClick={() => {

                        if(removeMember &&  ! isAdminUser){
                            removeMember(user)
                        }
                    }} >
                       <img src={RemoveUserIconImg} width={20} className={`hover-opacity-btn`}/>
                    </span> :
                    <span className="text-muted fs-2 mb-1 cursor-pointer"  onClick={() => {
                        if(addMember){

                            addMember(user)
                        }
                    }} >
                       <img src={AddUserIconImg} width={20} className={`hover-opacity-btn`}/>
                    </span>
                }
            </div>
        </div>


    );
};
export default SearchMemberItem;
