import React, {FC, useEffect, useState} from 'react';
import {
    NavLink, useNavigate, useParams
} from "react-router-dom";

import './header.scss'
import DropdownButton from "react-bootstrap/cjs/DropdownButton";
import Dropdown from "react-bootstrap/cjs/Dropdown";
import dayjs from "dayjs";
import {Trip, TripResponse} from "../../interfaces/trip.interface";
import Loader from "../loader/Loader";
import {useSelector} from "react-redux";
import {RootState} from "../../rootReducer";
import {User} from "../../interfaces/user.interface";
import Modal from "react-bootstrap/cjs/Modal";
import MemberItem from "../trips/cards/MemberItem";
import http from "../../services/api";
import {trip_url} from "../../services/api.utils";
import {showAlert} from "../../util";

import MembersImg from '../../images/icons/members.svg'
import SettingsImg from '../../images/icons/settings.svg'
import SettingsRedImg from '../../images/icons/settings-red.svg'
import BackButtonImgIcon from "../../images/icons/back-button.svg";
import EditIcon from "../../images/icons/edit.svg";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import InviteImg from "../../images/icons/invite.svg";
import {QRCode} from "react-qrcode-logo";
import logoImage from "../../images/logo.png";


type HeaderProps = {
    trip: Trip;
    enableSettingsButton?:boolean,
    activeDashboard?:string,
    updateActiveDashboard?:any
}

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}
const TripDashboardHeader: FC <HeaderProps> = ({ trip,enableSettingsButton,activeDashboard,updateActiveDashboard}) => {

    const navigate = useNavigate();

    const { width } = useViewport();
    const breakpoint = 620;

    const { user } = useSelector((state: RootState) => state);
    const [showMembersModel, setMembersModel] = useState(false);
    const [enableTitleEdit, setEnableTitleEdit] = useState(false);
    const [saveHover, setSaveHover] = useState(false);
    const [cancelHover, setCancelHover] = useState(false);
    const [tripName, setTripName] = useState("");
    const [originalTripName, setOriginalTripName] = useState("");

    const [joinCodeCopyMessage, setJoinCodeCopyMessage] = useState("Click to copy");
    const [joinUrlCopyMessage, setJoinUrlCopyMessage] = useState("Click to copy");
    const [showInvitation, setShowInvitation] = useState(false);


    useEffect(()=>{
        if(trip){
            setTripName(trip.name)
            setOriginalTripName(trip.name)
        }
    },[trip]);



    const updateTripName = () => {


        let data ={
            name: tripName,
            updateType: "basic"
        }


        http
            .put<any, TripResponse>(trip_url+"/"+trip._id, data)
            .then((res) => {

                if(res.status == 200){

                    showAlert("Trip updated...","success")
                    setEnableTitleEdit(false)

                }else{
                    showAlert("Invalid trip name","error")
                    setEnableTitleEdit(false)
                }


            }).catch((error) => {
            console.log(error);
        })
            .finally(() => {

            });


    };

    const { tripId,destinationId } = useParams<{  tripId: string,
        destinationId:string}>()



    return (
        <header className="header" >
            {trip &&  trip._id == tripId?

                width > breakpoint?
                    <div className="d-flex flex-column flex-md-row align-items-center pb-3 pt-3 mb-4 mt-2 ps-3 header-container">


                        <div className="col-4 d-flex ">
                            <div>
                                <h3 className="fs-4 fw-bolder text-dark" >{(tripName).length > 40? (tripName).substring(0,40) + '...' : tripName}

                                </h3>
                                <span className="bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                                        {dayjs(trip.destinations[0].startDate).format('DD MMMM YY')+ " to " +dayjs(trip.destinations[trip.destinations.length -1].endDate).format('DD MMMM YY')}
                                    </span>
                            </div>

                        </div>
                        {activeDashboard?
                            <div className="col-4 text-center toggle-container">
                                <div className="tabs">
                                    <input type="radio" id="radio-1" name="tabs" checked={activeDashboard == "trip"?true:false} onChange={function () {
                                        navigate(`/trip/${tripId}`, { replace: true });
                                        updateActiveDashboard("trip")
                                    }}/>
                                    <label className="tab" htmlFor="radio-1">Dashboard</label>
                                    <input type="radio" id="radio-2" name="tabs" checked={activeDashboard == "destination"?true:false} onChange={function () {
                                        navigate(`/trip/${tripId}`, { replace: true });
                                        updateActiveDashboard("destination")
                                    }}/>
                                    <label className="tab" htmlFor="radio-2">Destinations</label>
                                    <span className="glider"></span>
                                </div>
                            </div> :""
                        }

                        <div className={`${activeDashboard?"col-4 text-end d-flex justify-content-end":"col-8 text-end" }`}>

                            {trip.tripType != "solo"?

                                <OverlayTrigger  placement="bottom" overlay={
                                    <Tooltip id={`tooltip-members`}  className={``}>
                                        Members
                                    </Tooltip>
                                }>

                                    <div className="symbol-group symbol-hover me-3"  onClick={function () {
                                        setMembersModel(true)

                                    }}>
                                        <div className="symbol symbol-35px symbol-circle cursor-pointer">
                                                  <span className={`bg-light-danger symbol-label  text-danger fs-6 fw-bolder text-uppercase` }  style={activeDashboard == "settings"?{boxShadow:'#4c577d36 0px 0px 10px 0px'}:{}}>

                                                      <img src={MembersImg} width={15} className={``}  />
                                                  </span>

                                        </div>

                                    </div>
                                </OverlayTrigger>
                                :""}



                            {
                                trip?.userId._id == user?.data.userId?
                                    <OverlayTrigger  placement="bottom" overlay={
                                        <Tooltip id={`tooltip-settings`} className={``}>
                                            <div className="d-flex align-items-center">
                                                Settings
                                            </div>
                                        </Tooltip>
                                    }>

                                        <div className="symbol-group symbol-hover me-3"  onClick={function () {
                                            navigate(`/trip/${tripId}`, { replace: true });
                                            updateActiveDashboard("settings")
                                            //navigate(`/trip/${trip._id}/settings`)

                                        }}>
                                            <div className="symbol symbol-35px symbol-circle cursor-pointer">
                                                  <span className={`bg-light-danger symbol-label  text-danger fs-6 fw-bolder text-uppercase` }  style={activeDashboard == "settings"?{boxShadow:'#4c577d36 0px 0px 10px 0px'}:{}}>

                                                      <img src={activeDashboard == "settings"?SettingsRedImg: SettingsImg} width={15} className={``}  />
                                                  </span>

                                            </div>

                                        </div>
                                    </OverlayTrigger>

                                    : ""


                            }



                        </div>


                    </div>
                    :
                    <div className="d-flex flex-column flex-md-row align-items-center pb-3 pt-3 mb-4 mt-2 ps-0 header-container">


                        <div className={`container row`}>
                            <div className="col-8  d-flex ">
                                <div>
                                    <div className={`d-flex`}>
                                        <img src={BackButtonImgIcon} width={20} className={`hover-opacity-btn me-3` } onClick={()=>{

                                            navigate(`/trips/active`)

                                        }} />
                                        <div>

                                            <h3 className="header-title text-truncate" style={{ maxWidth:'80vw'}} >{(tripName).length > 40? (tripName).substring(0,40) + '...' : tripName}

                                            </h3>


                                            <span className="bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                                                {dayjs(trip.destinations[0].startDate).format('DD MMMM YY')+ " to " +dayjs(trip.destinations[trip.destinations.length -1].endDate).format('DD MMMM YY')}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className={`${activeDashboard?"col-4 d-flex  justify-content-end ":"col-xl-8 col-8 text-end" }`}>

                                {trip.tripType != "solo"?

                                    <div className="symbol-group symbol-hover me-3"  onClick={function () {
                                        setMembersModel(true)

                                    }}>
                                        <div className="symbol symbol-35px symbol-circle cursor-pointer">
                                                  <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-uppercase">

                                                      <img src={MembersImg} width={15} className={``} />
                                                  </span>

                                        </div>

                                    </div>
                                    :""}



                                {
                                    trip?.userId._id == user?.data.userId?
                                        <div className="symbol-group symbol-hover"  onClick={function () {
                                            navigate(`/trip/${tripId}`, { replace: true });
                                            updateActiveDashboard("settings")
                                            //navigate(`/trip/${trip._id}/settings`)

                                        }}>
                                            <div className="symbol symbol-35px symbol-circle cursor-pointer">
                                                  <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder text-uppercase">

                                                      <img src={SettingsImg} width={15} className={``} />
                                                  </span>

                                            </div>

                                        </div>

                                        : ""


                                }



                            </div>
                        </div>

                        <div className={`row`}>


                            {activeDashboard?
                                <div className="col-xl-4 text-center toggle-container mt-3 mt-xl-0">
                                    <div className="tabs">
                                        <input type="radio" id="radio-1" name="tabs" checked={activeDashboard == "trip"?true:false} onClick={function () {

                                            navigate(`/trip/${tripId}`, { replace: true });

                                            updateActiveDashboard("trip")
                                        }}/>
                                        <label className="tab" htmlFor="radio-1">Dashboard</label>
                                        <input type="radio" id="radio-2" name="tabs" checked={activeDashboard == "destination"?true:false} onClick={function () {
                                            navigate(`/trip/${tripId}`, { replace: true });

                                            updateActiveDashboard("destination")
                                        }}/>
                                        <label className="tab" htmlFor="radio-2">Destinations</label>
                                        <span className="glider"></span>
                                    </div>
                                </div> :""
                            }
                        </div>





                    </div>


                :
                <div className="d-flex flex-column flex-md-row align-items-center pb-3 pt-3 mb-4 mt-2 ps-3 header-container" style={{ opacity: 0.5, pointerEvents: "none" }}>


                    <div className="col-4 d-flex ">
                        <div>
                            <h3 className="header-title"  >Awesome trip name
                            </h3>
                            <span className="bg-transparent text-muted fw-bold p-0 my-1 fs-7">
                                        Some day to another day
                                    </span>
                        </div>

                    </div>
                    {activeDashboard?
                        <div className="col-4 text-center toggle-container">
                            <div className="tabs">
                                <input type="radio" id="radio-1" name="tabs" checked={activeDashboard == "trip"?true:false} onClick={function () {
                                    navigate(`/trip/${tripId}`, { replace: true });
                                    updateActiveDashboard("trip")
                                }}/>
                                <label className="tab" htmlFor="radio-1">Dashboard</label>
                                <input type="radio" id="radio-2" name="tabs" checked={activeDashboard == "destination"?true:false} onClick={function () {
                                    navigate(`/trip/${tripId}`, { replace: true });
                                    updateActiveDashboard("destination")
                                }}/>
                                <label className="tab" htmlFor="radio-2">Destinations</label>
                                <span className="glider"></span>
                            </div>
                        </div> :""
                    }

                    <div className={`${activeDashboard?"col-4 text-end":"col-8 text-end" }`}>

                         <span className={`fw-bolder mt-2 me-2 cursor-pointer hover-color hover-opacity-btn`} onClick={function () {

                             setMembersModel(true)

                         }}> <img src={MembersImg} width={15} className={``}  /> Members</span>

                    </div>


                </div>

         }
            <Modal show={showMembersModel} onHide={()=>{
                setMembersModel(false)
                setShowInvitation(false)
            }} animation={false}>
                <Modal.Header className={`border-0 pb-0`}>


                    <div className="btn btn-icon btn-sm btn-active-light-primary ms-2 p-0" >
                        <img src={BackButtonImgIcon} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>{
                            setMembersModel(false)
                            setShowInvitation(false)
                        }}/>
                    </div>
                    <Modal.Title className={`fw-bolder`}>{showInvitation? `Invite to trip`: `Members`}</Modal.Title>

                    <div className=" p-0" >
                        {trip && trip.userId._id ==  user?.data.userId  ?
                            showInvitation?
                                <img src={MembersImg} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>setShowInvitation(false)}/>
                                :
                                trip.status != 'completed'?
                                    <img src={InviteImg} className={`cursor-pointer hover-opacity-btn`} width={20} onClick={()=>setShowInvitation(true)}/>
                                    : ''

                            :""
                        }

                    </div>

                </Modal.Header>
                <Modal.Body className={`pt-0`}>
                    {showInvitation?
                        <div className=" invitation">
                            <div className="row text-center">
                                <span className="text-muted fw-bold fs-7 my-1">Scan or use code to join</span>
                            </div>
                            <div className="row justify-content-center text-center">
                                <QRCode
                                    value={window.location.origin + "/join/" + trip.invitation.joinCode}
                                    bgColor="#7d7c7c" fgColor="#f5f8fa" logoImage={logoImage}
                                    logoWidth={100} logoHeight={95} logoOpacity={0.2}/>
                                <OverlayTrigger placement="top" overlay={<Tooltip
                                    id="tooltip-code">{joinCodeCopyMessage}</Tooltip>}>

                                    <a className="fs-4 mt-5 fw-bolder text-hover-primary mb-2 cursor-pointer code"
                                       onClick={function () {
                                           navigator.clipboard.writeText(trip.invitation.joinCode)
                                           setJoinCodeCopyMessage("Copied!")
                                           setInterval(() => {
                                               setJoinCodeCopyMessage("Click to copy")
                                           }, 2000)
                                       }}>{trip.invitation.joinCode}</a>

                                </OverlayTrigger>

                                <OverlayTrigger placement="top" overlay={<Tooltip
                                    id="tooltip-dashboard">{joinUrlCopyMessage}</Tooltip>}>

                                    <a className="fs-8 fw-bolder text-hover-primary mb-2 cursor-pointer url"
                                       onClick={function () {
                                           navigator.clipboard.writeText(window.location.origin + "/join/" + trip.invitation.joinCode)
                                           setJoinUrlCopyMessage("Copied!")
                                           setInterval(() => {
                                               setJoinUrlCopyMessage("Click to copy")
                                           }, 2000)
                                       }}
                                    >{window.location.origin + "/join/" + trip.invitation.joinCode}</a>

                                </OverlayTrigger>


                            </div>

                        </div>

                        :
                        trip?.members.map(function (user,key) {
                            return(
                                <MemberItem user={user}key={key} tripType={trip.tripType} keyValue={key}/>
                            )
                        })
                    }

                </Modal.Body>
            </Modal>
        </header>
    );
};

export default TripDashboardHeader;
